
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, Container, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import GlobalStyles from './components/GlobalStyles';
import 'react-perfect-scrollbar/dist/css/styles.css';
//import './mixins/chartjs';
import Amplify, { Auth } from 'aws-amplify';
import theme from './theme';
import routes from './routes';
import awsconfig from './aws-exports';
import { TestRegister } from './serviceWorker'
import { GlobalDebug } from "./RemoveConsole";
import ContractorSignUpWiz from './MainWebsite/ContractorSignUpWiz'

Amplify.configure(awsconfig);

const App = (props) => {

    const Auth = useSelector(state => state.auth);

   
    //useEffect(() => {
    //    (process.env.NODE_ENV === "production" ||
    //        process.env.REACT_APP_ENV === "STAGING") &&
    //        (window.dev && window.dev !== 786) && 
    //        GlobalDebug(false);
    //}, []);

    const routing = useRoutes(routes(Auth));

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                {/*
                <ContractorSignUpWiz />
                */}
                <Container maxWidth="xl" >
                    <Box mt={2}>
                        {routing}
                        </Box>
                </Container>
                
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

TestRegister();

export default App;
