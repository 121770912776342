/*
 * 
 * get urgency id from ServiceActionList.js
 * allow rename Urgency
 * allow Ungency Copy
 * 
 * Colleps box for Steps, Progress, and ESC 
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
    Paper, Grid, Button,
    Backdrop,
    CircularProgress, Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar, FormControlLabel, Switch, TextField
} from '@mui/material';
import {
    removeEmptyStringElements,
    NewPageId,
    FindLatestDate,
    getAutoCompletedata
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';
import MuiAlert from '@mui/material/Alert';
import {
    ContractorBlocks,
    CompanyBlocks, EmailTriggerBlocks,
    CompanyEmailTriggerBlocks, CheckInBlock
} from '../Setup/UrgencyDetailOptions';

var dateToday = new Date();
let currentTime = dateToday.toISOString().replace("Z", "");

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

export default function SimpleCard(props) {
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData); 
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const NowEditing = useSelector(state => state.NowEditing);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        title: "",
        status: "Active",
        statusshow: true,
        TotalProgress: [],
        repeat: 0,
        TotalESC: [],
        ESC: [],
        StepReturn: [],
        Blocks: [],
        Contractor: [],
        UrgencySetupData: [],
        Company: [],
        ManagerApprovalSelection: [],
        StatusMessage: "",
        SaveDataBack: [],
        ESCReturn: [],
        ESCContractor: [],
        ESCCompany: [],
        ProgressReturn: [],
        SMSMobile: "",
        StepReturn: [],
        StepValueArray: [],
        ContractorData: [],  // used to get ESC data and provide to components
        CompanyData: [],     // however I am get that data directly in each component
        CompanyUrgencySetupData: [],
        SpecialInstructions: "",

    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        try {

            console.log("UrgencyDetails.step.1 - 83 - props", props);
            let todaydate = new Date().toLocaleString();
            let title = `${props.Activefield1} - ${todaydate}`;
            let ActiveKey = props.ActiveKey;
            let Activefield1 = props.Activefield1;
            let ActiveCategory = props.ActiveCategory;
            let StepValueArray = [];
            let field2 = "";
            let UpdateExisting = false;
            let UpdateKey = "";
            let UpdatePk1 = "";
            let status = "Active";
            let statusshow = true;
            let UUid = NewPageId("Urgency");
            let id = UUid;


            if (NowEditing === "Contractor") {
                title = `${ContractorData.CompanyName} - ${todaydate}`;
                ActiveKey = ContractorData.CompanyId;
                Activefield1 = ContractorData.CompanyName;
                ActiveCategory = props.CompanyId;
            }

            console.log("UrgencyDetails.title", title)

            setState(prevState => ({
                ...prevState,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                Category: props.Category,
                CustomerType: props.CustomerType,
                ActiveCategory: ActiveCategory,
                Activefield1: Activefield1,
                ActiveKey: ActiveKey,
                title: title,
                UUid: UUid
            }))


            if (props.Data) {

                // MobileNo = formatPhoneNumber(props.Data.mobile);

                if (props.Data.StepValueArray) {
                    StepValueArray = props.Data.StepValueArray;
                }

                if (props.Data.field2) {
                    field2 = props.Data.field2;
                }

                if (props.Data.id) {
                    id = props.Data.id;
                }

                if (props.Data.status) {
                    status = props.Data.status;
                    if (status === "Active") {
                        statusshow = true;
                    } else {
                        statusshow = false;
                    }
                }

                if (props.EditExisting) {
                    UpdateExisting = true;
                    UpdateKey = props.Data.key;
                    UpdatePk1 = props.Data.pk1;
                    title = props.Data.field1;
                }

                setState(prevState => ({
                    ...prevState,
                    SMSMobile: props.Data.mobile,  
                    StepValueArray: StepValueArray,
                    field2: field2,
                    id: id,
                    UpdateExisting: UpdateExisting,
                    UpdateKey: UpdateKey,
                    UpdatePk1: UpdatePk1,
                    title: title,
                    status: status,
                    statusshow: statusshow
                }))
            }

        } catch (err) { console.log("UrgencyDetails.useEffect.err", err) }

    }, [props.Data])

    
    useEffect(() => {

        let BlockProps = {
            CompanyId: state.CompanyId,
            CompanyName: state.CompanyName,
            ContractorId: ContractorData.CompanyId,
            CompanyData: [],
            Priority: state.Priority,
            ContractorName: ContractorData.CompanyName,
            Additem: (e) => Additem(e)
        }

        console.log("UrgencyDetails.BlockProps", BlockProps);

        let Blocks = CompanyBlocks(BlockProps);
        let ShowContractor = false;
        let ShowCompany = true;
        let SMSMobile = props.Data.mobile;

        if (props.Data.Category === "EmailTrigger") {

            //determin Contractor?
            if (UserData.CustomerType === "Contractor") {
                Blocks = EmailTriggerBlocks(BlockProps);
            } else {
                Blocks = CompanyEmailTriggerBlocks(BlockProps);
            }

        } else {

            //determin Contractor?
            if (NowEditing === "Contractor") {
                Blocks = ContractorBlocks(BlockProps);
            }
        }

        if (WebsiteType === "Checkin") {
            Blocks = CheckInBlock(BlockProps);
          //  SMSMobile = "+15625689508";
        }

        setState(prevState => ({
            ...prevState,
           // SMSMobile: SMSMobile,
            ShowContractor: ShowContractor,
            ShowCompany: ShowCompany,
            Blocks: Blocks,
        }))

    }, [state.CompanyId])



    const handleNext = () => {
        console.log("UrgencyDetails.handleNext", activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const ClosingDrawer = (e) => {
        console.log("ClosingDrawer", e)
        SetDeawerOpen(false)
    }

    const SaveData = () => {

        try {

            let ActiveKey = state.ActiveKey;
            let Address = "";
            let Phone = "";

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.field1,
                field2: UserData.field2,
                avatar: UserData.avatar,
            }

            let DB = [
                {
                    sk1: ContractorData.sk1, 
                    pk1: ContractorData.pk1
                },
                {
                    sk1: ContractorData.HQId,
                    pk1: ContractorData.HQId
                }
            ]


            let key = `Data::Online::Lambda::Pref::SMS::${state.SMSMobile}::${state.UUid}`;

           // key = "Data::Online::Lambda::Pref::SMS::undefined::Urgency_3JmIVuKE"
           // Data::Company::Info::2022-11-12T02:50:27.808Z

            if (state.UpdateExisting) {
                key = state.UpdateKey;
                ActiveKey = state.UpdatePk1;
            }

            let TriggerEmailaddress = "";
            let field1 = state.title;
            let field2 = state.field2;

            let DBInput = {
                pk1: ActiveKey,
                sk1: key,
                gsi1Pk2: state.CompanyId,
                gsi1Sk2: key,
                gsi2Pk2: ContractorData.ContractorId,
                gsi2Sk2: key
            }

            if (state.Category === "EmailTrigger") {

                let EmailTriggerobj = state.StepValueArray.find(x => x.KeyId === "CreateEmailTrigger");
                if (EmailTriggerobj && EmailTriggerobj.EmailTrigger) {

                    TriggerEmailaddress = EmailTriggerobj.EmailTrigger;
                    field1 = EmailTriggerobj.title;
                    field2 = TriggerEmailaddress;
                    
                    Address = ContractorData.Address;
                    Phone = ContractorData.phone;

                    key = `EmailTrigger::${TriggerEmailaddress}`;

                    if (state.UpdateExisting) {
                        key = state.UpdateKey;
                        ActiveKey = state.UpdatePk1;
                    }

                    DBInput = {
                        pk1: key,
                        sk1: key,
                        gsi1Pk2: state.CompanyId,
                        gsi1Sk2: key,
                        gsi2Pk2: ContractorData.ContractorId,
                        gsi2Sk2: key
                    }
                }

            }
            //var currentUnixTime = new Date();
            //var currentTime = currentUnixTime.toISOString();

            const FormdataJSON = {
                date: currentTime,
                ManagerApprovalStatus: "Pending",
                EmailTrigger: TriggerEmailaddress,
                HQId: HQData.HQId,
                HQName: HQData.HQName,
                SubDomain: HQData.SubDomain,
                HQLogo: HQData.avatar,
                Address: Address,
                phone: Phone,
                field1: field1,
                field2: field2,
                DB: DB,
                StepValueArray: state.StepValueArray,
                id: state.UUid,
                key: key,
                mobile: state.SMSMobile,
                Category: state.Category,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                ContractorId: ContractorData.ContractorId,
                ContractorName: ContractorData.ContractorName,
                posterId: posterId,
                status: state.status,
                //need to get this from somewhere
                ReplyEmail: "Do-Not-Reply@service-call-cloud.com",
                EndOfQuestions: state.EndOfQuestions,
                AllowServiceMessage: state.AllowServiceMessage,
                DenyServiceMessage: state.DenyServiceMessage,
                NotinSystemMessage: state.NotinSystemMessage,
                NotificationSent: state.NotificationSent,
                ...DBInput
            }

            let dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON));

            let DBInput2 = {
                ...DBInput,
                dataJSON: dataJSON
            }

            console.log("SelectStepBox.DBInput", DBInput2);
            console.log("SelectStepBox.FormdataJSON", FormdataJSON);

            API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput2 })).then(
                (results) => {
                    console.log("createBatch Data ", results);
                    if (props.CloseDrawer) {
                        props.CloseDrawer();
                    }
                }
            );

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const Additem = (data) => {
        setState(prevState => ({
            ...prevState,
            DetailComponent: data,
            ShowDetails: false,
            DetailsProps: {
                Category: state.Category,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
            }
        }))
        SetDeawerOpen(true);
    }



    const SaveSelection = (data) => {

        let StepValueArray1 = [];

        let Indexx = -1;

        console.log("UrgencyDetails.step.5a - 352 - data", data);
        console.log("UrgencyDetails.step.5 - 353 - SaveSelection", state.StepValueArray);

        if (state.StepValueArray && state.StepValueArray.length > 0) {
            Indexx = state.StepValueArray.findIndex(x => x.KeyId === data.KeyId);
            StepValueArray1 = state.StepValueArray;
            //  console.log("UrgencyDetails.SelectStepBox.Update1a", state.StepValueArray);
        }

        //   console.log("UrgencyDetails.SelectStepBox.Update2", StepValueArray1);

        if (Indexx > -1) {

            StepValueArray1[Indexx] = data;

        } else {

            if (data && data.id && data.id !== "") {
                StepValueArray1[data.id] = data;
            } else {
                StepValueArray1.push(data);
            }

        }

        console.log("UrgencyDetails.step.6 - 377 - SaveSelection", StepValueArray1);

        setState(prevState => ({
            ...prevState,
            StepValueArray: StepValueArray1
        }))
    }


    const SelectedValues = (item) => {
        console.log("UrgencyDetails.item", item);
        let valueee = "Active";
        if (item) {
            valueee = "Active";
        } else {
            valueee = "Disabled";
        }

        setState({
            ...state,
            status: valueee,
            statusshow: item,
            StatusMessage: "Please go through all the steps and save the policy at the end to enable or disable the policy"
        });
    };

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    console.log("UrgencyDetails.step.7 - 427 - state", state);

    return state.Blocks.length > 0 && (
        <Paper id={state.id} key={state.UUid} sx={{ p: 1 }}>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <FormControlLabel control={
                        <Switch
                            color="primary"
                            checked={state.statusshow}
                            // defaultChecked={true}//{state.status === "Active" ? true : false}
                            onChange={(event) => SelectedValues(event.target.checked)}
                        />
                    } label={`Policy ${state.status}`} />
                </Grid>

           

                <Grid item xs={12}>

                    <TextField
                        label="Policy title"
                        name="title"
                        value={state.title || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "title")}
                    />

                </Grid>


                <Grid item xs={12}>
                    <Typography>{state.StatusMessage}</Typography>
                </Grid>

                <Grid item xs={12}>

                    <Stepper activeStep={activeStep} orientation="vertical">

                        {state.Blocks.map((block, index) => {

                            return (

                                <Step key={index}>
                                    <StepLabel>{block.title}</StepLabel>
                                    <StepContent>

                                        <Grid container spacing={2}>

                                            <Grid item xs={12}>
                                                <Typography >{block.message}</Typography>
                                            </Grid>

                                            {block.ShowAdd && (
                                                <Grid item xs={12}>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => block.Additem(block.DetailComponent)}
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            )}

                                            <Grid item xs={12}>
                                                <RenderModule
                                                    LoadComponenet={block.LoadComponenet}
                                                    StepValueArray={state.StepValueArray}
                                                    SaveDataBack={state.SaveDataBack[index]}
                                                    SelectedDataReturn={(data) => SaveSelection(data)}
                                                    id={index}
                                                    activeStep={activeStep}
                                                    handleBack={() => handleBack()}
                                                    handleNext={() => handleNext(index)}
                                                    KeyId={block.id}
                                                    key={index + block.id}
                                                    {...block.propss}
                                                />
                                            </Grid>

                                        </Grid>
                                    </StepContent>
                                </Step>

                            )
                        })}




                        <Step key={100}>
                            <StepLabel>Save Details</StepLabel>
                            <StepContent>

                                <div
                                //className={classes.actionsContainer}
                                >
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        //  className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => SaveData()}
                                        //  className={classes.button}
                                        >
                                            Save Details
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>

                    </Stepper>
                </Grid>

            </Grid>

            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle="change this"
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: (e) => ClosingDrawer(e),
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />

        </Paper>
    );
}
