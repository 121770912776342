
export const Blockss = [
{
  pk1: "Data::Company::Info::2022-11-12T02:50:27.808Z",
  sk1: "Data::Company::Resource::Person::customermanager@test.com",
  dataJSON: {
      pk1: "Data::Company::Info::2022-11-12T02:50:27.808Z",
      sk1: "Data::Company::Resource::Person::customermanager@test.com",
      AllItemForms: [
            {
              sk1: "Data::Company::Resource::Person::customermanager@test.com",
              Category: "Person",
              Documents: [
                ],
              field1: "Person Infomation",
              FormData: [
                    {
                      Category: "Person",
                      defaultValue: "Customer Manager",
                      Editable: false,
                      extra: "field1",
                      key: "ff_tZLASV8h10",
                      label: "Name",
                      ListInfo: true,
                      options: [
                        ],
                      Order: 1,
                      required: true,
                      Searchable: true,
                      ShowValue: "Customer Manager",
                      TableColumn: true,
                      type: "textline",
                      VariableInput: "Customer Manager",
                      VariableName: "name"
                    },
                    {
                      Category: "Person",
                      defaultValue: "customermanager@test.com",
                      Editable: false,
                      extra: "field2",
                      key: "ff_tZLASV8h11",
                      label: "Email",
                      ListInfo: true,
                      options: [
                        ],
                      Order: 1,
                      required: true,
                      Searchable: true,
                      ShowValue: "customermanager@test.com",
                      TableColumn: true,
                      type: "email",
                      VariableInput: "customermanager@test.com",
                      VariableName: "email"
                    },
                    {
                      Category: "Person",
                      defaultValue: "America/Los_Angeles",
                      Details: {
                          altName: "America/Los_Angeles",
                          label: "America/Los_Angeles",
                          offset: -8,
                          value: "America/Los_Angeles"
                        },
                      Editable: false,
                      key: "ff_tZLASV8h12",
                      label: "Time Zone",
                      options: [
                        ],
                      Order: 1,
                      required: true,
                      Searchable: true,
                      ShowValue: "America/Los_Angeles",
                      TableColumn: true,
                      type: "TimeZone",
                      VariableInput: "America/Los_Angeles",
                      VariableName: "TimeZone"
                    },
                    {
                      Category: "Person",
                      defaultValue: "+13222233292",
                      Editable: true,
                      key: "ff_tZLASV8h13",
                      label: "Mobile/SMS",
                      ListInfo: true,
                      options: [
                        ],
                      Order: 3,
                      required: true,
                      ShowValue: "+13222233292",
                      TableColumn: false,
                      type: "phone",
                      VariableInput: "+13222233292",
                      VariableName: "mobile"
                    },
                    {
                      Category: "Person",
                      Editable: true,
                      key: "ff_tZLASV8h14",
                      label: "Work Phone",
                      ListInfo: true,
                      options: [
                        ],
                      Order: 3,
                      required: false,
                      Searchable: true,
                      TableColumn: false,
                      type: "phone",
                      VariableName: "phone"
                    }
                ],
              FormName: "Person Infomation",
              FormType: "Person",
              key: "Data::Company::Resource::Person::customermanager@test.com",
              PhotoService: [
                ],
              SearchWords: "x",
              ValueAdded: true
            },
            {
              Category: "AuthorityLevel",
              Documents: [
                ],
              field1: "Authority Level",
              FormData: [
                    {
                      Category: "AuthorityLevel",
                      defaultValue: true,
                      Editable: true,
                      key: "ff_tZLASV8h15",
                      label: "Allow SMS Service",
                      options: [
                        ],
                      Order: 2,
                      required: false,
                      Searchable: true,
                      TableColumn: false,
                      type: "AllowSMS",
                      VariableInput: true,
                      VariableName: "AllowSMSService"
                    },
                    {
                      Category: "AuthorityLevel",
                      defaultValue: 300,
                      Details: {
                          label: "Manager",
                          value: 300
                        },
                      Editable: true,
                      key: "ff_tZLASV8h16",
                      label: "Authority Level",
                      options: [
                        ],
                      Order: 2,
                      required: false,
                      Searchable: true,
                      ShowValue: "Manager",
                      TableColumn: false,
                      type: "Priviliges",
                      VariableInput: 300,
                      VariableName: "AuthorityLevel"
                    },
                    {
                      Category: "AuthorityLevel",
                      defaultValue: true,
                      Editable: true,
                      key: "ff_tZLASV8h17",
                      label: "Status",
                      options: [
                        ],
                      Order: 2,
                      required: false,
                      Searchable: true,
                      TableColumn: false,
                      type: "switch",
                      VariableInput: true,
                      VariableName: "status"
                    }
                ],
              FormName: "Authority Level",
              FormType: "AuthorityLevel",
              key: "Form::AuthorityLevel::ff_tZLASV8h::1",
              PhotoService: [
                ],
              SearchWords: "x",
              ValueAdded: true
            },
            {
              Category: "Invite",
              Documents: [
                ],
              field1: "Invite Person to setup Account",
              FormData: [
                    {
                      Category: "Invite",
                      DataSourse: "options",
                      defaultValue: "no",
                      extra: "Invite",
                      key: "ff_tZLASV8h86",
                      label: "Invite Person",
                      options: [
                            {
                              key: "email",
                              label: "Send Email to Invite Person",
                              value: "email"
                            },
                            {
                              key: "SMS",
                              label: "Send text/SMS message to Invite Person",
                              value: "SMS"
                            },
                            {
                              key: "no",
                              label: "No Invitation",
                              value: "no"
                            }
                        ],
                      Order: 20,
                      required: false,
                      Searchable: true,
                      ShowValue: "no",
                      type: "radio",
                      VariableInput: "no",
                      VariableName: "Invite"
                    },
                    {
                      Category: "Invite",
                      Editable: true,
                      extra: "Invite",
                      key: "ff_tZLASV8h87",
                      label: "Invitation Message",
                      options: [
                        ],
                      Order: 1,
                      required: false,
                      TableColumn: false,
                      type: "textbox",
                      VariableInput: "You have been added to the Service Request Notification System. The system allows the customer to request service with simple Text/SMS and track work progress on the mobile device.",
                      VariableName: "message"
                    }
                ],
              FormName: "Invite Person to setup Account",
              FormType: "Invite",
              key: "Form::Invite::ff_tZLASV8h::2",
              PhotoService: [
                ],
              SearchWords: "x",
              ValueAdded: true
            }
        ],
      AllowSMSService: true,
      BranchId: "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
      BranchName: "Main HQ",
      Category: "Person",
      CompanyId: "Data::Company::Info::2022-11-12T02:50:27.808Z",
      CompanyName: "Customer Company",
      ContractorId: "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
      CustomerType: "Company",
      date: "2023-01-13T02:24:02.860Z",
      Documents: [
        ],
      email: "customermanager@test.com",
      field1: "Customer Manager",
      field2: "customermanager@test.com",
      gsi1Pk2: "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
      gsi1Sk2: "phone::+13222233292",
      gsi2Pk2: "Data::Corp::HQ::Info::1668207562084_H8ERBT",
      gsi2Sk2: "email::customermanager@test.com",
      HQId: "Data::Corp::HQ::Info::1668207562084_H8ERBT",
      HQName: "New Test Contractor",
      id: "IA_wKj53Q3d",
      key: "Data::Company::Resource::Person::customermanager@test.com",
      Level: 300,
      mobile: "+15625555555",
      PhotoService: [
        ],
      posterId: {
          pk1: "Data::Corp::HQ::Info::1668207562084_H8ERBT",
          sk1: "Data::Company::Resource::Person::jamesone@test.com",
          avatar: "https://s3.amazonaws.com/popcontrols.service-call-cloud.com/public/1668208898414-Aliwhiteshirt2.jpg",
          email: "jamesone@test.com",
          mobile: "+12232222334"
        },
      Privileges: {
          label: "Manager",
          value: 300
        },
      SearchWords: "Person: Customer Manager, customermanager@test.com, America/Los_Angeles, +13222233292, AuthorityLevel: Manager, Invite: no, ",
      status: "Active",
      smpnumber: "$Testing12",
      TimeZone: {
          altName: "America/Los_Angeles",
          label: "America/Los_Angeles",
          offset: -8,
          value: "America/Los_Angeles"
        }
    },
  gsi1Pk2: "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
  gsi1Sk2: "phone::+13222233292",
  gsi2Pk2: "Data::Corp::HQ::Info::1668207562084_H8ERBT",
  gsi2Sk2: "email::customermanager@test.com",
  updatedAt: "2023-01-13T02:24:02.919Z",
    
    },
    {
        "pk1": "Data::Company::Info::2022-11-12T02:50:27.808Z",
        "sk1": "Data::Company::Resource::Person::customertech@test.com",
        "dataJSON": {
            "pk1": "Data::Company::Info::2022-11-12T02:50:27.808Z",
            "sk1": "Data::Company::Resource::Person::customertech@test.com",
            "AllItemForms": [
                {
                    "sk1": "Data::Company::Resource::Person::customertech@test.com",
                    "Category": "Person",
                    "Documents": [
                    ],
                    "field1": "Person Infomation",
                    "FormData": [
                        {
                            "Category": "Person",
                            "defaultValue": "Customer Tech",
                            "Editable": false,
                            "extra": "field1",
                            "key": "ff_nqZIYXBJ10",
                            "label": "Name",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "Customer Tech",
                            "TableColumn": true,
                            "type": "textline",
                            "VariableInput": "Customer Tech",
                            "VariableName": "name"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "customertech@test.com",
                            "Editable": false,
                            "extra": "field2",
                            "key": "ff_nqZIYXBJ11",
                            "label": "Email",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "customertech@test.com",
                            "TableColumn": true,
                            "type": "email",
                            "VariableInput": "customertech@test.com",
                            "VariableName": "email"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "America/Los_Angeles",
                            "Details": {
                                "altName": "America/Los_Angeles",
                                "label": "America/Los_Angeles",
                                "offset": -8,
                                "value": "America/Los_Angeles"
                            },
                            "Editable": false,
                            "key": "ff_nqZIYXBJ12",
                            "label": "Time Zone",
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "America/Los_Angeles",
                            "TableColumn": true,
                            "type": "TimeZone",
                            "VariableInput": "America/Los_Angeles",
                            "VariableName": "TimeZone"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "+12232222399",
                            "Editable": true,
                            "key": "ff_nqZIYXBJ13",
                            "label": "Mobile/SMS",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": true,
                            "ShowValue": "+12232222399",
                            "TableColumn": false,
                            "type": "phone",
                            "VariableInput": "+12232222399",
                            "VariableName": "mobile"
                        },
                        {
                            "Category": "Person",
                            "Editable": true,
                            "key": "ff_nqZIYXBJ14",
                            "label": "Work Phone",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "phone",
                            "VariableName": "phone"
                        }
                    ],
                    "FormName": "Person Infomation",
                    "FormType": "Person",
                    "key": "Data::Company::Resource::Person::customertech@test.com",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "AuthorityLevel",
                    "Documents": [
                    ],
                    "field1": "Authority Level",
                    "FormData": [
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_nqZIYXBJ15",
                            "label": "Allow SMS Service",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "AllowSMS",
                            "VariableInput": true,
                            "VariableName": "AllowSMSService"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": 100,
                            "Details": {
                                "label": "Field Resource",
                                "value": 100
                            },
                            "Editable": true,
                            "key": "ff_nqZIYXBJ16",
                            "label": "Authority Level",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "Field Resource",
                            "TableColumn": false,
                            "type": "Priviliges",
                            "VariableInput": 100,
                            "VariableName": "AuthorityLevel"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_nqZIYXBJ17",
                            "label": "Status",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "switch",
                            "VariableInput": true,
                            "VariableName": "status"
                        }
                    ],
                    "FormName": "Authority Level",
                    "FormType": "AuthorityLevel",
                    "key": "Form::AuthorityLevel::ff_nqZIYXBJ::1",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "Invite",
                    "Documents": [
                    ],
                    "field1": "Invite Person to setup Account",
                    "FormData": [
                        {
                            "Category": "Invite",
                            "DataSourse": "options",
                            "defaultValue": "no",
                            "extra": "Invite",
                            "key": "ff_nqZIYXBJ86",
                            "label": "Invite Person",
                            "options": [
                                {
                                    "key": "email",
                                    "label": "Send Email to Invite Person",
                                    "value": "email"
                                },
                                {
                                    "key": "SMS",
                                    "label": "Send text/SMS message to Invite Person",
                                    "value": "SMS"
                                },
                                {
                                    "key": "no",
                                    "label": "No Invitation",
                                    "value": "no"
                                }
                            ],
                            "Order": 20,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "no",
                            "type": "radio",
                            "VariableInput": "no",
                            "VariableName": "Invite"
                        },
                        {
                            "Category": "Invite",
                            "Editable": true,
                            "extra": "Invite",
                            "key": "ff_nqZIYXBJ87",
                            "label": "Invitation Message",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "textbox",
                            "VariableInput": "You have been added to the Service Request Notification System. The system allows the customer to request service with simple Text/SMS and track work progress on the mobile device.",
                            "VariableName": "message"
                        }
                    ],
                    "FormName": "Invite Person to setup Account",
                    "FormType": "Invite",
                    "key": "Form::Invite::ff_nqZIYXBJ::2",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                }
            ],
            "AllowSMSService": true,
            "BranchId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "BranchName": "Main HQ",
            "Category": "Person",
            "CompanyId": "Data::Company::Info::2022-11-12T02:50:27.808Z",
            "CompanyName": "Customer Company",
            "ContractorId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CustomerType": "Company",
            "date": "2023-01-13T02:24:42.731Z",
            "Documents": [
            ],
            "email": "customertech@test.com",
            "field1": "Customer Tech",
            "field2": "customertech@test.com",
            "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "gsi1Sk2": "phone::+12232222399",
            "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "gsi2Sk2": "email::customertech@test.com",
            "HQId": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "HQName": "New Test Contractor",
            "id": "IA_3umTNqnz",
            "key": "Data::Company::Resource::Person::customertech@test.com",
            "Level": 100,
            "mobile": "+15625555555",
            "PhotoService": [
            ],
            "posterId": {
                "pk1": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
                "sk1": "Data::Company::Resource::Person::jamesone@test.com",
                "avatar": "https://s3.amazonaws.com/popcontrols.service-call-cloud.com/public/1668208898414-Aliwhiteshirt2.jpg",
                "email": "jamesone@test.com",
                "mobile": "+12232222334"
            },
            "Privileges": {
                "label": "Field Resource",
                "value": 100
            },
            "SearchWords": "Person: Customer Tech, customertech@test.com, America/Los_Angeles, +12232222399, AuthorityLevel: Field Resource, Invite: no, ",
            "status": "Active",
            "smpnumber": "$Testing12",
            "TimeZone": {
                "altName": "America/Los_Angeles",
                "label": "America/Los_Angeles",
                "offset": -8,
                "value": "America/Los_Angeles"
            }
        },
        "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "gsi1Sk2": "phone::+12232222399",
        "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
        "gsi2Sk2": "email::customertech@test.com",
        "updatedAt": "2023-01-13T02:24:42.833Z"
    },
    {
        "pk1": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "sk1": "Data::Company::Resource::Person::managerone@test.com",
        "dataJSON": {
            "pk1": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "sk1": "Data::Company::Resource::Person::managerone@test.com",
            "AllItemForms": [
                {
                    "sk1": "Data::Company::Resource::Person::managerone@test.com",
                    "Category": "Person",
                    "Documents": [
                    ],
                    "field1": "Person Infomation",
                    "FormData": [
                        {
                            "Category": "Person",
                            "defaultValue": "Manager One",
                            "Editable": false,
                            "extra": "field1",
                            "key": "ff_V1rbr7tm10",
                            "label": "Name",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "Manager One",
                            "TableColumn": true,
                            "type": "textline",
                            "VariableInput": "Manager One",
                            "VariableName": "name"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "managerone@test.com",
                            "Editable": false,
                            "extra": "field2",
                            "key": "ff_V1rbr7tm11",
                            "label": "Email",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "managerone@test.com",
                            "TableColumn": true,
                            "type": "email",
                            "VariableInput": "managerone@test.com",
                            "VariableName": "email"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "America/Los_Angeles",
                            "Details": {
                                "altName": "America/Los_Angeles",
                                "label": "America/Los_Angeles",
                                "offset": -8,
                                "value": "America/Los_Angeles"
                            },
                            "Editable": false,
                            "key": "ff_V1rbr7tm12",
                            "label": "Time Zone",
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "America/Los_Angeles",
                            "TableColumn": true,
                            "type": "TimeZone",
                            "VariableInput": "America/Los_Angeles",
                            "VariableName": "TimeZone"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "+13222233333",
                            "Editable": true,
                            "key": "ff_V1rbr7tm13",
                            "label": "Mobile/SMS",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": true,
                            "ShowValue": "+13222233333",
                            "TableColumn": false,
                            "type": "phone",
                            "VariableInput": "+13222233333",
                            "VariableName": "mobile"
                        },
                        {
                            "Category": "Person",
                            "Editable": true,
                            "key": "ff_V1rbr7tm14",
                            "label": "Work Phone",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "phone",
                            "VariableName": "phone"
                        }
                    ],
                    "FormName": "Person Infomation",
                    "FormType": "Person",
                    "key": "Data::Company::Resource::Person::managerone@test.com",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "AuthorityLevel",
                    "Documents": [
                    ],
                    "field1": "Authority Level",
                    "FormData": [
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_V1rbr7tm15",
                            "label": "Allow SMS Service",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "AllowSMS",
                            "VariableInput": true,
                            "VariableName": "AllowSMSService"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": 800,
                            "Details": {
                                "label": "Branch Admin",
                                "value": 800
                            },
                            "Editable": true,
                            "key": "ff_V1rbr7tm16",
                            "label": "Authority Level",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "Branch Admin",
                            "TableColumn": false,
                            "type": "Priviliges",
                            "VariableInput": 800,
                            "VariableName": "AuthorityLevel"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_V1rbr7tm17",
                            "label": "Status",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "switch",
                            "VariableInput": true,
                            "VariableName": "status"
                        }
                    ],
                    "FormName": "Authority Level",
                    "FormType": "AuthorityLevel",
                    "key": "Form::AuthorityLevel::ff_V1rbr7tm::1",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "Invite",
                    "Documents": [
                    ],
                    "field1": "Invite Person to setup Account",
                    "FormData": [
                        {
                            "Category": "Invite",
                            "DataSourse": "options",
                            "defaultValue": "no",
                            "extra": "Invite",
                            "key": "ff_V1rbr7tm86",
                            "label": "Invite Person",
                            "options": [
                                {
                                    "key": "email",
                                    "label": "Send Email to Invite Person",
                                    "value": "email"
                                },
                                {
                                    "key": "SMS",
                                    "label": "Send text/SMS message to Invite Person",
                                    "value": "SMS"
                                },
                                {
                                    "key": "no",
                                    "label": "No Invitation",
                                    "value": "no"
                                }
                            ],
                            "Order": 20,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "no",
                            "type": "radio",
                            "VariableInput": "no",
                            "VariableName": "Invite"
                        },
                        {
                            "Category": "Invite",
                            "Editable": true,
                            "extra": "Invite",
                            "key": "ff_V1rbr7tm87",
                            "label": "Invitation Message",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "textbox",
                            "VariableInput": "You have been added to the Service Request Notification System. The system allows the customer to request service with simple Text/SMS and track work progress on the mobile device.",
                            "VariableName": "message"
                        }
                    ],
                    "FormName": "Invite Person to setup Account",
                    "FormType": "Invite",
                    "key": "Form::Invite::ff_V1rbr7tm::2",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                }
            ],
            "AllowSMSService": true,
            "BranchId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "BranchName": "Main HQ",
            "Category": "Person",
            "CompanyId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CompanyName": "Main HQ",
            "ContractorId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CustomerType": "Contractor",
            "date": "2023-01-13T02:20:02.236Z",
            "Documents": [
            ],
            "email": "managerone@test.com",
            "field1": "Contractor Manager One",
            "field2": "managerone@test.com",
            "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "gsi1Sk2": "phone::+13222233333",
            "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "gsi2Sk2": "email::managerone@test.com",
            "HQId": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "HQName": "New Test Contractor",
            "id": "IA_D6Mf9sW4",
            "key": "Data::Company::Resource::Person::managerone@test.com",
            "Level": 800,
            "mobile": "+15625555555",
            "PhotoService": [
            ],
            "posterId": {
                "pk1": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
                "sk1": "Data::Company::Resource::Person::jamesone@test.com",
                "avatar": "https://s3.amazonaws.com/popcontrols.service-call-cloud.com/public/1668208898414-Aliwhiteshirt2.jpg",
                "email": "jamesone@test.com",
                "mobile": "+12232222334"
            },
            "Privileges": {
                "label": "Branch Admin",
                "value": 800
            },
            "SearchWords": "Person: Manager One, managerone@test.com, America/Los_Angeles, +13222233333, AuthorityLevel: Branch Admin, Invite: no, ",
            "status": "Active",
            "smpnumber": "$Testing12",
            "TimeZone": {
                "altName": "America/Los_Angeles",
                "label": "America/Los_Angeles",
                "offset": -8,
                "value": "America/Los_Angeles"
            }
        },
        "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "gsi1Sk2": "phone::+13222233333",
        "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
        "gsi2Sk2": "email::managerone@test.com",
        "updatedAt": "2023-01-13T02:20:02.402Z"
    },
    {
        "pk1": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "sk1": "Data::Company::Resource::Person::techone@test.com",
        "dataJSON": {
            "pk1": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "sk1": "Data::Company::Resource::Person::techone@test.com",
            "AllItemForms": [
                {
                    "sk1": "Data::Company::Resource::Person::techone@test.com",
                    "Category": "Person",
                    "Documents": [
                    ],
                    "field1": "Person Infomation",
                    "FormData": [
                        {
                            "Category": "Person",
                            "defaultValue": "Tech One",
                            "Editable": false,
                            "extra": "field1",
                            "key": "ff_JBOmaOAt5",
                            "label": "Name",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "Tech One",
                            "TableColumn": true,
                            "type": "textline",
                            "VariableInput": "Tech One",
                            "VariableName": "name"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "techone@test.com",
                            "Editable": false,
                            "extra": "field2",
                            "key": "ff_JBOmaOAt6",
                            "label": "Email",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "techone@test.com",
                            "TableColumn": true,
                            "type": "email",
                            "VariableInput": "techone@test.com",
                            "VariableName": "email"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "America/Los_Angeles",
                            "Details": {
                                "altName": "America/Los_Angeles",
                                "label": "America/Los_Angeles",
                                "offset": -8,
                                "value": "America/Los_Angeles"
                            },
                            "Editable": false,
                            "key": "ff_JBOmaOAt7",
                            "label": "Time Zone",
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "America/Los_Angeles",
                            "TableColumn": true,
                            "type": "TimeZone",
                            "VariableInput": "America/Los_Angeles",
                            "VariableName": "TimeZone"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "+15625731986",
                            "Editable": true,
                            "key": "ff_JBOmaOAt8",
                            "label": "Mobile/SMS",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": true,
                            "ShowValue": "+15625731986",
                            "TableColumn": false,
                            "type": "phone",
                            "VariableInput": "+15625731986",
                            "VariableName": "mobile"
                        },
                        {
                            "Category": "Person",
                            "Editable": true,
                            "key": "ff_JBOmaOAt9",
                            "label": "Work Phone",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "phone",
                            "VariableName": "phone"
                        }
                    ],
                    "FormName": "Person Infomation",
                    "FormType": "Person",
                    "key": "Data::Company::Resource::Person::techone@test.com",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "AuthorityLevel",
                    "Documents": [
                    ],
                    "field1": "Authority Level",
                    "FormData": [
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_JBOmaOAt10",
                            "label": "Allow SMS Service",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "AllowSMS",
                            "VariableInput": true,
                            "VariableName": "AllowSMSService"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": 500,
                            "Details": {
                                "label": "Field Resource",
                                "value": 500
                            },
                            "Editable": true,
                            "key": "ff_JBOmaOAt11",
                            "label": "Authority Level",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "Field Resource",
                            "TableColumn": false,
                            "type": "Priviliges",
                            "VariableInput": 500,
                            "VariableName": "AuthorityLevel"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_JBOmaOAt12",
                            "label": "Status",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "switch",
                            "VariableInput": true,
                            "VariableName": "status"
                        }
                    ],
                    "FormName": "Authority Level",
                    "FormType": "AuthorityLevel",
                    "key": "Form::AuthorityLevel::ff_JBOmaOAt::1",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "Invite",
                    "Documents": [
                    ],
                    "field1": "Invite Person to setup Account",
                    "FormData": [
                        {
                            "Category": "Invite",
                            "DataSourse": "options",
                            "defaultValue": "no",
                            "extra": "Invite",
                            "key": "ff_JBOmaOAt84",
                            "label": "Invite Person",
                            "options": [
                                {
                                    "key": "email",
                                    "label": "Send Email to Invite Person",
                                    "value": "email"
                                },
                                {
                                    "key": "SMS",
                                    "label": "Send text/SMS message to Invite Person",
                                    "value": "SMS"
                                },
                                {
                                    "key": "no",
                                    "label": "No Invitation",
                                    "value": "no"
                                }
                            ],
                            "Order": 20,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "no",
                            "type": "radio",
                            "VariableInput": "no",
                            "VariableName": "Invite"
                        },
                        {
                            "Category": "Invite",
                            "Editable": true,
                            "extra": "Invite",
                            "key": "ff_JBOmaOAt85",
                            "label": "Invitation Message",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "textbox",
                            "VariableInput": "You have been added to the Service Request Notification System. The system allows the customer to request service with simple Text/SMS and track work progress on the mobile device.",
                            "VariableName": "message"
                        }
                    ],
                    "FormName": "Invite Person to setup Account",
                    "FormType": "Invite",
                    "key": "Form::Invite::ff_JBOmaOAt::2",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                }
            ],
            "AllowSMSService": true,
            "AppliesTo": "Company",
            "BranchId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "BranchName": "Main HQ",
            "Category": "Person",
            "CompanyId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CompanyName": "Main HQ",
            "ContractorId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CustomerType": "Contractor",
            "date": "2022-11-12T00:55:00.320Z",
            "Documents": [
            ],
            "email": "techone@test.com",
            "field1": "Contractor Tech One",
            "field2": "techone@test.com",
            "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "gsi1Sk2": "phone::+15625731986",
            "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "gsi2Sk2": "email::techone@test.com",
            "HQId": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "HQName": "New Test Contractor",
            "id": "IA_t8s34jdv",
            "key": "Data::Company::Resource::Person::techone@test.com",
            "Level": 500,
            "LoadComponenet": "ShowDetailsText",
            "mobile": "+15625555555",
            "PhotoService": [
            ],
            "posterId": {
                "pk1": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
                "sk1": "Data::Company::Resource::Person::jamesone@test.com",
                "avatar": "https://s3.amazonaws.com/popcontrols.service-call-cloud.com/public/1668208898414-Aliwhiteshirt2.jpg",
                "email": "jamesone@test.com",
                "mobile": "+12232222334"
            },
            "Privileges": {
                "label": "Field Resource",
                "value": 500
            },
            "SearchWords": "Person: Tech One, techone@test.com, America/Los_Angeles, +15625731986, AuthorityLevel: Field Resource, Invite: no, ",
            "status": "Active",
            "smpnumber": "$Testing12",
            "TimeZone": {
                "altName": "America/Los_Angeles",
                "label": "America/Los_Angeles",
                "offset": -8,
                "value": "America/Los_Angeles"
            },
            "title": "Information"
        },
        "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "gsi1Sk2": "phone::+15625731986",
        "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
        "gsi2Sk2": "email::techone@test.com",
        "updatedAt": "2022-11-12T00:55:00.512Z"
    },
    {
        "pk1": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "sk1": "Data::Company::Resource::Person::techtwo@test.com",
        "dataJSON": {
            "pk1": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "sk1": "Data::Company::Resource::Person::techtwo@test.com",
            "AllItemForms": [
                {
                    "sk1": "Data::Company::Resource::Person::techtwo@test.com",
                    "Category": "Person",
                    "Documents": [
                    ],
                    "field1": "Person Infomation",
                    "FormData": [
                        {
                            "Category": "Person",
                            "defaultValue": "Tech Two",
                            "Editable": false,
                            "extra": "field1",
                            "key": "ff_uuavIDqT10",
                            "label": "Name",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "Tech Two",
                            "TableColumn": true,
                            "type": "textline",
                            "VariableInput": "Tech Two",
                            "VariableName": "name"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "techtwo@test.com",
                            "Editable": false,
                            "extra": "field2",
                            "key": "ff_uuavIDqT11",
                            "label": "Email",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "techtwo@test.com",
                            "TableColumn": true,
                            "type": "email",
                            "VariableInput": "techtwo@test.com",
                            "VariableName": "email"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "America/Los_Angeles",
                            "Details": {
                                "altName": "America/Los_Angeles",
                                "label": "America/Los_Angeles",
                                "offset": -8,
                                "value": "America/Los_Angeles"
                            },
                            "Editable": false,
                            "key": "ff_uuavIDqT12",
                            "label": "Time Zone",
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "America/Los_Angeles",
                            "TableColumn": true,
                            "type": "TimeZone",
                            "VariableInput": "America/Los_Angeles",
                            "VariableName": "TimeZone"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "+12232222334",
                            "Editable": true,
                            "key": "ff_uuavIDqT13",
                            "label": "Mobile/SMS",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": true,
                            "ShowValue": "+12232222334",
                            "TableColumn": false,
                            "type": "phone",
                            "VariableInput": "+12232222334",
                            "VariableName": "mobile"
                        },
                        {
                            "Category": "Person",
                            "Editable": true,
                            "key": "ff_uuavIDqT14",
                            "label": "Work Phone",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "phone",
                            "VariableName": "phone"
                        }
                    ],
                    "FormName": "Person Infomation",
                    "FormType": "Person",
                    "key": "Data::Company::Resource::Person::techtwo@test.com",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "AuthorityLevel",
                    "Documents": [
                    ],
                    "field1": "Authority Level",
                    "FormData": [
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_uuavIDqT15",
                            "label": "Allow SMS Service",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "AllowSMS",
                            "VariableInput": true,
                            "VariableName": "AllowSMSService"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": 500,
                            "Details": {
                                "label": "Field Resource",
                                "value": 500
                            },
                            "Editable": true,
                            "key": "ff_uuavIDqT16",
                            "label": "Authority Level",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "Field Resource",
                            "TableColumn": false,
                            "type": "Priviliges",
                            "VariableInput": 500,
                            "VariableName": "AuthorityLevel"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_uuavIDqT17",
                            "label": "Status",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "switch",
                            "VariableInput": true,
                            "VariableName": "status"
                        }
                    ],
                    "FormName": "Authority Level",
                    "FormType": "AuthorityLevel",
                    "key": "Form::AuthorityLevel::ff_uuavIDqT::1",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "Invite",
                    "Documents": [
                    ],
                    "field1": "Invite Person to setup Account",
                    "FormData": [
                        {
                            "Category": "Invite",
                            "DataSourse": "options",
                            "defaultValue": "no",
                            "extra": "Invite",
                            "key": "ff_uuavIDqT86",
                            "label": "Invite Person",
                            "options": [
                                {
                                    "key": "email",
                                    "label": "Send Email to Invite Person",
                                    "value": "email"
                                },
                                {
                                    "key": "SMS",
                                    "label": "Send text/SMS message to Invite Person",
                                    "value": "SMS"
                                },
                                {
                                    "key": "no",
                                    "label": "No Invitation",
                                    "value": "no"
                                }
                            ],
                            "Order": 20,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "no",
                            "type": "radio",
                            "VariableInput": "no",
                            "VariableName": "Invite"
                        },
                        {
                            "Category": "Invite",
                            "Editable": true,
                            "extra": "Invite",
                            "key": "ff_uuavIDqT87",
                            "label": "Invitation Message",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "textbox",
                            "VariableInput": "You have been added to the Service Request Notification System. The system allows the customer to request service with simple Text/SMS and track work progress on the mobile device.",
                            "VariableName": "message"
                        }
                    ],
                    "FormName": "Invite Person to setup Account",
                    "FormType": "Invite",
                    "key": "Form::Invite::ff_uuavIDqT::2",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                }
            ],
            "AllowSMSService": true,
            "BranchId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "BranchName": "Main HQ",
            "Category": "Person",
            "CompanyId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CompanyName": "Main HQ",
            "ContractorId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CustomerType": "Contractor",
            "date": "2023-01-13T02:16:26.119Z",
            "Documents": [
            ],
            "email": "techtwo@test.com",
            "field1": "Contractor Tech Two",
            "field2": "techtwo@test.com",
            "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "gsi1Sk2": "phone::+12232222334",
            "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "gsi2Sk2": "email::techtwo@test.com",
            "HQId": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "HQName": "New Test Contractor",
            "id": "IA_uFJ8OLQu",
            "key": "Data::Company::Resource::Person::techtwo@test.com",
            "Level": 500,
            "mobile": "+15625555555",
            "PhotoService": [
            ],
            "posterId": {
                "pk1": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
                "sk1": "Data::Company::Resource::Person::jamesone@test.com",
                "avatar": "https://s3.amazonaws.com/popcontrols.service-call-cloud.com/public/1668208898414-Aliwhiteshirt2.jpg",
                "email": "jamesone@test.com",
                "mobile": "+12232222334"
            },
            "Privileges": {
                "label": "Field Resource",
                "value": 500
            },
            "SearchWords": "Person: Tech Two, techtwo@test.com, America/Los_Angeles, +12232222334, AuthorityLevel: Field Resource, Invite: no, ",
            "status": "Active",
            "smpnumber": "$Testing12",
            "TimeZone": {
                "altName": "America/Los_Angeles",
                "label": "America/Los_Angeles",
                "offset": -8,
                "value": "America/Los_Angeles"
            }
        },
        "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "gsi1Sk2": "phone::+12232222334",
        "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
        "gsi2Sk2": "email::techtwo@test.com",
        "updatedAt": "2023-01-13T02:16:26.260Z"
    },
    {
        "pk1": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "sk1": "Data::Company::Resource::Person::salesperson@test.com",
        "dataJSON": {
            "pk1": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "sk1": "Data::Company::Resource::Person::salesperson@test.com",
            "AllItemForms": [
                {
                    "sk1": "Data::Company::Resource::Person::salesperson@test.com",
                    "Category": "Person",
                    "Documents": [
                    ],
                    "field1": "Person Infomation",
                    "FormData": [
                        {
                            "Category": "Person",
                            "defaultValue": "Sales Person",
                            "Editable": false,
                            "extra": "field1",
                            "key": "ff_wU8pG4W610",
                            "label": "Name",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "Sales Person",
                            "TableColumn": true,
                            "type": "textline",
                            "VariableInput": "Sales Person",
                            "VariableName": "name"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "salesperson@test.com",
                            "Editable": false,
                            "extra": "field2",
                            "key": "ff_wU8pG4W611",
                            "label": "Email",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "salesperson@test.com",
                            "TableColumn": true,
                            "type": "email",
                            "VariableInput": "salesperson@test.com",
                            "VariableName": "email"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "America/Los_Angeles",
                            "Details": {
                                "altName": "America/Los_Angeles",
                                "label": "America/Los_Angeles",
                                "offset": -8,
                                "value": "America/Los_Angeles"
                            },
                            "Editable": false,
                            "key": "ff_wU8pG4W612",
                            "label": "Time Zone",
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": "America/Los_Angeles",
                            "TableColumn": true,
                            "type": "TimeZone",
                            "VariableInput": "America/Los_Angeles",
                            "VariableName": "TimeZone"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": "+12232222151",
                            "Editable": true,
                            "key": "ff_wU8pG4W613",
                            "label": "Mobile/SMS",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": true,
                            "ShowValue": "+12232222151",
                            "TableColumn": false,
                            "type": "phone",
                            "VariableInput": "+12232222151",
                            "VariableName": "mobile"
                        },
                        {
                            "Category": "Person",
                            "Editable": true,
                            "key": "ff_wU8pG4W614",
                            "label": "Work Phone",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "phone",
                            "VariableName": "phone"
                        }
                    ],
                    "FormName": "Person Infomation",
                    "FormType": "Person",
                    "key": "Data::Company::Resource::Person::salesperson@test.com",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "AuthorityLevel",
                    "Documents": [
                    ],
                    "field1": "Authority Level",
                    "FormData": [
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_wU8pG4W615",
                            "label": "Allow SMS Service",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "AllowSMS",
                            "VariableInput": true,
                            "VariableName": "AllowSMSService"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": 600,
                            "Details": {
                                "label": "Assistance Manager",
                                "value": 600
                            },
                            "Editable": true,
                            "key": "ff_wU8pG4W616",
                            "label": "Authority Level",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "Assistance Manager",
                            "TableColumn": false,
                            "type": "Priviliges",
                            "VariableInput": 600,
                            "VariableName": "AuthorityLevel"
                        },
                        {
                            "Category": "AuthorityLevel",
                            "defaultValue": true,
                            "Editable": true,
                            "key": "ff_wU8pG4W617",
                            "label": "Status",
                            "options": [
                            ],
                            "Order": 2,
                            "required": false,
                            "Searchable": true,
                            "TableColumn": false,
                            "type": "switch",
                            "VariableInput": true,
                            "VariableName": "status"
                        }
                    ],
                    "FormName": "Authority Level",
                    "FormType": "AuthorityLevel",
                    "key": "Form::AuthorityLevel::ff_wU8pG4W6::1",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },
                {
                    "Category": "Invite",
                    "Documents": [
                    ],
                    "field1": "Invite Person to setup Account",
                    "FormData": [
                        {
                            "Category": "Invite",
                            "DataSourse": "options",
                            "defaultValue": "no",
                            "extra": "Invite",
                            "key": "ff_wU8pG4W686",
                            "label": "Invite Person",
                            "options": [
                                {
                                    "key": "email",
                                    "label": "Send Email to Invite Person",
                                    "value": "email"
                                },
                                {
                                    "key": "SMS",
                                    "label": "Send text/SMS message to Invite Person",
                                    "value": "SMS"
                                },
                                {
                                    "key": "no",
                                    "label": "No Invitation",
                                    "value": "no"
                                }
                            ],
                            "Order": 20,
                            "required": false,
                            "Searchable": true,
                            "ShowValue": "no",
                            "type": "radio",
                            "VariableInput": "no",
                            "VariableName": "Invite"
                        },
                        {
                            "Category": "Invite",
                            "Editable": true,
                            "extra": "Invite",
                            "key": "ff_wU8pG4W687",
                            "label": "Invitation Message",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "textbox",
                            "VariableInput": "You have been added to the Service Request Notification System. The system allows the customer to request service with simple Text/SMS and track work progress on the mobile device.",
                            "VariableName": "message"
                        }
                    ],
                    "FormName": "Invite Person to setup Account",
                    "FormType": "Invite",
                    "key": "Form::Invite::ff_wU8pG4W6::2",
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                }
            ],
            "AllowSMSService": true,
            "BranchId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "BranchName": "Main HQ",
            "Category": "Person",
            "CompanyId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CompanyName": "Main HQ",
            "ContractorId": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "CustomerType": "Contractor",
            "date": "2023-01-13T03:37:52.698Z",
            "Documents": [
            ],
            "email": "salesperson@test.com",
            "field1": "Contractor Sales Person",
            "field2": "salesperson@test.com",
            "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
            "gsi1Sk2": "phone::+12232222151",
            "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "gsi2Sk2": "email::salesperson@test.com",
            "HQId": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
            "HQName": "New Test Contractor",
            "id": "IA_bUV3h3yM",
            "key": "Data::Company::Resource::Person::salesperson@test.com",
            "Level": 600,
            "mobile": "+15625555555",
            "PhotoService": [
            ],
            "posterId": {
                "pk1": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
                "sk1": "Data::Company::Resource::Person::jamesone@test.com",
                "avatar": "https://s3.amazonaws.com/popcontrols.service-call-cloud.com/public/1668208898414-Aliwhiteshirt2.jpg",
                "email": "jamesone@test.com",
                "mobile": "+12232222334"
            },
            "Privileges": {
                "label": "Assistance Manager",
                "value": 600
            },
            "SearchWords": "Person: Sales Person, salesperson@test.com, America/Los_Angeles, +12232222151, AuthorityLevel: Assistance Manager, Invite: no, ",
            "status": "Active",
            "smpnumber": "$Testing12",
            "TimeZone": {
                "altName": "America/Los_Angeles",
                "label": "America/Los_Angeles",
                "offset": -8,
                "value": "America/Los_Angeles"
            }
        },
        "gsi1Pk2": "Data::Corp::Branch::Info::2022-11-11T23:17:51.242Z",
        "gsi1Sk2": "phone::+12232222151",
        "gsi2Pk2": "Data::Corp::HQ::Info::1668207562084_H8ERBT",
        "gsi2Sk2": "email::salesperson@test.com",
        "updatedAt": "2023-01-13T03:37:52.755Z"
    }

    ]
