import React, { useState, useEffect } from 'react';
import {
    NewPageId,
    Pk1SingelItem,
    MiddlePostSearch, ContreactorPostSearch
} from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import {
    Grid,
    Backdrop, Box,
    CircularProgress,
    Card, CardHeader, Button, CardContent
} from '@mui/material';
import ListItems from '../List/ListItems';
import ListModule from '../List/ListModule';
import ShowDetailsText from '../Add/ShowDetailsText';
import { adjustedTime, TimeStampEndofDay, formatDate } from '../utils/DateTime';
import DrawerShow from '../utils/DrawerModule';
import NarrowDatesToolBar from '../components/NarrowDatesToolBar';

const UUid = NewPageId("shwdtl");
let olddate = adjustedTime(-30, "Start");
let Todaydatestring = adjustedTime(1, "End");

export default function SimpleCard(props) {

    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const NowEditing = useSelector(state => state.NowEditing)
    //const [expanded, setExpanded] = React.useState(false);
    //const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    //const UserData = useSelector(state => state.UserData);
    //const cid = useSelector(state => state.cid);

    const initialState = {
        Data: [],
        id: UUid,
        StartDate: olddate,
        EndDate: Todaydatestring,
        ShowEditButton: true,
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("HistoryPerson.props1", props)
               
        GetDatafromNarrow(props.ActiveKey, olddate, Todaydatestring);

        let LocalStart = formatDate(olddate);
        let LocalEnd = formatDate(Todaydatestring);

        setState(prevState => ({
            ...prevState,
            ShowDates: `${LocalStart} to ${LocalEnd}`
        }));


    }, [])

    //useEffect(() => {

    //    //let ShowEditButton = true;

    //    //if (props.ShowEditButton) {
    //    //    ShowEditButton = props.ShowEditButton;
    //    //}
    //    if (props.ShowEditButton != null) {
    //        setState(prevState => ({
    //            ...prevState,
    //            ShowEditButton: props.ShowEditButton,
    //        }));
    //    }

    //}, [props.ShowEditButton])

    //useEffect(() => {

    //    console.log("HistoryPerson.props2", props)

    //    if (props.ActiveKey) {

    //        setState(prevState => ({
    //            ...prevState,
    //            Category: props.ActiveCategory,
    //            AssetId: props.ActiveKey,
    //        }))

    //        if (props.CompanyId && props.ActiveKey) {
    //            GetDatafromDB(props.CompanyId, props.ActiveKey);
    //        }
    //    }

    //}, [props.ActiveKey])

    //const GetDatafromDB = async (pk, sk) => {
    //    SetLoading(true);

    //    let NewData2 = await Pk1SingelItem(pk, sk);
    //    let NewData = await NewData2;
    //    console.log("HistoryPerson.NewData", NewData)

    //    if (NewData) {
    //        let Data = JSON.parse(NewData.dataJSON);
    //        console.log("HistoryPerson.NewData3", Data)
    //        setState(prevState => ({
    //            ...prevState,
    //            Data: Data
    //        }))
    //    }

    //    SetLoading(false);
    //}

    const GetDatafromNarrow = async (AssetId, startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        let UTCdate = new Date(endDate).toISOString();
        let Heading = "Person Call History";
        let PK1 = `Post::${AssetId}`;

        console.log("ShowDetailPkSk.historydata", AssetId, startDate, endDate);
        //console.log("SRPage.endDate.UTC", UTCdate);

        if (NowEditing === "Contractor" || NowEditing === "Sub") {
            PK1 = `Post::ResponseTime::${AssetId}`;
            //PK1 = `Post::ResponseTime::Data::Company::Resource::Person::jamesone@test.com`;
            NewData = await MiddlePostSearch(PK1, startDate, UTCdate);
            Heading = "Person Response History";
        } else {
            NewData = await MiddlePostSearch(PK1, startDate, UTCdate);
        }

        // console.log("SRPage.NewData1", NewData);
        let ShowString = "";
        if (NewData) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);
                AutoSelectData.push(ShowString);
            })
        }

        setState(prevState => ({
            ...prevState,
            AssetData: AutoSelectData,
            Heading: Heading,
            PK1: PK1
            //  FullData: AutoSelectData
        }))

        SetLoading(false);
        console.log("ShowDetailPkSk.NewData2", AutoSelectData);
    }

    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Service History",
            // SetupSubscription: true,
            DetailComponent: "SRDetailsPkSk",
            ServiceData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        SetDeawerOpen(true);
        //}
    }



    const SortPref = (StartDate, EndDate) => {
        console.log("AssetId, startDate, endDate2", state.AssetId, StartDate, EndDate);
        if (StartDate !== state.StartDate || EndDate !== state.EndDate) {

            let NewStartDate = TimeStampEndofDay(StartDate, "Start");
            let NewEndDate = TimeStampEndofDay(EndDate, "End");

            GetDatafromNarrow(props.ActiveKey, NewStartDate, NewEndDate);

            let LocalStart = formatDate(NewStartDate);
            let LocalEnd = formatDate(NewEndDate);

            setState(prevState => ({
                ...prevState,
                StartDate: NewStartDate,
                EndDate: NewEndDate,
                ShowDates: `${LocalStart} to ${LocalEnd}`
            }));
        }

    }

    const CloseDrawer = () => {
        SetDeawerOpen(false)
    }

   

    console.log("HistoryPerson.state", state)

    return (
        <Box mt={2}>
            
                        <Card>
                            <CardHeader
                    title={state.Heading}
                                subheader={state.ShowDates}
                            />
                            <CardContent>
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>
                                        <NarrowDatesToolBar
                                            SortPref={(StartDate, EndDate) => SortPref(StartDate, EndDate)}
                                            StartDate={state.StartDate}
                                            EndDate={state.EndDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ListModule
                                            //  AddBox={(add) => AddBox(add)}
                                            title="History"
                                            ShowDetailLink={state.ShowEditButton}
                                            disabled={false}
                                            DetailLink={(e) => AddBox(e)}
                                            DataBack={state.AssetData}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>


            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                //LoadComponenet="ItemAddPage"
                propss={{
                    Category: state.Category,
                    CloseDrawer: (e) => CloseDrawer(),
                    Data: state.ServiceData
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />

        </Box>
    );
}
