import React, { useEffect, useState } from 'react';
import {    
    Grid, Button,
    FormControl, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';

export default function RadioButtonsGroup(props) {

    const initialState = {
        Delay: "0",
    }
    const [state, setState] = useState(initialState);


    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }
        console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [props.StepValueArray])

    const handleNext = () => {

        let SelectedDataReturn = {
            ...state,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("SaveDataBack.Priority", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const KeyArray = (data, cat) => {

        let newdata = data.target.value;

        setState(prevState => ({
            ...prevState,
            [cat]: newdata
        }));


    }

    console.log("Priority.state", state)
    console.log("Priority.props", props)

    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="Priority"
                        name="radio-buttons-group"
                        onChange={(data) => KeyArray(data, "Delay")}
                        value={state.Delay}
                    >

                        <FormControlLabel value="0" control={<Radio />} label="No Reminder" />
                        <FormControlLabel value="1440" control={<Radio />} label="After 24 Hours" />
                        <FormControlLabel value="2880" control={<Radio />} label="After 2 days" />
                        <FormControlLabel value="4320" control={<Radio />} label="After 3 days" />
                        <FormControlLabel value="10080" control={<Radio />} label="After 1 week" />

                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <div>
                        <Button
                            disabled={props.activeStep === 0}
                            onClick={() => props.handleBack()}
                        // className={classes.button}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleNext()}
                        //  className={classes.button}
                        >
                            {'Next'}
                        </Button>
                    </div>
                </div>
            </Grid>
        </Grid>

    );
}
