import React, { useState, useEffect } from 'react';
//import { createStyles, makeStyles } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import {
  List, ListItem, IconButton,
  ListItemAvatar, Avatar,
  ListItemText, Typography, Divider, Tooltip
} from "@mui/material";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import GoogleGetGPSAuto from '../views/modules/GoogleGetGPSAuto';

//import { TextInput } from "../components/ChatSMSTextInput";
//import { MessageLeft, MessageRight } from "../components/ChatSMSPosition";

import BuildIcon from '@mui/icons-material/Edit';
import DrawerShow from '../utils/DrawerModule';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    padding: theme.spacing(2),
  },
  ReadMessage: {
    backgroundColor: 'white',
  },
  UnReadMessage: {
    backgroundColor: 'yellow',
  },
}));


let Classsss = "";
let PersonIdexist = true;
let EventDataUpdate = [];

// 
export default function ChatSMS(props) {
  const classes = useStyles();
  //const Auth = useSelector(state => state.auth)
  const UserData = useSelector(state => state.UserData);
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const initialState = {
    EventData: [],
    UpdateEvent: [],
      UpdateFlag: false,
      Class: ""
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {

    let UpdateFlag = false;
    let Category = "Message";
    let ShowMessage = true;
    let NewEvent = [];
    let NewEventObj = {};
    let UpdateEvent = [];
    let MessageRead = [];
    let SREvent = props.SREvent;
    let EventData = SREvent.EventData;

    if (EventData && EventData.length > 0) {
      EventData.map((eachMessage, ind) => {

        if (eachMessage.PrivateMessage) {
          if (eachMessage.PrivateMessageList.includes(UserData.key)) {
            ShowMessage = true;
          } else {
            ShowMessage = false;
          }
        }


        if (eachMessage.Category && eachMessage.Category !== "") {
          Category = eachMessage.Category;
        } else {
          Category = "Message";
        }

        if (eachMessage.SenderName === "System") {
          Classsss = classes.ReadMessage;
        } else {
          if (eachMessage.MessageRead) {
            //if userid exist
            if (eachMessage.MessageRead.includes(UserData.key)) {
              Classsss = classes.ReadMessage;
              MessageRead = eachMessage.MessageRead;
            } else {
              Classsss = classes.UnReadMessage;
              MessageRead = [...eachMessage.MessageRead, UserData.key]
              UpdateFlag = true;
            }
          }
         // console.log("EventDataList.PersonIdexist", Classsss)
        }

          NewEventObj = {
              ...eachMessage,
          //  avatar: eachMessage.avatar,
          //SenderName: eachMessage.SenderName,
          //SenderCompany: eachMessage.SenderCompany,
          //TimeStamp: eachMessage.TimeStamp,
          //Body: eachMessage.Body,
          Category: Category,
        //  Class: Classsss,
          key: ind
        }

        UpdateEvent.push({
          ...eachMessage,
          MessageRead: MessageRead
        })

        //Show message if person authorized if private
        if (ShowMessage) {
          NewEvent.push(NewEventObj);
        }
        
      })

      //if (UpdateFlag) {
      //  setState(prevState => ({
      //    ...prevState,
      //    UpdateEvent: UpdateEvent,
      //  }));
      //}
    }

    setState(prevState => ({
      ...prevState,
      EventData: NewEvent,
      UpdateFlag: UpdateFlag,
      UpdateEvent: UpdateEvent,
    }));

    //if (props.UpdateFlag) {
    //  props.UpdateFlag(UpdateFlag);
    //}

    //  if (UpdateFlag && props.UpdateFlag) {
    //  SREvent.EventData = UpdateEvent;
    //      props.UpdateFlag(SREvent);
    //}

  }, [props.SREvent.EventData])

  const handleInclude = data => {
    console.log("EventDataList.databack", data)
  }

  const CloseDrawer = () => {
    SetDeawerOpen(false)
  }

  const AddUpdate = index => {

    SetDeawerOpen(true)
  }

  console.log("EventDataList.props", props)
  console.log("EventDataList.state", state)

  return state.EventData && (
    <>
      <List>
        {state.EventData.map((eachMessage, ind) => {

            return (eachMessage.SenderName === "Question" ? (
            <>
              <ListItem
                key={ind + eachMessage.key}
                alignItems="flex-start"
              >
                <ListItemText
                  primary={eachMessage.SenderName}
                  secondary={<Typography>{eachMessage.Body}</Typography>}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>

          ) : (
              <>
                <ListItem
                  key={ind}
                  //className={eachMessage.Class}
                        >
                            {/* 
                  <ListItemAvatar>
                    {eachMessage.avatar && eachMessage.avatar !== "" ? (
                      <Avatar alt={eachMessage.SenderName} src={eachMessage.avatar} />
                    ) : (
                        <Avatar alt={eachMessage.SenderName}></Avatar>
                      )}

                            </ListItemAvatar>
*/}
                            <ListItemText


                    primary={`${eachMessage.SenderName} - ${eachMessage.SenderCompany}`}
                    secondary={
                        <React.Fragment>


                            
                           
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="primary"
                        >
                          {new Date(eachMessage.TimeStamp).toLocaleString()}
                            </Typography>

                            {eachMessage.Category === "Location"
                                && eachMessage.Location
                                && eachMessage.Location.lat !== "" ? (

                                <GoogleGetGPSAuto
                                    defaultValue={eachMessage.Location}
                                    GPSfound={() => console.log("gps")}
                                />

                            ) : (

                        <Typography>
                          {`Answer: ${eachMessage.Body}`}
                        </Typography>
                            )}
                      </React.Fragment>
                    }
                            />

                        </ListItem>
                        
                <Divider variant="inset" />
              </>
            )
          )
        })
          
        }
      </List>

      {/*     
      <DrawerShow
        LoadComponenet={state.NewLoadComponent}
        CloseDrawer={() => CloseDrawer()}
        DrawerTitle={state.Category}
        //LoadComponenet="ItemAddPage"
        propss={{
          Category: state.Category,
          CloseDrawer: (e) => CloseDrawer(e),
          handleInclude: (e) => handleInclude(e),
          label: state.question,
          title: "Update Information",
          CompanyId: props.CompanyId,
          CompanyName: props.CompanyName,
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
      */}

    </>
  );
}
