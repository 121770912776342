import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
//import moment from 'moment';
import {
    Card, CardContent,
    FormControl, InputLabel, Select, MenuItem,
    Switch,
    Button,
    TextField, Stack,
    Typography,
    Grid,
    CardHeader,
} from '@mui/material';
import SelectAssets from '../components/SelectAssets';
//import Quill from '../components/quill'
import { NewPageId, removeEmptyStringElements } from '../utils/CommonGraphql';
import AutoComplateBox from '../components/AutoComplete';
import Amplify, { API, graphqlOperation } from "aws-amplify";
//import { useDispatch } from 'react-redux';
import Page from '../components/Page';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CloudUpload from '@mui/icons-material/CloudUpload';
//import { currentTime } from '../utils/DateTime';
import UploadMain from '../Upload/UploadMain';
import { createBatch } from '../graphql/mutations';
//import { batchGet } from "../graphql/queries";
import { DocumentType } from '../utils/Documents';
import DrawerShow from '../utils/DrawerModule';
import { SendEmail } from '../utils/SendEmail';

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
});

//const dynamodb = new AWS.DynamoDB.DocumentClient();

const dynamodb2 = new AWS.DynamoDB({
    apiVersion: '2012-08-10'
});

const BatchData = async (FindTeams) => {

    try {

        let marshalled = [];
        FindTeams.map(each => {
            marshalled.push(AWS.DynamoDB.Converter.marshall(each));
        });

        //get team data
        let TeamPerms = {
            RequestItems: {
                "svcbot_db": {
                    Keys: marshalled,
                    ProjectionExpression: "dataJSON"
                }
            }
        };

        var SelectedEscDBData = await GetDBDataBatch(TeamPerms);
        var unmarshalled = [];
        SelectedEscDBData.Responses.svcbot_db.map(each => {
            unmarshalled.push(AWS.DynamoDB.Converter.unmarshall(each));
        });
        // console.log(`unmarshalled:`,line, JSON.stringify(unmarshalled, null, 2));
        return unmarshalled;

    } catch (err) {

    }
};

var GetDBDataBatch = (params) => {
    try {
        // console.log("GetDBDataBatch parmas", JSON.stringify(params, null, 2))
        return new Promise((resolve, reject) => {
            dynamodb2.batchGetItem(params, function (err, data) {
                if (err) console.log("GetDBDataBatch err", err, err.stack); // an error occurred
                else resolve(data); // successful response
            });
        });
    } catch (err) {
        console.log('GetDBDataBatch err', err);
    }
};

var dateToday = new Date();
let currentTime = dateToday.toISOString();

const Profile = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    //const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const [SelectCompany, SetSelectCompany] = useState(false);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [AddPersonal, SetAddPersonal] = useState(false);
    const [Upload, SetUpload] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        Message: "",
        MessageNo: "",
        Personal: [],
        PrivateMessage: false,
        CurrentCompany: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        PhotoService: [],
        Documents: [],
        ImageStateSet: [],
        Upload: false,
        SubscriptionName: "",
        UploadMessage: "",
        DocumentType: "All",
        SubscriptionKey: "",
        SpecialInstructions: "",
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let NewId = NewPageId("Doc");
        let UploadMessage = "";
        let Personal = [];
        let SubscriptionName = "";
        let DocumentType = "All";
        let UpdateSubscription = false;
        let SubscriptionKey = "";
        let Updatepk1 = "";

        
            if (rest.Data) {
                Personal = rest.Data.DistributionList;
                SubscriptionName = rest.Data.field1;
                DocumentType = rest.Data.DocumentType;
                UpdateSubscription = true;
                SubscriptionKey = rest.Data.sk1;
                Updatepk1 = rest.Data.pk1;
                UploadMessage = "and Send Files"
            }
       

            setState(prevState => ({
                ...prevState,
                NewId: NewId,
                Personal: Personal,
                CompanyName: rest.CompanyName,
                CompanyId: rest.CompanyId,
                CustomerType: rest.CustomerType,
                SubscriptionName: SubscriptionName,
                DocumentType: DocumentType,
                UpdateSubscription: UpdateSubscription,
                SubscriptionKey: SubscriptionKey,
                Updatepk1: Updatepk1,
                UploadMessage: UploadMessage,
            }));
        
    }, [rest.CompanyId])

    const AddItem = () => {

        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Add Person",
            // UploadFiles: false,
            DetailComponent: "ItemAddPage",
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "Person",
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        //    GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const GetCompanyName = (data) => {

        setState(prevState => ({
            ...prevState,
            CurrentCompany: data.CompanyName,
            CompanyId: data.CompanyId,
        }));

        SetSelectCompany(false);

    }

    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const ProcessMessage = () => {

        let DistributionList = [];
        let DB = [];
        console.log("SubscriptionAdd.Personal", state.Personal)
        //create a list for email and Private message
        if (state.Personal.length > 0) {
            state.Personal.map(each => {
                // PrivateMessageList.push(each.key)
                DistributionList.push({
                    field1: each.field1,
                    field2: each.field2,
                    email: each.email,
                    mobile: each.mobile,
                    phone: each.phone,
                    pk1: each.pk1,
                    sk1: each.sk1
                })

                DB.push({
                    pk1: each.pk1,
                    sk1: each.sk1
                })

            })
        }
        console.log("SubscriptionAdd.DistributionList", DistributionList)

        SaveData(DistributionList, DB);

        if (state.PhotoService.length > 0 || state.Documents.length > 0) {
            if (DB.length > 0 && !rest.SetupSubscription) {
                GetFreshData(DB);
            }
        }

        //console.log("AddMessage.NewEventData", NewEventData)
    }

    const SaveData = (DistributionList, DB) => {

        try {

            let InputArray = [];

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }

            let Dockey = `Doc::${state.DocumentType}::${currentTime}`;
            let SubscriptionKey = `Doc::Subscription::${state.DocumentType}::${state.NewId}`;

            if (state.SubscriptionKey !== "" && state.SubscriptionKey.includes("Doc::Subscription")) {
                SubscriptionKey = state.SubscriptionKey;
            }

            let AddDocument = {
                pk1: `Doc::${rest.CompanyId}`,  //find docs based on date
                sk1: currentTime,
                gsi1Pk2: rest.CompanyId,
                gsi1Sk2: Dockey,
                gsi2Pk2: ContractorData.ContractorId,
                gsi2Sk2: Dockey
            }

            let Subss = {
                pk1: rest.CompanyId,
                sk1: SubscriptionKey,
                gsi1Pk2: SubscriptionKey,
                gsi1Sk2: rest.CompanyId,
                gsi2Pk2: ContractorData.ContractorId,
                gsi2Sk2: SubscriptionKey
            }

            const FormdataJSON = {
                date: currentTime,
                DistributionList: DistributionList,
                DB: DB,
                field1: state.SubscriptionName,
                field2: `Document Type: ${state.DocumentType}`,
                DocumentType: state.DocumentType,
                id: state.NewId,
                CompanyId: rest.CompanyId,
                CompanyName: rest.CompanyName,
                ContractorId: ContractorData.ContractorId,
                SearchWords: state.SubscriptionName,
                posterId: posterId,
                PhotoService: state.PhotoService,
                key: SubscriptionKey,
                Documents: state.Documents,
                Category: "Subscription",
                ...Subss,
            }

            let dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON));

            let UpdateSubs = {
                ...Subss,
                dataJSON: dataJSON
            }
            InputArray.push(removeEmptyStringElements(UpdateSubs));

            if (state.PhotoService.length > 0 || state.Documents.length > 0) {
                if (!rest.SetupSubscription) {
                    const NewFormdataJSON = {
                        ...FormdataJSON,
                        Category: "Documents",
                        key: Dockey,
                        ...AddDocument
                    }

                    dataJSON = JSON.stringify(removeEmptyStringElements(NewFormdataJSON));

                    let AddDoc = {
                        ...AddDocument,
                        dataJSON: dataJSON
                    }
                    InputArray.push(removeEmptyStringElements(AddDoc));
                }
            }

            console.log("ContactList.InputArray", InputArray);
            console.log("ContactList.FormdataJSON", FormdataJSON);

            if (InputArray.length > 0) {
                API.graphql(graphqlOperation(createBatch, { input: InputArray }))
                    .then((results) => {
                        console.log('createBatch Data ', results);

                        dispatch({ type: 'TRIGGER_UPDATE', payload: true });

                        if (rest.CloseDrawer) {
                            rest.CloseDrawer();
                        }
                    })
            }

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const GetFreshData = async (DB) => {
        try {
            let PersonalList = []

            var SelectedEscDBData2 = await BatchData(DB);

            if (SelectedEscDBData2 && SelectedEscDBData2.length > 0) {
                SelectedEscDBData2.map(each => {
                    // PrivateMessageList.push(each.key)
                    PersonalList.push(each.dataJSON.email)
                })
            }

         //   PersonalList = ["ali_rehan@msn.com"]
            console.log("SubscriptionAdd.batchdata", PersonalList);

            GoToLambda(PersonalList)

        } catch (error) {
            console.log("SubscriptionAdd.batchdata.err", error);
        }
    }

    const GoToLambda = (PersonList) => {

        try {

            let LocalDate = new Date(currentTime).toLocaleString();
            let ContractorSubDomain = `https://${HQData.SubDomain}`;
            //let title = `${state.SubscriptionName} - ${LocalDate}`;

            let SendEvent = {
                
                PhotoService: state.PhotoService,
                Documents: state.Documents,
                SpecialInstructions: state.SpecialInstructions,
                PreSelect: [],
                title: `${state.SubscriptionName} - ${LocalDate}`,
                postHTML: "",
                PersonList: PersonList,
                postTEXT: "",
                ReplyEmail: "no-reply@service-call-cloud.com",
                PostId: state.NewId,

                ContractorAddress: ContractorData.Address,
                ContractorPhone: ContractorData.phone,
                avatar: HQData.avatar,
                ContractorName: ContractorData.CompanyName,

                UnsubscribeLink: `${ContractorSubDomain}/Unsubscribe`,
                ActionButton: `${ContractorSubDomain}`,
                ActionButtonTitle: "Go Online",
                ActionButtonShow: true
            }

            console.log("SubscriptionAdd1.SendEmail", SendEvent)

            SendEmail(SendEvent);
           
           

        } catch (err) { }

    }

    //const AddItem = () => {

    //    setState(prevState => ({
    //        ...prevState,
    //        DetailComponent: "ItemAddPage",
    //    }))

    //    OpenDrawer(true);
    //}

    //console.log("Requestor.ContractorData", ContractorData)
    //console.log("Requestor.UserData", UserData)
    //console.log("Requestor.cid", cid)
    //console.log("Requestor.state", state)
    console.log("AddMessage.rest", rest)
    console.log("AddMessage.state", state)

    return (
        <Page key={state.NewId}>
            <Grid container spacing={2}>

                {rest.SetupSubscription ? (
                    <>
                        <Grid item xs={12} >
                            <TextField
                                label="Subscription Name"
                                name="SubscriptionName"
                                value={state.SubscriptionName || ""}
                                fullWidth
                                variant="outlined"
                                onChange={(e) => SetValues(e.target.value, "SubscriptionName")}
                            />
                        </Grid>
                        
                        <Grid item xs={12} >

                            <FormControl variant="outlined" style={{ width: '100%' }} >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Document Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    //id={`select-${Item.key}-${index}`}
                                  //  defaultValue="All"
                                    error={state.ErrorMessage}
                                    required={true}
                                    disabled={state.disabled}
                                    autoWidth={true}
                                    value={state.DocumentType}
                                    onChange={(e) => {
                                        SetValues(e.target.value, "DocumentType");
                                    }}
                                    label="Document Type"
                                >
                                    {DocumentType.map((o, indd) => (
                                        <MenuItem key={indd + o.key} value={o.key}>
                                            {o.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl >
                        </Grid>
                    </>
                   
                ) : (
                        <>

                            <Grid item xs={12} >
                                <Card>
                                    <CardHeader
                                        title={`Upload Files for ${state.SubscriptionName}`}
                                    />

                                    <CardContent>
                                        <UploadMain
                                            id="PhotoService"
                                            key="PhotoService"
                                            title="Upload Images, PDF, or Zip"
                                            ImageList={(e) => SaveImage("PhotoService", e)}
                                            DocumentList={(e) => SaveImage("Documents", e)}
                                            coid={ContractorData.ContractorId}
                                            ImageStateSet={(e) => SaveImage("ImageStateSet", e)}
                                            ImageStateSetBack={state.ImageStateSet}
                                            fileallowed="any"
                                            cat="PhotoNamePlate"
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    label="Notes / Special Instructions"
                                    name="SpecialInstructions"
                                    value={state.SpecialInstructions || ""}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    variant="outlined"
                                    onChange={(e) => SetValues(e.target.value, "SpecialInstructions")}
                                />
                            </Grid>

                        </>
                )}
                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title="Select personal who will receive files"
                        />

                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <Typography variant="h4">
                                        {state.CurrentCompany}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} >
                                    <Stack
                                        direction={{ xs: 'column', lg: 'row' }}
                                        spacing={2}
                                    >

                                        {UserData.CustomerType === "Contractor" && (

                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => SetSelectCompany(!SelectCompany)}
                                            >
                                                Change Company
                                            </Button>

                                        )}

                                        {UserData.CustomerType === "Contractor" &&
                                            state.CurrentCompanyId !== ContractorData.ContractorId &&
                                            (

                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => GetCompanyName(UserData)}
                                                >
                                                    {`Back to ${UserData.CompanyName}`}
                                                </Button>

                                            )}

                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => AddItem()}
                                            disabled={rest.disabled}
                                        >
                                            {`Add New Person`}
                                        </Button>

                                    </Stack>
                                </Grid>

                                {SelectCompany && UserData.CustomerType === "Contractor" && (
                                    <>
                                        <Grid item xs={12} >
                                            <Typography variant="h4">
                                                Select Company
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <AutoComplateBox
                                                //SaveDetails={rest.SaveDetails}
                                                //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                                Category="Company"
                                                FormType="Company"
                                                title="Company"
                                                // id={Newpageiddd}
                                                ShowEditButton={false}
                                                CompanyId={ContractorData.ContractorId}
                                                ReturnBackData={e => console.log(e)}
                                                PreviewEdit={false}
                                                EditItem={e => console.log("EditItem", e)}
                                                //FormData={each.FormData}
                                                onSubmit={(model) => GetCompanyName(model)}
                                            />
                                        </Grid>
                                    </>
                                )}


                                <Grid item xs={12} >
                                    <Typography variant="h4">
                                        Select Personal
                                    </Typography>
                                </Grid>

                                <Grid xs={12} item>


                                    <SelectAssets
                                        SelectedAsset={(e) => SetValues(e, "Personal")}
                                        CompanyId={state.CompanyId}
                                        CompanyName={state.CurrentCompany}
                                        title="Select Personnel"
                                        Category="Person"
                                        Personal={state.Personal}
                                        AssetList={state.Personal}
                                    // FormData={rest.FormData}
                                    />



                                </Grid>
                            </Grid>

                        </CardContent>


                    </Card>
                </Grid>
                <Grid xs={12} item>
                    <Button color="primary"
                        variant="contained"
                        onClick={() => ProcessMessage()}>
                        {`Save List ${state.UploadMessage}`}
                    </Button>
                </Grid>
            </Grid>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.NewCategory}
                DrawerTitle={state.DrawerTitle}
                propss={{
                    Category: state.NewCategory,
                    CompanyId: rest.CompanyId,
                    CompanyName: rest.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e),
                    // UploadFiles: state.UploadFiles,
                    id: state.DrawerKey,
                    // AddItem: () => AddItem("ItemAddPage", "Person")
                }}
                key={state.DrawerKey}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </Page>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
