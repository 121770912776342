import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    TextField,
    Button, Stepper, Step, StepContent, StepLabel,
    //Grid,
    //Card,
    //CardContent,
    Typography, Snackbar,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ESCContractorStandAlone from '../Setup/ESCContractorStandAlone';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

//key={indd} value={`${o.value}||${o.label}`

let Options = [
    {
        key: 1,
        Category: "Location",
        label: "Location"
    },
    {
        key: 2,
        Category: "Person",
        label: "Person"
    },
    {
        key: 65,
        Category: "ManagerApproval",
        label: "Manager Approval"
    },

    {
        key: 63,
        Category: "quote",
        label: "Quote / Proposal"
    },
    {
        key: 3,
        Category: "Equipment",
        label: "Equipment"
    },
    {
        key: 8,
        Category: "YesNo",
        label: "Yes / No Answer"
    },
    {
        key: 4,
        Category: "PO",
        label: "PO"
    },
    {
        key: 5,
        Category: "Project",
        label: "Project Info"
    },
    {
        key: 6,
        Category: "Warranty",
        label: "Warranty Info"
    },
    {
        key: 7,
        Category: "Additional",
        label: "Additional Info"
    },

]

let CheckInOptions = [
    {
        key: 1,
        Category: "Assets",
        label: "Assets, Equipment, Location, etc"
    },
    {
        key: 2,
        Category: "GPS",
        label: "Request Location"
    },
    //{
    //    key: 3,
    //    Category: "Equipment",
    //    label: "Equipment"
    //},
    {
        key: 8,
        Category: "YesNo",
        label: "Yes / No Answer"
    },
    {
        key: 4,
        Category: "PO",
        label: "PO"
    },
    {
        key: 5,
        Category: "Project",
        label: "Project Info"
    },
    {
        key: 6,
        Category: "Warranty",
        label: "Warranty Info"
    },
    {
        key: 7,
        Category: "Additional",
        label: "Additional Info"
    },

]


export default function SimpleCard(props) {
    const classes = useStyles();
    //const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    //const [expanded, setExpanded] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const initialState = {
        title: "",
        message: "",
        key: "",
        include: false,
        AlarmMessage: "",
        OpenAlert: false,
        Category: "Location",
        Keywords: "",
        field1: "",
        words: "",
        ESC: [],
        Options: Options,
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("SMSAddEdit.props", props)

        let Optionss = Options;

        if (window.WebsiteType && window.WebsiteType === "Checkin") {
            Optionss = CheckInOptions;
        }

        setState(prevState => ({
            ...prevState,
            Options: Optionss
        }));

        if (props && props.step) {

            setState(prevState => ({
                ...prevState,
                ...props.step,
            }));
        }
    }, [])

    const handleClose = (event, reason) => {
        // handle close of Alert

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const Updatedata = () => {

        if (state.field1 !== "") {
            let NewStep = props.step;
            NewStep.field1 = state.field1;
            NewStep.label = state.label;
            NewStep.Category = state.Category;
            NewStep.keywords.words = state.words + ",";
            NewStep.keywords.ESC = state.ESC;
            console.log("SMSAddEdit.NewStep", NewStep);
            if (props.handleInclude) {
                props.handleInclude(NewStep)
            }

            if (props.CloseDrawer) {
                props.CloseDrawer();
            }

        } else {
            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please enter question",
                OpenAlert: true
            }));
        }

    }


    const SelectedValues = (item, name) => {

        setState({
            ...state,
            [name]: item.target.value,
        });

    };

    const ESCReturn = data => {
        console.log("SelectStepBox.ESCReturn", data);
        setState(prevState => ({
            ...prevState,
            ESC: data
        }))
    }

    console.log("SMSAddEdit.state", state);
    return (
        <>

            <Stepper activeStep={activeStep} orientation="vertical">

                <Step key={10}>
                    <StepLabel>SMS Question</StepLabel>
                    <StepContent>

                        <TextField
                            required={true}
                            label="Question"
                            name="field1"
                            value={state.field1 || ""}
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            onChange={(e) => SelectedValues(e, "field1")}
                        />

                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext()}
                                    className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>

                <Step key={0}>
                    <StepLabel>Category</StepLabel>
                    <StepContent>

                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                            <InputLabel id="demo-simple-select-outlined-label">Question Category</InputLabel>
                            <Select
                                //labelId="demo-simple-select-outlined-label"
                                //id={`select-${Item.key}-${index}`}
                                // defaultValue={Item.defaultValue}
                                // error={ErrorMessage}
                                // required={Item.required}
                                //multiple
                                disabled={state.disabled}
                                autoWidth={true}
                                value={state.Category || ""}
                                onChange={(e) => SelectedValues(e, "Category")}
                                label="Question Category"
                            >
                                {state.Options.map((o, indd) => (
                                    <MenuItem key={indd} value={o.Category}>
                                        {o.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl >


                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext()}
                                    className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>

                {/* 
                <Step key={1}>
                    <StepLabel>KeyWords (optional)</StepLabel>
                    <StepContent>
                        <Typography>
                            Seperate each keyword with a comma
                        </Typography>

                        <TextField
                            label="Keywords"
                            name="Keywords"
                            value={state.words || ""}
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            onChange={(e) => SelectedValues(e, "words")}
                        />

                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext()}
                                    className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>

                <Step key={2}>
                    <StepLabel>Notifification if Keywords found (optional)</StepLabel>
                    <StepContent>

                        <ESCContractorStandAlone
                            CompanyId={props.CompanyId}
                            showcustom={false}
                            Defaults={state.ESC}
                            CompanyName={props.CompanyName}
                            SelectedDataReturn={(e) => ESCReturn(e)}
                            showcustom={false}
                        />

                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext()}
                                    className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>
                */}


                <Step key={3}>
                    <StepLabel>Save Data</StepLabel>
                    <StepContent>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => Updatedata()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>
            </Stepper>
            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

        </>
    );
}
