import Chart from 'react-apexcharts';
import React, { useState, useEffect } from "react";
import { Card, Grid,  } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    NewPageId,
} from '../utils/CommonGraphql';

const ProductChannel = (props) => {
    const initialState = {
        NewId: NewPageId("chrt")
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        CreateChartOptions(props.BarData, props.BarNames);

    }, [props.BarData, props.BarNames])

    const CreateChartOptions = (BarData, BarNames) => {

        var options = {
            chart: {
                height: 300,
                width: 300,
                type: "bar",
                stacked: false,
                foreColor: "#333",
                background: 'transparent',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ["#16adff", "#247BA0"],
            series: [
                {
                    name: "Equipment",
                    data: BarData
                },
            ],
            stroke: {
                width: [4, 4]
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            xaxis: {
                categories: BarNames
            },
            title: {
                text: props.title,
                align: "center",
                margin: 5,
                offsetY: 20,
                style: {
                    fontSize: "16px"
                }
            },
        };

        let series = [
            {
                name: "Equipment",
                data: BarData
            },
        ];

        setState(prevState => ({
            ...prevState,
            options: options,
            series: series
        }))

    }

    console.log("Charts.state", state);

    return state.options && (
        <div key={state.NewId}>
        <Card
            variant="outlined"
            sx={{ height: '100%' }}
            
        >
             
            <Grid
                alignItems="center"
                container
                sx={{
                    justifyContent: {
                        xs: 'center'
                    },
                    py: 1
                }}
            >
                <Grid item>
                    <Chart
                            options={state.options}
                            series={state.series}
                        type="bar"
                        width={350}
                    />
                </Grid>

            </Grid>
        </Card>
            
        
        </div>
    );
};

export default ProductChannel;