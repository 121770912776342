/*
 * 
 * get urgency id from ServiceActionList.js
 * allow rename Urgency
 * allow Ungency Copy
 * 
 * Colleps box for Steps, Progress, and ESC 
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch, createSvcbotDb } from '../graphql/mutations';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
    Paper, Grid, Button, Box,
    Backdrop,
    CircularProgress, Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar, FormControlLabel, Switch, TextField
} from '@mui/material';
import {
    removeEmptyStringElements,
    PrimaryKeyDataGet,
    ContractorDataPerCat,
    NewPageId,
    FindLatestDate,
    getAutoCompletedata
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';
import MuiAlert from '@mui/material/Alert';
import SelectAssets from '../components/SelectAssets';
import AccountSetup from '../Setup/AccountSetup';
import { Buffer } from 'buffer';
import { DateTime } from "luxon";
//import { open } from 'node:fs';
//import { ContractorBlocks, CompanyBlocks, EmailTriggerBlocks } from '../Setup/UrgencyDetailOptions';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}



let CheckInQuestions = [
    {
        VariableInput: true,
        include: false,
        field1: 'What is the plan for today?',
        Category: 'plan',
        keywords: [Object],
        defaultValue: true,
        label: 'What is the plan for today?',
        PreSelect: false,
        key: 'plan'
    },
    {
        VariableInput: true,
        include: false,
        field1: 'What time will you be done for today? please make sure to checkout when you are leaving.',
        Category: 'endtime',
        keywords: [Object],
        defaultValue: true,
        label: 'What time will you be done for today? please make sure to checkout when you are leaving.',
        PreSelect: false,
        key: 'endtime'
    },
    {
        VariableInput: true,
        include: false,
        field1: 'Please share your location',
        Category: 'Location',
        keywords: [Object],
        defaultValue: true,
        label: 'Please share your location',
        PreSelect: false,
        key: 'Location'
    }
];

//let AccountSetups = [
//    {
//        VariableInput: true,
//        include: false,
//        field1: 'Account Setup',
//        Category: 'AccountSetup',
//        keywords: "",
//        defaultValue: true,
//        label: 'Account Setup',
//        PreSelect: false,
//        key: 'AccountSetup'
//    },   
//];

var currentUnixTime = new Date();
var currentTime = currentUnixTime.toISOString();

export default function SimpleCard(props) {
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const NowEditing = useSelector(state => state.NowEditing)
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        SMSQuestionsNew: [],
        StepValueArray: [],
        RequestorInfo: false,
        SenderName: "",
        SenderCompany: "",
        Quote: false,
        DoneDone: false
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        try {
            
            console.log("NewCheckIn.props", props);

            GetEachPolicy(props.SREvent)

            setState(prevState => ({
                ...prevState,
                ...props.SREvent,
                SREvent: props.SREvent
                //mobile: mobile,
                //field1: field1,
                //field2: field2,
                //SenderName: SenderName,
                //SenderCompany: SenderCompany,
                //posterId: posterId,
                //RequestorInfo: RequestorInfo
            }))
            // }

        } catch (err) { console.log("UrgencyDetails.useEffect.err", err) }

    }, [])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)               
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    const TriggerLambda = (event) => {
        console.log("NewServiceCall.TriggerLambda", event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }



    const GetEachPolicy = (SREvent) => {

        try {

            let ContractorPolicyFound = "";
            let PolicyId = "";
            let FindPolicyraw = {};
            let CompanyPolicyFound = "";
            let PersonPolicyFound = "";
            let FinalPolicy = "";
            let SMSQuestions = SREvent.SMSQuestions;
            let SMSQuestionsNew = [];
            let LoadComponenet = "";
            let SenderCompany = state.CompanyName;
            // let SenderName = UserData.field1;
            let From = UserData.email;
            let PolicyFlag = true;
            let newSMSQuestions = [];

            let Location = [{
                SenderCompany: SREvent.posterId.CompanyName,
                SenderName: SREvent.posterId.field1,
                LoadComponenet: "LocationBox",
                Category: "Location",
                PhotoService: [],
                label: "Please confirm your current location and click on Next",
                field1: "Location",
                Documents: [],
                TimeStamp: currentTime,
                SenderCompanyType: "Company",
                To: SREvent.ContractorSMS,
                Body: "",
                key: "Location",
                id: "Location"
            }];

            let Document = [{
                Category: "Documents",
                field1: "Documents/Reports/Pictures/Drawings",
                label: "Documents",
                To: SREvent.ContractorSMS,
                id: "Document",
                key: "Document"
            }];

            let Assets = [{
                Category: "Assets",
                field1: "Please identify the Assets (Equipment, Location, etc) involved in this visit",
                label: "Assets (Equipment, Location, etc)",
                id: "Assets",
                key: "Assets"
            }];

            switch (props.Direction) {
                case "Location":
                    let Locationss = SMSQuestions.findIndex(x => x.Category === "Location")
                    if (Locationss && Locationss > -1) {
                        SMSQuestions.splice(0, newSMSQuestions)
                    } else {
                        SMSQuestions = [...Location]
                    }
                    break;
                case "Document":
                    let Documentsss = SMSQuestions.findIndex(x => x.Category === "Document")
                    if (Documentsss && Documentsss > -1) {
                        SMSQuestions.splice(0, Documentsss)
                    } else {
                        SMSQuestions = [...Document]
                    }
                    break;
                case "Assets":
                    let Assetssss = SMSQuestions.findIndex(x => x.Category === "Assets")
                    if (Assetssss && Assetssss > -1) {
                        SMSQuestions.splice(0, Assetssss)
                    } else {
                        SMSQuestions = [...Assets]
                    }
                    break;
                case "checkout":
                    SMSQuestions = SREvent.CheckOutQuestions;
                    SREvent.Direction = "checkout";
                    break;
                case "newaccount":
                    SMSQuestions = SREvent.SMSQuestions;
                    break;
                default:
                    SMSQuestions = SREvent.SMSQuestions;
            }

           // SMSQuestions = SREvent.SMSQuestions;
            
            
            console.log("NewServiceCall.SMSQuestions", SMSQuestions);


            if (SMSQuestions.length > 0) {
                SMSQuestions.map((each, indd) => {

                    switch (each.Category) {
                        case "GPS":
                            LoadComponenet = "LocationBox";                            
                            break;
                        case "Equipment":
                            LoadComponenet = "AssetEachBox";
                            break;
                        case "Images":
                            LoadComponenet = "UploadEachBox";
                            break;
                        case "pictures":
                            LoadComponenet = "UploadEachBox";
                            break;
                        case "Documents":
                            LoadComponenet = "UploadEachBox";
                            break;
                        case "Reason":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "PO":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "Additional":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "Project":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "Warranty":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "Quote":
                            LoadComponenet = "YesNoBox";
                            break;
                        case "AccountSetup":
                            LoadComponenet = "AccountSetup";
                            break;
                        case "Assets":
                            LoadComponenet = "AssetEachBox";
                            break;
                        default:
                            LoadComponenet = "DescriptionBox";
                            break;
                    }

                    console.log("NewServiceCall.SMSQuestions2", each.key, indd);

                    SMSQuestionsNew.push({
                        ...each,
                        SenderCompany: state.SenderCompany,
                        SenderName: state.SenderName,
                        avatar: state.Senderavatar,
                        PhotoService: [],
                        Documents: [],
                        From: From,
                        TimeStamp: currentTime,
                        SenderCompanyType: "Company",
                        To: state.mobile,
                        Body: "",
                        LoadComponenet: LoadComponenet,
                        KeyId: each.key + indd
                    })

                    console.log("NewServiceCall.SMSQuestionsNew", SMSQuestionsNew);
                })
            }
            // }

            setState(prevState => ({
                ...prevState,
                SREvent: SREvent,
                SMSQuestionsNew: SMSQuestionsNew,
                StepValueArray: SMSQuestionsNew,
                FinalPolicy: FinalPolicy,
                PolicyId: PolicyId,
                ContractorPolicyFound: ContractorPolicyFound,
                PolicyFlag: PolicyFlag
            }))

          
        } catch (err) { console.log("GetApplicablePolicies err", err) }
    };



    const AdjustData = () => {
        let StepValueArray1 = state.StepValueArray;
        let postHTML = state.postHTML;
        let postTEXT = state.postTEXT;
        let DBList = state.DBList;
        let PhotoService = state.PhotoService;
        let Documents = state.Documents;
        let NewStepValueArray = [];
        let SearchWords = state.SREvent;

        if (props.Direction === "checkout") {
            NewStepValueArray.push({
                
                Body: "****************** Check Out ******************",
                SenderCompany: "",
                Category: "checkout",
                SenderName: "checkout",
                avatar: "",
                AssetList: []
            })
        }

        StepValueArray1.map((each, indd) => {
            
            postHTML += `<h4>${each.field1}</h4>${each.Body}`;
            postTEXT += `( ${each.Category}? ) ${each.Body}\n\n`;
            SearchWords += ` ${each.Body},`;

            if (each.AssetList && each.AssetList.length > 0) {
                DBList = [...DBList, ...each.AssetList];
            }

            if (each.PhotoService && each.PhotoService.length > 0) {
                PhotoService = [...PhotoService, ...each.PhotoService];
            }

            if (each.Documents && each.Documents.length > 0) {
                Documents = [...Documents, ...each.Documents];
            }
            //question
            NewStepValueArray.push({
                ...each,
                Body: each.field1,
                SenderCompany: ContractorData.CompanyName,
                SenderName: "Question",
                avatar: ContractorData.avatar,
                AssetList: []
            })
            //answer
            NewStepValueArray.push({
                ...each,
                Body: each.Body,
                SenderCompany: state.SenderCompany,
                SenderName: state.SenderName,
                avatar: state.Senderavatar,
                AssetList: []
            })


        })

        postHTML += "</p>";

        CombinedEvent(postHTML, postTEXT, DBList, PhotoService, Documents, NewStepValueArray, SearchWords);
    }

    const CombinedEvent = (postHTML, postTEXT, DBList, PhotoService, Documents, NewStepValueArray, SearchWords) => {

        let event = state.SREvent;
        let posterId = event.posterId;
        let title = `${state.field1} - ${posterId.field1} - ${posterId.CompanyName}`;

        let EventData = NewStepValueArray;
        if (event.EventData && event.EventData.length > 0) {
            EventData = [...event.EventData, ...NewStepValueArray];
        }

        let UTCTume = DateTime.utc().toString();

        let ProgressHistory = {
            Progress: 0,
            date: currentTime,
        }

        let event2 = {
            ...event,
            EventData: EventData,
            postHTML: postHTML,
            postTEXT: postTEXT,
            Documents: Documents,
            PhotoService: PhotoService,
            DBList: DBList,
            currentTime: UTCTume,
            UTCTime: UTCTume,
            date: UTCTume,
            Senderavatar: posterId.avatar,
            SearchWords: `${title} ${SearchWords}`,
            title: title,
            status: "Triggered",
            CompanyStatus: "pending",
            ContractorStatus: "pending",
           // ContractorData: [],
            Progress: 0,
            ProgressHistory: ProgressHistory,
          //  CurrentProgressStep: 0,
           // posterId: posterId,
            ActionButtonTitle: "Check online",
        };

        const NewEvent = {

            pk1: event.ServiceRequest.pk1,
            sk1: event.ServiceRequest.sk1,
            gsi1Pk2: event.gsi1Pk2,
            gsi1Sk2: UTCTume,
            gsi2Pk2: event.gsi2Pk2,
            gsi2Sk2: UTCTume,
            dataJSON: JSON.stringify(removeEmptyStringElements(event2))
        };

        console.log("Data to FindPolicy ---", event2);

        if (event.Direction === "CheckIn") {
            //trigger text message for checkout
            SendSMS(event)
        }

        UpdateDatabase(NewEvent);
        TriggerESCData(event);    

        setState(prevState => ({
            ...prevState,
            DoneDone: true,
            StatusMessage: "Thank you for completing the informaiton. You can close this page now"
        }))

    }

    const SendSMS = event => {

        let EndMessage = `Please use following link to check-out at the end of your shift. \n\n${event.CheckOutLink} \n\nThank You`; 

        let SMSstring = {
            Body: EndMessage,
            FromSMS: event.ContractorSMS,
            From: event.ContractorSMS,
            To: [event.ContractorSMS],
            mediaUrl: [],
            pk1: event.posterId.CompanyId,
            CompanyId: event.posterId.CompanyId,
            //ContractorId: ContractorId,
            //SMSList: [{
            //    PersonName: Field1,
            //    PersonId: Field1,
            //    phone: Invitation.Mobile
            //}]
        };

        console.log("ItemAddPage.Invite.SMS", SMSstring)

        API.post("SMStoTwilio", "/twilio", {
            body: { Records: SMSstring }
        });
    }

    const TriggerESCData = (event) => {
        console.log("NewServiceCall.TriggerLambda", event);

        API.post("TriggerESCData", "/ESCData", {
            body: { Records: event }
        });
    }

    const UpdateDatabase = event => {


        let PosttoDB = {
            MutationType: "updateSvcbotDb",
            DBData: event
        };

        // Post to DB
        TriggerLambda(PosttoDB);

    }


    const handleNext = () => {
        console.log("UrgencyDetails.handleNext", activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  

    const ClosingDrawer = (e) => {
        console.log("ClosingDrawer", e)
        SetDeawerOpen(false)
    }


    const SaveSelection = (data) => {

        console.log("NewServiceCall.SaveSelection", data);

        let Quote = false;
        let QuoteQuestion = "";
        let Quoteanswe = "";
        let NewQuote = state.Quote;
        let event = state.SREvent; 
        let PhotoService = state.PhotoService;
        let Documents = state.Documents;
        

        if (data && data.field1 && data.Body) {

            let CurrentQuestion = data.field1;
            let LatestAnswer = data.Body;
            console.log("NewServiceCall.qqq", CurrentQuestion, LatestAnswer);
            if (CurrentQuestion !== "" && LatestAnswer !== "") {

                QuoteQuestion = CurrentQuestion.toLowerCase();
                Quoteanswe = LatestAnswer.toLowerCase();

                if (QuoteQuestion.includes("quote")
                    || QuoteQuestion.includes("proposal")
                ) {
                    if (Quoteanswe.includes("yes")) {
                        Quote = true;
                    }
                }
            }
        }

        if (data && data.PhotoService && data.PhotoService.length > 0) {
            PhotoService = [...PhotoService, ...data.PhotoService];
            event.PhotoService = PhotoService;
        }

        if (data && data.Documents && data.Documents.length > 0) {
            Documents = [...Documents, ...data.Documents];
            event.Documents = Documents;
        }
            

        if (!state.Quote) {
            setState(prevState => ({
                ...prevState,
                Quote: Quote,
            }))
        }

        let StepValueArray1 = [];
        let Indexx = -1;

        if (state.StepValueArray && state.StepValueArray.length > 0) {
            Indexx = state.StepValueArray.findIndex(x => x.KeyId === data.KeyId);
            StepValueArray1 = state.StepValueArray;
        }

        if (Indexx > -1) {

            StepValueArray1[Indexx] = data;

        } else {
         
            StepValueArray1.push(data);
        }



        setState(prevState => ({
            ...prevState,
            StepValueArray: StepValueArray1,
            Documents: Documents,
            PhotoService: PhotoService,
            SREvent: event
        }))

        //}
    }   

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const SetRequestor = (data) => {
        console.log("NewServiceCall.poster.data", data);

        if (data && data.posterId) {
            setState(prevState => ({
                ...prevState,
                ...data,
                SREvent: data,
                
            }));
        }

    }

    const CheckRequestor = () => {
        if (state.Email) {
            handleNext();
        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please provide Email",
                OpenAlert: true
            }));

        }
    }

    const CheckReason = () => {
        let StepValueArray = state.StepValueArray;
        if (state.Description && state.Description !== "") {

            StepValueArray[0] = {

            }

            handleNext();
        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please state the reason for the service request",
                OpenAlert: true
            }));

        }
    }

    // console.log("UrgencyDetails.SelectStepBox.Update5", state.StepValueArray)
    console.log("NewCheckin.state", state);
    console.log("NewCheckin.StepValueArray", state.StepValueArray)
    console.log("NewCheckin.activeStep", activeStep)

    return state.SMSQuestionsNew.length > 0 && (
        <Paper id={state.id} key={state.UUid} sx={{ p: 1 }}>

            <Grid container spacing={2}>
                {state.DoneDone ? (
                <Grid item xs={12}>
                        <Typography component="div" variant="h3">
                            {state.StatusMessage}
                        </Typography>
                </Grid>
                ) : (
                <Grid item xs={12}>

                    <Stepper activeStep={activeStep} orientation="vertical">

                        {state.WaitForEmail ? (
                            <Step key={10011}>
                                <StepLabel>Account Setup</StepLabel>
                                <StepContent>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Typography >Following inforamtion is required</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AccountSetup
                                                SelectedDataReturn={(e) => SetRequestor(e)}
                                                SREvent={props.SREvent}
                                               // handleNext={() => handleNext()}
                                                title={`Account Setup`}
                                                Category="Person"
                                            />
                                        </Grid>
                                       
                                    </Grid>
                                </StepContent>
                            </Step>
                        ) : null}

                      
                        {state.SMSQuestionsNew.map((block, index) => {

                            return (

                                <Step key={index}>
                                    <StepLabel>{block.label}</StepLabel>
                                    <StepContent>

                                        <Grid container spacing={2}>

                                        
                                            <Grid item xs={12}>
                                                <Typography >{block.field1}</Typography>
                                            </Grid>
                                       

                                            <Grid item xs={12}>
                                                <RenderModule
                                                    {...props}
                                                    LoadComponenet={block.LoadComponenet}
                                                    StepValueArray={state.StepValueArray}
                                                    Category={block.Category}
                                                    SREvent={state.SREvent}
                                                    // SaveDataBack={state.SaveDataBack[index]}
                                                    SelectedDataReturn={(data) => SaveSelection(data)}
                                                    id={index}
                                                    activeStep={activeStep}
                                                    handleBack={() => handleBack()}
                                                    handleNext={() => handleNext(index)}
                                                    KeyId={block.KeyId}
                                                    key={index + block.id}

                                                />
                                            </Grid>

                                        </Grid>
                                    </StepContent>
                                </Step>

                            )
                        })}




                        <Step key={1000}>
                            <StepLabel>Final Step</StepLabel>
                            <StepContent>

                                <div
                                //className={classes.actionsContainer}
                                >
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        //  className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => AdjustData()}
                                        //  className={classes.button}
                                        >
                                            Save data
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>

                    </Stepper>
                        </Grid>

                )}

            </Grid>

            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
          
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle="change this"
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: (e) => ClosingDrawer(e),
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />

        </Paper>
    );
}
