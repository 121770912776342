import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid, Avatar, CardHeader, IconButton,
  Typography, Button, Card, MenuItem, Menu,
  Stepper, Step, StepLabel, StepContent
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch, createSvcbotDb } from '../graphql/mutations';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import Requestor from './Requestor';
import ItemAddForms from '../Add/ItemAddForms';
import { removeEmptyStringElements, NewPageId, Pk1SingelItem, PrimaryKeyDataGet, FindLatestDate } from '../utils/CommonGraphql';
import { FormSchema } from '../Forms/FormList';
import { DateTodaySlash, currentTime, formatDate } from '../utils/DateTime';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UploadMain from '../Upload/UploadMain';
//import ComboForm from '../Forms/ComboForm';
//import DynamicForms from '../Forms/FormShow';
import Loadable from 'react-loadable';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';
import StepBox from '../Setup/StepBox';

const ComboForm = Loadable({
  loader: () => import('../Forms/ComboForm'),
  loading() {
    return <div>Loading...</div>
  }
});

const DynamicForms = Loadable({
  loader: () => import('../Forms/FormShow'),
  loading() {
    return <div>Loading...</div>
  }
});

var store = require('store');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UUid = NewPageId("NewSvc");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const isNotEmptyObject = (value) => {
  return value && value.constructor === Object && Object.keys(value).length > 0;
}

let Schedulefordate = [
  {
    key: "DateTimeRange",
    label: "Dates",
    labelpermanent: "Dates",
    ModuleTitle: "Dates",
    message: "Select dates for service",
    LoadComponenet: "DateTimeRange",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "DateTimeRange",
    DataSendArrayName: "DateTimeRange",
    disabled: false,
    Category: "Date",
    VariableInput: "",
  },
  {
    key: "title",
    label: "Title",
    labelpermanent: "Title",
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "description",
    label: "Description",
    labelpermanent: "Description",
    ModuleTitle: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Trade",
    label: "Service Specielty Trade",
    ModuleTitle: "Trade",
    message: "Please select Service Specielty Trade",
    LoadComponenet: "ShowTradeOption",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ShowTradeOption",
    DataSendArrayName: "TradeSelected",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Location",
    label: "Location",
    ModuleTitle: "Location",
    message: "Location of the work",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedLocation",
    DataSendArrayName: "",
    disabled: false,
    Category: "Location",
    VariableInput: "",
  },
];

let Urgent = [
  {
    key: "title",
    label: "Title",
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "description",
    label: "Description",
    labelpermanent: "Description",
    ModuleTitle: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },

  {
    key: "Trade",
    label: "Service Specielty Trade",
    ModuleTitle: "Trade",
    message: "Please select Service Specielty Trade",
    LoadComponenet: "ShowTradeOption",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ShowTradeOption",
    DataSendArrayName: "TradeSelected",
    disabled: false,
    Category: "",
    VariableInput: "",
  },


  {
    key: "Location",
    label: "Location",
    ModuleTitle: "Location",
    message: "Location of the work",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedLocation",
    DataSendArrayName: "",
    disabled: false,
    Category: "Location",
    VariableInput: "",
  },

  {
    key: "DateTimeRange",
    label: "Dates",
    ModuleTitle: "Dates",
    message: "Select dates for service",
    LoadComponenet: "DateTimeRange",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "DateTimeRange",
    DataSendArrayName: "DateTimeRange",
    disabled: false,
    Category: "Date",
    VariableInput: "",
  },

  //{
  //  key: "TimeRange",
  //  label: "Select Time",
  //  ModuleTitle: "TimeRange",
  //  message: "Select Time for service",
  //  LoadComponenet: "TimeRange",
  //  LoadComponenet2: "",
  //  LoadComponenet3: "",
  //  Trigger1: false,
  //  Trigger2: false,
  //  Trigger3: false,
  //  required: false,
  //  DataReceivedArrayName: "TimeRange",
  //  DataSendArrayName: "",
  //  disabled: false,
  //  Category: "Time",
  //  VariableInput: "",
  //},

  {
    key: "Upload",
    label: "Picture or documents",
    ModuleTitle: "Upload",
    message: "Provide picture or documents",
    LoadComponenet: "Upload",
    LoadComponenet2: "ShowImage",
    LoadComponenet3: "ShowImage",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedDocuments",
    DataSendArrayName: "SelectedDocuments",
    disabled: false,
    Category: "Upload",
    VariableInput: "",
  },

  {
    key: "ExtraNotification",
    label: "Private Message",
    ModuleTitle: "Private Message",
    message: "Add Private Message",
    LoadComponenet: "ExtraNotification",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: true, // trigger=true to activate text box for private message
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "PrivateMessage",
    DataSendArrayName: "",
    disabled: false,
    Category: "ExtraNotification",
    VariableInput: "",
  },

  {
    key: "ExtraNotification",
    label: "Extra Notification",
    ModuleTitle: "Extra Notification",
    message: "The system will automatically notify the on-call personal.  Please use this step to add other personal that need to receive copy of this service request",
    LoadComponenet: "ExtraNotification",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false, // trigger=true to activate text box for private message
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ExtraNotification",
    DataSendArrayName: "",
    disabled: false,
    Category: "ExtraNotification",
    VariableInput: "",
  },
];

let ToDo = [
  {
    key: "title",
    label: "Title",
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: true,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Upload",
    label: "Picture or documents",
    ModuleTitle: "Upload",
    message: "Provide picture or documents",
    LoadComponenet: "Upload",
    LoadComponenet2: "ShowImage",
    LoadComponenet3: "ShowImage",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedDocuments",
    DataSendArrayName: "SelectedDocuments",
    disabled: false,
    Category: "Upload",
    VariableInput: "",
  },
  {
    key: "description",
    label: "Description",
    ModuleTitle: "Description",
    labelpermanent: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Trade",
    label: "Service Specielty Trade",
    ModuleTitle: "Trade",
    message: "Please select Service Specielty Trade",
    LoadComponenet: "ShowTradeOption",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ShowTradeOption",
    DataSendArrayName: "TradeSelected",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Equipment",
    label: "Equipment",
    ModuleTitle: "Equipment",
    message: "Please select the Equipment",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedEquipment",
    DataSendArrayName: "",
    disabled: false,
    Category: "Equipment",
    VariableInput: "",
  },
  {
    key: "Location",
    label: "Location",
    ModuleTitle: "Location",
    message: "Location of the work",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedLocation",
    DataSendArrayName: "",
    disabled: false,
    Category: "Location",
    VariableInput: "",
  },
];

let quote = [
  {
    key: "title",
    label: "Title",
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: true,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },

  {
    key: "Upload",
    label: "Picture or documents",
    ModuleTitle: "Upload",
    message: "Provide picture or documents",
    LoadComponenet: "Upload",
    LoadComponenet2: "ShowImage",
    LoadComponenet3: "ShowImage",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedDocuments",
    DataSendArrayName: "SelectedDocuments",
    disabled: false,
    Category: "Upload",
    VariableInput: "",
  },

  {
    key: "description",
    label: "Description",
    ModuleTitle: "Description",
    labelpermanent: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
];

let DateOption = [
  {
    label: "Start Date",
    VariableName: "StartDate",
    value: DateTodaySlash,
  },
  {
    label: "End Date",
    VariableName: "EndDate",
    value: DateTodaySlash,
  },
  {
    label: "Start Time",
    VariableName: "StartTime",
    value: "07:00",
  },
  {
    label: "End Time",
    VariableName: "EndTime",
    value: "16:00",
  }
];

const Account = (props) => {
  const classes = useStyles();
  const params = useParams();
  let query = useQuery();
  const ContractorData = useSelector(state => state.ContractorData);
  const [savedetails, Setsavedetails] = useState(false);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  const [DeawerOpen, SetDeawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const initialState = {
    Category: "",
    CompanyId: cid.CompanyId,
    FormJSON: [],
    FormData: [],
    AlarmMessage: "",
    UrgencySelected: [],
    Documents: [],
    PhotoService: [],
    DrawerCategory: "",
    DrawerTitle: "",
    DetailComponent: "",
    DrawerFormData: [],
    NewService: [],
    TradeSelected: [],
    TotalFormData: [],
    Requestor: {},
    OpenAlert: false,
    ShowNewServiceForm: false,
    ShowNewServiceBox: false,
    ImagePic: "",
    DateTimeRange: DateOption,
    ServiceSteps: Urgent,
    LiveMode: true,  // allow to use this as preview
  }
  const [state, setState] = useState(initialState);
  const [FormGroupSelectFound, SetFormGroupSelectFound] = useState();

  useEffect(() => {
    console.log("NewServiceSteps.props ", props);
    // if contractor - get the policy based on person selected
    if (UserData.CustomerType === "Contractor") {
      setState(prevState => ({
        ...prevState,
        ShowNewServiceForm: false,
        CompanyId: cid.CompanyId,
        CompanyName: cid.CompanyName,
      }));
    }

    if (UserData.CustomerType === "Company") {
      setState(prevState => ({
        ...prevState,
        Requestor: UserData,
        CompanyId: UserData.CompanyId,
        CompanyName: UserData.CompanyName,
        ShowNewServiceForm: true,
      }));
    }

    if (props.DemoMode) {
      setState(prevState => ({
        ...prevState,
        LiveMode: false,
      }));
    }

    if (props.UrgencyType && props.UrgencyType !== "") {
      let ServiceStepss = Urgent;
      let title = "Emergency Service";
      switch (props.UrgencyType) {
        case "Schedulefordate":
          ServiceStepss = Schedulefordate;
          title = "Schedule for Date";
          break;
        case "Emergency":
          ServiceStepss = Urgent;
          title = "Emergency Service";
          break;
        case "ToDo":
          ServiceStepss = ToDo;
          title = "Add to do list";
          break;
        case "Quote":
          ServiceStepss = quote;
          title = "Request for Proposal";
          break;
        default:
          ServiceStepss = Urgent;
          title = "Emergency Service";
      }

      setState(prevState => ({
        ...prevState,
        ServiceSteps: ServiceStepss,
        PageTitle: title
      }));
    }


  }, [])

  useEffect(() => {
    FirstStep();
  }, [state.Requestor])

  useEffect(() => {
    //Create New Service form with Urgency and Trade
    CreateNewServiceForm(state.UrgencySelected, state.TradeSelected);
  }, [state.UrgencySelected, state.TradeSelected])

  const CreateNewServiceForm = (UrgencySelected, TradeSelected) => {

    //Load Base New Service Form
    let NewServiceFormData = FormSchema.filter(x => x.Category === "NewService");

    if (UrgencySelected && UrgencySelected.length > 0) {
      let UrgencyIndex = NewServiceFormData.findIndex(xx => xx.VariableName === "Urgency");
      NewServiceFormData[UrgencyIndex].options = UrgencySelected;
      NewServiceFormData[UrgencyIndex].DataSourse = "options";
    }

    if (TradeSelected && TradeSelected.length > 0) {
      let TradeIndex = NewServiceFormData.findIndex(xx => xx.VariableName === "Trade");
      NewServiceFormData[TradeIndex].options = TradeSelected;
      NewServiceFormData[TradeIndex].DataSourse = "options";
    }

    console.log("NewService.NewServiceFormData", NewServiceFormData)

    setState(prevState => ({
      ...prevState,
      NewServiceFormData: NewServiceFormData,
    }));
  }

  const FirstStep = () => {
    try {

      if (state.Requestor.CustomerType === "Company") {

        //if company - look for the policy in local storage
        let LocalStorageNewServicePref = store.get(`FORMS_PREF::NewService::${ContractorData.ContractorId}`);
        console.log("LocalStorageNewServicePref", LocalStorageNewServicePref)
        if (LocalStorageNewServicePref) {
          // let FormGroupSelect =
          ShowCustomForms(LocalStorageNewServicePref);

          //GetFormsDetails(FormGroupSelect);
          //GetUrgencyDetails(FormGroupSelect)
          //GetTradeDetails(FormGroupSelect)

        } else {
          LoadPref(UserData);
        }
      }

      setState(prevState => ({
        ...prevState,
        // ShowNewServiceForm: true,
        //Requestor: UserData,
        CompanyId: state.Requestor.CompanyId,
        CompanyName: state.Requestor.CompanyName
        //  NewServiceFormData: NewServiceFormData,
      }));

    } catch (err) { console.log("FirstStep err", err) }
  }
  //"Person", state.CompanyId, state.CompanyName, "SelectAssets", `Add Private Message ${state.CompanyName}`
  //const ExtraOptionDrawer = (cat, cid, CompanyName, module, title, PrivateMessage) => {
  //  console.log("cat", cat)
  //  setState(prevState => ({
  //    ...prevState,
  //    DrawerCategory: cat,
  //    DrawerCompanyId: cid,
  //    DrawerCompanyName: CompanyName,
  //    DrawerTitle: title,
  //    DetailComponent: module,
  //    PrivateMessage: PrivateMessage,
  //    DrawerFormData: [],
  //  }))
  //  ExtrahandleClose();
  //  SetDeawerOpen(true);
  //};

  const SelectedAsset = data => {
    console.log("NewService.SelectedAsset", data)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const ExtrahandleClose = () => {
    setAnchorEl(null);
  };

  /*
   * LoadComponenet={state.DetailComponent}
        propss={{
          SelectedAsset: (e) => SelectedAsset(e),
          CompanyId: state.CompanyId,
          title: state.DrawerTitle,
          Category: state.DrawerCategory,
          FormData: state.DrawerFormData
        }}
        DrawerStatus={DeawerOpen}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
   * 
   * */

  //const GetFromDB = async (Pref_Policy_Id) => {
  //  try {
  //    let ContractorEsc = await Pk1SingelItem(ContractorData.ContractorId, Pref_Policy_Id);
  //    console.log("FormFromDb1", ContractorEsc)
  //    return ContractorEsc;
  //  } catch (err) { console.log("GetFromDB err", err) }
  //}

  function GetFromDB(Pref_Policy_Id) {
    return new Promise((resolve) => {
      Pk1SingelItem(ContractorData.ContractorId, Pref_Policy_Id).then(x => {
        if (x) {
          let databack = JSON.parse(x.dataJSON)
          console.log("FormFromDb1", databack)
          resolve(databack);
        } else {
          console.log("No data in x")
        }
      })
    });
  }

  const ShowCustomForms = (each) => {
    try {
      console.log("FormFromDb3", each)
      let FormFromDb = {};
      let FormInfo = store.get(each);
      if (FormInfo) {
        GetFormsDetails(FormInfo);
        GetUrgencyDetails(FormInfo)
        GetTradeDetails(FormInfo)
      }
      else {
        GetFromDB(each).then(data => {
          GetFormsDetails(data);
          GetUrgencyDetails(data)
          GetTradeDetails(data)
        })
      }


    } catch (err) { console.log("GetFromLocalStorage err", err) }
  }

  const GetFormsDetails = (FormGroupSelect) => {
    try {
      //FormsSelected
      let FormJSON = [];
      let FormUse = {};

      if (FormGroupSelect && FormGroupSelect.FormsSelected && FormGroupSelect.FormsSelected.length > 0) {
        FormGroupSelect.FormsSelected.map((each, index) => {
          FormUse = store.get(each);
          console.log("FormUse", FormUse)
          if (FormUse) {
            FormJSON.push({
              FormType: "AutoComplate",
              key: each,
              FormName: FormUse.field1,
              //FormType: FormUse.FormType,
              Category: FormUse.Category,
              FormData: FormUse.FormData,
              ValueAdded: false
            })

            console.log("NewService.FormJSON", FormJSON)

            setState(prevState => ({
              ...prevState,
              FormJSON: FormJSON,
              ShowNewServiceBox: true,

            }));

          }
        })
      }
    } catch (err) { console.log("PutFormDataInState err", err) }
  }

  const GetUrgencyDetails = (FormGroupSelect) => {
    try {
      //FormsSelected
      let FormJSON = [];
      let Urgency = [];
      let Trade = [];
      let FormUse = {};

      if (FormGroupSelect.UrgencySelected && FormGroupSelect.UrgencySelected.length > 0) {
        FormGroupSelect.UrgencySelected.map((each, index) => {
          FormUse = store.get(each);
          console.log("FormUse1", FormUse)
          if (FormUse) {
            Urgency.push({
              key: FormUse.key,
              value: FormUse.key,
              label: FormUse.field1,
              field1: FormUse.field1,
              pk1: FormUse.pk1,
            })

            console.log("NewService.Urgency", Urgency)

            setState(prevState => ({
              ...prevState,
              //FormJSON: FormJSON,
              UrgencySelected: Urgency,
              //UrgencyCount: Urgency.length,
              ShowNewServiceBox: true,
            }));

          }
        })
      }



    } catch (err) { console.log("PutFormDataInState err", err) }
  }

  const GetTradeDetails = (FormGroupSelect) => {
    try {
      //FormsSelected
      let FormJSON = [];
      let Urgency = [];
      let Trade = [];
      let FormUse = {};

      if (FormGroupSelect.TradeSelected && FormGroupSelect.TradeSelected.length > 0) {
        FormGroupSelect.TradeSelected.map((each, index) => {
          FormUse = store.get(each);
          console.log("FormUse2", FormUse)
          if (FormUse) {
            Trade.push({
              key: FormUse.key,
              value: FormUse.key,
              label: FormUse.field1,
              field1: FormUse.field1,
              pk1: FormUse.pk1,
            })

            console.log("NewService.Trade", Trade)

            setState(prevState => ({
              ...prevState,
              TradeSelected: Trade,
              // TradeCount: Trade.length
            }));
          }
        })
      }



    } catch (err) { console.log("PutFormDataInState err", err) }
  }


  const LoadPref = async (PersonData) => {

    try {
      console.log("PersonData", PersonData);
      let PersonId = PersonData.id;

      let CompanyId = PersonData.CompanyId;
      let TeamId = "dkksksslslls";
      let LocationId = "dkksksslslls";

      let ContractorEsc = await PrimaryKeyDataGet(ContractorData.ContractorId, "Data::Online::Pref::NewService");
      let CompanyEsc = await PrimaryKeyDataGet(CompanyId, "Data::Online::Pref::NewService");

      let Location = [];
      let Company = [];
      let Person = [];
      let Team = [];
      let CompanyJSON = {};
      let ContractorJSON = "";
      let Contractor = [];
      let PersonJSON = "";
      let FormGroupSelect = "";
      var data = {};
      let Pref_Policy_Id = "";
      var UsePolicy = 0;
      var NewServicePref = "";
      let BackUpdata = {};
      //let LastSMS = event.EventData.length - 1;

      if (ContractorEsc) {
        ContractorEsc.forEach((eqq) => {
          ContractorJSON = JSON.parse(eqq.dataJSON);
          Contractor.push(ContractorJSON);
        })
      }

      if (CompanyEsc) {
        CompanyEsc.forEach((eqq) => {

          CompanyJSON = JSON.parse(eqq.dataJSON);
          if (CompanyJSON && CompanyJSON.Active) {

            if (eqq.sk1.indexOf("#Company") > -1) {
              Company.push(CompanyJSON);
            }

            if (eqq.sk1.indexOf(`#Person#${PersonId}`) > -1) {
              Person.push(CompanyJSON);
            }

            if (eqq.sk1.indexOf(`#Team#${TeamId}`) > -1) {
              Team.push(CompanyJSON);
            }

            if (eqq.sk1.indexOf(`#Location#${LocationId}`) > -1) {
              Location.push(CompanyJSON);
            }

          }
          CompanyJSON = {};

        });
      }

      console.log("LoginCompany", Company);
      console.log("Loginperson", Person);
      console.log("Contractor", Contractor);

      if (Contractor.length > 0) {
        data = FindLatestDate(Contractor);
        BackUpdata = data;
        UsePolicy = 1;
      }

      if (Company.length > 0) {
        data = FindLatestDate(Company);
        UsePolicy = 1;
      }

      if (Location.length > 0) {
        data = FindLatestDate(Location);
        UsePolicy = 1;
      }

      if (Person.length > 0) {
        data = FindLatestDate(Person);
        UsePolicy = 1;
      }

      if (data.Selection && data.Selection.length > 0) {
        console.log("LoginESC", data.Selection[0]);
        Pref_Policy_Id = data.Selection[0];
      } else {
        Pref_Policy_Id = BackUpdata.Selection[0];
        console.log("BackUpPolicy_Used", Pref_Policy_Id);
      }

      console.log("data", data);

      //FormGroupSelect =
      ShowCustomForms(Pref_Policy_Id);

      //if (FormGroupSelect === "UseState") {
      //  FormGroupSelect = FormGroupSelectFound;
      //}

      //GetFormsDetails(FormGroupSelect);
      //GetUrgencyDetails(FormGroupSelect)
      //GetTradeDetails(FormGroupSelect)

      //return Pref_Policy_Id;



    } catch (err) { console.log("SeperateData err", err); }
  };

  const SubmitData = () => {
    try {

      let PhotoService = state.PhotoService;
      let Documents = state.Documents;
      let PostId = UUid;
      let ExtraCommonData = "";
      let InputArray = [];
      let SearchWords = `${state.CompanyName} | ${state.title} | `;
      let ExtraFormSummery = [];
      let post = `<p>${state.title}</p>`;
      let posterId = {
        pk1: UserData.pk1,
        sk1: UserData.sk1,
        field1: UserData.filed1,
        field2: UserData.filed2,
        avatar: UserData.avatar,
      }
      let Requestor = {
        pk1: state.Requestor.pk1,
        sk1: state.Requestor.sk1,
        field1: state.Requestor.filed1,
        field2: state.Requestor.filed2,
        avatar: state.Requestor.avatar,
      };

      if (state.description !== "") {
        post += state.description;
      }

      let CommonData = {
        date: currentTime,
        id: UUid,
        PostId: PostId,
        CompanyId: state.CompanyId,
        CompanyName: state.CompanyName,
        ContractorId: ContractorData.ContractorId,
        PhotoService: PhotoService,
        Documents: Documents,
        posterId: posterId,
        Requestor: Requestor,
        field1: state.title,
      }

      let PostData = {
        ...CommonData,
        ServiceSteps: state.ServiceSteps,
        SearchWords: SearchWords,
        ServiceUrgency: state.ServiceUrgency,
        ServiceTrade: state.ShowTradeOption,
        ContractorId: ContractorData.ContractorId,
        post: post,
        title: state.title,
        key: `Post||${state.CompanyId}||${currentTime}`,
        OriginalMessage: state.title,
        pk1: `Post::${state.CompanyId}`,
        sk1: currentTime,
        gsi1Pk2: PostId,
        gsi1Sk2: currentTime,
        gsi2Pk2: `Post::${ContractorData.ContractorId}`,
        gsi2Sk2: currentTime,
      };

      let input = {
        // find all post by customer sort by date/time
        pk1: `Post::${state.CompanyId}`,
        sk1: currentTime,
        // find post by Post Id - that needs to be in all replys
        gsi1Pk2: PostId,
        gsi1Sk2: currentTime,
        // find post by contractor id
        gsi2Pk2: `Post::${ContractorData.ContractorId}`,
        gsi2Sk2: currentTime,
        dataJSON: JSON.stringify(removeEmptyStringElements(PostData)),
      };

      InputArray.push(removeEmptyStringElements(input));

      state.ServiceSteps.map(eachstep => {

        //if trade
        if (eachstep.LoadComponenet === "ShowTradeOption") {

          ExtraCommonData = CommonData;
          ExtraCommonData.key = `Service::Trade::${eachstep.sk1}::${currentTime}`;
          ExtraCommonData.pk1 = state.CompanyId;
          ExtraCommonData.sk1 = `Service::Trade::${eachstep.sk1}::${currentTime}`;

          input = {
            pk1: state.CompanyId,
            sk1: `Service::Trade::${eachstep.sk1}::${currentTime}`,
            gsi1Pk2: PostId,
            gsi1Sk2: currentTime,
            gsi2Pk2: ContractorData.ContractorId,
            gsi2Sk2: `Service::Trade::${eachstep.sk1}::${currentTime}`,
            dataJSON: JSON.stringify(removeEmptyStringElements(ExtraCommonData)),
          };

          InputArray.push(removeEmptyStringElements(input));
          ExtraCommonData = "";
        }

        //if Autocomplete
        if (eachstep.LoadComponenet === "Autocomplete") {

          ExtraCommonData = CommonData;
          ExtraCommonData.key = `Service::${eachstep.sk1}::${currentTime}`;
          ExtraCommonData.pk1 = state.CompanyId;
          ExtraCommonData.sk1 = `Service::${eachstep.sk1}::${currentTime}`;

          input = {
            pk1: state.CompanyId,
            sk1: `Service::${eachstep.sk1}::${currentTime}`,
            gsi1Pk2: PostId,
            gsi1Sk2: currentTime,
            gsi2Pk2: ContractorData.ContractorId,
            gsi2Sk2: `Service::${eachstep.sk1}::${currentTime}`,
            dataJSON: JSON.stringify(removeEmptyStringElements(ExtraCommonData)),
          };

          InputArray.push(removeEmptyStringElements(input));
          ExtraCommonData = "";
        }

        //if DateTimeRange
        if (eachstep.LoadComponenet === "DateTimeRange") {

          ExtraCommonData = CommonData;
          ExtraCommonData.key = `DateTimeRange::${eachstep.EndDateValue}::${currentTime}`;
          ExtraCommonData.pk1 = state.CompanyId;
          ExtraCommonData.sk1 = `DateTimeRange::${eachstep.EndDateValue}::${currentTime}`;

          input = {
            pk1: state.CompanyId,
            sk1: `DateTimeRange::${eachstep.EndDateValue}::${currentTime}`,
            gsi1Pk2: PostId,
            gsi1Sk2: currentTime,
            gsi2Pk2: ContractorData.ContractorId,
            gsi2Sk2: `DateTimeRange::${eachstep.EndDateValue}::${currentTime}`,
            dataJSON: JSON.stringify(removeEmptyStringElements(ExtraCommonData)),
          };

          InputArray.push(removeEmptyStringElements(input));
          ExtraCommonData = "";
        }

        //if Private Message

        //If Extra Notification

      })

      console.log("NewServiceSteps.InputArray1", CommonData)
      console.log("NewServiceSteps.InputArray2", PostData)
      console.log("NewServiceSteps.InputArray3", InputArray)

    } catch (err) { console.log("NewServiceSteps.SubmitData.err", err) }
  }

  const SaveDetails = () => {

    try {

      let PostId = UUid;
      let Field1 = "";
      let Field2 = "";
      let TotalData = {};
      let ShortData = {};
      let CommonData = {}
      let SearchWords = "";
      let MissingInfoFlag = false;
      let dataJSON = "";
      let ServiceUrgency = "";
      let ServiceTrade = "";
      let PhotoService = state.PhotoService;
      let Documents = state.Documents;
      let SummeryInfo = {};
      let AlarmMessage = 'Please enter ';
      let Phone = "";
      let Email = "";
      let Trade = "";
      let InputArray = [];
      let EachFormData = "";
      let ExtraFormSummery = [];
      let AddNewItem = "";
      let Tradeidd = "";
      let CompanyName = "";
      let FormDD = {};
      let LocationObject = {};
      let UrgencyId = "";
      let post = "";
      let title = "";
      let Requestor = {
        pk1: state.Requestor.pk1,
        sk1: state.Requestor.sk1,
        field1: state.Requestor.filed1,
        field2: state.Requestor.filed2,
        avatar: state.Requestor.avatar,
      };
      let posterId = {
        pk1: UserData.pk1,
        sk1: UserData.sk1,
        field1: UserData.filed1,
        field2: UserData.filed2,
        avatar: UserData.avatar,
      }

      CommonData = {
        date: currentTime,
        id: UUid,
        ContractorId: ContractorData.ContractorId,
        PostId: PostId,
        CompanyId: state.CompanyId,
        CompanyName: state.CompanyName,
        ContractorId: ContractorData.ContractorId,
        PhotoService: PhotoService,
        Documents: Documents,
        posterId: posterId,
        Requestor: Requestor
      }

      state.NewService.FormData.map((each, ind) => {
        console.log('NewService.each', each);

        if (each.required) {
          if (each.VariableInput === "") {
            //trigger alarm message if input value missing
            MissingInfoFlag = true;
            AlarmMessage += `* ${each.label} `;
          }
        }

        if (each.PhotoService && each.PhotoService.length > 0) {
          PhotoService = [...PhotoService, ...each.PhotoService];
        }

        if (each.Documents && each.Documents.length > 0) {
          Documents = [...Documents, ...each.Documents];
        }

        if (each.extra === "post") {
          post = each.VariableInput;
        }

        if (each.extra === "field1") {
          title = each.VariableInput;
          SearchWords += `${each.VariableInput} | `;
        }

        if (each.extra === "trade") {
          ServiceTrade = each.VariableInput;
          Tradeidd = each.SelectedId;
          SearchWords += `${each.VariableInput} | `;
        }

        if (each.extra === "field2") {
          ServiceUrgency = each.VariableInput;
          UrgencyId = each.SelectedId;
          SearchWords += `${each.VariableInput} | `;
        }

      })

      ShortData = {
        ...CommonData,
        title: title,
        ServiceUrgency: ServiceUrgency,
        SearchWords: SearchWords,
        ServiceTrade: ServiceTrade,
        PhotoService: PhotoService,
        Documents: Documents,
        sk1: currentTime,
        gsi1Pk2: PostId,
        gsi1Sk2: currentTime,
        gsi2Sk2: currentTime,
      };

      //////////////////// Process each form ///////////////////////////////
      if (state.TotalFormData && state.TotalFormData.length > 0) {

        state.TotalFormData.map((EachForm, index) => {

          if (!EachForm.ValueAdded) {
            MissingInfoFlag = true;
            AlarmMessage += `* Please complete ${EachForm.FormName} `;
          }

          Setsavedetails(EachForm.ValueAdded);

          SearchWords += `${EachForm.Category} | ${EachForm.field1} | ${EachForm.field2} `;

          SummeryInfo = {
            pk1: EachForm.pk1,
            sk1: EachForm.sk1,
            field1: EachForm.field1,
            field2: EachForm.field2,
            SearchWords: EachForm.SearchWords,
            Category: EachForm.Category,
          }

          //get summery of each form
          ExtraFormSummery.push(SummeryInfo)

          if (EachForm.PhotoService && EachForm.PhotoService.length > 0) {
            PhotoService = [...PhotoService, ...EachForm.PhotoService];
          }

          if (EachForm.Documents && EachForm.Documents.length > 0) {
            Documents = [...Documents, ...EachForm.Documents];
          }


          let Assetdata = ShortData;
          Assetdata.key = `Service::${EachForm.Category}::${EachForm.sk1}::${currentTime}`;
          Assetdata.pk1 = state.CompanyId;
          Assetdata.sk1 = `Service::${EachForm.Category}::${EachForm.sk1}::${currentTime}`;
          Assetdata.SearchWords = SearchWords;
          Assetdata.SummeryInfo = SummeryInfo;
          Assetdata.field1 = EachForm.field1;
          Assetdata.field2 = EachForm.field2;
          Assetdata.PhotoService = PhotoService;
          Assetdata.Documents = Documents;
          Assetdata.gsi2Pk2 = ContractorData.ContractorId;
          Assetdata.ExtraFormData = EachForm.FormData;
          console.log('NewService.Assetdata', Assetdata)

          EachFormData = {
            // find all post by customer sort by date/time
            pk1: state.CompanyId,
            sk1: `Service::${EachForm.sk1}::${currentTime}`,
            // find post by Post Id - that needs to be in all replys
            gsi1Pk2: PostId,
            gsi1Sk2: currentTime,
            // find post by contractor id
            gsi2Pk2: EachForm.sk1,
            gsi2Sk2: currentTime,
            dataJSON: JSON.stringify(removeEmptyStringElements(Assetdata)),
          };

          InputArray.push(removeEmptyStringElements(EachFormData));

          console.log('NewService.Assetdata', Assetdata)

        })
      }

      TotalData = {
        ...CommonData,
        NewServiceFormData: state.NewService.FormData,
        ExtraFormSummery: ExtraFormSummery,
        SearchWords: SearchWords,
        key: `Post||${state.CompanyId}||${currentTime}`,
        field1: Field1,
        field2: Field2,
        post: post,
        title: title,
        ServiceUrgency: ServiceUrgency,
        ServiceTrade: ServiceTrade,
        OriginalMessage: title,
        PhotoService: PhotoService,
        Documents: Documents,
        pk1: `Post::${state.CompanyId}`,
        sk1: currentTime,
        gsi1Pk2: PostId,
        gsi1Sk2: currentTime,
        gsi2Pk2: `Post::${ContractorData.ContractorId}`,
        gsi2Sk2: currentTime,
      };

      console.log('NewService.TotalData', TotalData)

      let input = {
        // find all post by customer sort by date/time
        pk1: `Post::${state.CompanyId}`,
        sk1: currentTime,
        // find post by Post Id - that needs to be in all replys
        gsi1Pk2: PostId,
        gsi1Sk2: currentTime,
        // find post by contractor id
        gsi2Pk2: `Post::${ContractorData.ContractorId}`,
        gsi2Sk2: currentTime,
        dataJSON: JSON.stringify(removeEmptyStringElements(TotalData)),
      };

      InputArray.push(removeEmptyStringElements(input));

      if (ServiceUrgency !== "") {
        let ShortDataUrgency = ShortData;
        ShortDataUrgency.key = `Service::Urgency::${UrgencyId}::${currentTime}`;
        ShortDataUrgency.pk1 = state.CompanyId;
        ShortDataUrgency.gsi2Pk2 = ContractorData.ContractorId;
        ShortDataUrgency.gsi2Sk2 = `Service::Urgency::${UrgencyId}::${currentTime}`
        console.log('NewService.ShortDataUrgency', ShortDataUrgency)
        let inputUrgency = {
          // find all post by customer sort by date/time
          pk1: state.CompanyId,
          sk1: `Service::Urgency::${UrgencyId}::${currentTime}`,
          // find post by Post Id - that needs to be in all replys
          gsi1Pk2: PostId,
          gsi1Sk2: currentTime,
          // find post by contractor id
          gsi2Pk2: ContractorData.ContractorId,
          gsi2Sk2: `Service::Urgency::${UrgencyId}::${currentTime}`,
          dataJSON: JSON.stringify(removeEmptyStringElements(ShortDataUrgency)),
        };
        InputArray.push(removeEmptyStringElements(inputUrgency));
      }

      if (ServiceTrade !== "") {
        let ShortDataTrade = ShortData;
        ShortDataTrade.key = `Service::Trade::${Tradeidd}::${currentTime}`;
        ShortDataTrade.pk1 = state.CompanyId;
        ShortDataTrade.sk1 = `Service::Trade::${Tradeidd}::${currentTime}`;
        ShortDataTrade.gsi2Pk2 = ContractorData.ContractorId;
        ShortDataTrade.gsi2Sk2 = `Service::Trade::${Tradeidd}::${currentTime}`;
        console.log('NewService.ServiceTrade', ShortDataTrade)
        let inputTradfe = {
          // find all post by customer sort by date/time
          pk1: state.CompanyId,
          sk1: `Service::Trade::${Tradeidd}::${currentTime}`,
          // find post by Post Id - that needs to be in all replys
          gsi1Pk2: PostId,
          gsi1Sk2: currentTime,
          // find post by contractor id
          gsi2Pk2: ContractorData.ContractorId,
          gsi2Sk2: `Service::Trade::${Tradeidd}::${currentTime}`,
          dataJSON: JSON.stringify(removeEmptyStringElements(ShortDataTrade)),
        };
        InputArray.push(removeEmptyStringElements(inputTradfe));
      }


      console.log('NewService.InputArray', InputArray)

    } catch (err) { console.log("SaveData err", err); }

  }

  /*
          FormdataInput = {
            FormData: FormData,
            SearchWords: SearchWords,
            ContractorData: ContractorData,
            date: currentTime,
            PersonData: UserData,
               CompanyId: CompanyId,
            CompanyName: CompanyName,
            id: UUid,
            key: key,
            field1: Field1,
            field2: Field2,
            ContractorId: ContractorData.ContractorId,
  
            post: post,
            title: title,
            ServiceUrgency: ServiceUrgency,
            ServiceTrade: ServiceTrade,
            PostId: PostId,
  
  
            CompanyId: props.cid.CompanyId,
            CompanyName: state.CompanyName,
            ContractorId: props.TotalState.Contractor.ContractorId,
            cid: props.cid,
            OriginalMessage: state.Title,
            date: currentTime,
            posterId: posterId,
            PostId: PostId,
            PhotoService: PhotoService,
            Documents: Documents,
            pk1: props.cid.CompanyId,
            sk1: `Data::Company::Items::${EachForm.FormType}::${EachForm.ItemId}`,
            gsi1Pk2: "Items::" + PostId,
            gsi1Sk2: currentTime,
            gsi2Pk2: props.TotalState.Contractor.ContractorId,
            gsi2Sk2: `Data::Company::Items::${EachForm.FormType}::${EachForm.ItemId}`,
          };
  
          AddNewItem = {
            pk1: props.cid.CompanyId,
            sk1: `Data::Company::Items::${EachForm.FormType}::${EachForm.ItemId}`,
            gsi1Pk2: "Items::" + PostId,
            gsi1Sk2: currentTime,
            gsi2Pk2: props.TotalState.Contractor.ContractorId,
            gsi2Sk2: `Data::Company::Items::${EachForm.FormType}::${EachForm.ItemId}`,
            dataJSON: JSON.stringify(removeEmptyStringElements(FormdataInput)),
          };
  
          InputArray.push(removeEmptyStringElements(AddNewItem));
        
  
          //FormdataJSON = {
  
            
         
  
          //  EscPolicyFound: EscPolicyFound,
  
  
          //  PhotoService: PhotoService,
          //  Documents: Documents,
          //  //phone: Phone,
          //  //email: Email,
          //  pk1: CompanyId,
          //  sk1: key,
          //  gsi2Pk2: ContractorData.ContractorId,
          //  gsi2Sk2: key
          //}
  
  
  
          dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))
  
  
          FormDD = {
            ...FormData,
            SearchWords: SearchWords,
          }
  
          console.log('GoingTODB1', FormdataJSON)
  
        }
  
        //if alerts
        if (MissingInfoFlag) {
          setState(prevState => ({
            ...prevState,
            AlarmMessage: AlarmMessage,
            OpenAlert: true
          }));
        } else {
          //if no alerts
  
          if (state.Category === "Person") {
            DBInput = {
              pk1: CompanyId,
              sk1: key,
              gsi1Pk2: ContractorData.ContractorId,
              gsi1Sk2: `phone::${Phone}`,
              gsi2Pk2: ContractorData.ContractorId,
              gsi2Sk2: `email::${Email}`,
              dataJSON: dataJSON
            }
  
            InputArray.push(removeEmptyStringElements(DBInput));
  
            DBInput2 = {
              pk1: CompanyId,
              sk1: `${Field1} ${Field2}`,
              gsi1Pk2: ContractorData.ContractorId,
              gsi1Sk2: `${Field1} ${Field2}`,
              gsi2Pk2: ContractorData.ContractorId,
              gsi2Sk2: key,
              dataJSON: dataJSON
            }
  
            InputArray.push(removeEmptyStringElements(DBInput2));
  
          } else {
  
            DBInput = {
              pk1: CompanyId,
              sk1: key,
              gsi1Pk2: ContractorData.ContractorId,
              gsi1Sk2: `${Field1} ${Field2}`,
              gsi2Pk2: ContractorData.ContractorId,
              gsi2Sk2: key,
              dataJSON: dataJSON
            }
  
            InputArray.push(removeEmptyStringElements(DBInput));
          }
  
  
          console.log('GoingTODB2', InputArray)
  
  
  
  
          //image resize lambda
          if (PhotoService.length > 0) {
            API.post("ImageResize", "/send", {
              body: { Records: PhotoService }
            });
          }
  
          //if (InputArray.length > 0) {
          //  API.graphql(graphqlOperation(createBatch, { input: InputArray }))
          //    .then((results) => {
          //      console.log('createBatch Data ', results);
  
          //      //if (props.ReturnBackData) {
          //      //  console.log('props.ReturnBackData', props.ReturnBackData)
          //      //  props.ReturnBackData(FormdataJSON);
          //      //}
  
          //      if (props.CloseDrawer) {
          //        props.CloseDrawer(FormDD);
          //      }
          //    })
  
          //  //if (props.ReturnBackData) {
          //  //        props.ReturnBackData(FormdataJSON);
          //  //      }
  
          //  if (props.CloseDrawer) {
          //    props.CloseDrawer();
          //  }
          //}
  
          {
            //HTMLText Trade ToDo_Priority Urgency Title
            console.log("Title", state.Title);
            console.log("Trade", state.TradeServicesList);
            console.log("Urgency", state.ServiceUrgency);
            console.log("HTMLText", state.HTMLText);
            console.log("ToDo_Priority", state.ToDo_Priority);
            console.log("RequestedDate", state.RequestedDate);
  
            console.log("ImageList", state.ImageList);
            console.log("DocumentList", state.DocumentList);
            console.log("EmailNotificationList", state.EmailNotificationList);
            console.log("FormValueArray", state.FormValueArray);
  
  
            try {
              const urg = state.ServiceUrgency.split("::");
              const RequestedDate = state.RequestedDate;
  
              let AlarmMessage = "";
              toast.dismiss();
              if (!state.ServiceUrgency) {
                AlarmMessage += "* Please select Action Requested ";
              }
  
              if (!state.Title) {
                AlarmMessage += "* Please add Short Description ";
              }
              if (
                state.TradeServicesList.length > 0 &&
                state.ServiceTrade.length < 1
              ) {
                AlarmMessage += "* Please select Skills Category ";
              }
  
              if (urg[0] === "2" && RequestedDate === "") {
                AlarmMessage += "* Please select Date ";
              }
  
              if (AlarmMessage !== "") {
                toast.error(AlarmMessage, {
                  position: "top-center",
                  autoClose: false,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                return;
              }
  
              //const CompanyID = props.cid.CompanyId; //'companyid'+uuidv1();
              //const UUid = NewPageId("Asst");
              let InputArray = [];
              //let PostId = state.PostId.replace(/[^a-zA-Z0-9_]/g, "");
              //let EquipId = "Data::Equip::" + UUid;
              //let LocationId = "Data::Company::Location::" + UUid;
              //const ContractorId = props.TotalState.Contractor.ContractorId; //"59efb960-532c-11e9-b5a0-a51ea3090ffe";
              var currentUnixTime = new Date();
              var currentTime = currentUnixTime.toISOString();
              let InfoToShow = [];
              let EachFormObjArr = {};
              let EachFormData = "";
              let FormdataJSON = "";
              let TotalShowArray = [];
              let TradeArr = [];
              let emailHTML = "-";
  
  
              var Documents = removeEmptyStringElements(state.DocumentList);
              var PhotoService = removeEmptyStringElements(state.ImageList);
              let posterId = removeEmptyStringElements(props.TotalState.Login.UserInfo);
  
              let RequestorId = props.TotalState.Login.UserInfo.PersonId;
              let RequestorName = props.TotalState.Login.UserInfo.name;
  
              // if(props.TotalState.Login.UserInfo.CustomerType === "Contractor"){
              //   RequestorId = state.PersonId;
              //   RequestorName = state.PersonName;
              // }
  
              let UrgencyMessage = [];
              UrgencyMessage.push({
                key: "UrgencyMessage1",
                label: "Service Urgency",
                value: urg[1],
              });
              let servicedatess = `${state.CompanyName} - ${props.TotalState.Login.UserInfo.name} - ${urg[1]}`;
  
              if (urg[0] == "1") {
                UrgencyMessage.push({
                  key: "UrgencyMessage2",
                  label: "Expected completion date",
                  value: RequestedDate,
                });
                UrgencyMessage.push({
                  key: "UrgencyMessage3",
                  label: "Priority",
                  value: state.ToDo_Priority,
                });
                servicedatess = `${servicedatess} - Expected completion date: ${RequestedDate} - Priority: ${state.ToDo_Priority}`;
              }
  
              if (urg[0] == "2") {
                UrgencyMessage.push({
                  key: "UrgencyMessage4",
                  label: "Requested Service date",
                  value: RequestedDate,
                });
                servicedatess = `${servicedatess} - Requested Service date: ${RequestedDate}`;
              }
  
              let ServiceDetails = {
                key: currentTime + 1990,
                title: "Service Request",
                type: "Card",
                ListType: "Text",
                Link: false,
                // LinkTo: "Reply",
                // LinkName: "Reply",
                InfoToShow: UrgencyMessage,
              };
  
              TotalShowArray.push(ServiceDetails);
  
              // get trade service selected
              state.ServiceTrade.map((eachtrade) => {
                let eacharr = eachtrade.split("::");
                TradeArr.push({
                  key: eacharr[0],
                  label: "Specialty",
                  value: eacharr[1],
                });
              });
  
              //create what to show on table
              let TableData = [
                { key: `woid`, label: "Work Order", value: PostId },
                {
                  key: `shortdescription`,
                  label: "Short Description",
                  value: state.Title,
                },
                { key: `requestdate`, label: "Request Date", value: currentTime },
                { key: `duedate`, label: "Due Date", value: RequestedDate },
                {
                  key: `poster`,
                  label: "Request by",
                  value: props.TotalState.Login.UserInfo.name,
                },
                { key: `urg`, label: "Action", value: urg[1] },
                ...TradeArr,
              ];
  
              let TradeShow = {
                key: currentTime + 2,
                title: `Services`,
                type: "Card",
                ListType: "List",
                Link: false,
                LinkTo: "Reply",
                LinkName: "Reply",
                InfoToShow: TradeArr,
              };
  
              TotalShowArray.push(removeEmptyStringElements(TradeShow));
  
              let PosterInformation = {
                key: currentTime + 3,
                title: "Service Posted by..",
                type: "Card",
                ListType: "Description",
                Link: false,
                InfoToShow: [
                  {
                    key: "main2",
                    label: "Name",
                    value: props.TotalState.Login.UserInfo.name || "-",
                  },
                  {
                    key: "main3",
                    label: "Email",
                    value: props.TotalState.Login.UserInfo.email || "-",
                  },
                  {
                    key: "main4",
                    label: "Phone",
                    value: props.TotalState.Login.UserInfo.phone || "-",
                  },
                  {
                    key: "main5",
                    label: "Company",
                    value: props.TotalState.Login.UserInfo.CompanyName || "-",
                  },
                ],
              };
  
              TotalShowArray.push(removeEmptyStringElements(PosterInformation));
  
              if (props.Requestor) {
                let RosterInformation = {
                  key: currentTime + 3,
                  title: "Service Requested by..",
                  type: "Card",
                  ListType: "Description",
                  Link: false,
                  InfoToShow: [
                    {
                      key: "main2",
                      label: "Name",
                      value: props.TotalState.Login.UserInfo.name || "-",
                    },
                    {
                      key: "main3",
                      label: "Email",
                      value: props.TotalState.Login.UserInfo.email || "-",
                    },
                    {
                      key: "main4",
                      label: "Phone",
                      value: props.TotalState.Login.UserInfo.phone || "-",
                    },
                    {
                      key: "main5",
                      label: "Company",
                      value: props.TotalState.Login.UserInfo.CompanyName || "-",
                    },
                  ],
                };
  
                TotalShowArray.push(removeEmptyStringElements(RosterInformation));
              }
  
              // let DBAccess = {
              //   pk1: props.cid.CompanyId,
              //   sk1: `History::Items::`,
              //   gsi1Pk2: "Items::" + PostId,
              //   gsi1Sk2: currentTime,
              //   gsi2Pk2: props.TotalState.Contractor.ContractorId,
              //   gsi2Sk2: `History::Items::`
              // }
  
              // let FormDataBack = FormShowInformation(state.FormValueArray, true, {}, DBAccess, false, currentTime)
              // console.log("FormDataBack..", state.FormValueArray, FormDataBack);
              let Field1 = "";
              let Field2 = "";
              let SearchString = "";
              // put each form data in db
              if (state.FormValueArray.length > 0) {
                state.FormValueArray.map((EachForm, index) => {
  
  
                  if (EachForm.FormType === "Location") {
                    TableData.push({
                      key: "Location",
                      label: "Location",
                      value: `${Field1}, ${Field2}`,
                    });
                  }
  
                  if (EachForm.FormType === "Equipment") {
                    TableData.push({
                      key: "Equipment",
                      label: "Equipment",
                      value: `${Field1}, ${Field2}`,
                    });
                  }
  
                  if (EachForm.FormType === "Approval") {
                    TableData.push({
                      key: "Approval",
                      label: "Approval",
                      value: `${Field1}, ${Field2}`,
                    });
                  }
  
                  if (EachForm.FormType === "Payment") {
                    TableData.push({
                      key: "Payment",
                      label: "Payment",
                      value: `${Field1}, ${Field2}`,
                    });
                  }
  
                  FormdataJSON = {
                    ...EachForm,
                    CompanyId: props.cid.CompanyId,
                    CompanyName: state.CompanyName,
                    ContractorId: props.TotalState.Contractor.ContractorId,
                    cid: props.cid,
                    OriginalMessage: state.Title,
                    date: currentTime,
                    posterId: posterId,
                    PostId: PostId,
                    PhotoService: PhotoService,
                    Documents: Documents,
                    pk1: props.cid.CompanyId,
                    sk1: `History::Items::${EachForm.FormType}::${currentTime}::${EachForm.ItemId}`,
                    gsi1Pk2: "Items::" + PostId,
                    gsi1Sk2: currentTime,
                    gsi2Pk2: props.TotalState.Contractor.ContractorId,
                    gsi2Sk2: `History::Items::${EachForm.FormType}::${EachForm.ItemId}::${currentTime}`,
                  };
  
                  EachFormData = {
                    pk1: props.cid.CompanyId,
                    sk1: `History::Items::${EachForm.FormType}::${currentTime}::${EachForm.ItemId}`,
                    gsi1Pk2: "Items::" + PostId,
                    gsi1Sk2: currentTime,
                    gsi2Pk2: props.TotalState.Contractor.ContractorId,
                    gsi2Sk2: `History::Items::${EachForm.FormType}::${EachForm.ItemId}::${currentTime}`,
                    dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON)),
                  };
  
                  InputArray.push(removeEmptyStringElements(EachFormData));
  
                  // adding new item - equipment/address/form/etc
                  if (EachForm.NewValue) {
                    let FormdataInput = {
                      ...EachForm,
                      CompanyId: props.cid.CompanyId,
                      CompanyName: state.CompanyName,
                      ContractorId: props.TotalState.Contractor.ContractorId,
                      cid: props.cid,
                      OriginalMessage: state.Title,
                      date: currentTime,
                      posterId: posterId,
                      PostId: PostId,
                      PhotoService: PhotoService,
                      Documents: Documents,
                      pk1: props.cid.CompanyId,
                      sk1: `Data::Company::Items::${EachForm.FormType}::${EachForm.ItemId}`,
                      gsi1Pk2: "Items::" + PostId,
                      gsi1Sk2: currentTime,
                      gsi2Pk2: props.TotalState.Contractor.ContractorId,
                      gsi2Sk2: `Data::Company::Items::${EachForm.FormType}::${EachForm.ItemId}`,
                    };
  
                    let AddNewItem = {
                      pk1: props.cid.CompanyId,
                      sk1: `Data::Company::Items::${EachForm.FormType}::${EachForm.ItemId}`,
                      gsi1Pk2: "Items::" + PostId,
                      gsi1Sk2: currentTime,
                      gsi2Pk2: props.TotalState.Contractor.ContractorId,
                      gsi2Sk2: `Data::Company::Items::${EachForm.FormType}::${EachForm.ItemId}`,
                      dataJSON: JSON.stringify(removeEmptyStringElements(FormdataInput)),
                    };
                    InputArray.push(removeEmptyStringElements(AddNewItem));
                  }
  
                  //console.log("NewSvc Form data", FormdataJSON);
  
                  TotalShowArray.push(removeEmptyStringElements(EachForm.ShowInfo));
                }); // each form add
              } // if forms are filled
  
              emailHTML = renderEmail(
                TotalShowArray,
                servicedatess,
                state.HTMLText,
                UUid
              );
              //let htmlemailstring =
  
              //ReactDOMServer.renderToStaticMarkup(<ShowInformation ShowInfo={TotalShowArray} />)
  
              const formData = {
                Notification: state.SelctedEscPolicy,
                //PersonalList: state.SelctedEscPolicyPersonalList, //??
                CompanyId: props.cid.CompanyId,
                CompanyName: state.CompanyName,
                cid: props.cid,
                ContractorId: props.TotalState.Contractor.ContractorId,
                date: currentTime,
                PostId: PostId,
                title: state.Title,
                OriginalMessage: servicedatess,
                ShowInfo: TotalShowArray,
                post: state.HTMLText,
                ServiceUrgency: state.ServiceUrgency,
                ServiceTrade: state.ServiceTrade,
                ServiceRequestDate: GetDate(),
                PostPlaneText: state.PlaneText,
                posterId: posterId,
                ExtraNotification: state.extraemails,
                PhotoService: PhotoService,
                Documents: Documents,
              };
              //setState({TotalShowArray: TotalShowArray}, () => {})
  
              //console.log("TotalShowArray", TotalShowArray)
              const totaldata = removeEmptyStringElements(formData);
  
              const formDataMini = {
                //Notification: state.SelctedEscPolicy,
                //PersonalList: state.SelctedEscPolicyPersonalList,  //??
                CompanyId: props.cid.CompanyId,
                CompanyName: state.CompanyName,
                cid: props.cid,
                ContractorId: props.TotalState.Contractor.ContractorId,
                date: currentTime,
                PostId: PostId,
                title: state.Title,
                OriginalMessage: servicedatess,
                //ShowInfo: TotalShowArray,
                post: state.HTMLText,
                ServiceUrgency: state.ServiceUrgency,
                ServiceTrade: state.ServiceTrade,
                ServiceRequestDate: GetDate(),
                //PostPlaneText: state.PlaneText,
                posterId: posterId,
                //ExtraNotification: state.extraemails,
                //PhotoService: PhotoService,
                //Documents: Documents,
              };
              //setState({TotalShowArray: TotalShowArray}, () => {})
  
              //console.log("TotalShowArray", TotalShowArray)
              const totaldataMini = removeEmptyStringElements(formDataMini);
  
              // seperate search for document & pictures
              if (
                state.ImageList.length > 0 ||
                state.DocumentList.length > 0
              ) {
                const Docinfo = {
                  PhotoService: PhotoService,
                  CompanyId: props.cid.CompanyId,
                  title: state.Title,
                  CompanyName: state.CompanyName,
                  ContractorId: props.TotalState.Contractor.ContractorId,
                  posterId: posterId,
                  date: currentTime,
                  PostId: PostId,
                  Documents: Documents,
                };
  
                const DocData = {
                  pk1: props.cid.CompanyId,
                  sk1: `History::Doc::${currentTime}`,
                  gsi1Pk2: "Items::" + PostId,
                  gsi1Sk2: currentTime,
                  gsi2Pk2: props.TotalState.Login.UserInfo.PersonId,
                  gsi2Sk2: `History::Doc::${currentTime}`,
                  dataJSON: JSON.stringify(removeEmptyStringElements(Docinfo)),
                };
                InputArray.push(DocData);
              }
  
              // // payment insert
              // if (state.ServiceApproval) {
              //   if (state.ServiceApproval.Payment !== "") {
  
              //     const PaymentData = {
              //       pk1: `Payment::${props.cid.CompanyId}`,
              //       sk1: state.ServiceApproval.Payment + "::" + currentTime,
              //       gsi2Pk2: 'Payment::' + props.TotalState.Contractor.ContractorId,
              //       gsi2Sk2: state.ServiceApproval.Payment + "::" + currentTime,
              //       dataJSON: JSON.stringify(removeEmptyStringElements(totaldata))
              //     }
              //     InputArray.push(PaymentData);
              //   }
              // }
  
              // const ServiceLevels = {
              //   pk1: 'Svc::' + props.TotalState.Contractor.ContractorId,
              //   sk1: state.ServiceUrgency + "::" + currentTime,
              //   // gsi1Pk2: 'Svc::' + props.TotalState.Contractor.ContractorId,
              //   // gsi1Sk2: state.ServiceUrgency+"::"+currentTime,
              //   gsi2Pk2: 'Svc::' + props.TotalState.Contractor.ContractorId,
              //   gsi2Sk2: state.ServiceTrade + "::" + currentTime,
              //   dataJSON: JSON.stringify(removeEmptyStringElements(totaldata))
              // }
              // InputArray.push(ServiceLevels);
  
              const inputProgress = {
                // find all post by customer sort by date/time
                pk1: props.cid.CompanyId,
                sk1: "History::Progress::0::" + currentTime,
                // find post by Post Id - that needs to be in all replys
                gsi1Pk2: "Items::" + PostId,
                gsi1Sk2: currentTime,
                // find post by contractor id
                gsi2Pk2: props.TotalState.Contractor.ContractorId,
                gsi2Sk2: "History::Progress::0::" + currentTime,
                dataJSON: JSON.stringify(totaldataMini),
              };
              InputArray.push(removeEmptyStringElements(inputProgress));
  
              const inputUrgency = {
                // find all post by customer sort by date/time
                pk1: props.cid.CompanyId,
                sk1: `History::Urgency::${state.ServiceUrgency}::` + currentTime,
                // find post by Post Id - that needs to be in all replys
                gsi1Pk2: "Items::" + PostId,
                gsi1Sk2: currentTime,
                // find post by contractor id
                gsi2Pk2: props.TotalState.Contractor.ContractorId,
                gsi2Sk2:
                  `History::Urgency::${state.ServiceUrgency}::` + currentTime,
                dataJSON: JSON.stringify(totaldataMini),
              };
              InputArray.push(removeEmptyStringElements(inputUrgency));
  
              const inputTrade = {
                // find all post by customer sort by date/time
                pk1: props.cid.CompanyId,
                sk1: `History::Trade::${state.ServiceTrade}::` + currentTime,
                // find post by Post Id - that needs to be in all replys
                gsi1Pk2: "Items::" + PostId,
                gsi1Sk2: currentTime,
                // find post by contractor id
                gsi2Pk2: props.TotalState.Contractor.ContractorId,
                gsi2Sk2: `History::Trade::${state.ServiceTrade}::` + currentTime,
                dataJSON: JSON.stringify(totaldataMini),
              };
              InputArray.push(removeEmptyStringElements(inputTrade));
  
              const RequestorIdInput = {
                // find all post by customer sort by date/time
                pk1: props.cid.CompanyId,
                sk1: "History::Person::" + RequestorId,
                // find post by Post Id - that needs to be in all replys
                gsi1Pk2: "Items::" + PostId,
                gsi1Sk2: currentTime,
                // find post by contractor id
                gsi2Pk2: props.TotalState.Contractor.ContractorId,
                gsi2Sk2: "History::" + RequestorId,
                dataJSON: JSON.stringify(totaldataMini),
              };
              InputArray.push(removeEmptyStringElements(RequestorIdInput));
  
              const input = {
                // find all post by customer sort by date/time
                pk1: `Post::${props.cid.CompanyId}`,
                sk1: currentTime,
                // find post by Post Id - that needs to be in all replys
                gsi1Pk2: PostId,
                gsi1Sk2: currentTime,
                // find post by contractor id
                gsi2Pk2: "Post::" + props.TotalState.Contractor.ContractorId,
                gsi2Sk2: currentTime,
                dataJSON: JSON.stringify(totaldata),
              };
              InputArray.push(removeEmptyStringElements(input));
  
              if (state.ImageList.length > 0) {
  
                //API.post("ImageResize", "/send", {
                //    body: { Records: state.ImageList },
                //});
  
              }
  
              //API.graphql(graphqlOperation(createBatch, { input: InputArray })).then(
              //    (results) => {
              //        console.log("createBatch Data ", results);
              //    }
              //);
  
              // triggering each notification policy
              if (state.SelctedEscPolicy.length > 0) {
                state.SelctedEscPolicy.map((eachNotigy, index) => {
                  let StepDatas = {
                    pk1: `${PostId}-${index}`,
                    sk1: "Start::" + currentTime,
                    gsi1Pk2: PostId,
                    gsi1Sk2: currentTime,
                    gsi2Pk2: "Notify::" + props.TotalState.Contractor.ContractorId,
                    gsi2Sk2: currentTime,
                    dataJSON: JSON.stringify(removeEmptyStringElements(eachNotigy)),
                  };
  
                  let LambaParms = {
                    pk1: `${PostId}-${index}`,
                    PostId: PostId,
                    CompanyId: props.cid.CompanyId,
                    ContractorId: props.TotalState.Contractor.ContractorId,
                    CompanyName: state.CompanyName,
                    ContractorName: props.TotalState.Contractor.ContractorName,
                    ContractorPhone: props.TotalState.Contractor.ContractorPhone,
                    step: 1,
                    topic:
                      "arn:aws:sns:us-east-1:109857144948:StepFunctionsSample-TaskTimer2223d79a-2ab2-4296-8cfb-7643b22873fa-SNSTopic-1CWBSNJAZQMTW",
                    steps: eachNotigy.steps,
                    message: servicedatess,
                    subject: servicedatess,
                    replyToEmails: props.TotalState.Login.UserInfo.email,
                    emailHTML: emailHTML,
                    EmailFrom: "no-reply@svcbot.com",
                  };
                  console.log("trigger", JSON.stringify(LambaParms));
                  //API.graphql(
                  //    graphqlOperation(createSvcbotDb, { input: StepDatas })
                  //).then((results) => {
                  //    TriggerLambda(LambaParms);
                  //});
                });
              }
            } catch (err) {
              console.error("error from api", err);
            }
          }
  
        } // end of no alerts



ServiceSteps: [
      {
        key: "title",
        label: "Title",
        ModuleTitle: "Title",
        message: "Please enter heading / title",
        LoadComponenet: "Title",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "title",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        CompanyId: cid.CompanyId,
        VariableInput: "",
      },


  */



  const SaveAsDraft = () => {

    let FindTitle = state.ServiceSteps.find(x => x.LoadComponenet === "Title")
    if (FindTitle && FindTitle.VariableInput !== "") {

      const totaldata = {
        field1: FindTitle.VariableInput,
        SearchWords: FindTitle.VariableInput,
        SaveState: state,
        date: currentTime,
        Requestor: {
          pk1: state.Requestor.pk1,
          sk1: state.Requestor.sk1,
          field1: state.Requestor.filed1,
          field2: state.Requestor.filed2,
          avatar: state.Requestor.avatar,
        },
        posterId: {
          pk1: UserData.pk1,
          sk1: UserData.sk1,
          field1: UserData.filed1,
          field2: UserData.filed2,
          avatar: UserData.avatar,
        },
      };

      const input = {
        // find all post by customer sort by date/time
        pk1: `Draft::${state.CompanyId}`,
        sk1: currentTime,
        // find post by Post Id - that needs to be in all replys
        gsi1Pk2: `Draft::${UserData.key}`,
        gsi1Sk2: currentTime,
        // find post by contractor id
        gsi2Pk2: "Draft::" + ContractorData.ContractorId,
        gsi2Sk2: currentTime,
        dataJSON: JSON.stringify(totaldata),
      };

      const inputt = removeEmptyStringElements(input);

      console.log("NewServiceSteps.Draft1 ", inputt);
      console.log("NewServiceSteps.Draft2 ", totaldata);
      console.log("NewServiceSteps.Draft3 ", UserData);

      API.graphql(graphqlOperation(createSvcbotDb, { input: inputt })).then(
        (results) => {
          console.log("createBatch Data ", results);
          if (props.CloseDrawer) {
            props.CloseDrawer();
          }

        }
      );
    } else {
      //send alert
      setState(prevState => ({
        ...prevState,
        AlarmMessage: "Title is required field to save as draft",
        OpenAlert: true
      }));
    }

  };

  const handleClose = (event, reason) => {
    // handle close of Alert

    setState(prevState => ({
      ...prevState,
      AlarmMessage: "",
      OpenAlert: false
    }));

  };

  const SelectedPerson = (e) => {

    LoadPref(e);
    //NewServicePref =  NewServicePref;
    console.log("NewServicePref", e)

    setState(prevState => ({
      ...prevState,
      Requestor: e,
      ShowNewServiceForm: true
    }));

  }

  const SaveImage = (name, e) => {
    setState(prevState => ({
      ...prevState,
      [name]: e
    }));
  };

  const ReturnBackData = form => {
    //console.log('formItemAddForm1', form);
    //let projects = form.concat(state.NewService); //[...state.NewService, ...form]
    console.log('formItemAddForm1', form);



    setState(prevState => ({
      ...prevState,
      TotalFormData: form
    }));
  }

  //const FlatFormData = (AllLevels, SelectedLevel=[]) => {
  //  console.log("NewServiceLevel.Level1", AllLevels);
  //  console.log("NewServiceLevel.SelectedLevel", SelectedLevel);
  //  let RightLevel = false;
  //  //AllLevels.map(Level => {
  //  for (var i = 0; i < AllLevels.length; i++) {

  //    if (AllLevels[i] && AllLevels[i].FormData) {
  //      console.log("NewServiceLevel.Level2", AllLevels[i]);
  //      RightLevel = false;
  //      FlatFormData(AllLevels[i].FormData, AllLevels[i]);
  //      break;
  //    } else {
  //      RightLevel = true;
  //      console.log("NewServiceLevel.Level3", Level);
  //    }

  //    if (RightLevel) {
  //      console.log("NewServiceLevel.RightLevel", SelectedLevel);
  //    }

  //  }

  //    console.log("NewServiceLevel.RightLevel", Level);
  //console.log("NewServiceLevel.OldTotalFormData", state.TotalFormData);
  ////let RightLevel = state.TotalFormData;
  //let TotalFormData = [...state.TotalFormData, Level];
  //console.log("NewServiceLevel.NewTotalFormData", TotalFormData);
  //setState(prevState => ({
  //  ...prevState,
  //  TotalFormData: TotalFormData
  //}));


  // }

  const CreateHTML = (AllForms) => {
    let TotalHTML = "";
    console.log("NewService.CreateHTML.Enter", AllForms);
    AllForms.map(eachEntry => {
      if (eachEntry.AllItemForms) {
        CreateHTML(eachEntry.AllItemForms);
        console.log("NewService.CreateHTML.AllItemForms", eachEntry);
      } else if (eachEntry.FormData) {
        TotalHTML = buildTable(eachEntry.FormData);
        console.log("NewService.CreateHTML.FormData", eachEntry.FormData);
      }
    })

  }

  const buildTable = (arr) => {
    const rows = arr.map((row, i) => {
      return <tr style={{ backgroundColor: i % 2 ? '#F0FFF2' : 'white' }} key={i}>
        <td>{row.label}</td><td>{row.VariableInput}</td>
      </tr>
    })
    return <table><tbody>{rows}</tbody></table>
  }

  const OnFormSubmit = (model, key) => {
    try {

      console.log("formItemAddForm2", model);

      setState(prevState => ({
        ...prevState,
        NewService: model
      }));

    } catch (err) { }
  };

  const SelectedValues = (data, verable, key) => {
    //let ServiceSteps = state.ServiceSteps;
    //ServiceSteps[key].VariableInput = data;
    setState(prevState => ({
      ...prevState,
      [verable]: data,
      //ServiceSteps: ServiceSteps
    }));
    console.log("NewServiceSteps.SelectedValues", verable, data)
  }


  const AddToImage = (data, verable) => {
    setState(prevState => ({
      ...prevState,
      [verable]: data,

      //[data, ...state[verable]],
    }));
    console.log("NewServiceSteps.AddToImage", verable, data)
  }

  const SearchWordsToHTML = (search) => {
    try { 
    //let search = "Location | 443 Olive Ave, Long Beach, CA 90802, USA | || Building | 12332 | || Room | 2233 | ";
    let item = search.split("||");
    console.log("item", item);
    let eachline = "";
    let NewHTML = "<p>";
    let trimmed = "";
    item.forEach(lines => {
      eachline = lines.replaceAll(" | ", ": ")
      trimmed = eachline.trim()
      NewHTML += trimmed.replace(/:([^:]*)$/, '<br/>');
    })
    NewHTML += "</p>";

      return NewHTML;
    } catch (err) {
      console.log("NewServiceSteps.SearchWordsToHTML", err)
    }
  }

  const DropDownSelectedItem = (selected, step) => {
    try { 
    let HTML = "<p>";
    let NewString = "";
    let DataSendArrayName = step.DataSendArrayName;
    let Dropdown = state[DataSendArrayName];
    let thevalue = Dropdown.find(x => x.key === selected);
    HTML += `${step.label}: ${thevalue.label}`;
    HTML += "</p>";
    NewString = thevalue.label;
      return { HTML, NewString };
    } catch (err) {
      console.log("NewServiceSteps.DropDownSelectedItem", err)
    }
  }

  const ParseDateRange = dates => {
    let datevalue = "";
    let HTML = "<p>";
    let NewString = "";
    let EndDateValue = "";

    dates.map(date => {
      if (date.VariableName === "StartDate" || date.VariableName === "EndDate") {
        datevalue = formatDate(date.value)
      } else {
        datevalue = date.value;
      }

      HTML += `${date.label}: ${datevalue}`;
      HTML += `<br/>`;
      NewString += `${date.label}: ${datevalue} | `;

      if (date.VariableName === "EndDate") {
        EndDateValue = new Date(date.value);
        EndDateValue = EndDateValue.toISOString();
      }
    })
    HTML += "</p>";
    return { HTML, NewString, EndDateValue };
  }

  const FindLocation = (data) => {
    try {
      console.log("NewServiceSteps.FindLocation1", data);
      if (data.Location && Object.keys(data.Location).length > 0) {
        console.log("NewServiceSteps.FindLocation2", data.Location);
      }
    } catch (err) {
      console.log("NewServiceSteps.FindLocation.err", err);
    }
  }

  const handleNext = (item, required, key) => {

    let Nextstepgo = false;
    let ShowHTML = "";
    let pk1 = "";
    let sk1 = "";
    let field1 = "";
    let SearchWords = "";
    let HTMLString = "";
    let EndDateValue = "";

    if (required) {
      if (state[item] && state[item].length > 0) {
        Nextstepgo = true;
      } else {
        Nextstepgo = false;
      }
    } else {
      Nextstepgo = true
    }

    if (Nextstepgo) {

      let ServiceSteps = state.ServiceSteps;
      let Itemdetails = state[item];

      switch (ServiceSteps[key].LoadComponenet) {
        case "Autocomplete":
          ShowHTML = SearchWordsToHTML(Itemdetails.SearchWords);
          field1 = Itemdetails.field1;
          SearchWords = Itemdetails.SearchWords;
          FindLocation(Itemdetails);
          pk1 = Itemdetails.pk1;
          sk1 = Itemdetails.sk1;
          break;
        case "Title":
          ShowHTML = Itemdetails;
          field1 = Itemdetails;
          SearchWords = Itemdetails;
          pk1 = "";
          sk1 = "";
          break;
        case "Description":
          ShowHTML = Itemdetails;
          field1 = "";
          SearchWords = "";
          pk1 = "";
          sk1 = "";
          break;
        case "ShowTradeOption":
          HTMLString = DropDownSelectedItem(Itemdetails, ServiceSteps[key]);
          ShowHTML = HTMLString.HTML;
          field1 = HTMLString.NewString;
          SearchWords = HTMLString.NewString;
          pk1 = ContractorData.ContractorId;
          sk1 = Itemdetails;
          break;
        case "DateTimeRange":
          HTMLString = ParseDateRange(Itemdetails);
          ShowHTML = HTMLString.HTML;
          field1 = HTMLString.NewString;
          SearchWords = HTMLString.NewString;
          EndDateValue = HTMLString.EndDateValue;
          pk1 = "";
          sk1 = "";
          break;
        case "Title":
          ShowHTML = Itemdetails;
          field1 = Itemdetails;
          SearchWords = Itemdetails;
          pk1 = "";
          sk1 = "";
          break;

      }

      HTMLString = "";

      ServiceSteps[key].VariableInput = state[item];
      ServiceSteps[key].ShowHTML = ShowHTML;
      ServiceSteps[key].field1 = field1;
      ServiceSteps[key].SearchWords = SearchWords;
      ServiceSteps[key].EndDateValue = EndDateValue;
      ServiceSteps[key].pk1 = pk1;
      ServiceSteps[key].sk1 = sk1;
      EndDateValue = "";
      console.log("NewServiceSteps.ServiceSteps", ServiceSteps);
      setState(prevState => ({
        ...prevState,
        ServiceSteps: ServiceSteps
      }));

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setState(prevState => ({
        ...prevState,
        AlarmMessage: "Please select items before proceeding to next step",
        OpenAlert: true
      }));
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const CloseDrawerrr = () => {
    props.CloseDrawer()
  }


  //console.log('NewService.TotalFormData2', state.TotalFormData);
  return (
    <Page
      className={classes.root}
      title="Account"
    >



      <Grid
        container
        spacing={1}
      >

        <Grid
          item
          xs={12}
        >

          <Stepper activeStep={activeStep} orientation="vertical">
            {state.ServiceSteps && state.ServiceSteps.map((eachstep, indd) => {

              return (

                <Step key={indd}>
                  <StepLabel>{eachstep.label}{eachstep.required ? " (required)" : " (optional)"}</StepLabel>
                  <StepContent>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Typography>
                          {eachstep.message}
                        </Typography>
                      </Grid>
                      {/*    
                          <Grid item xs={12}>
                            <Card>
                              <Typography gutterBottom variant="subtitle1">
                                {"showvalues"}
                              </Typography>
                            </Card>
                          </Grid>
                          */}
                      <Grid item xs={12}>
                        <RenderModule
                          key={eachstep.key + indd + "key"}
                          VariableName={eachstep.key}
                          label={eachstep.label}
                          CompanyId={state.CompanyId}
                          Category={eachstep.Category}
                          id={eachstep.key + indd}
                          title={eachstep.ModuleTitle}
                          VariableInput={state[eachstep.DataReceivedArrayName]}
                          disabled={eachstep.disabled}
                          DataSent={state[eachstep.DataSendArrayName]}
                          LoadComponenet={eachstep.LoadComponenet}
                          onSubmit={data => SelectedValues(data, eachstep.DataReceivedArrayName, indd)}
                          SelectedValues={data => SelectedValues(data, eachstep.DataReceivedArrayName, indd)}
                          ImageList={(e) => AddToImage(e, "PhotoService")}
                          DocumentList={(e) => AddToImage(e, "Documents")}
                          Trigger1={eachstep.Trigger1}
                          Trigger2={eachstep.Trigger2}
                          Trigger3={eachstep.Trigger3}
                        />

                      </Grid>

                      {eachstep.LoadComponenet2 !== "" ? (
                        <Grid item xs={12}>
                          <RenderModule
                            key={eachstep.key + indd + "key2"}
                            VariableName={eachstep.key}
                            label={eachstep.label}
                            ImageDisplay={state.PhotoService}
                            id={eachstep.key + indd + "mod2"}
                            title={eachstep.ModuleTitle}
                            disabled={eachstep.disabled}
                            LoadComponenet={eachstep.LoadComponenet2}
                            ImageList={(e) => SelectedValues(e, "PhotoService")}
                            DocumentList={(e) => SelectedValues(e, "Documents")}
                            Trigger1={eachstep.Trigger1}
                            Trigger2={eachstep.Trigger2}
                            Trigger3={eachstep.Trigger3}
                          />
                        </Grid>
                      ) : null}

                      {eachstep.LoadComponenet3 !== "" ? (
                        <Grid item xs={12}>
                          <RenderModule
                            ImageDisplay={state.Documents}
                            key={eachstep.key + indd + "key3"}
                            label={eachstep.label}
                            VariableName={eachstep.key}
                            id={eachstep.key + indd + "mod3"}
                            title={eachstep.ModuleTitle}
                            disabled={eachstep.disabled}
                            LoadComponenet={eachstep.LoadComponenet3}
                            ImageList={(e) => SelectedValues(e, "PhotoService")}
                            DocumentList={(e) => SelectedValues(e, "Documents")}
                            Trigger1={eachstep.Trigger1}
                            Trigger2={eachstep.Trigger2}
                            Trigger3={eachstep.Trigger3}
                          />
                        </Grid>
                      ) : null}


                      <Grid item xs={12}>
                        <div className={classes.actionsContainer}>
                          <div>
                            {indd > 0 && (
                              <Button
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Back
                              </Button>
                            )}

                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleNext(eachstep.DataReceivedArrayName, eachstep.required, indd)}
                              className={classes.button}
                            >
                              {'Next'}
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </StepContent>
                </Step>

              )

            })}
          </Stepper>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Button onClick={SaveAsDraft} className={classes.button}>
            Save As Draft
          </Button>
        </Grid> SubmitData
                <Grid
          item
          xs={12}
        >
          <Button onClick={SubmitData} className={classes.button}>
            Submit Data
          </Button>
        </Grid>

      </Grid>

      <Snackbar open={state.OpenAlert} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
      <DrawerShow
        LoadComponenet={state.DetailComponent}
        propss={{
          SelectedAsset: (e) => SelectedAsset(e),
          CompanyId: state.CompanyId,
          CompanyName: state.DrawerCompanyName,
          title: state.DrawerTitle,
          PrivateMessage: state.PrivateMessage,
          Category: state.DrawerCategory,
          FormData: state.DrawerFormData
        }}
        DrawerStatus={DeawerOpen}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
    </Page>
  );
};

export default Account;
