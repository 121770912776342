import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
//import * as Yup from 'yup';
//import { Formik } from 'formik';
import {
    Box, Button, Container, Snackbar, Grid, FormGroup,
    TextField, Typography, LinearProgress, Checkbox, FormControlLabel
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch as UseDispt } from 'react-redux';
import {
    removeEmptyStringElements,
    ConfirmationNumber,
    UpdateData
} from '../../utils/CommonGraphql'
//import { currentTime } from '../../utils/DateTime';
//import { createBatch } from '../../graphql/mutations';
import { strengthIndicator, strengthColor } from "../../utils/PasswordStrength";
import { Auth, API, graphqlOperation } from "aws-amplify";
import Page from '../../components/Page';
//import { FormSchema, CommonFormUpdated, FormPrefArray } from '../../Forms/FormList';
import PhoneInput from 'react-phone-input-2';
import { SendEmail } from '../../utils/SendEmail';


//import awsconfig from '../../aws-exports';

//var store = require('store');
var validator = require("email-validator");
//const ContractorId = window.ContractorId;

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
});

const dynamodb = new AWS.DynamoDB.DocumentClient();


const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    field: {
        margin: '10px 0',
    },
    countryList: {
        ...theme.typography.body1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


function LinearProgressWithLabel(props) {

    console.log("LinearProgressWithLabel", props);

    let Display = 0;
    let percentvalue = Math.round(props.value, 0);
    if (percentvalue && percentvalue > 0) {
        Display = percentvalue;
    }
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Display}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const UpdateDB = (event) => {
    try {
        console.log(`Loginpage.UpdateDB`, event);
        var params = {

            Key: {
                'pk1': event.pk1,
                'sk1': event.sk1,
            },
            ExpressionAttributeNames: {
                "#gsi1Pk2": "gsi1Pk2",
                "#gsi1Sk2": "gsi1Sk2",
                "#gsi2Pk2": "gsi2Pk2",
                "#gsi2Sk2": "gsi2Sk2",
                "#dataJSON": "dataJSON"
            },
            TableName: "svcbot_db",
            UpdateExpression: "set #gsi1Pk2 = :gsi1Pk2, #gsi1Sk2 = :gsi1Sk2, #gsi2Pk2 = :gsi2Pk2, #gsi2Sk2 = :gsi2Sk2, #dataJSON = :dataJSON",
            ExpressionAttributeValues: {
                ":gsi1Pk2": event.gsi1Pk2,
                ":gsi1Sk2": event.gsi1Sk2,
                ":gsi2Pk2": event.gsi2Pk2,
                ":gsi2Sk2": event.gsi2Sk2,
                ":dataJSON": event.dataJSON
            },
        };

        dynamodb.update(params, function (err, data) {
            if (err) {
                //callback(err);
                console.log("Loginpage.UpdateDB.err:", err);
            } else {
                //callback(null, data);
                console.log(`Loginpage.UpdateDB.success`);
            }
        });

    } catch (err) {
        console.log('PutInDB err', err);
    }

}; // end PutInDB

var GetDBData = (params) => {
    return new Promise((resolve, reject) => {
        dynamodb.query(params, function (err, data) {
            if (err) {
                console.log("errrr", err, err.stack);
                // an error occurred
            } else {
                console.log("databack DB", data)
                resolve(data);
            }// successful response
        });
    });
};

const GetData = async (CompanyId, sk1) => {
    try {
        let getItemParams2 = {
            //IndexName: "gsi1Pk2-gsi1Sk2-index",
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1)",
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1",
            },
            ExpressionAttributeValues: {
                ":pk1": CompanyId,
                ":sk1": sk1
            }
        };

        let EscPolicy = await GetDBData(getItemParams2);

        console.log("LoginPage.ContractorId.EscPolicy", sk1, EscPolicy)

        return EscPolicy.Items;

    } catch (err) {
        console.log('GetData err', err);
    }
};

const BatchWrite = (InputArray) => {
    try {
        let params = {
            RequestItems: {
                "svcbot_db": InputArray
            }
        };
        //  console.log(`BatchWrite.params: ${JSON.stringify(params)}`);
        dynamodb.batchWrite(params, function (err, data) {
            if (err) {
                //callback(err);
                console.log(`BatchWrite.err: ${err}`);
                // return false;
            } else {
                console.log("BatchWrite.worked:", data);
                //  return true;
            }
        });
    } catch (err) {
        console.log('BatchWrite err', err);
    }
}


const LoginView = (props) => {
    const classes = useStyles();
    const dispatch = UseDispt()
    const navigate = useNavigate();
    console.log("LoginPage.props", props)
    const initialState = {
        username: "",
        password: "",
        confirm: "",
        email: "",
        mobile: "",
        ContractorId: window.ContractorId,
        step: "SignInEmail",
        AlartMessage: "",
        StrengthLevel: 0,
        authcode: "",
        open: false,
        confirmmessage: "",
        toDashboard: false,
        ContractorName: window.ContractorName,
        usernamecode: "",
        Direction: "",
        loggo: "",
        PersonStatus: "Waiting",
        receiveSMS: true,
        receiveEmail: true,
        receivePhone: true

    };

    const [state, setState] = React.useState(initialState);

    const onAddValue = (names, values) => {
        // console.log("new Input", [names], values)

        let TheValue = values;
        // let usernamecode = "";

        if (names === "email") {
            TheValue = values.toLowerCase();

        }

        setState((prevState) => ({
            ...prevState,
            [names]: TheValue,
            // usernamecode: usernamecode
        }))

        if (names === "password") {
            PasswordStrength(values);
        }
        if (names === "confirm") {
            if (state.password !== values) {

                setState((prevState) => ({
                    ...prevState,
                    confirmmessage: "Two passwords do not match"
                }))

            } else {

                setState((prevState) => ({
                    ...prevState,
                    confirmmessage: ""
                }))

            }
        }
    };

    //window.location.hostname

    useEffect(() => {

        if (window.location.hostname
            && window.location.hostname !== "") {

            setState((prevState) => ({
                ...prevState,
                loggo: `${window.location.origin}/public/logo512.png`
            }))
        }

    }, []);

    const PasswordStrength = (pwd) => {
        const strength = strengthIndicator(pwd);
        const color = strengthColor(strength);
        var StrengthLevel = Math.round((strength / 5) * 100);
        if (strength < 5) {

            setState((prevState) => ({
                ...prevState,
                ProgressStatus: "exception"
            }))
        } else {

            setState((prevState) => ({
                ...prevState,
                ProgressStatus: "success"
            }))
        }

        setState((prevState) => ({
            ...prevState,
            StrengthColor: color,
            StrengthLevel: StrengthLevel,
        }))

    };

    //const PasswordCheck = () => {

    //    if (state.Direction === "Forgot") {

    //        if (state.confirm === state.password && state.password !== "") {
    //            OpenSystem();
    //            SignIn(state.password);
    //        } else {
    //            setState((prevState) => ({
    //                ...prevState,
    //                AlartMessage: "Passwords did not match, please re-enter",
    //                step: "Waiting",
    //                open: true
    //            }))
    //        }

    //    } else {
    //        ConfirmContact();
    //    }

    //}

    const ConfirmContact = () => {

        if (state.confirm === state.password && state.password !== "") {

            let Emailcode = ConfirmationNumber();
            let SMScode = ConfirmationNumber();

            console.log(
                `SMS_Code: ${SMScode} -- email ${Emailcode}`
            );

            if (state.email) {
                //send email with code
                SendConfirmationEmail(Emailcode);
            }

            if (state.mobile) {
                //send sms to confirm
                SendConfirmationSMS(SMScode);
            }

            setState((prevState) => ({
                ...prevState,
                skjdls: Emailcode,
                alsiw: SMScode,
                step: "confirmcode"
            }))

        } else {
            setState((prevState) => ({
                ...prevState,
                AlartMessage: "Passwords did not match, please re-enter",
                open: true
            }))
        }

    }

    const SendConfirmationEmail = (code) => {

        try {

            //let LocalDate = new Date(currentTime).toLocaleString();
            //let ContractorSubDomain = `https://${ContractorData.SubDomain}.service-call-cloud.com`;
            ////let title = `${state.SubscriptionName} - ${LocalDate}`;

            let SendEvent = {

                PhotoService: [],
                Documents: [],
                SpecialInstructions: "",
                PreSelect: [],
                title: "Confirm Email",
                postHTML: `<p>Please confirm email</p><p>Email: ${state.email}</p><p>Confirmation Code: ${code}</p>`,
                PersonList: [state.email],
                postTEXT: `Please confirm email \n Email: ${state.email} \n Confirmation Code: ${code}`,
                ReplyEmail: "no-reply@service-call-cloud.com",
                PostId: "checkingemail",

                ContractorAddress: state.ContractorDataActive.Address,
                ContractorPhone: state.ContractorDataActive.phone,
                //ContractorLogo: state.ContractorDataActive.avatar,
                avatar: state.HQdataJSON.avatar,
                ContractorName: state.ContractorDataActive.CompanyName,

                UnsubscribeLink: `https://${state.HQdataJSON.SubDomain}/Unsubscribe`,
                ActionButton: `https://${state.HQdataJSON.SubDomain}`,
                ActionButtonTitle: "Go Online",
                ActionButtonShow: false
            }


            console.log(
                "LoginPage.test.2.SendEmail",
                SendEvent
            );

            SendEmail(SendEvent);



        } catch (err) { }

    }

    const SendConfirmationSMS = (code) => {

        try {

            var SMSstring = {
                Body: `Please confirm mobile # ${state.mobile}. Confirmation Code: ${code}`,
                FromSMS: state.mobile,
                From: "+15623936334",
                To: [state.mobile],
                //PostId: PostId,
                mediaUrl: []
            };

            console.log(
                "LoginPage.test.2.SMSstring",
                SMSstring
            );

            API.post("SMStoTwilio", "/twilio", {
                body: { Records: SMSstring }
            });

        } catch (err) { }

    }


    const SignUp = async () => {

        let usernamecode = `${window.cid}::${state.email}`;
        usernamecode = usernamecode.toLocaleLowerCase();
        let PPw = state.PersonActive.id;

        //UpdatePassword();

        await Auth.signUp({
            username: usernamecode,
            password: PPw,
            attributes: {},
            validationData: [], // optional
        })
            .then((data) => {
                SignIn(state.password);

            })
            .catch((err) => console.log("Loginpage.SignUp.err", err));
    };


    const handleClose = () => {
        //setState({ ...state, open: false });
        setState((prevState) => ({
            ...prevState,
            open: false
        }))
    };

    const ConfirmSignUp = async () => {

        //emailauthcode smsauthcode
        let emailConfirmed = false;
        let smsConfirmed = false;

        if (state.emailauthcode === state.skjdls) {
            emailConfirmed = true;
        }

        if (state.smsauthcode === state.alsiw) {
            smsConfirmed = true;
        }

        setState((prevState) => ({
            ...prevState,
            emailConfirmed: emailConfirmed,
            smsConfirmed: smsConfirmed
        }))

        if (smsConfirmed || emailConfirmed) {

            if (state.Direction === "Forgot") {
                //show new password setup
                setState((prevState) => ({
                    ...prevState,
                    step: "Waiting"
                }))

            } else {
               // OpenSystem();
                SignUp();
            }

        } else {
            //message - not match, re-enter or resend code.

            setState((prevState) => ({
                ...prevState,
                open: true,
                AlartMessage: "Code did not match, please re-enter"
            }))

        }

    };

    const SignIn = async (smpnumber) => {

        if (smpnumber === state.password) {

            //let usernamecode = `${window.cid}::${state.email}`;
            //usernamecode = usernamecode.toLocaleLowerCase();
            //let PPw = state.PersonActive.id;
            //console.log("LoginPage.Test.SignIn", usernamecode, PPw);
            //Auth.signIn(usernamecode, PPw).then(user => {
            //    console.log("LoginPage.Test.SignIn.user", user);
            //    LoadDataRedux();

            //}).catch(err => {
            //    console.log("Sign In err codes", err);
                setState((prevState) => ({
                    ...prevState,
                    toDashboard: false,
                    step: "Unsubscribebox",
                }))
           /* })*/

        } else {

            setState((prevState) => ({
                ...prevState,
                toDashboard: false,
                confirmcodemessage: `Please enter correct password`,
                step: "Active" //"SignInEmail"
            }))
        }
    };

    const Unsubscribe = () => {

        try {

            var PersonD = state.PersonData;
            let PersonActive = state.PersonActive;

          //  if (state.receiveEmail === true || state.receiveSMS === true) {

                let receiveEmail = state.receiveEmail
                //if (state.receiveEmail === true) {
                //    receiveEmail = false;
                //}

            let receiveSMS = state.receiveSMS
            let receivePhone = state.receivePhone
                //if (state.receiveSMS === true) {
                //    receiveSMS = false;
                //}

                let Allformms = PersonActive.AllItemForms;

                let Optin = Allformms.findIndex(x => x.FormType === "Optin");

                let Optinformdata = Allformms[Optin].FormData;

                console.log("OptOut.Optinformdata", Optinformdata);

                Optinformdata.map((each, indd) => {
                    switch (each.VariableName) {
                        case "receiveEmail":
                            Optinformdata[indd].VariableInput = receiveEmail;
                            Optinformdata[indd].defaultValue = receiveEmail;
                            break;
                        case "receiveSMS":
                            Optinformdata[indd].VariableInput = receiveSMS;
                            Optinformdata[indd].defaultValue = receiveSMS;
                            break;
                        case "receivePhone":
                            Optinformdata[indd].VariableInput = receivePhone;
                            Optinformdata[indd].defaultValue = receivePhone;
                            break;
                        default:
                    }
                })

                Allformms[Optin].FormData = Optinformdata;

                console.log("OptOut.Allformms", Allformms);
                
                let NewDataJSON = {
                    ...state.PersonActive,
                    AllItemForms: Allformms,
                    status: state.PersonStatus,
                    smpnumber: state.password,
                    //mobile: receiveSMS,
                    //email: receiveEmail,
                    receiveEmail: receiveEmail,
                    receiveSMS: receiveSMS,
                    receivePhone: receivePhone,
                    emailConfirmed: state.emailConfirmed,
                    smsConfirmed: state.smsConfirmed
                }

                console.log("LoginPage.NewDataJSON", NewDataJSON);

                var ValueToUpdate = {
                    pk1: PersonD.pk1,
                    sk1: PersonD.sk1,
                    gsi2Pk2: PersonD.gsi2Pk2,
                    gsi2Sk2: PersonD.gsi2Sk2,
                    gsi1Pk2: PersonD.gsi1Pk2,
                    gsi1Sk2: PersonD.gsi1Sk2,
                    dataJSON: removeEmptyStringElements(NewDataJSON)
                };

                UpdateDB(ValueToUpdate);

                //dispatch({ type: 'USER_DATA', payload: NewDataJSON });
                //dispatch({ type: 'SHOW_HELP', payload: true });

                setState((prevState) => ({
                    ...prevState,
                    step: "Message",
                }))
          //  }

        } catch (err) {
            console.log("Sign In err codes", err);
        }
    };


    const VerifyAccount = async () => {
        try {

            let NewEmail = state.email.toLowerCase();
            //setState((prevState) => ({
            //    ...prevState,
            //    email: NewEmail,
            //    Direction: Direction
            //}))



            //alarm message if no email
            if (!NewEmail && !validator.validate(NewEmail)) {
                setState((prevState) => ({
                    ...prevState,
                    open: true,
                    AlartMessage: "* Please enter correct email "
                }))
                return;
            }

            let ContractorBucket = window.ContractorId;

            if (NewEmail.includes("@notificationbridge.com")) {
                ContractorBucket = "Developer::Account::0786";
            }

            //get person data
            let getItemParams = {
                TableName: "svcbot_db",
                IndexName: "gsi2Pk2-gsi2Sk2-index",
                KeyConditionExpression:
                    "#gsi2Pk2 = :gsi2Pk2 and begins_with(#gsi2Sk2, :gsi2Sk2)",
                ExpressionAttributeNames: {
                    "#gsi2Pk2": "gsi2Pk2",
                    "#gsi2Sk2": "gsi2Sk2",
                },
                ExpressionAttributeValues: {
                    ":gsi2Pk2": ContractorBucket,
                    ":gsi2Sk2": "email::" + NewEmail,
                },
            };

            var PersonFound = await GetDBData(getItemParams);

            if (PersonFound.Count > 0) {
                var PersonData = PersonFound.Items[0];
                var PersonActive = PersonData.dataJSON;

                setState((prevState) => ({
                    ...prevState,
                    PersonData: PersonData,
                    PersonActive: PersonActive,
                    receiveSMS: PersonActive.receiveSMS,
                    receiveEmail: PersonActive.receiveEmail
                }))

                //console.log(
                //    "LoginPage.test.1.PersonData",
                //    PersonData
                //);

                //setState((prevState) => ({
                //    ...prevState,
                //    PersonData: PersonData,
                //}))

                //if (PersonActive && PersonActive.ContractorId) {
                //    LoadContractorInfo(PersonActive.ContractorId)
                //}


                //if (PersonActive.mobile && PersonActive.mobile !== "") {
                //    setState((prevState) => ({
                //        ...prevState,
                //        mobile: PersonActive.mobile
                //    }))
                //}

                

                //if person has been invited and waiting for account setup
                if (PersonActive.status === "Waiting") {
                    setState((prevState) => ({
                        ...prevState,
                        step: "Unsubscribebox",
                        PersonStatus: "Waiting"
                    }))

                    //send email and sms
                 //   ConfirmContact();

                }

                //if person has created the accout and is active
                if (PersonActive.status === "Active") {

                    setState((prevState) => ({
                        ...prevState,
                        step: "Active",
                    }))
                }

                //if person has created the accout and is active
                if (PersonActive.status === "Removed") {

                    setState((prevState) => ({
                        ...prevState,
                        step: "Removed"
                    }))
                }
            } else {
                dispatch({ type: 'LOGGED_OUT' });
                setState((prevState) => ({
                    ...prevState,
                    confirmcodemessage: `Sorry, your email is not in the system. Please try again or contact ${state.ContractorName}`,
                    step: "SignInEmail"
                }))
            }

        } catch (err) { }
    };

    

    const LoadContractorInfo = async (ContractorId) => {

        try {

            var ContractorFound = await GetData(window.ContractorId, ContractorId);
            var HQFound = await GetData(window.ContractorId, window.ContractorId);

            console.log("LoginPage.ContractorId.ContractorFound", ContractorFound)
            console.log("LoginPage.ContractorId.HQFound", HQFound)

            if (ContractorFound) {
                var ContractorData = ContractorFound[0];
                var ContractorDataActive = ContractorData.dataJSON;
                dispatch({ type: 'CONTRACTOR_DATA', payload: ContractorDataActive });

                setState((prevState) => ({
                    ...prevState,
                    ContractorDataActive: ContractorDataActive
                }))

            }

            if (HQFound) {
                var HQFounddd = HQFound[0];
                var HQdataJSON = HQFounddd.dataJSON;
                dispatch({ type: 'HQ_DATA', payload: HQdataJSON });

                setState((prevState) => ({
                    ...prevState,
                    HQdataJSON: HQdataJSON
                }))
            }

        } catch (err) { }
    };

    const SignInStart = () => {
        if (state.PersonActive && state.PersonActive.smpnumber !== "") {
            SignIn(state.PersonActive.smpnumber)
        } else {
            setState((prevState) => ({
                ...prevState,
                AlartMessage: "Account Not Found",
                open: true
            }))
        }

    }

    const ForgotPassword = () => {

        let Emailcode = ConfirmationNumber();
        let SMScode = ConfirmationNumber();

        console.log(
            `SMS_Code: ${SMScode} -- email ${Emailcode}`
        );

        if (state.email) {
            //send email with code
            //    SendConfirmationEmail(Emailcode);
        }

        if (state.mobile) {
            //send sms to confirm
            //     SendConfirmationSMS(SMScode);
        }

        setState((prevState) => ({
            ...prevState,
            skjdls: Emailcode,
            alsiw: SMScode,
            step: "confirmcode",
            Direction: "Forgot"
        }))
    }


    console.log("LoginPage.state", state)

    return (
        <Page
            className={classes.root}
            title="Unsubscribe"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">

                    {state.loggo !== "" ? (
                        <Box my={3}
                            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        >

                            <img
                                src={state.loggo}
                                height="100"
                            />

                        </Box>
                    ) : null}


                    {state.step === "SignInEmail" && (
                        <>

                            <Box mb={3}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Unsubscribe
                                </Typography>
                            </Box>
                            {state.confirmcodemessage}
                            <TextField
                                fullWidth
                                label="Email Address"
                                margin="normal"
                                name="email"
                                //onBlur={onAddValue}
                                onChange={e => onAddValue("email", e.target.value)}
                                type="email"
                                value={state.email || ""}
                                variant="outlined"
                            />
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    //disabled={isSubmitting}
                                    onClick={() => VerifyAccount("Account")}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Next
                                </Button>
                            </Box>

                        </>
                    )}
                    {state.step === "Active" && (
                        <>
                            <Box mb={3}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Need Password to Unsubscribe
                                </Typography>
                            </Box> 
                            {`Email: ${state.email}`}
                            
                            <TextField
                                fullWidth
                                label="Password"
                                margin="normal"
                                name="password"
                                //onBlur={onAddValue}
                                onChange={e => onAddValue("password", e.target.value)}
                                type="password"
                                value={state.password || ""}
                                variant="outlined"
                            />
                            {state.confirmcodemessage}
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    //disabled={isSubmitting}
                                    onClick={SignInStart}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Next
                                </Button>
                            </Box>

                            {/* */}
                            <Box my={2}>
                                <Button
                                    onClick={() => ForgotPassword()}
                                    type="submit"
                                >
                                    Forgot Password
                                </Button>
                            </Box>
                        </>
                    )}

                    {state.step === "Message" && (
                        <>
                            <Box mb={3}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Updates
                                </Typography>
                            </Box>
                            <Typography color="textPrimary" variant="h3">
                            The system has been updated.
                            </Typography>

                        </>
                    )}

                    {state.step === "Unsubscribebox" && (
                        <>
                            <Box mb={3}>

                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Select Email or SMS/Text to Unsubscribe.
                                </Typography>

                                <Typography
                                    color="textPrimary"
                                    variant="h4"
                                >
                                    Use the UPDATE ME link to re-activate notification.
                                </Typography>

                            </Box>

                            
                            
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={state.receiveEmail}
                                        onChange={e => onAddValue("receiveEmail", e.target.checked)}
                                    />
                                } label="Email Notifications" />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={state.receiveSMS}
                                        onChange={e => onAddValue("receiveSMS", e.target.checked)}
                                    />
                                } label="Mobile SMS/Text Notifications" />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={state.receivePhone}
                                        onChange={e => onAddValue("receivePhone", e.target.checked)}
                                    />
                                } label="Phone Notifications" />
                            </FormGroup>

                            <Box my={2}>
                                <Button
                                    color="primary"
                                    //disabled={isSubmitting}
                                    onClick={() => Unsubscribe()}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Unsubscribe
                                </Button>
                            </Box>
                        </>
                    )}                  


                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={state.open}
                        onClose={handleClose}
                        message={state.AlartMessage}
                        key="w87482ows"
                    />

                </Container>
            </Box>
        </Page>
    );
};

export default LoginView;
