import React, { useState, useEffect } from 'react';
import {
    Card, CardHeader, CardContent,
    Box, Button,
    Backdrop, CircularProgress, Grid
} from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, getAutoCompletedata, removeEmptyStringElements } from '../utils/CommonGraphql';
import ListRadio from '../List/ListRadio';
import ListModule from '../List/ListModule';
import { Buffer } from 'buffer';
//import { currentTime } from '../utils/DateTime';

var dateToday = new Date();
let currentTime = dateToday.toISOString().replace("Z", "");

//const base64decode = (data) => {
//    let buf = "";
//    //if (typeof Buffer.from === "function") {
//    //    buf = Buffer.from(data, 'base64');
//    //} else {
//    //    // older Node versions, now deprecated
//    //    buf = new Buffer(data, 'base64'); // Ta-da
//    //}

//    const buff = Buffer.from(data, "base64");
//    const formEncodedParams = buff.toString("utf-8");
//    const urlSearchParams = new URLSearchParams(formEncodedParams);

//    const body = urlSearchParams.get("Body");

//   // console.log(body);

//    console.log("SMSSetup.buffer", body);
//};

const UUid = NewPageId("SMSSetup");

const ListItems = (props) => {

    //let query = useQuery();
    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData)
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const NowEditing = useSelector(state => state.NowEditing)
    const cid = useSelector(state => state.cid);
    //if window is mobile or regular
    // const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 6,
        AllowAdd: false,
        ListType: "ListModule",
        CompanyId: UserData.CompanyId,
        ContractorData: [],
        CompanyData: [],
        propss: {},
        key: NewPageId("win"),
        SMSJson: [],
        Category: "AddSMS",
        Activefield1: props.CompanyName,
        defaultValue: 0,
        DeActivePolicies: [],
        CompanyPref: [],
        SelectedArray: [],
        status: "Active",
        IsDev: false,
        //NewLoadComponent: "UrgencyDetails"
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('SMSSetup.params', params);
        console.log('SMSSetup.props', props);

        //let body = "VG9Db3VudHJ5PVVTJlRvU3RhdGU9Q0EmU21zTWVzc2FnZVNpZD1TTWQwOTVkMmE5YzU4NGY0NzliZWIxZjg1YzAwNjFhOTUxJk51bU1lZGlhPTAmVG9DaXR5PSZGcm9tWmlwPTkwODA1JlNtc1NpZD1TTWQwOTVkMmE5YzU4NGY0NzliZWIxZjg1YzAwNjFhOTUxJkZyb21TdGF0ZT1DQSZTbXNTdGF0dXM9cmVjZWl2ZWQmRnJvbUNpdHk9TE9ORytCRUFDSCZCb2R5PUluc2hhQWxsYWgrJkZyb21Db3VudHJ5PVVTJlRvPSUyQjE1NjIyNzAxNTE3JlRvWmlwPSZOdW1TZWdtZW50cz0xJk1lc3NhZ2VTaWQ9U01kMDk1ZDJhOWM1ODRmNDc5YmViMWY4NWMwMDYxYTk1MSZBY2NvdW50U2lkPUFDZWM3OWIyOGYyNzI3YWYzZjJhMjczYjI4NzNmOWQ2YmUmRnJvbT0lMkIxNTYyNzYwMTkxNCZBcGlWZXJzaW9uPTIwMTAtMDQtMDE=";
        //base64decode(body);

       // console.log("SMSSetup.ContractorSMS", ContractorSMS);

        let Activefield1 = state.Activefield1;
        if (props.Activefield1) {
            Activefield1 = props.Activefield1;
        }

        let IsDev = false;
        if (UserData && UserData.Level > 2000) {
            IsDev = true;
        }

        if (props.Data) {
            setState(prevState => ({
                ...prevState,
                ...props.Data,
                Activefield1: Activefield1,
                IsDev: IsDev
            }));
        }

        //get list of all active SMS numbers in use for the branch
        GetContractorSMS("AddSMS", ContractorData.ContractorId, "SMSNumbers");

        //get deactive policies
        GetDatafromDB("SMSCompany", props.CompanyId, "DeActivePolicies", "status", "Disabled");

        //get company pref file
        GetDatafromDB("CompanyPref", props.CompanyId, "CompanyPref", "status", "");

    }, [props.Data])

    useEffect(() => {

        let ContractorSMS = [];
        let CompanySMS = [];
        //sort contractor and company data in to each sms# and create array per sms#
        if (state.SMSNumbers && state.SMSNumbers.length > 0) {
            if (state.ContractorData && state.ContractorData.length > 0) {
                ContractorSMS = eachContractorSMS(state.SMSNumbers, state.ContractorData);
            }

            else {
                ContractorSMS = state.SMSNumbers;
            }

            if (state.CompanyData && state.CompanyData.length > 0) {
                CompanySMS = eachContractorSMS(state.SMSNumbers, state.CompanyData);
            }
            console.log("SMSSetup.ContractorSMS", ContractorSMS);
            if (CompanySMS.length > 0) {
                CombineBoth(CompanySMS, ContractorSMS);
            } else {
                setState(prevState => ({ ...prevState, TotalSMS: ContractorSMS }))
            }

        }

    }, [state.SMSNumbers, state.ContractorData, state.CompanyData])

    useEffect(() => {
        let SelectedArray = [];
        let SelectedArrayValues = [];
        if (state.CompanyPref && state.CompanyPref.length > 0) {
            let CompanyPref = state.CompanyPref[0];
            if (CompanyPref && CompanyPref.SelectedArray) {
                SelectedArray = CompanyPref.SelectedArray
                SelectedArray.map(each => {
                    if (each.ActiveKey === props.ActiveKey) {
                        SelectedArrayValues[each.mobile] = each.key;
                    }
                })
            }
        }

        setState(prevState => ({
            ...prevState,
            SelectedArray: SelectedArray,
            SelectedArrayValues: SelectedArrayValues
        }))

    }, [state.CompanyPref])


    // getting list of SMS's
    const GetContractorSMS = async (Category, CompanyId, ArrayName, Asset = "", SMS = "") => {
        SetLoading(true);
        
        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: "status",
                    extraValue: "Active"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId, Asset, SMS);

        if (NewData && NewData.SMSNumbers && NewData.SMSNumbers.length > 0) {

            //get contractor ESC Data
            GetDatafromDB("SMSCustom", ContractorData.ContractorId, "ContractorData", "status", "Active");

            //get company ESC Data
            if (ContractorData.ContractorId !== props.CompanyId) {
                GetDatafromDB("SMSCompany", props.CompanyId, "CompanyData", "status", "Active");
            }
        }
        setState(prevState => ({ ...prevState, ...NewData }))
        SetLoading(false);
        // console.log("UrgencyDetails.NewData", NewData);

        console.log("SMSSetup.NewData.SMSs - NewData", NewData.SMSNumbers);

    }

    //getting list of policies
    const GetDatafromDB = async (Category, CompanyId, ArrayName, extraVar, extraValue) => {
        SetLoading(true);
        
        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: extraVar,
                    extraValue: extraValue
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId);
        console.log("SMSSetup.GetContractorSMS", Category, CompanyId, NewData);
        setState(prevState => ({ ...prevState, ...NewData }))

        //let PolicyData = NewData.ESCContractor;
        //eachContractorSMS(SMSList, PolicyData)
        // setState(prevState => ({ ...prevState, ...NewData }))
        SetLoading(false);
        // console.log("UrgencyDetails.NewData", NewData);

      //  console.log("SMSSetup.NewData.SMS.UrgencyDetails-data", NewData);

    }

    const CombineBoth = (CompanySMS, ContractorSMS) => {

        let CompanyData = [];
        let NewTotalArray = [];
        let SMSbox = [];
        let ContracotAttv = [];
        let CompanyAcctvv = [];

        ContractorSMS.map((each, indd) => {

            console.log("SMSSetup.each.ActivePolicyData", each.ActivePolicyData);
            if (each.ActivePolicyData && each.ActivePolicyData.length > 0) {
                ContracotAttv = each.ActivePolicyData;
            }

            CompanyData = CompanySMS.find(x => x.mobile === each.mobile);

            console.log("SMSSetup.CompanyData", CompanyData);
            if (Object.keys(CompanyData).length > 0) {

                if (CompanyData.ActivePolicyData && CompanyData.ActivePolicyData.length > 0) {
                    CompanyAcctvv = CompanyData.ActivePolicyData;
                }

                NewTotalArray = [...CompanyAcctvv, ...ContracotAttv];
            } else {
                NewTotalArray = ContracotAttv;
            }

            SMSbox.push({
                ActivePolicyData: NewTotalArray,
                field1: each.field1,
                field2: each.field2,
                mobile: each.mobile,
                key: indd
            })

            NewTotalArray = [];
            CompanyAcctvv = [];
            ContracotAttv = [];

        })

        if (SMSbox.length > 0) {
            setState(prevState => ({ ...prevState, TotalSMS: SMSbox }))
        }
    }

    const eachContractorSMS = (SMSList, PolicyData) => {

        try {

            //only use active policies
            let ActivePolicyData = [];
            let SMSbox = [];


            if (SMSList && SMSList.length > 0) {
                SMSList.map((each, indd) => {
                    PolicyData.map((CompanyEach, inddd) => {

                        console.log("SMSSetup.CompanyEach.sk1, each.mobile", CompanyEach.sk1, each.mobile);

                        if (CompanyEach.sk1.includes(each.mobile)) {
                            ActivePolicyData.push(CompanyEach)
                        }
                    })

                    SMSbox.push({
                        ActivePolicyData: ActivePolicyData,
                        field1: each.field1,
                        field2: each.field2,
                        mobile: each.mobile,
                        key: indd
                    })

                    ActivePolicyData = [];

                })
            }

            console.log("SMSSetup.NewData.SMSbox", SMSbox);

            return SMSbox

        } catch (err) { }

    }

    const SaveData = () => {

        try {

            let ActiveKey = state.CompanyId;

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.field1,
                field2: UserData.field2,
                avatar: UserData.avatar,
            }

            let key = `Data::Online::Lambda::Pref::Policy`;

            let field1 = props.Activefield1;
            let field2 = props.CompanyName;

            let DBInput = {
                pk1: ActiveKey,
                sk1: key,
                gsi1Pk2: props.CompanyId,
                gsi1Sk2: key,
                //gsi2Pk2: ContractorData.ContractorId,
                //gsi2Sk2: key
            }


            const FormdataJSON = {
                date: currentTime,
                field1: field1,
                field2: field2,
                HQId: HQData.HQId,
                HQName: HQData.HQName,
                SubDomain: HQData.SubDomain,
                SelectedArray: state.SelectedArray,
                id: UUid,
                key: key,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                ContractorId: ContractorData.ContractorId,
                ContractorName: ContractorData.ContractorName,
                posterId: posterId,
                status: state.status,
                ...DBInput
            }

            let dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON));

            let DBInput2 = {
                ...DBInput,
                dataJSON: dataJSON
            }

            console.log("SelectStepBox.DBInput", DBInput2);
            console.log("SelectStepBox.FormdataJSON", FormdataJSON);

            API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput2 })).then(
                (results) => {
                    console.log("createBatch Data ", results);
                    if (props.CloseDrawer) {
                        props.CloseDrawer();
                    }
                }
            );

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    // Additional Information Box
    const AddBox = (add) => {
        console.log("SMSSetup.AddBox", add);
        let NewKey = NewPageId("smsset");

        let EditExisting = false;
        if (props.ActiveKey === add.pk1) {
            EditExisting = true;
        }

        //open Drawer
        setState(prevState => ({
            ...prevState,
            //DetailComponent: add.DetailComponent,
            DetailComponent: "UrgencyDetails", //state.NewLoadComponent,
            Category: state.Category,
            ShowDetails: false,
            DetailsProps: {
                id: NewKey,
                Category: state.Category,
                ShowEditButton: false,
                PreviewEdit: false,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                //  CustomerType: state.CustomerType,
                CloseDrawer: (e) => CloseDrawer(e),
                ActiveCategory: props.ActiveCategory,
                Activefield1: props.Activefield1,
                ActiveKey: props.ActiveKey,
                EditExisting: EditExisting,
                Data: add
            },

        }))

        OpenDrawer(true);
    }

    const AddNew = (data) => {
        let NewKey = NewPageId("smsset");
        setState(prevState => ({
            ...prevState,
            DetailComponent: "UrgencyDetails",
            ShowDetails: false,
            DetailsProps: {
                //title: add.field1,
                Category: state.Category,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                CloseDrawer: (e) => CloseDrawer(e),
                ActiveCategory: props.ActiveCategory,
                Activefield1: props.Activefield1,
                ActiveKey: props.ActiveKey,
                EditExisting: false,
                //ShowEditButton: false,
                //PreviewEdit: false,
                Data: {
                    mobile: data.mobile,
                    field1: data.field1,
                    field2: data.field2,
                    id: NewKey,
                    Category: state.Category,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,

                }
            },
            // key: add.key || NewKey
        }))

        OpenDrawer(true);
    }

    //const CompanyData = (data) => {
    //    console.log("data", data);
    //    GetDatafromDB(state.Category, data.pk1);
    //}

    const AddItem = (data) => {
        let NewKey = NewPageId("smsset");
        setState(prevState => ({
            ...prevState,
            //DetailComponent: "NotificationTriggerSetup",
            DetailComponent: state.NewLoadComponent, //"ItemAddPage",
            ShowDetails: false,
            DetailsProps: {
                //title: add.field1,
                Category: state.Category,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                CloseDrawer: (e) => CloseDrawer(e),
                ActiveCategory: props.ActiveCategory,
                Activefield1: props.Activefield1,
                ActiveKey: props.ActiveKey,
                //ShowEditButton: false,
                //PreviewEdit: false,
                Data: {
                    mobile: "", //data.mobile,
                    //field1: data.field1,
                    //field2: data.field2,
                    //id: NewKey,
                    Category: state.Category,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                }
            },
            // key: add.key || NewKey
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        GetContractorSMS("AddSMS", ContractorData.ContractorId, "SMSNumbers");
        //get deactive policies
        GetDatafromDB("SMSCompany", props.CompanyId, "DeActivePolicies", "status", "Disabled");
        OpenDrawer(false);
    }

    const SelectedValue = (data, mobile) => {

        let SelectedArray = state.SelectedArray;

        let findindex = SelectedArray.findIndex(x => x.mobile === mobile && x.ActiveKey === props.ActiveKey)

        if (findindex > -1 && SelectedArray[findindex]) {
            SelectedArray[findindex].key = data;
        } else {
            SelectedArray.push({
                mobile: mobile,
                key: data,
                ActiveKey: props.ActiveKey
            })
        }

        console.log("SMSSetup.SelectedArray", findindex, SelectedArray);

        setState(prevState => ({
            ...prevState,
            SelectedArray: SelectedArray
        }))

    }

    console.log("SMSSetup.state", state);

    return (
        <>
            <Grid container spacing={2}>

                {/*
                    state.AllowAdd && (
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                        // sx={{ m: 1 }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => AddItem()}
                            >
                                Add New SMS
                            </Button>
                        </Box>
                    </Grid>
                    )
                */}
                {state.IsDev ? (
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    // sx={{ m: 1 }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => AddItem()}
                        >
                            Add New SMS
                        </Button>
                    </Box>
                    </Grid>
                ) : null}

                

                {state.TotalSMS && state.TotalSMS.map((each, indd) => (
                    <Grid item xs={12}>
                        <Card
                            key={"smscardd" + indd}
                        >
                            <CardHeader
                                title={each.field1}
                                subheader={each.field2}
                                action={
                                    <Button
                                        onClick={() => AddNew(each)}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {`New Policy`}
                                    </Button>
                                }
                            />
                            <CardContent>
                                <ListRadio
                                    AddBox={(add) => AddBox(add)}
                                    title="Active Policies"
                                    SearchWords=""
                                    ShowEdit={true}
                                    disabled={false}
                                    SelectedKeyArray={(e) => SelectedValue(e, each.mobile)}
                                    defaultValue={state.SelectedArrayValues[each.mobile] !== "" ? state.SelectedArrayValues[each.mobile] : ""}
                                    DetailLink={(e) => AddBox(e)}
                                    DataBack={each.ActivePolicyData}
                                />

                            </CardContent>
                        </Card>
                    </Grid>
                ))}

                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    // sx={{ m: 1 }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => SaveData()}
                        >
                            Save Selections
                        </Button>
                    </Box>
                </Grid>

                {state.DeActivePolicies.length > 0 && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title="Disabled Policies"
                                subheader=""
                            />
                            <CardContent>
                                <ListModule
                                    //  AddBox={(add) => AddBox(add)}
                                    title="De-Active Policies"
                                    disabled={false}
                                    DetailLink={(e) => AddBox(e)}
                                    DataBack={state.DeActivePolicies}
                                />

                            </CardContent>
                        </Card>
                    </Grid>
                )}

            </Grid>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.Category}
                propss={state.DetailsProps}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </>
    );
};

export default ListItems;
