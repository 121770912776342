
export const DocumentType = [
   
    {
        label: "Service Reports",
        key: "Service"
    },
    {
        label: "New Service Request",
        key: "Post"
    },
    {
        label: "Proposal/Quotes",
        key: "Quote"
    },
    {
        label: "Reports",
        key: "Reports"
    },
    {
        label: "Drawings",
        key: "Drawings"
    },
    {
        label: "Manuals",
        key: "Manuals"
    },
]

export const CheckInDocumentType = [

    {
        label: "Service Reports",
        key: "Service_Report"
    },    
    {
        label: "Proposal/Quotes",
        key: "Proposal"
    },
    {
        label: "Reports",
        key: "Reports"
    },
    {
        label: "Drawings",
        key: "Drawings"
    },
    {
        label: "Images / Pictures",
        key: "Pictures"
    }
]

export const PolicyDocuments = [

    {
        label: "Safety Policy",
        key: "Safety"
    },
    {
        label: "Rules & Policies",
        key: "Rules"
    },
    {
        label: "Site Maps",
        key: "Maps"
    },
    
]

