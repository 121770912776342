import React, { useState, useEffect } from 'react';
import {
    Paper,
    Grid,
    Backdrop,
    CircularProgress,
    List, ListItem, ListItemIcon, ListItemText, Divider

} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { NewPageId } from '../utils/CommonGraphql';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TextsmsIcon from '@mui/icons-material/Textsms';
import InfoIcon from '@mui/icons-material/Info';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import EmailIcon from '@mui/icons-material/Email';
import ContactsIcon from '@mui/icons-material/Contacts';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HelpBox from '../components/HelpBox';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

export default function SimpleAccordion(props) {
    const dispatch = useDispatch();
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const NowEditing = useSelector(state => state.NowEditing) //
    const WebsiteType = useSelector(state => state.WebsiteType)
    const cid = useSelector(state => state.cid);
    const [activeStep, setActiveStep] = useState(0);
    const [SelectCompany, SetSelectCompany] = useState(false);
    const initialState = {
        DataBack: [],
        propss: {},
        CompanyName: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        UserCustomerType: UserData.CustomerType,
      //  CompanyInfo: cid,
       // ItemInfo: cid,
        Blocks: [],
        AppliesTo: "Company",
        SetupType: "Custom",
        Shift: [],
    }
    const [state, setState] = useState(initialState);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);


    useEffect(() => {
        
       // console.log("CompanyPrefSetup.UserData", UserData);
        

        // change company when different company selection is made through Toolbar
       // if (props.CompanyId) {
            console.log("CompanyPrefSetup.props", props);
            if (ContractorData.ContractorId === props.CompanyId) {
                setState(prevState => ({
                    ...prevState,
                    SetupType: "Default"
                }))
            } else {
                setState(prevState => ({
                    ...prevState,
                    SetupType: "Custom",
                    
                }))
            }

            setState(prevState => ({
                ...prevState,
                NewKey: NewPageId("key"),
                AppliesTo: "Company",
                CompanyName: props.CompanyName,
                ...props
            }))
       // }

    }, [props.CompanyId])



    const handleChange = (event) => {
        //console.log("event.target.value", event.target.name, event.target.value)
        let vvaluue = event.target.value
        let name = event.target.name;
        setState(prevState => ({ ...prevState, [name]: vvaluue }))
    };

    const SelectedCat = (ItemInfo) => {
        //console.log("cat", cat)
        console.log("ItemInfo", ItemInfo)
        setState(prevState => ({
            ...prevState,
            NewKey: NewPageId("key"),
            ItemInfo: ItemInfo,
            field2: state.CompanyInfo.field1,
            field1: ItemInfo.field1,
        }))
    };

    const SetUpDrawer = (
        loadcomponent,
        Category,
        ListType = "",
        NewLoadComponent = "",
        message = "",
        FormType = "",
        title = "") => {

        if (Category === "AssignedAccounts") {
            dispatch({ type: 'ACCOUNT_ACCESS', payload: props.ItemData });
        }
        console.log("Listitems.SetUpDrawer.Category", Category, loadcomponent)
        let data = {
            ...props.ItemData,
            LoadComponenet: loadcomponent,
            Category: Category,
            ListType: ListType,
            FormType: FormType,
            NewLoadComponent: NewLoadComponent,
            AppliesTo: state.AppliesTo,
            title: title,
            Activefield1: props.Activefield1,
            ActiveCategory: props.ActiveCategory,
            ActiveKey: props.ActiveKey,
           // Data: props.ItemData,
          //  ItemData: props.ItemData,
           // field2: state.CompanyInfo.field1,
            field1: message,
            //CompanyInfo: state.CompanyInfo,
            //CompanyId: props.CompanyId,
            //sk1: props.CompanyId,
            //CompanyName: props.CompanyName,
            //CustomerType: props.CustomerType,
            
        }
        console.log("Listitems.SetUpDrawer", data)
        props.AddBox(data);
    };

    //const handleReset = () => {
    //  setActiveStep(0);
    //};

    //const KeyArray = (data, cat) => {
    //  console.log("KeyArray", data, cat)
    //  setState(prevState => ({
    //    ...prevState,
    //    [cat]: data
    //  }));
    //}

    //const ClosingDrawer = () => {
    //  SetDeawerOpen(false);
    //}

    //const GetCompanyName = (data) => {

    //  setState(prevState => ({
    //    ...prevState,
    //    CurrentCompany: data.field1,
    //    CompanyId: data.pk1,
    //    CompanyData: data
    //  }));

    //  //SetSelectCompany(false);
    //}

    let ContractorEditCompany = [
        {
            key: "Information",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",
                Category: "Company",
                ListType: "",
                NewLoadComponent: "",
                ShowEditButton: true,
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
        {
            key: "Preselect",
            label: `Pre-Select Assets/Personnel`,
            Icon: <PlaylistAddCheckIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "PreSelect",
                Category: "Location",
                ListType: "Company",
                NewLoadComponent: "CreateContactUs",
                message: "If the customer service call always is for the same Location, Assets, Equipment, Approving Personal, etc.",
                FormType: "",
                title: `Pre-Select Assets/Personnel`,
            }
        },
        {
            key: "SMSTrigger",
            label: `Custom Notification Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Customize Notification Policy`,
                FormType: "",
                title: `Customize Notification Policy`,
            }
        },
        {
            key: "EmailTrigger",
            label: `Email Triggers`,
            Icon: <EmailIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "EmailTriggerSetup",
                Category: "EmailTrigger",
                ListType: "SMSSetup",
                NewLoadComponent: "UrgencyDetails",
                message: `Email Trigger`,
                FormType: "",
                title: `Email Trigger`,
            }
        },
        
        {
            key: "ContactList",
            label: `Contact List for Account`,
            Icon: <ContactsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "PreSelect",
                Category: "Person",
                ListType: "Contractor",
                NewLoadComponent: "CreateContactUs",
                message: "Contact List for Account",
                FormType: "",
                title: `Contact List for Account`,
            }
        },
        {
            key: "SMSCompnaySelect",
            label: `Invite Setup for Account`,
            Icon: <MarkChatReadIcon />,
            SetUpDrawer: {
                loadcomponent: "SMSCompnaySelect",
                Category: "SMSSelect",
                ListType: "SMSCompnaySelect",
                NewLoadComponent: "SMSCompnaySelect",
                message: `Invite Setup for Account`,
                FormType: "",
                title: `Invite Setup for Account`,
            }
        },
        {
            key: "CheckIn",
            label: `Check in/out Setup`,
            Icon: <AddLocationAltIcon />,
            ShowHelp: false,
            title: "Checkin / Checkout Setup",
            description: "Checkin / Checkout Setup",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "CheckInSetupPage",
                Category: "CheckIn",
                ListType: "Contractor",
                NewLoadComponent: "CheckInSetupPage",
                message: "Please select locations and personnel",
                FormType: "",
                title: `Check in/out Setup`,
            }
        },

    ];

    let CheckInOwner = [
        {
            key: "Information",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Information",
            description: "Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",
                Category: "Company",
                ListType: "",
                NewLoadComponent: "",
                ShowEditButton: true,
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
        
        {
            key: "SMSTrigger",
            label: `Text/SMS Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Text/SMS Policy",
            description: "Text/SMS Policy",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Text/SMS Policy`,
                FormType: "",
                title: `Text/SMS Policy`,

                

            }
        },
        {
            key: "policy",
            label: `Policy Documents`,
            Icon: <EmailIcon />,
            ShowHelp: false,
            title: "Policy Documents",
            description: "Policy Documents",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "PolicyPage",
                Category: "Policy",
                ListType: "ListModule",
                NewLoadComponent: "PolicyDoc",
                message: `Policy Documents`,
                FormType: "",
                title: `Policy Documents`,
                id: NewPageId("Policy")
            }
        },

        //{
        //    key: "ContactList",
        //    label: `Contact List for Account`,
        //    Icon: <ContactsIcon />,
        //    ShowHelp: false,
        //    title: "Branch Information",
        //    description: "Branch Information",
        //    ShowVideo: false,
        //    VideoId: "11111111",
        //    SetUpDrawer: {
        //        loadcomponent: "PreSelect",
        //        Category: "Person",
        //        ListType: "Contractor",
        //        NewLoadComponent: "CreateContactUs",
        //        message: "Contact List for Account",
        //        FormType: "",
        //        title: `Contact List for Account`,
        //    }
        //},
        //{
        //    key: "SMSCompnaySelect",
        //    label: `Invite Setup for Account`,
        //    Icon: <MarkChatReadIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "SMSCompnaySelect",
        //        Category: "SMSSelect",
        //        ListType: "SMSCompnaySelect",
        //        NewLoadComponent: "SMSCompnaySelect",
        //        message: `Invite Setup for Account`,
        //        FormType: "",
        //        title: `Invite Setup for Account`,
        //    }
        //},
        //{
        //    key: "CheckIn",
        //    label: `Check in/out Setup`,
        //    Icon: <AddLocationAltIcon />,
        //    ShowHelp: false,
        //    title: "Checkin / Checkout Setup",
        //    description: "Checkin / Checkout Setup",
        //    ShowVideo: false,
        //    VideoId: "11111111",
        //    SetUpDrawer: {
        //        loadcomponent: "CheckInSetupPage",
        //        Category: "CheckIn",
        //        ListType: "Contractor",
        //        NewLoadComponent: "CheckInSetupPage",
        //        message: "Please select locations and personnel",
        //        FormType: "",
        //        title: `Check in/out Setup`,
        //    }
        //},

    ];

    let ContractorPersonOptions = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: "Person",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },

        {
            key: "AssignAcct",
            label: `Assigned Accounts`,
            Icon: <AccountBalanceIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ListSelectedAccounts",
                Category: "AssignedAccounts",
                ListType: "SMSSetup",
                NewLoadComponent: "SelectCompanies",
                message: `Assigned Accounts`,
                FormType: "",
                title: `Assigned Accounts`,
            }
        },
        //{
        //    key: "ContactList",
        //    label: `Contact List for ${state.Activefield1}`,
        //    Icon: <ContactsIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "PreSelect",
        //        Category: "Person",
        //        ListType: "Contractor",
        //        NewLoadComponent: "CreateContactUs",
        //        message: "SMS Setup",
        //        FormType: "",
        //        title: `Contact List for ${state.Activefield1}`,
        //    }
        //}

    ];
    

    let PersonOptions = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsData",   //"ShowDetailsPkSk", //
                Category: "Person",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
       
        {
            key: "SMSTrigger",
            label: `Customize SMS Trigger for ${state.Activefield1}`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Customize SMS Trigger for ${state.Activefield1}`,
                FormType: "",
                title: `Customize SMS Trigger for ${state.Activefield1}`,
            }
        },
        {
            key: "ContactList",
            label: `Contact List for ${state.Activefield1}`,
            Icon: <ContactsIcon />,
            SetUpDrawer: {
                loadcomponent: "PreSelect",
                Category: "Person",
                ListType: "Contractor",
                NewLoadComponent: "CreateContactUs",
                message: "SMS Setup",
                FormType: "",
                title: `Contact List for ${state.Activefield1}`,
            }
        }

    ];

    let AssetOptions = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: props.ActiveCategory,
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },

        //{
        //    key: "SMSTrigger",
        //    label: `Customize SMS Trigger for ${state.Activefield1}`,
        //    Icon: <TextsmsIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "SMSSetup",
        //        Category: "AddSMS",
        //        ListType: "SMSSetup",
        //        NewLoadComponent: "ItemAddPage",
        //        message: `Customize SMS Trigger for ${state.Activefield1}`,
        //        FormType: "",
        //        title: `Customize SMS Trigger for ${state.Activefield1}`,
        //    }
        //},
        //{
        //    key: "ContactList",
        //    label: `Contact List for ${state.Activefield1}`,
        //    Icon: <ContactsIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "PreSelect",
        //        Category: "Person",
        //        ListType: "Contractor",
        //        NewLoadComponent: "CreateContactUs",
        //        message: "SMS Setup",
        //        FormType: "",
        //        title: `Contact List for ${state.Activefield1}`,
        //    }
        //}

    ];

    let ContractorEditContractor = [
        {
            key: "Information",
            label: `Branch Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: "Branch",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
        {
            key: "SMSTrigger",
            label: `Branch Notification Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Branch Notification Policy`,
                FormType: "",
                title: `Branch Notification Policy`,
            }
        },
        {
            key: "SMSCompnaySelect",
            label: `Branch Invite Setup`,
            Icon: <MarkChatReadIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSCompnaySelect",
                Category: "SMSSelect",
                ListType: "SMSCompnaySelect",
                NewLoadComponent: "SMSCompnaySelect",
                message: `Select Public SMS`,
                FormType: "",
                title: `Select Public SMS`,
            }
        },
        {
            key: "ContactList",
            label: `Branch Contact List`,
            Icon: <ContactsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "PreSelect",
                Category: "Person",
                ListType: "Contractor",
                NewLoadComponent: "CreateContactUs",
                message: "SMS Setup",
                FormType: "",
                title: `Contact List`,
            }
        },
        //{
        //    key: "NewSMS",
        //    label: `New SMS# request`,
        //    Icon: <AddShoppingCartIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "ItemAddPage",
        //        Category: "RequestNewSMS",
        //        ListType: "",
        //        NewLoadComponent: "CreateContactUs",
        //        message: "New SMS# request",
        //        FormType: "",
        //        title: `New SMS# request`,
        //    }
        //},
        
        //{
        //    key: "Branding",
        //    label: `Corporate`,
        //    Icon: <ImagesearchRollerIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "BrandingUpdate",
        //        Category: "Branding",
        //        HQId: ContractorData.HQId,
        //        ListType: "",
        //        NewLoadComponent: "BrandingUpdate",
        //        message: "Branding Logo and corporate name change",
        //        FormType: "",
        //        title: `Branding / Logo / Name`,
        //    }
        //},
    ];


    let CompanyEditCompany = [
        //{
        //    key: "Details",
        //    label: `${props.Activefield1} Information`,
        //    Icon: <InfoIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "ShowDetailsPkSk", //"ShowDetailsText", 
        //        Category: "Person",
        //        ListType: "",
        //        NewLoadComponent: "",
        //        message: "",
        //        FormType: "",
        //        title: `Information`,
        //    }
        //},
        {
            key: "EmailTrigger",
            label: `Email Triggers`,
            Icon: <EmailIcon />,
            ShowHelp: true,
            title: "Documents and Pictures",
            description: "All documents and pictures uploaded for specific customers.  Sort by document type (reports, proposals, manuals, drawings, etc.).  Subscription setup for preselected list of personnel who will receive email with documents.",
            ShowVideo: true,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "EmailTriggerSetup",
                Category: "EmailTrigger",
                ListType: "SMSSetup",
                NewLoadComponent: "UrgencyDetails",
                message: `Email Trigger`,
                FormType: "",
                title: `Email Trigger`,
            }
        },
        {
            key: "CheckIn",
            label: `Check in/out Setup`,
            Icon: <AddLocationAltIcon />,
            ShowHelp: false,
            title: "Checkin / Checkout Setup",
            description: "Checkin / Checkout Setup",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "CheckInSetupPage",
                Category: "CheckIn",
                ListType: "Contractor",
                NewLoadComponent: "CheckInSetupPage",
                message: "Please select locations and personnel",
                FormType: "",
                title: `Check in/out Setup`,
            }
        },

    ];

    let OwnerEditOwner = [
        //{
        //    key: "Details",
        //    label: `${props.Activefield1} Information`,
        //    Icon: <InfoIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "ShowDetailsPkSk", //"ShowDetailsText", 
        //        Category: "Person",
        //        ListType: "",
        //        NewLoadComponent: "",
        //        message: "",
        //        FormType: "",
        //        title: `Information`,
        //    }
        //},
        {
            key: "EmailTrigger",
            label: `Email Triggers`,
            Icon: <EmailIcon />,
            ShowHelp: true,
            title: "Documents and Pictures",
            description: "All documents and pictures uploaded for specific customers.  Sort by document type (reports, proposals, manuals, drawings, etc.).  Subscription setup for preselected list of personnel who will receive email with documents.",
            ShowVideo: true,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "EmailTriggerSetup",
                Category: "EmailTrigger",
                ListType: "SMSSetup",
                NewLoadComponent: "UrgencyDetails",
                message: `Email Trigger`,
                FormType: "",
                title: `Email Trigger`,
            }
        },
        {
            key: "CheckIn",
            label: `Check in/out Setup`,
            Icon: <AddLocationAltIcon />,
            ShowHelp: false,
            title: "Checkin / Checkout Setup",
            description: "Checkin / Checkout Setup",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",  //"UrgencyDetails",
                Category: "CheckIn",
                ListType: "Owner",
                NewLoadComponent: "SMSSetup",  //"UrgencyDetails",
                message: "",
                FormType: "",
                title: `Check in/out Setup`,
            }
        },

    ]

    let CompanyEditPerson = [
        //{
        //    key: "Information",
        //    label: `Information`,
        //    Icon: <InfoIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "",
        //        Category: "",
        //        ListType: "",
        //        NewLoadComponent: "",
        //        message: "",
        //        FormType: "",
        //        title: `Information`,
        //    }
        //},
        {
            key: "Details",
            label: `${props.Activefield1} Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: "Person",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
        
        //{
        //    key: "EmailTrigger",
        //    label: `Customize Email Triggers`,
        //    Icon: <EmailIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "EmailTriggerSetup",
        //        Category: "EmailTrigger",
        //        ListType: "Customer",
        //        NewLoadComponent: "EmailTrigger",
        //        message: "If the customer service call always is for the same Location, Assets, Equipment, Approving Personal, etc.",
        //        FormType: "",
        //        title: `Customize Email Triggers`,
        //    }
        //},

    ]

    let SubPerson = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: "Person",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
    ]

    useEffect(() => {

        //determin Contractor?
        let Blocks = CompanyEditCompany;
        let ShowContractor = false;
        let ShowCompany = true;
        if (UserData.CustomerType === "Contractor") {
            // do not show company box when contractor is editing Defaults
            if (props.CompanyId === ContractorData.ContractorId) {
                Blocks = ContractorEditContractor;
            } else {
                Blocks = ContractorEditCompany;
            }
        }

        console.log("CompanyPrefSetup.NowEditing", state.ActiveCategory, NowEditing);

        if (state.ActiveCategory === "Person" && NowEditing === "Company") {
            Blocks = PersonOptions;
        }

        if (state.ActiveCategory === "Person" && NowEditing === "Sub") {
            Blocks = SubPerson;
        }

        if (state.ActiveCategory === "Person" && NowEditing === "Contractor") {
            Blocks = ContractorPersonOptions;
        } 

        if (state.ActiveCategory === "Location" && NowEditing === "Company") {
            Blocks = AssetOptions;
        }

        if (state.ActiveCategory === "Equipment" && NowEditing === "Company") {
            Blocks = AssetOptions;
        }

        if (state.ActiveCategory === "Owner" && NowEditing === "Owner") {
            Blocks = OwnerEditOwner;
        }

        if (WebsiteType === "Checkin") {
            Blocks = CheckInOwner;
        }
        

        setState(prevState => ({
            ...prevState,
            //ShowContractor: ShowContractor,
            //ShowCompany: ShowCompany,
            Blocks: Blocks,
        }))

        //if (props && props.Data) {
        //  setState(prevState => ({
        //    ...prevState,
        //    title: props.Data.field1,
        //    Category: props.Data.Category,
        //    SMSMobile: props.Data.mobile,
        //  }));
        //}

    }, [state.ActiveCategory, props.CompanyId])

    console.log("CompanyPrefSetup.state", state);

    return state.Blocks.length > 0 && (
        <div
            //className={classes.root}
        >
            <Paper
                sx={{p:1}}
                //className={classes.paper}
            >
                {state.ActiveCategory !== "Person" && (
                <Grid container spacing={1}>
                    {NowEditing === "Contractor" ? (
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Default Policy - Applies to all customers without a custom policy
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {`- Custom Policy Setup`}
                            </Typography>
                        </Grid>
                    )}

                </Grid>
                )}

                <Grid item xs={12} >
                    <List component="nav" aria-label="Setup">

                        {state.Blocks.map((eachSetup, indd) => (
                            <ListItem
                                onClick={() => SetUpDrawer(
                                    eachSetup.SetUpDrawer.loadcomponent,
                                    eachSetup.SetUpDrawer.Category,
                                    eachSetup.SetUpDrawer.ListType,
                                    eachSetup.SetUpDrawer.NewLoadComponent,
                                    eachSetup.SetUpDrawer.message,
                                    eachSetup.SetUpDrawer.FormType,
                                    eachSetup.SetUpDrawer.title
                                )}
                                key={indd + eachSetup.key}
                                button
                            >
                                <ListItemIcon>
                                    {eachSetup.Icon}
                                </ListItemIcon>
                                <ListItemText primary={eachSetup.label} />

                                {eachSetup.ShowHelp && (
                                    <ListItemIcon>
                                    <HelpBox
                                            title={eachSetup.title}
                                            description={eachSetup.description}
                                            ShowVideo={eachSetup.ShowVideo}
                                            VideoId={eachSetup.VideoId}
                                        />
                                    </ListItemIcon>
                                )}

                            </ListItem>
                        ))}

                   </List>

                    

                </Grid>

            </Paper>

            <Backdrop
               // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
          

        </div>
    );
}
