/*
 * 
 * 
 * 
 * 
 * 
 */



import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
    Backdrop,
    CircularProgress,
    Box, Button
} from '@mui/material';
//import { ThemeProvider, useTheme, makeStyles } from '@mui/styles';
import { useParams, useLocation } from 'react-router-dom';
import { API } from "aws-amplify";
//import { createBatch, createSvcbotDb } from '../graphql/mutations';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../components/Page';
import ItemProfilePicture from './ItemProfilePicture';
import ItemAddForms from './ItemAddForms';
import {
    removeEmptyStringElements,
    NewPageId,
    Pk1SingelItem,
    isNotEmptyObject,
    UpdateData
} from '../utils/CommonGraphql';
import DynamicForms from '../Forms/FormShow';
import { FormArray } from '../Forms/FormList';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function buildTable(arr) {
    const rows = arr.map((row, i) => {
        return <tr style={{ backgroundColor: i % 2 ? '#F0FFF2' : 'white' }} key={i}>
            <td>{row[0]}</td><td>{row[1]}</td>
        </tr>
    })
    return <table><tbody>{rows}</tbody></table>
}


const Account = (props) => {
    // const classes = useStyles();
    const params = useParams();
    const [loading, SetLoading] = useState(false);
    // let query = useQuery();
    const dispatch = useDispatch();
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    //console.log('cid cid ', cid)
    const initialState = {
        Category: "",
        FormJSON: [],
        FormData: [],
        FormExtra: [],
        AlarmMessage: "",
        OpenAlert: false,
        ImagePic: "",
        UpdateFormJSON: [],
        DefaultImage: "",
        IsDev: false,   // is developer
    }
    const [state, setState] = useState(initialState);


    useEffect(() => {

        GetDatafromDB(ContractorData.HQId, ContractorData.HQId);
        // GetDeveloperForm()

    }, []);

    const GetDeveloperForm = () => {

        let DevEdit = FormArray("DevEdit", NewPageId("DevEdit"));
        if (DevEdit && DevEdit[0].FormData) {
            return DevEdit[0].FormData
        } else {
            return []
        }
    }

    const GetDatafromDB = async (pk, sk) => {
        SetLoading(true);

        let getforms = ["ContractorSignupCompany", "ContractorSignupLocation"];
        let AllItemFormsarry = [];
        let PhotoService = "";
        let DevForm = [];
        let Data = "";

        let DisableForm = true;
        let IsDev = false;

        if (UserData && UserData.Level > 750) {
            DisableForm = false;
        }

        if (UserData && UserData.Level > 2000) {
            IsDev = true;
        }

        //get data from Redux
        if (HQData && HQData.hasOwnProperty('CompanyId')) {
            Data = HQData
        } else {
            //get data from DB
            let NewData2 = await Pk1SingelItem(pk, sk);
            let NewData = await NewData2;
            if (NewData) {
                Data = JSON.parse(NewData.dataJSON);
            }
        }

        let AllItemForms = Data.AllItemForms;

        getforms.map(each => {
            AllItemFormsarry.push(AllItemForms.find(x => x.Category === each))
        })

        if (Data && Data.PhotoService && Data.PhotoService.length > 0) {
            PhotoService = Data.PhotoService[0];
        }

        if (Data && Data.DevData && Data.DevData.hasOwnProperty('FormType')) {
            DevForm = Data.DevData.FormData;
        }

        if (DevForm.length === 0) {
            DevForm = GetDeveloperForm();
        }

        console.log('ItemAddPage-Data', Data)
        console.log('ItemAddPage-DevForm', DevForm)

        setState(prevState => ({
            ...prevState,
            Data: Data,
            CompanyId: ContractorData.HQId,
            pk1: ContractorData.HQId,
            sk1: ContractorData.HQId,
            DevForm: DevForm,
          //  UpdateFormJSON: AllItemFormsarry,
            UpdatedataJSON: { AllItemForms: AllItemFormsarry },
            UpdateExisting: true,
            Category: "HQ",
            DisableForm: DisableForm,
            IsDev: IsDev,
            ImagePic: PhotoService
        }))



        SetLoading(false);

    }

    const Image = imgg => {
        console.log('ItemAddPage.Image', imgg);
        setState(prevState => ({
            ...prevState,
            ImagePic: imgg
        }));
    }

    const ReturnBackData = form => {
        console.log('formItemAddForm', form);
        setState(prevState => ({
            ...prevState,
            FormData: form
        }));
    }

    const AutoCompleteReturn = form => {
        console.log('AutoCompleteReturn', form);
        setState(prevState => ({
            ...prevState,
            FormExtra: form
        }));
    }

    const UpdateManfesto = img => {
        console.log('New Logo', img);

        let CreactThums = [
            {
                "filename": img.filename,
                "bucket": HQData.SubDomain,
                "dir": "public/",
                "thum": "favicon.ico",
                "width": 64
            },
            {
                "filename": img.filename,
                "bucket": HQData.SubDomain,
                "dir": "public/",
                "thum": "logo192.png",
                "width": 192
            },
            {
                "filename": img.filename,
                "bucket": HQData.SubDomain,
                "dir": "public/",
                "thum": "logo512.png",
                "width": 512
            },
            {
                "filename": img.filename,
                "bucket": HQData.SubDomain,
                "dir": "public/",
                "thum": "apple-touch-icon.png",
                "width": 180
            },
            {
                "filename": img.filename,
                "bucket": HQData.SubDomain,
                "dir": "public/",
                "thum": "favicon-32x32.png",
                "width": 32
            }
            ,
            {
                "filename": img.filename,
                "bucket": HQData.SubDomain,
                "dir": "public/",
                "thum": "favicon-16x16.png",
                "width": 16
            }
        ];
        

            API.post("ImageResize", "/send", {
                body: { Records: CreactThums }
            });

    }


    const SaveData = (FormDataBack) => {

        try {
            console.log("ItemAddForm1.SaveData2", FormDataBack);

           

            var currentUnixTime = new Date();
            var currentTime = currentUnixTime.toISOString();

            let Location = "";
            let Address = "";
            let CompanyName = "";
            let BranchName = "";
            let Name = "";
            let email = "";
            let phone = "";
            let mobile = "";
            let areacode = "";
            let SMSFunction = "";
            let website = "";
            let InputArray = [];
            let avatar = "";
            let postTEXT = "";
            let TimeZone = "";
            let BranchOffices = 0;
            let BranchType = "Branch";
            let VariableInputShow = "";
            let PhotoService = [];
            let url = "";
            let SubDomain = NewPageId("SubDom");

            if (state.ImagePic && state.ImagePic.ImageFullPath) {
                avatar = state.ImagePic.ImageFullPath;
                PhotoService = [state.ImagePic];

                UpdateManfesto(state.ImagePic);
            }

            //  let ContractorSignupAdmin = state.StepValueArray.find(x => x.Category === "ContractorSignupAdmin")

            // postHTML += `<tr align="left"><th ></th><th >Thanks you for sign up. Our team memebers will configure the system and contact you once eveything is ready.</th></tr>`;

            FormDataBack.AllItemForms.map((each, index) => {

                if (each.KeyId === "ContractorSignupLocation" && isNotEmptyObject(each.Location)) {
                    //  console.log('ItemAddPage-Address1', each.Details)
                    Location = each.Location;
                }

                each.FormData.map((EachForm, indd) => {
                    console.log("ContractorSignup.EachForm", EachForm);
                    VariableInputShow = EachForm.VariableInput;

                    switch (EachForm.VariableName) {
                        case "Address":
                            Address = EachForm.VariableInput
                            break;
                        case "CompanyName":
                            CompanyName = EachForm.VariableInput;
                            BranchName = EachForm.VariableInput;
                            break;
                        case "name":
                            Name = EachForm.VariableInput
                            break;
                        case "email":
                            email = EachForm.VariableInput;
                            if (email) {
                                email = email.toLowerCase();
                            }
                            break;
                        case "website":
                            website = EachForm.VariableInput;
                            break;
                        case "phone":
                            phone = EachForm.VariableInput
                            break;
                        case "TimeZone":
                            TimeZone = EachForm.VariableInput;
                            VariableInputShow = EachForm.VariableInput.value;
                            break;
                        case "mobile":
                            mobile = EachForm.VariableInput
                            break;
                        case "Branch":
                            BranchOffices = EachForm.VariableInput
                            break;
                        case "areacode":
                            areacode = EachForm.VariableInput
                            break;
                        case "SMSFunction":
                            SMSFunction = EachForm.VariableInput
                            break;
                        default:
                           // SMSFunction = EachForm.VariableInput
                            break;
                    }

                    // postHTML += `<tr align="left"><th >${EachForm.label}</th><th >${VariableInputShow}</th></tr>`;
                    postTEXT += `${EachForm.label}: ${VariableInputShow} `;
                })
            })

            if (BranchOffices > 1) {
                BranchType = "HQ";
            }

            const FormdataJSON = {
                date: currentTime,
                AllItemForms: FormDataBack.AllItemForms,
                Location: Location,
                Address: Address,
                CompanyName: CompanyName,
                avatar: avatar,
                BranchName: BranchName,
                PhotoService: PhotoService,
                TimeZone: TimeZone,
                email: email,
                phone: phone,
                mobile: mobile,
                BranchType: BranchType,
                BranchOffices: BranchOffices,
                SearchWords: postTEXT,
                HQName: CompanyName,
                HQId: HQData.HQId,
                field1: `${CompanyName} - HQ`,
                field2: Address,
                website: website,
                //   SubDomain: SubDomain,
                Category: "HQ",
                ContractorName: CompanyName,
                posterId: {
                    pk1: UserData.pk1,
                    sk1: UserData.sk1,
                    field1: UserData.filed1,
                    field2: UserData.filed2,
                    avatar: UserData.avatar,
                    email: UserData.email,
                    mobile: UserData.mobile,
                    phone: UserData.phone,
                },
                status: "Active",
            }

            if (FormDataBack.UpdateExisting) {

                let newFormdataJSON = {
                    ...state.Data,
                    ...FormdataJSON
                }

                let UpdateDataaa = {
                    pk1: FormDataBack.UpdateKey,
                    sk1: FormDataBack.UpdatePk1,
                    dataJSON: JSON.stringify(removeEmptyStringElements(newFormdataJSON))
                }

                console.log('ItemAddForm1.newFormdataJSON', newFormdataJSON)

                setState(prevState => ({
                    ...prevState,
                    Data: newFormdataJSON
                }));

                UpdateData(UpdateDataaa)
                    .then((up) => {
                        // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                        dispatch({ type: 'HQ_DATA', payload: newFormdataJSON });
                        if (props.CloseDrawer) {
                            props.CloseDrawer();
                        }

                    }).catch((err) => console.log("SRDetails.failed", err));

            }

        } catch (err) { console.log("SaveData err", err); }
    }

    const DevUpdate = () => {

        try {
            console.log("ItemAddForm.SaveData2", state.DevData);

            var currentUnixTime = new Date();
            var currentTime = currentUnixTime.toISOString();

            let Status = "";
            let fee = 0;
            //let BranchType = "Branch";
            let VariableInputShow = "";
            //let PhotoService = [];
            let Bucket = "";
            let SubDomain = "";

            if (state.DevData && state.DevData.FormData.length > 0) {
                state.DevData.FormData.map((EachForm, indd) => {
                    console.log("ContractorSignup.EachForm", EachForm);
                    VariableInputShow = EachForm.VariableInput;

                    switch (EachForm.VariableName) {
                        case "SubDomain":
                            SubDomain = EachForm.VariableInput
                            break;
                        case "Bucket":
                            Bucket = EachForm.VariableInput
                            break;
                        case "fee":
                            fee = EachForm.VariableInput
                            break;
                        case "status":
                            if (EachForm.VariableInput) {
                                Status = "Active"
                            } else {
                                Status = "Disabled"
                            }
                            break;
                            default:
                    }
                })

                const FormdataJSON = {
                    date: currentTime,
                    DevData: state.DevData,
                    SubDomain: SubDomain,
                    Bucket: Bucket,
                    fee: fee,
                    posterId: {
                        pk1: UserData.pk1,
                        sk1: UserData.sk1,
                        field1: UserData.filed1,
                        field2: UserData.filed2,
                        avatar: UserData.avatar,
                        email: UserData.email,
                        mobile: UserData.mobile,
                        phone: UserData.phone,
                    },
                    status: Status,
                }

                let newFormdataJSON = {
                    ...state.Data,
                    ...FormdataJSON
                }

                let UpdateDataaa = {
                    pk1: state.pk1,
                    sk1: state.sk1,
                    dataJSON: JSON.stringify(removeEmptyStringElements(newFormdataJSON))
                }

                console.log('ItemAddPage-DB-Update-newFormdataJSON', newFormdataJSON)

                setState(prevState => ({
                    ...prevState,
                    Data: newFormdataJSON
                }));

                UpdateData(UpdateDataaa)
                    .then((up) => {

                        dispatch({ type: 'HQ_DATA', payload: newFormdataJSON });

                    }).catch((err) => console.log("SRDetails.failed", err));
            }

        } catch (err) { console.log("SaveData err", err); }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
        //Snackbar, Alert
    };

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    console.log('ItemAddPage-state', state)

    return (
        <Page
            key={props.id}
            title="Account"
        >
            <Container >
                <Grid
                    container
                    spacing={1}
                >

                    <Grid
                        item
                        lg={4}
                        md={6}
                        xs={12}
                    >
                        <ItemProfilePicture
                            ImagePic={imgg => Image(imgg)}
                            AcceptFileType="image/*"
                            DefaultImage={state.ImagePic}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={8}
                        md={6}
                        xs={12}
                    >

                        <Grid container>

                            <Grid item xs={12}>
                                <ItemAddForms
                                  //  ReturnBackData={e => ReturnBackData(e)}
                                 //   AutoCompleteReturn={e => AutoCompleteReturn(e)}
                                    Category={state.Category}
                                    SaveData={(e) => SaveData(e)}
                                    CompanyId={state.CompanyId}
                                    UpdatePk1={state.pk1}
                                    UpdateKey={state.sk1}
                                    key={props.id}
                                    id={props.id}
                                   // UpdateFormJSON={state.UpdateFormJSON}
                                    UpdatedataJSON={state.UpdatedataJSON}
                                    DisableForm={state.DisableForm}
                                    //FormExtra={state.FormExtra}
                                    UpdateExisting={state.UpdateExisting}
                                />
                            </Grid>


                            {state.DevForm
                                && state.DevForm.length > 0
                                && state.IsDev
                                && (
                                <Grid item xs={12}>
                                    <DynamicForms
                                        key={"autteedfdsfoi"}
                                        id={"autdsfdgdtoi"}
                                        SaveDetails={true}
                                        Category="DevEdit"
                                        FormType="DevEdit"
                                        DisableForm={false}
                                        //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                        //  CompanyId={state.CompanyId}
                                        title="Developer Edit"
                                        ShowEditButton={false}
                                        UpdateFormData={e => console.log("UpdateFormData", e)}
                                        PreviewEdit={false}
                                        EditItem={e => console.log("EditItem", e)}
                                        FormData={state.DevForm}
                                        onSubmit={(model) => SetValues(model, "DevData")}
                                    />
                                </Grid>
                            )}

                            {state.IsDev && (
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    p={2}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        //  disabled={rest.DisableForm}
                                        onClick={() => DevUpdate()}
                                    >
                                        Developer Settings Update
                                    </Button>
                                </Box>
                            </Grid>
                            )}

                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Page>
    );
};

export default Account;
