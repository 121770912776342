/*
 * 
 * Signup new contractor
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/styles';
//import { currentTime } from '../utils/DateTime';
import {
    Paper, Grid, Button, Stack,
    Backdrop,
    CircularProgress, Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar,
} from '@mui/material';
import { API } from "aws-amplify";
import {
    removeEmptyStringElements,
    NewPageId,
    CreateContractorId,
    isNotEmptyObject,
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import MuiAlert from '@mui/material/Alert';
import SignupWizEachStep from './SignupWizEachStep';
import { SendEmail } from '../utils/SendEmail';

let ThisDate = new Date().getTime();
const UUid = CreateContractorId(ThisDate);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//const useStyles = makeStyles((theme) => ({
//    root: {
//        // backgroundColor: theme.palette.background.dark,
//        //minHeight: '100%',
//        padding: theme.spacing(2),
//    },
//    paper: {
//        padding: theme.spacing(2),
//        color: theme.palette.text.secondary,
//    },
//}));

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

let DevData = {
    "key": "autdsfdgdtoi",
    "FormName": "Developer Edit",
    "FormType": "DevEdit",
    "Category": "DevEdit",
    "PhotoService": [],
    "Documents": [],
    "FormData": [
        {
            "Order": 3,
            "options": [],
            "label": "SubDomain",
            "VariableName": "SubDomain",
            "Editable": false,
            "TableColumn": false,
            "VariableInput": "ssuubbdomain.sellonline.com",
            "type": "textline",
            "key": "DevEdit_kf71hTZq34",
            "Category": "DevEdit",
            "required": true,
            "defaultValue": "ssuubbdomain.sellonline.com",
            "ShowValue": "ssuubbdomain.sellonline.com"
        },
        {
            "Order": 3,
            "options": [],
            "label": "S3 Bucket",
            "VariableName": "Bucket",
            "Editable": false,
            "TableColumn": false,
            "VariableInput": "news3bucket",
            "type": "textline",
            "key": "DevEdit_kf71hTZq35",
            "Category": "DevEdit",
            "required": true,
            "defaultValue": "news3bucket",
            "ShowValue": "news3bucket"
        },
        {
            "Order": 3,
            "options": [],
            "label": "Subscription Fee",
            "VariableName": "fee",
            "Editable": false,
            "TableColumn": false,
            "VariableInput": "1900",
            "type": "textline",
            "key": "DevEdit_kf71hTZq36",
            "Category": "DevEdit",
            "required": true,
            "defaultValue": "1900",
            "ShowValue": "1900"
        },
        {
            "Order": 2,
            "options": [],
            "label": "Status",
            "VariableName": "status",
            "defaultValue": true,
            "Category": "DevEdit",
            "VariableInput": true,
            "type": "switch",
            "key": "DevEdit_kf71hTZq37",
            "required": false,
            "Searchable": true,
            "Editable": true,
            "TableColumn": false
        }
    ]
}

const Blocks = [
    {
        title: "Company Information",
        message: "Please enter Company Name and short description of the type of business",
        LoadComponenet: "ContractorSignupCompany",
        id: "ContractorSignupCompany",
        FormData: [],
        Category: "ContractorSignupCompany",
        // AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "SMSAddEdit",
        //  Additem: (add) => props.Additem(add),
        ReturnArray: "StepReturn",
        propss: {
            //  FormData: [],
            //Data: "",
            //CompanyId: props.CompanyId,
            //CompanyName: props.CompanyName,
            //SelectedDataReturn: (e) => props.SaveSelection(e, "Priority"),
            //SearchWords: "",
            //ShowEdit: true,

        }
    },
    {
        title: "Main Location & Branches",
        message: `Please enter the main Business location address.  Additional branches can be added 
once the base system setup is complete`,
        LoadComponenet: "ContractorSignupLocation",
        id: "ContractorSignupLocation",
        Category: "ContractorSignupLocation",
        //AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "SMSAddEdit",
        // Additem: (add) => props.Additem(add),
        ReturnArray: "StepReturn",
        FormData: [],
        propss: {
            // FormData: [],
            //Data: props.ContractorData,
            //CompanyId: props.CompanyId,
            //CompanyName: props.CompanyName,
            //SelectedDataReturn: (e) => props.SaveSelection(e, "StepReturn"),
            //SearchWords: "",
            //ShowEdit: true,

        }
    },
    {
        title: "Admin Setup",
        message: `Please enter the information and email of the first System Admin. The Admin will be able to add
 other personal and assign access level and privillages`,
        LoadComponenet: "ContractorSignupAdmin",
        id: "ContractorSignupAdmin",
      //  Category: "ContractorSignupAdmin",
        Category: "Person",
        // AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "ProgressSelectSteps",
        FormData: [],
        //   Additem: (add) => props.Additem(add),
        ReturnArray: "ProgressReturn",
        propss: {
            //Data: "",
            //CompanyId: props.CompanyId,
            //DetailComponent: "ProgressSelectSteps",
            //CompanyName: props.CompanyName,
            //SelectedDataReturn: (e) => props.SaveSelection(e, "ProgressReturn"),
            //SearchWords: "",
            //ShowEdit: true,

        }
    },
    {
        title: `SMS number`,
        message: `Please select area code for the first SMS, which is included in the plan.  Seperate SMS is
required for each Branch office.  Additional SMS can be purchased.`,
        LoadComponenet: "ContractorSignupSMS",
        id: "ContractorSignupSMS1",
        Category: "ContractorSignupSMS",
        //  AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "ContractorSignupSMS",
        FormData: [],
        //  Additem: (add) => props.Additem(add),
        ReturnArray: "ESCReturn",
        propss: {
            //Data: props.ContractorData,
            //CompanyId: props.CompanyId,
            //CompanyName: props.CompanyName,
            //SelectedDataReturn: (e) => props.SaveSelection(e, "ESCContractor"),
            //SearchWords: "",
            //ShowEdit: true,
            //showcustom: true,
        },
    },

//    {
//        title: `SMS number # 2`,
//        message: `Please select area code for the second SMS, which is included in the plan.  Seperate SMS is
//required for each Branch office.  Additional SMS can be purchased.`,
//        LoadComponenet: "ContractorSignupSMS2",
//        id: "ContractorSignupSMS2",
//        Category: "ContractorSignupSMS2",
//        //  AddBox: (e) => props.AddBox(e),
//        ShowAdd: false,
//        DetailComponent: "ContractorSignupSMS2",
//        FormData: [],
//        //  Additem: (add) => props.Additem(add),
//        ReturnArray: "ESCReturn",
//        propss: {
//            //Data: props.ContractorData,
//            //CompanyId: props.CompanyId,
//            //CompanyName: props.CompanyName,
//            //SelectedDataReturn: (e) => props.SaveSelection(e, "ESCContractor"),
//            //SearchWords: "",
//            //ShowEdit: true,
//            //showcustom: true,
//        },
//    },

    //{
    //    title: "Select Box Color",
    //    message: "this is the message for this module 2",
    //    LoadComponenet: "ColorSelection",
    //    id: "ColorSelection",
    //    AddBox: (e) => props.AddBox(e),
    //    ShowAdd: false,
    //    DetailComponent: "",
    //    Additem: (add) => props.Additem(add),
    //    ReturnArray: "ColorSelected",
    //    propss: {
    //        Data: "",
    //        CompanyId: props.CompanyId,
    //        CompanyName: props.CompanyName,
    //        SelectedDataReturn: (e) => props.SaveSelection(e, "ColorSelected"),
    //        SearchWords: "",
    //        ShowEdit: true,
    //        showcustom: true,
    //    }
    //},


    //{
    //    title: "SMS Feedback Messages",
    //    message: "SMS Feedback Messages",
    //    LoadComponenet: "SMSMessagesBack",
    //    id: "SMSMessagesBack",
    //    AddBox: (e) => props.AddBox(e),
    //    ShowAdd: false,
    //    DetailComponent: "",
    //    Additem: (add) => props.Additem(add),
    //    ReturnArray: "SMSMessagesBack",
    //    propss: {
    //        Data: "",
    //        CompanyId: props.CompanyId,
    //        CompanyName: props.CompanyName,
    //        SelectedDataReturn: (e) => props.SaveSelection(e, "SMSMessagesBack"),
    //        SearchWords: "",
    //        ShowEdit: true,
    //        showcustom: true,
    //    }
    //},
];


export default function SimpleCard(props) {
   // const classes = useStyles();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [expanded, setExpanded] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        StepValueArray: Blocks,
        SignUpComplete: false,
        SignUpMessageHeading: "",
        SignUpMessage: ""

    }
    const [state, setState] = useState(initialState);

    //useEffect(() => {

    //    let jssss = { pk: "this1", sk1: "next1" }
    //        //, { pk: "this2", sk1: "next2" }, { pk: "this3", sk1: "next3" },]
    //    let mrsh = AWS.DynamoDB.Converter.marshall(jssss);
    //    console.log('marshall-mrsh', mrsh)
    //    let unm = AWS.DynamoDB.Converter.unmarshall(mrsh);
    //    console.log('marshall-unmrsh', unm)

    //}, [])


    const handleNext = () => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    const ClosingDrawer = (e) => {
        console.log("ClosingDrawer", e)
        SetDeawerOpen(false)
    }

    const SaveData = async () => {

        try {

            var currentUnixTime = new Date();
            var currentTime = currentUnixTime.toISOString();

            let Location = "";
            let Address = "";
            let CompanyName = "";
            let Name = "";
            let email = "";
            let phone = "";
            let mobile = "";
            let areacode = "";
            let SMSFunction = "";
            let website = "";
            let InputArray = [];
            let postHTML = "<div><p>Thanks you for sign up. Our team memebers will configure the system and contact you once eveything is ready.</p>";
            postHTML += "<p>Please see the quick start video</p>";
            postHTML += `<p><a href="https://youtu.be/IE4d-PX4WwY" target="_blank">
                            <img alt="Start" src="https://www.service-opportunity.com/images/intro_video_still.png">
                        </a></p>`;
           // postHTML += "<p>Please see the quick start video</p>";
            let postTEXT = "";
            let TimeZone = "";
            let BranchOffices = 0;
            let BranchType = "Branch";
            let VariableInputShow = "";
            let url = "";
            let SubDomain = "";
            let ContractorSignupAdmin = state.StepValueArray.find(x => x.Category === "Person")

           // postHTML += `<table><tr align="left"><th ></th><th ></th></tr>`;
            postHTML += `<table>`;

            state.StepValueArray.map((each, index) => {

                if (each.KeyId === "ContractorSignupLocation" && isNotEmptyObject(each.Location)) {
                    //  console.log('ItemAddPage-Address1', each.Details)
                    Location = each.Location;
                }

                each.FormData.map((EachForm, indd) => {
                    console.log("ContractorSignup.EachForm", EachForm);
                    VariableInputShow = EachForm.VariableInput;

                    switch (EachForm.VariableName) {
                        case "Address":
                            Address = EachForm.VariableInput
                            break;
                        case "CompanyName":
                            CompanyName = EachForm.VariableInput
                            break;
                        case "name":
                            Name = EachForm.VariableInput
                            break;
                        case "email":
                            email = EachForm.VariableInput;
                            if (email) {
                                email = email.toLowerCase();
                            }
                            break;
                        case "website":
                            website = EachForm.VariableInput;
                            break;
                        case "phone":
                            phone = EachForm.VariableInput
                            break;
                        case "TimeZone":
                            TimeZone = EachForm.ShowValue;
                            VariableInputShow = EachForm.ShowValue;
                            break;
                        case "mobile":
                            mobile = EachForm.VariableInput
                            break;
                        case "Branch":
                            BranchOffices = EachForm.VariableInput
                            break;
                        case "areacode":
                            areacode = EachForm.VariableInput
                            break;
                        case "SMSFunction":
                            SMSFunction = EachForm.VariableInput
                            break;
                        case "SubDomain":
                            SubDomain = EachForm.VariableInput
                            break;
                            default:
                    }

                    postHTML += `<tr align="left"><th >${EachForm.label}</th><th >${VariableInputShow}</th></tr>`;
                    postTEXT += `${EachForm.label}: ${VariableInputShow} \n`;
                })
            })

            if (BranchOffices > 1) {
                BranchType = "HQ";
            }

            let HQkey = `Data::Corp::HQ::Info::${UUid}`;
            let Branchkey = HQkey;  // `Data::Corp::Branch::Info::${UUid}`;
            let PersonKey = `Data::Company::Resource::Person::${email}`;
           // let LocationKey = `Data::Company::Asset::Location::${UUid}`;

            postHTML += `<tr align="left"><th >HQ ID</th><th >${UUid}</th></tr>`;
           // postHTML += `<tr align="left"><th >Branch ID</th><th >${Branchkey}</th></tr>`;
            postHTML += "</table></div>";

            const FormdataJSON = {
                date: currentTime,
               // StepValueArray: state.StepValueArray,
                AllItemForms: state.StepValueArray,
                Location: Location,
                Address: Address,
                CompanyName: CompanyName,
                DevData: DevData,
                CompanyId: HQkey,
                HQId: HQkey,
                BranchId: HQkey,
                CustomerType: "Contractor",
                Name: Name,
                TimeZone: TimeZone,
                email: email,
                phone: phone,
                mobile: mobile,
                areacode: areacode,
                BranchType: BranchType,
                BranchOffices: BranchOffices,
                SMSFunction: SMSFunction,
                SearchWords: postTEXT,
                key: HQkey,
                HQName: CompanyName,
                BranchName: `${CompanyName} - HQ`,
                field1: `${CompanyName} - HQ`,
                field2: Address,
                website: website,
                SubDomain: SubDomain,
                id: UUid,
                Category: "HQ",
                ContractorId: HQkey,
                ContractorName: CompanyName,
                posterId: {
                    field1: "Signup"
                },
                status: "Active",
            }

            //HQ corporate
            let HQadd = {
                pk1: HQkey,
                sk1: HQkey,
                gsi1Pk2: "Developer::Account::0786",
                gsi1Sk2: UUid,
                gsi2Pk2: "Developer::Account::0786",
                gsi2Sk2: HQkey,
            }

            let HQJson = {
                ...HQadd,
                ...FormdataJSON
            }
            
            let HQDB = {
                'PutRequest': {
                    'Item': {
                        ...HQadd,
                        dataJSON: removeEmptyStringElements(HQJson)
                    }
                }
            };

            InputArray.push(HQDB);

            ////Branch corporate
            //let Branchadd = {
            //    pk1: HQkey,
            //    sk1: Branchkey,
            //    gsi1Pk2: Branchkey,
            //    gsi1Sk2: HQkey,
            //    gsi2Pk2: HQkey,
            //    gsi2Sk2: Branchkey,
            //}

            //let BranchJson = {
            //    ...Branchadd,
            //    ...FormdataJSON,
            //    ContractorId: Branchkey,
            //    key: Branchkey,
            //    CompanyId: Branchkey,
            //    BranchId: Branchkey,
            //    Category: "Branch",
            //    BranchName: CompanyName,
            //    field1: CompanyName,
            //}
            
            //let BranchDB = {
            //    'PutRequest': {
            //        'Item': {
            //            ...Branchadd,
            //            dataJSON: removeEmptyStringElements(BranchJson)
            //        }
            //    }
            //};

            //InputArray.push(BranchDB);

            //Location
            //let Locationadd = {
            //    pk1: Branchkey,
            //    sk1: LocationKey,
            //    gsi1Pk2: LocationKey,
            //    gsi1Sk2: Branchkey,
            //    gsi2Pk2: HQkey,
            //    gsi2Sk2: LocationKey,
            //}

            //let LocationJson = {
            //    ...Locationadd,
            //    ...FormdataJSON,
            //    ContractorId: Branchkey,
            //    key: LocationKey,
            //    CompanyId: Branchkey,
            //    BranchId: Branchkey,
            //    Category: "Location",
            //    BranchName: CompanyName,
            //}

            //let LocationaddDB = {
            //    'PutRequest': {
            //        'Item': {
            //            ...Locationadd,
            //            dataJSON: removeEmptyStringElements(LocationJson)
            //        }
            //    }
            //};

            //InputArray.push(LocationaddDB);

            let PersonFormdataJSON = {
                ...FormdataJSON,
                AllItemForms: [ContractorSignupAdmin],
                key: PersonKey,
                status: "Waiting",
                field1: Name,
                field2: email,
                Privileges: { value: 1000, label: "Corporate Admin" },
                Level: 1000,
                pk1: Branchkey,
                sk1: PersonKey,
                gsi1Pk2: Branchkey,
                gsi1Sk2: `phone::${mobile}`,
                gsi2Pk2: HQkey,
                gsi2Sk2: `email::${email}`,
                ContractorId: Branchkey,
                CompanyId: Branchkey,
                BranchId: Branchkey,
                Category: "Person",
            }

           

            let DBInput2 = {
                'PutRequest': {
                    'Item': {
                        pk1: Branchkey,
                        sk1: PersonKey,
                        gsi1Pk2: Branchkey,
                        gsi1Sk2: `phone::${mobile}`,
                        gsi2Pk2: HQkey,
                        gsi2Sk2: `email::${email}`,
                        dataJSON: removeEmptyStringElements(PersonFormdataJSON)
                    }
                }
            };

            InputArray.push(DBInput2);



            let Developer = {
                ...FormdataJSON,
                AllItemForms: [],
                key: `Data::Company::Resource::Person::ali_rehan@msn.com`,
                status: "Active",
                field1: "Developer Admin",
                field2: "NavigationBridge.com",
                Privileges: { value: 5000, label: "Developer Admin" },
                Level: 5000,
                pk1: Branchkey,
                sk1: `Data::Company::Resource::Person::ali_rehan@msn.com`,
                gsi1Pk2: Branchkey,
                gsi1Sk2: `phone::+15627601914`,
                gsi2Pk2: HQkey,
                gsi2Sk2: `email::ali_rehan@msn.com`,
                ContractorId: Branchkey,
                CompanyId: Branchkey,
                BranchId: Branchkey,
                Category: "Person",
            }


            let DBInput3 = {
                'PutRequest': {
                    'Item': {
                        pk1: Branchkey,
                        sk1: `Data::Company::Resource::Person::ali_rehan@msn.com`,
                        gsi1Pk2: Branchkey,
                        gsi1Sk2: `phone::+15627601914`,
                        gsi2Pk2: HQkey,
                        gsi2Sk2: `email::ali_rehan@msn.com`,
                        dataJSON: removeEmptyStringElements(Developer)
                    }
                }
            };

            InputArray.push(DBInput3);

            console.log("ContractorSignup.FormdataJSON", FormdataJSON);
            console.log("ContractorSignup.PersonFormdataJSON", PersonFormdataJSON);
            console.log("ContractorSignup.InputArray", InputArray);

            BatchWrite(InputArray, PersonFormdataJSON, postHTML, postTEXT);

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const BatchWrite = (InputArray, PersonFormdataJSON, postHTML, postTEXT) => {
        try {
            let params = {
                RequestItems: {
                    "svcbot_db": InputArray
                }
            };

            dynamodb.batchWrite(params, function (err, data) {
                if (err) {
                    console.log(`BatchWrite.err: ${err}`);
                    EmailNotification(PersonFormdataJSON, postHTML, postTEXT, true)
                    setState(prevState => ({
                        ...prevState,
                        SignUpComplete: true,
                        SignUpMessageHeading: "SignUp Failed",
                        SignUpMessage: "An email has been sent to our technical support team.  They will contact you shortly."
                    }))
                } else {
                    console.log("BatchWrite.worked:", data);
                    EmailNotification(PersonFormdataJSON, postHTML, postTEXT, false)
                    setState(prevState => ({
                        ...prevState,
                        SignUpComplete: true,
                        SignUpMessageHeading: "SignUp Complete",
                        SignUpMessage: "Our team will contact you shortly with system configuration and access details"
                    }))
                }
            });

        } catch (err) {
            console.log('BatchWrite err', err);
        }
    }

    const EmailNotification = (PersonFormdataJSON, postHTML, postTEXT, errormessage) => {

        let title = "";
        let PersonList = [{
            field1: "Developer",
            email: "support@sninc.us",
            key: "Developer::Account::0786",
            pk1: "",
            sk1: "",
            mobile: "",
            Avatar: "",
        }];

        //if error - send email to support
        if (errormessage) {
            title = `Error while signing up ${PersonFormdataJSON.CompanyName}`
        } else {
            title = `Sign Up Complete - ${PersonFormdataJSON.CompanyName}`
            PersonList = [PersonFormdataJSON.email, "ali.rehan.jci@gmail.com", "support@sninc.us"]

            //PersonList = [
            //    {
            //        field1: PersonFormdataJSON.Name,
            //        email: PersonFormdataJSON.email,
            //        key: PersonFormdataJSON.key,
            //        pk1: PersonFormdataJSON.pk1,
            //        sk1: PersonFormdataJSON.sk1,
            //        mobile: PersonFormdataJSON.mobile,
            //        Avatar: "",
            //    },
            //    {
            //        field1: "Developer",
            //        email: "Support@NotificationBridge.com",
            //        key: "Developer::Account::0786",
            //        pk1: "",
            //        sk1: "",
            //        mobile: "",
            //        Avatar: "",
            //    }
            //]

        }

        let ContractorSubDomain = "https://www.service-opportunity.com/";

        let SendEvent = {

            //PhotoService: state.PhotoService,
            //Documents: state.Documents,
            SpecialInstructions: "",
            PreSelect: [],
            title: title,
            postHTML: postHTML,
            postTEXT: postTEXT,
            PersonList: PersonList,
            ReplyEmail: "support@sninc.us",
            PostId: UUid,

            ContractorAddress: "30 N Gould St, Suite 37310, Sheridan, WY 82801",
            ContractorPhone: "562-760-1914",
            ContractorLogo: "",
            ContractorName: "Service opportunity",
            avatar: "http://www.service-opportunity.com/images/ServiceOppotunity_Black.png",

            UnsubscribeLink: `${ContractorSubDomain}/Unsubscribe`,
            ActionButton: `${ContractorSubDomain}`,
            ActionButtonTitle: "Contact Us",
            ActionButtonShow: false
        }

        console.log("SubscriptionAdd1.SendEmail", SendEvent)

        SendEmail(SendEvent);

        //var emailstring = {

        //    title: title,
        //    PersonList: PersonList,
        //    Avatar: "",
        //    //BackgroundColor: "#eeeeee",
        //    //ButtonColor: "#44872e",
        //    //HeadingColor: "#44872e",
        //    //ButtonTextColor: "#ffffff",
        //    ContractorName: "Notification Bridge",
        //    CompanyName: PersonFormdataJSON.CompanyName,
        //    ActionButton: `https://bomrng.com/`,
        //    ContractorLogo: "",
        //    ActionButtonTitle: "Online Access",
        //    headerBanner: "https://images.pexels.com/photos/4050299/pexels-photo-4050299.jpeg?cs=srgb&dl=woman-sitting-on-the-floor-using-a-laptop-4050299.jpg&fm=jpg",
        //    PhotoService: [],
        //    Documents: [],
        //    SpecialInstructions: "",
        //    PreSelectList: [],
        //    UnsubscribeLink: "https://bomrng.com/UnsubscribeLink",
        //    // email: "ali.s.rehan@jci.com", //each person email
        //    // title: event.title,
        //    //  Message: NewEventAdd.Body,
        //    postHTML: postHTML,
        //    postTEXT: postTEXT,
        //    EmailTemplate: {
        //        BackgroundColor: "#eeeeee",
        //        ButtonColor: "#44872e",
        //        HeadingColor: "#44872e",
        //        ButtonTextColor: "#ffffff",
        //        headerBanner: "https://images.pexels.com/photos/4050299/pexels-photo-4050299.jpeg?cs=srgb&dl=woman-sitting-on-the-floor-using-a-laptop-4050299.jpg&fm=jpg",
        //        ContractorLogo: "https://gostreamtv.com/wp-content/uploads/channel-logos/logo_tv.png",
        //        ContractorName: "Notification Bridge",
        //    },
        //    PostId: UUid,
        //    ContractorId: "Developer::Account::0786",
        //    CompanyId: PersonFormdataJSON.CompanyId,
        //    ReplyEmail: "admin@NotificationBridge.com"

        //};

        //console.log("ItemAddPage.Invite.email", emailstring)

        //if (emailstring) {
        //    API.post("TriggerEmail", "/post", {
        //        body: { Records: emailstring }
        //    });
        //}
    }

    const SelectedDataReturn = (data) => {

        let StepValueArray = state.StepValueArray;
        let Indexx = state.StepValueArray.findIndex(x => x.KeyId === data.KeyId);

        if (Indexx === -1) {
            Indexx = data.id;
        }

        StepValueArray[Indexx] = data;
        console.log("state.StepValueArray.after", StepValueArray);

        setState(prevState => ({
            ...prevState,
            StepValueArray: StepValueArray
        }))
    }


    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    console.log("ContractorSignup.state", state)

    return state.SignUpComplete ? (
        <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
        >
            <Typography variant="h1" gutterBottom component="div">
                {state.SignUpMessageHeading}
            </Typography>
            <Typography variant="h4" gutterBottom component="div">
                {state.SignUpMessage}
            </Typography>
            <Typography variant="h4" gutterBottom component="div">
                Thank you,
            </Typography>
            <Typography variant="h3" gutterBottom component="div">
                NotificationBridge.com
            </Typography>
        </Stack>
    ) : (
        <div id={props.key} key={"key" + props.key}>
                <Paper
                    //className={classes.paper}
                >

                <Grid container spacing={2}>
                        {/* 
                    <Grid item xs={12}>

                        <Typography variant="h3" gutterBottom component="div">
                            New SignUp
                        </Typography>

                    </Grid>
                    */}

                    <Grid item xs={12}>


                        <Stepper activeStep={activeStep} orientation="vertical">

                            {Blocks.map((block, index) => {

                                return (

                                    <Step key={index}>
                                        <StepLabel>{block.title}</StepLabel>
                                        <StepContent>

                                            <Grid container spacing={2}>

                                                <Grid item xs={12}>
                                                    <Typography >{block.message}</Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <SignupWizEachStep
                                                        //  LoadComponenet={block.LoadComponenet}
                                                        StepValueArray={state.StepValueArray}
                                                        //    SaveDataBack={state.SaveDataBack[index]}
                                                        Category={block.Category}
                                                        title={block.title}
                                                        id={index}
                                                        activeStep={activeStep}
                                                        handleBack={() => handleBack()}
                                                        handleNext={() => handleNext(index)}
                                                        SelectedDataReturn={(data) => SelectedDataReturn(data)}
                                                        KeyId={block.id}
                                                        key={index + block.id}

                                                    />
                                                </Grid>

                                            </Grid>
                                        </StepContent>
                                    </Step>

                                )
                            })}

                            <Step key={100}>
                                <StepLabel>Save Details</StepLabel>
                                <StepContent>

                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Typography >
                                                Our team will contact you once the base system setup is complete.
                                                At this time, we will also setup online demo and walk you through system
                                                custimization to meet your business needs
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                                <div
                                                   // className={classes.actionsContainer}
                                                >
                                                <div>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                      //  className={classes.button}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => SaveData()}
                                                       // className={classes.button}
                                                    >
                                                        Complete Signup
                                                    </Button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>

                        </Stepper>
                    </Grid>

                </Grid>

                    <Backdrop
                      //  className={classes.backdrop}
                        open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                    <Snackbar
                        open={state.OpenAlert}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        message={state.AlarmMessage}
                    />
                    {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

                <DrawerShow
                    LoadComponenet={state.DetailComponent}
                    CloseDrawer={() => ClosingDrawer()}
                    DrawerTitle="change this"
                    //LoadComponenet="ItemAddPage"
                    propss={{
                        CloseDrawer: (e) => ClosingDrawer(e),
                        ...state.DetailsProps
                    }}
                    DrawerStatus={DeawerOpen}
                    // CloseDrawer={(e) => SetDeawerOpen(false)}
                    OpenDrawer={(e) => SetDeawerOpen(false)}
                    anchor="left"
                />
            </Paper>
        </div>
    );
}
