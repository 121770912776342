import React, { useEffect, useState } from 'react';
import {
    TextField,
    //Collapse,
    Typography,
    //Paper,
    Grid,
    CardHeader, Card, Button, CardContent,
    //Backdrop,
    //CircularProgress,
    //Card,
    //FormGroup,
    //CardContent,
    //CardActions,
    Snackbar,
    //CardHeader,
    FormGroup, FormControlLabel, Checkbox,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import { NewPageId, removeEmptyStringElements } from '../utils/CommonGraphql';
import { API } from "aws-amplify";
import ListModule from '../List/ListModule';
import DrawerShow from '../utils/DrawerModule';
import { SendEmail } from '../utils/SendEmail';

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

var GetDBData = (params) => {
    try {
        return new Promise((resolve, reject) => {
            dynamodb.query(params, function (err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else resolve(data); // successful response
            });
        });
    } catch (err) {
        console.log('GetDBData err', err);
    }
};

//var testMail = 'testmail@fakemail.com';
const ValidateEmail = testMail => {

    var check_email = '[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](gmail.com|msn.com|hotmail.com|yahoo.com)';
    var patt = new RegExp(check_email);
    var result = patt.test(testMail);

    console.log("AccountSetup.ValidateEmail", result)
    return result;
}

export default function RadioButtonsGroup(props) {
    //  const ContractorData = useSelector(state => state.ContractorData);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const initialState = {
        AssetList: [],
        AlarmMessage: "",
        OpenAlert: false,
        Name: "",
        Email: "",
        CompanyName: "",
        Zip: "",
        NoCompanyEmail: false,
        NeedCompanyEmailAvailable: false,
        Acknowledge: false
    }
    const [state, setState] = useState(initialState);
    //  const [DrawerStatus, OpenDrawer] = useState(false);


    useEffect(() => {

        if (props.SREvent && props.SREvent.ContractorData) {
            GetPolicy(props.SREvent.ContractorData.CompanyId);
        }

    }, [props.SREvent])

    const GetPolicy = async (CompanyId) => {

        let DataBack = [];
        let Acknowledge = state.Acknowledge;

        let getItemParams = {
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1) ",
            ScanIndexForward: false,
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1"
            },
            ExpressionAttributeValues: {
                ":pk1": CompanyId,
                ":sk1": "Data::Policy::"
            }
        };

        var ContractorFound = await GetDBData(getItemParams);

        if (ContractorFound.Count > 0) {
            ContractorFound.map(each => {
                DataBack.push(each.dataJSON)
            })
        } else {
            Acknowledge = true
        }

        setState(prevState => ({
            ...prevState,
            DataBack: DataBack,
            Acknowledge: Acknowledge
        }));

    }

    const TriggerEmail = () => {

        let PhotoService = [];
        let Documents = [];

        if (state.DataBack && state.DataBack.length > 0) {

            state.DataBack.map(each => {
                PhotoService = [...PhotoService, ...each.PhotoService]
                Documents = [...Documents, ...each.Documents]
            })


            var emailstring = {

                PhotoService: PhotoService,
                Documents: Documents,
                SpecialInstructions: "",
                PreSelect: [],
                title: `Policy Documents`,
                postHTML: "<h3>Please review following Policy Documents</h3>",
                postTEXT: "Please review following Policy Documents",
                PersonList: [state.Email],
                ReplyEmail: "no-reply@service-call-cloud.com", //UserData.email,
                PostId: state.NewId,

                ContractorAddress: "",
                ContractorPhone: "",
                ContractorLogo: props.SREvent.ContractorData.avatar,
                ContractorName: props.SREvent.ContractorData.CompanyName,
                ContractorId: props.SREvent.ContractorData.CompanyId,
                CompanyId: props.SREvent.ContractorData.CompanyId,
                avatar: "",

                UnsubscribeLink: `https://app.contractorcheckin.com/Unsubscribe`,
                ActionButton: `https://app.contractorcheckin.com`,
                ActionButtonTitle: "Online Access",
                ActionButtonShow: false,
            };

            console.log("ItemAddPage.Invite1.email", emailstring)
            SendEmail(emailstring);
        }
    }

    const CreatePerson = () => {
        try {

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let mobile = props.SREvent.RequestorSMS;
            let OldConversation = props.SREvent;
            let ContractorData = props.SREvent.ContractorData;
            let Emailaddress = state.Email;
            let ParseEmail = Emailaddress.split("@");
            // let emailName = ParseEmail[0];
            let Domain = ParseEmail[1];
            //let CCName = ParseEmail[1].split(".");
            //CCName.pop();

            let Acknowledge = "Denied";
            if (state.Acknowledge) {
                Acknowledge = "Accepted";
            }

            let dateToday = new Date();
            let currentTime = new Date(dateToday).getTime();
            let CompanyId = `Data::Sub::Info::${Domain}::${state.Zip}`;

            let ContractorId = ContractorData.ContractorId;
            let ContractorName = ContractorData.CompanyName;

            // let ContName = CCName.replace(/[_.-]/g, ' ');
            let CompanyName = state.CompanyName;  //ContName.titleCase();

            // let PersonName = emailName.replace(/[_.-]/g, ' ');
            let field1 = state.Name;  // PersonName.titleCase();

            let email = Emailaddress;
            let key = `Data::Company::Resource::Person::${email}`;
            let phone = mobile;
            // let CompanyName = ContractorName;
            let field2 = email;
            let TimeZone = ContractorData.TimeZone;
            // let CompanyId = Contractorkey;
            let SearchWords = `${field1} ${email} ${CompanyName}`;
            let PostId = NewPageId("Person");
            let Privileges = {
                "label": "Field Resource",
                "value": 100
            };
            let posterId = {
                field1: field1,
                field2: field2,
                CompanyId: CompanyId,
                CompanyName: CompanyName,
                avatar: "",
                key: key,
            }

            let AllItemForms = [
                {
                    "sk1": key,
                    "Category": "Person",
                    "Documents": [
                    ],
                    "field1": "Person Infomation",
                    "FormData": [
                        {
                            "Category": "Person",
                            "defaultValue": field1,
                            "Editable": false,
                            "extra": "field1",
                            "key": "ff_p6ZcrMay10",
                            "label": "Name",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": field1,
                            "TableColumn": true,
                            "type": "textline",
                            "VariableInput": field1,
                            "VariableName": "name"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": email,
                            "Editable": false,
                            "extra": "field2",
                            "key": "ff_p6ZcrMay11",
                            "label": "Email",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": email,
                            "TableColumn": true,
                            "type": "email",
                            "VariableInput": email,
                            "VariableName": "email"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": TimeZone.value,
                            "Details": TimeZone,
                            "Editable": false,
                            "key": "ff_p6ZcrMay12",
                            "label": "Time Zone",
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "ShowValue": TimeZone.value,
                            "TableColumn": true,
                            "type": "TimeZone",
                            "VariableInput": TimeZone.value,
                            "VariableName": "TimeZone"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": mobile,
                            "Editable": true,
                            "key": "ff_p6ZcrMay13",
                            "label": "Mobile/SMS",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": true,
                            "ShowValue": mobile,
                            "TableColumn": false,
                            "type": "phone",
                            "VariableInput": mobile,
                            "VariableName": "mobile"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": phone,
                            "Editable": true,
                            "key": "ff_p6ZcrMay14",
                            "label": "Work Phone",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": true,
                            "ShowValue": phone,
                            "TableColumn": false,
                            "type": "phone",
                            "VariableInput": phone,
                            "VariableName": "phone"
                        },
                        {
                            "Category": "Person",
                            "defaultValue": Acknowledge,
                            "Editable": true,
                            "key": "ff_p6ZcrMay15",
                            "label": "Acknowledge Policy",
                            "ListInfo": true,
                            "options": [
                            ],
                            "Order": 3,
                            "required": true,
                            "ShowValue": Acknowledge,
                            "TableColumn": false,
                            "type": "checkbox",
                            "VariableInput": Acknowledge,
                            "VariableName": "Policy"
                        }
                    ],
                    "FormName": "Person Infomation",
                    "FormType": "Person",
                    "key": key,
                    "PhotoService": [
                    ],
                    "SearchWords": "x",
                    "ValueAdded": true
                },

            ];

            //  console.log("AllItemForms 421", JSON.stringify(AllItemForms, null, 2));

            let FormdataJSON = {
                AllItemForms: AllItemForms,
                BranchId: ContractorId,
                ContractorDataId: ContractorId,
                BranchName: ContractorName,
                HQId: "DeveloperId",
                HQName: "DeveloperId",
                status: "Waiting",
                SearchWords: SearchWords,
                AllowSMSService: true,
                Category: "Person",
                CustomerType: "Company",
                TimeZone: TimeZone,
                CompanyId: CompanyId,
                CompanyName: CompanyName,
                avatar: "",
                id: PostId,
                key: key,
                Privileges: Privileges,
                Level: 100,
                field1: field1,
                field2: field2,
                ContractorId: ContractorId,
                date: currentTime,
                posterId: posterId,
                PhotoService: [],
                Documents: [],
                phone: phone,
                mobile: mobile,
                email: email
            };

            let PersonInfo = {

                BranchId: ContractorId,
                BranchName: ContractorName,
                HQId: "DeveloperId",
                HQName: "ContractorCheckIn.com",
                Category: "Person",
                CustomerType: "Company",
                TimeZone: TimeZone,
                CompanyId: CompanyId,
                CompanyName: CompanyName,
                avatar: "",
                id: PostId,
                key: key,
                Privileges: Privileges,
                Level: 100,
                field1: field1,
                field2: field2,
                ContractorId: ContractorId,
                date: currentTime,
                posterId: posterId,
                PhotoService: [],
                Documents: [],
                phone: phone,
                mobile: mobile,
                email: email
            };

            console.log("FormdataJSON 455", JSON.stringify(FormdataJSON, null, 2));

            OldConversation.posterId = PersonInfo;
            OldConversation.WaitForEmail = false;
            CheckCompanyExist(FormdataJSON, OldConversation);
            TriggerEmail();

            //let SelectedDataReturn = {
            //    ...state,
            //    id: props.id,
            //    KeyId: props.KeyId
            //}

            //console.log("AccountSetup.key", SelectedDataReturn)

            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(OldConversation)
            }

            if (props.handleNext) {
                props.handleNext();
            }

            let DBInput = {
                pk1: CompanyId,
                sk1: key,
                gsi1Pk2: `phone::${mobile}`,
                gsi1Sk2: ContractorId,
                gsi2Pk2: key,   //"Developer::Account::0786",
                gsi2Sk2: ContractorId, //`email::${Emailaddress}`,
                dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
            };

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let PosttoDB = {
                MutationType: "createSvcbotDb",
                DBData: DBInput
            };

            // let Messagereceived = `Account created, please refresh the Person list on the app`;
            // MessageBackSMS(TwilioSMSData.To, TwilioSMSData.From, Messagereceived);

            // Post to DB
            TriggerLambda(PosttoDB);


        } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }


    }

    const CheckCompanyExist = async (data, OldConversation) => {

        let getItemParams = {
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1) ",
            ScanIndexForward: false,
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1"
            },
            ExpressionAttributeValues: {
                ":pk1": "Developer::Account::0786",
                ":sk1": data.CompanyId
            }
        };

        var ContractorFound = await GetDBData(getItemParams);

        if (ContractorFound.Count > 0) {
            //create under owner
            CreateUnderOwner(data, ContractorFound)
        } else {
            //create company
            CreateCompany(data, OldConversation)
        }
    }

    const CreateUnderOwner = (data, CompanyData) => {
        try {

            let dataJSON = CompanyData.dataJSON;

            let DBInput = {
                pk1: data.ContractorId,
                sk1: data.CompanyId,
                //gsi1Pk2: key,
                //gsi1Sk2: BranchId,
                gsi2Pk2: data.CompanyId,
                gsi2Sk2: data.ContractorId,
                dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
            };

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let PosttoDB = {
                MutationType: "createSvcbotDb",
                DBData: DBInput
            };

            // Post to DB
            TriggerLambda(PosttoDB);

        } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }

    }

    const CreateCompany = (data, OldConversation) => {
        try {

            let SearchWords = data.CompanyName;
            let PostId = NewPageId("Company");

            let AllItemForms = [
                {
                    "sk1": data.CompanyId,
                    "Category": "Company",
                    "Documents": [
                    ],
                    "FormData": [
                        {
                            "Category": "Company",
                            "defaultValue": data.CompanyName,
                            "Editable": false,
                            "extra": "field1",
                            "key": 100,
                            "label": "Company Name",
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "TableColumn": true,
                            "type": "textline",
                            "VariableInput": data.CompanyName,
                            "VariableName": "CompanyName"
                        },
                        {
                            "Category": "Company",
                            "defaultValue": "",
                            "Editable": true,
                            "extra": "field2",
                            "key": 101,
                            "label": "Business Type",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "textline",
                            "VariableInput": "",
                            "VariableName": "BusinessType"
                        }
                    ],
                    "FormName": "Company Infomation",
                    "FormType": "Company",
                    "key": data.CompanyId,
                    "PhotoService": [
                    ],
                    "ValueAdded": true
                }
            ];

            //  console.log("AllItemForms 421", JSON.stringify(AllItemForms, null, 2));

            let FormdataJSON = {
                ...data,
                AllItemForms: AllItemForms,
                status: "Active",
                SearchWords: SearchWords,
                AllowSMSService: true,
                Category: "Company",
                CustomerType: "Company",
                id: PostId,
                key: data.CompanyId,
                field1: data.CompanyName,
                field2: ""
            };

            console.log("FormdataJSON 455", JSON.stringify(FormdataJSON, null, 2));

            let DBInput = {
                pk1: "Developer::Account::0786",
                sk1: data.CompanyId,
                //gsi1Pk2: key,
                //gsi1Sk2: BranchId,
                gsi2Pk2: data.CompanyId,
                gsi2Sk2: data.ContractorId,
                dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
            };

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let PosttoDB = {
                MutationType: "createSvcbotDb",
                DBData: DBInput
            };

            // let Messagereceived = `Account created, please refresh the Person list on the app`;
            // MessageBackSMS(TwilioSMSData.To, TwilioSMSData.From, Messagereceived);

            // Post to DB
            TriggerLambda(PosttoDB);

            CreateUnderOwner(data, { dataJSON: FormdataJSON })


        } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }


    }

    const TriggerLambda = (event) => {
        console.log("NewServiceCall.TriggerLambda", event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }

    const SetValues = (data, name) => {

        if (name === "Email" && data !== "") {
            data = data.toLowerCase();
        }

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

    }

    const handleNext = () => {

        const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        let AlarmMessage = "";
        let OpenAlert = false;
        let NeedCompanyEmailAvailable = false;
        
        if (state.Name === "") {
            OpenAlert = true;
            AlarmMessage += " * Please enter your Name ";
        }

        if (!state.Acknowledge) {
            OpenAlert = true;
            AlarmMessage += " * Please Acknowledge Policy Documents ";
        }

        if (state.CompanyName === "") {
            OpenAlert = true;
            AlarmMessage += " * Please enter your Company Name ";
        }

        if (state.Email === "" && !emailRegexp.test(state.Email)) {
            OpenAlert = true;
            AlarmMessage += " * Please enter Company Email ";
        }

        if (ValidateEmail(state.Email)) {
            if (!state.NoCompanyEmail) {
                OpenAlert = true;
                AlarmMessage += " * Gmail, MSN, HotMail, Yahoo not preferred. Please use company email, or mark the check box ";
                NeedCompanyEmailAvailable = true;
            }
        }

        if (state.Zip === "") {
            OpenAlert = true;
            AlarmMessage += " * Please enter your Local Company office Zip/Postal code ";
        }

        if (OpenAlert) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: OpenAlert,
                NeedCompanyEmailAvailable: NeedCompanyEmailAvailable
            }));

        } else {

            CreatePerson();

        }

    }

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Document Details",
            // SetupSubscription: true,
            DetailComponent: "ShowDetailsText",
            ItemData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        OpenDrawer(true);
        //}
    }

    const CloseDrawer = (c) => {
        //    GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }


    console.log("AccountSetup.state", state)
    console.log("AccountSetup.props", props)

    return (
        <>
            <Grid container spacing={1}>

                <Grid item xs={12} >
                    <TextField
                        label="Name"
                        name="Name"
                        value={state.Name || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Name")}
                    />

                </Grid>
                <Grid item xs={12} >
                    <TextField
                        label="Email"
                        name="Email"
                        value={state.Email || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Email")}
                    />

                </Grid>

                {state.NeedCompanyEmailAvailable ? (
                    <Grid item xs={12} >
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox onChange={(e) => SetValues(e.target.checked, "NoCompanyEmail")} />}
                                label="Company Email not availabe" />
                        </FormGroup>

                    </Grid>
                ) : null}

                <Grid item xs={12} >
                    <TextField
                        label="Company Name"
                        name="CompanyName"
                        value={state.CompanyName || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "CompanyName")}
                    />

                </Grid>
                <Grid item xs={12} >
                    <TextField
                        label="Your Office Postal/Zip Code"
                        name="Zip"
                        value={state.Zip || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Zip")}
                    />

                </Grid>

                {state.DataBack && state.DataBack.length > 0 && state.Email !== "" ? (
                    <Grid item xs={12} >

                        <Card>
                            <CardHeader
                                title="Policy Document List"
                                subheader="Please acknowledge"

                            />
                            <CardContent>
                                <Grid container spacing={1}>

                                    {state.DataBack && (
                                        <>

                                            <Grid item xs={12} >
                                                <Typography variant="body1" gutterBottom>
                                                    {`An email is being sent to ${state.Email} with link to all the Policy documents, please click on Acknowledge check box`}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} >
                                                <ListModule
                                                    title="Policy Documents"
                                                    //  ShowDetailLink={false}
                                                    AllowDelete={true}
                                                    ShowDetailLink={true}
                                                    DetailLink={(e) => AddBox(e)}
                                                    disabled={false}
                                                    DataBack={state.DataBack}
                                                />
                                            </Grid>

                                            <Grid item xs={12} >
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            value={state.Acknowledge}
                                                            onChange={(e) => SetValues(e.target.checked, "Acknowledge")} />} label="Acknowledge" />
                                                </FormGroup>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>
                ) : null}

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <DrawerShow
                LoadComponenet="ShowDetailsText"
                CloseDrawer={(e) => CloseDrawer(e)}
                Category="Policy"
                DrawerTitle="Policy Document"
                propss={{
                    Category: "Policy",
                    CompanyId: state.CompanyId,
                    CompanyName: state.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e),
                    //  TriggerUpdate: true,
                    //  SetupSubscription: state.SetupSubscription,
                    Data: state.ItemData,
                    // SubData: state.SubData,
                    id: state.DrawerKey,
                    // AddItem: () => AddItem("ItemAddPage", "Person")
                }}
                key={state.DrawerKey}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </>

    );
}
