import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Typography,
    IconButton, Avatar

} from '@mui/material';
//import DynamicForms from '../Forms/FormShow';
import { NewPageId, FindCategoryIcon } from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import MaterialGallery from '../Gallery/MaterialGallery';
import DocumentList from '../List/DocumentList';
import { useDispatch, useSelector } from 'react-redux';

const HtmlToReactParser = require('html-to-react').Parser;

const HTMLtoReact = (htmlInput) => {
    var htmlToReactParser = new HtmlToReactParser();
    var reactElement = htmlToReactParser.parse(htmlInput);
    return reactElement;
}


let NexText = [];

const ProfileDetails = ({ className, ...rest }) => {

    const dispatch = useDispatch();
    const UserData = useSelector(state => state.UserData)

    let initialState = {
        FormJSON: [],
        FormId: "kjsadjhsdaf",
        FormValueArray: [],
        FromArry: [],
        FormJSONBack: [],
        UpdateFormJSON: [],
        ImagePic: "",
        Category: "",
        ShowEditButton: false
    };

    const [state, setState] = useState(initialState);
    const [DrawerStatus, OpenDrawer] = useState(false);

    useEffect(() => {
        console.log("ShowDetails.props", rest);
        let FormId = NewPageId("ItemShowDetails");
        let ShowEditButton = false;
        let NewData = {};
        if (rest.Data) {
            NewData = rest.Data;
          //  NewData.key = FormId;
            SeperateData(NewData);
        }       

        //if (rest.ShowEditButton || UserData.Level > 200) {
        //    ShowEditButton = true;
        //}

        if (rest.ShowEditButton) {
            ShowEditButton = true;
        }

        setState(prevState => ({
            ...prevState,
            FormId: FormId,
            ShowEditButton: ShowEditButton
        }));

    }, [rest.Data])

    const SeperateData = data => {
        console.log("ShowDetails.data", data);
        try {

            let pk = "";
            let sk = "";
            let Category = "";
            let NewFromArry = [];
            let FormExtra = [];
            let FormJSON = [];
            let PhotoService = [];
            let Documents = [];
            let avatar = "";
            let AllItemForms = [];

            if (data && data.avatar && data.avatar !== "") {
                avatar = data.avatar;
            }

            if (data && data.AllItemForms) {

                AllItemForms = data.AllItemForms;
                dispatch({ type: 'CURRENT_EDIT', payload: data });

                console.log("ShowDetails.data.AllItemForms", data.AllItemForms);
                //if (data.AllItemForms) {
                //    console.log("ShowDetails.NewFromArry", NewFromArry);
                //    data.AllItemForms.map((eachForm, ind) => {
                //        if (eachForm && eachForm !== null) {

                //            //find if second layer
                //            if (eachForm.AllItemForms) {
                //                console.log("ShowDetails.data.SeperateData", eachForm);
                //                SeperateData(eachForm)

                //            } else {
                                
                //                if (eachForm.FormType === "AutoComplate") {

                //                    FormExtra.push(eachForm)

                //                } else {

                //                    if (eachForm.FormData) {

                //                        console.log("ShowDetails.data.eachForm.FormData.1", eachForm.FormData);

                //                        FormJSON.push(eachForm)
                //                        NewFromArry.push({
                //                            pk1: data.pk1,
                //                            sk1: data.sk1,
                //                            Category: data.Category,
                //                            FormData: eachForm.FormData
                //                        })

                //                    } else {
                //                        console.log("ShowDetails.data.eachForm.FormData.2", eachForm.FormData);
                //                        NewFromArry.push({
                //                            pk1: eachForm.pk1,
                //                            sk1: eachForm.sk1,
                //                            Category: eachForm.Category,
                //                            FormData: eachForm
                //                        })

                //                    }
                //                }
                //            }
                //        }
                //    })
                //}
            }

            console.log("ShowDetails.data.FormJSON", FormJSON);
            console.log("ShowDetails.data.NewFromArry", NewFromArry);

            //if (data && data.FormExtra && data.FormExtra.length > 0) {
            //    data.FormExtra.map(each => {
            //        if (each && each !== null) {
            //            FormExtra.push(each)
            //        }
            //    })
            //}

            if (data && data.PhotoService && data.PhotoService.length > 0) {
                PhotoService = data.PhotoService;
            }

            if (data && data.Documents && data.Documents.length > 0) {
                Documents = data.Documents;
            }

            

            let NewDDD = {
                AllItemForms: AllItemForms,
              //  UpdatedataJSON: data,
              //  UpdateFormJSON: AllItemForms,
                FormExtra: FormExtra,
                Category: data.Category,
                field1: data.field1,
                ImagePic: PhotoService,
                PhotoService: PhotoService,
                Documents: Documents,
                avatar: avatar,
                pk1: data.pk1,
                sk1: data.sk1,
                CompanyId: data.CompanyId,
                CompanyName: data.CompanyName
            }

            console.log("ShowDetails.edit.NewDDD", NewDDD);

            setState(prevState => ({
                ...prevState,
                ...NewDDD
            }));

        } catch (err) { }
    }

    const EditDetails = () => {
        
        OpenDrawer(true);
    }


    const CloseDrawer = (c) => {
        // GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

    const EachLine = (eachline, index) => {
       
        let VariableInput = "";

        if (eachline.ShowValue) {
            VariableInput = eachline.ShowValue;

            let checkhtml = /<\/?[a-z][\s\S]*>/i.test(eachline.ShowValue);

            if (checkhtml) {
                console.log("ShowDetails.eachline", checkhtml, eachline);
                VariableInput = HTMLtoReact(eachline.ShowValue);
            }

        } else {

            if (typeof eachline.VariableInput === 'string') {
                VariableInput = eachline.VariableInput;
            } else {
                if (eachline.VariableInput && eachline.VariableInput.label) {
                    VariableInput = eachline.VariableInput.label;
                } else if (eachline.VariableInput && eachline.VariableInput.value) {
                    VariableInput = eachline.VariableInput.value;
                }
            }
        }

        if (VariableInput !== "") {
            return (
                <>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        key={index + 1}
                        sx={{ mb: 1 }}
                    >
                        <Typography variant="h5">
                            {eachline.label}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        key={index + 2}
                        sx={{ mb: 1 }}
                    >
                        <Typography>
                            {VariableInput}
                        </Typography>
                    </Grid>
                </>
            )
        }

    }

    console.log("Testing.Form.Edit.1.Showdetails.state", state);
    console.log("Testing.Form.Edit.Showdetails.props", rest);

    return (

        <div key={state.FormId}>

            <Grid container spacing={2}>

               
                <Grid item xs={12}>
                    <Card key={"crd1" + state.FormId}>
                        <CardHeader
                            title={rest.Activefield1}
                            subheader="Information"
                            action={state.ShowEditButton && (
                                <IconButton aria-label="settings"
                                    onClick={() => EditDetails()}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                                )
                            }
                            avatar={state.avatar !== "" ? (
                                        <Avatar aria-label="recipe" src={state.avatar} />
                                    ) : (
                                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                            {FindCategoryIcon(state.Category)}
                                        </Avatar>
                                    )
                                }
                            
                        />

                        <CardContent>
                            <Grid container spacing={0} >

                                {rest.Data && rest.Data.AllItemForms
                                    && rest.Data.AllItemForms.length > 0
                                    && rest.Data.AllItemForms.map((each, index) => (
                                        each.FormType === "AutoComplate" ? (

                                            <Grid
                                                item
                                                xs={12}
                                                key={index + "Showdd4"}
                                            >

                                                <Card
                                                    key={index + "carddssww"}
                                                >
                                                    <CardHeader
                                                        //subheader="The information can be edited"
                                                        title={each.Category}
                                                    />
                                                    <Divider />
                                                    <CardContent>
                                                        {/*
                                                            NexText.length > 0 && NexText.map((eachline, line) => (
                                                            <Grid item xs={12} key={line + each.pk1}>
                                                                <Typography>{eachline}</Typography>
                                                            </Grid>
                                                            ))
                                                        */}

                                                        <Typography>{each.SearchWords}</Typography>

                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                        ) : (

                                                each.FormData.map((eachline, line) => (
                                                    EachLine(eachline, line + index + eachline.VariableName)
                                                ))
                                                
                                                )
                                         ))}

                                {/*
                                    state.FormExtra && state.FormExtra.length > 0
                                    && state.FormExtra.map((each, index) => {
                                        console.log("ShowDetails.FormExtra.each", each);
                                        if (each && each !== null && each.SearchWords) {
                                            NexText = each.SearchWords.split(",");
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={index + "Showdd4"}
                                                >

                                                    <Card
                                                        key={index + "carddssww"}
                                                    >
                                                        <CardHeader
                                                            //subheader="The information can be edited"
                                                            title={each.Category}
                                                        />
                                                        <Divider />
                                                        <CardContent>
                                                            {NexText.length > 0 && NexText.map((eachline, line) => (
                                                                <Grid item xs={12} key={line + each.pk1}>
                                                                    <Typography>{eachline}</Typography>
                                                                </Grid>
                                                            ))}

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )
                                            NexText = [];
                                        }
                                    }
                                    )
                                */}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {state.PhotoService && state.PhotoService.length > 0 && (
                    <Grid xs={12} item>
                        <Card key={"creedsdfff1"}>
                            <CardHeader
                                title="Images / Video"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Image")}
                                    </Avatar>
                                }
                            />
                            <CardContent>
                                <MaterialGallery
                                    PhotoService={state.PhotoService}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {state.Documents && state.Documents.length > 0 && (
                    <Grid xs={12} item>
                        <DocumentList
                            DocumentList={state.Documents}
                        />
                    </Grid>
                )}

            </Grid>
            {/*
            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => EditDetails()}
                >
                    Edit
                </Button>
            </Box>
            */}

            <DrawerShow
                LoadComponenet="ItemAddPage"
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                   // UpdateFormJSON: state.UpdateFormJSON,
                    Category: state.Category,
                    //UpdateFormExtra: state.FormExtra,
                    UpdateExisting: true,
                    CompanyId: state.CompanyId,
                   // UpdatePk1: state.pk1,
                  //  UpdateKey: state.sk1,
                    CompanyName: state.CompanyName,
                    ImageUpload: true,
                    ImagePic: state.ImagePic,
                    UpdatedataJSON: rest.Data,
                    id: state.sk1,
                    avatar: state.avatar,
                    //Category: state.Category,
                    //key: state.key,
                    //CompanyId: state.CompanyId,
                    //CompanyName: state.CompanyName,
                    //CustomerType: state.CustomerType,
                    //...state.DetailsProps
                    //ESCId: props.ESCId
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>

    );
};

ProfileDetails.propTypes = {
    className: PropTypes.string
};

export default ProfileDetails;

