import React, { useState, useEffect } from 'react';
import { Box, Container, Backdrop, CircularProgress, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import ToolbarHeading from '../components/MapHeading';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, MiddlePostSearch, ContreactorPostSearch } from '../utils/CommonGraphql';
import SRList from '../List/SRColumn';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime';
import SRThreeColumns from '../List/SRThreeColumns';
import { API, graphqlOperation } from "aws-amplify";
import GoogleMapShow from '../views/modules/GoogleMap';
import Skeleton from '../components/Skeleton';
import { onCreateSvcbotDbgsi1, onCreateSvcbotDbgsi2, onCreateSvcbotDb } from '../graphql/subscriptions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    productCard: {
        height: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));


//let Todaydate = new Date();
let olddate = adjustedTime(-30, "Start");
let Todaydatestring = adjustedTime(1, "End"); //Todaydate.toISOString();

const ListItems = (props) => {

    let query = useQuery();
    console.log("query", query);

    
    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData);
    const dispatch = useDispatch();
    const NowEditing = useSelector(state => state.NowEditing)
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)
    const ContractorData = useSelector(state => state.ContractorData);
    const cid = useSelector(state => state.cid);
    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 12,
        //ListType: "ListModule",
        ListType: "ListModule",
        CompanyId: ContractorData.CompanyId,
        pk1: ContractorData.CompanyId,
        propss: {},
        key: NewPageId("SRList"),
        StartDate: olddate,
        EndDate: Todaydatestring,
        Sort: "Progress",
        Data: [],
        FullData: []
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('MapPage.params', params);
        console.log('CompanyPage.TabList.MapPage.props', props);

        setState(prevState => ({
            ...prevState,
            Category: "Location",
            CompanyId: ContractorData.CompanyId
        }));

    
        //get data
       // console.log("MapPage.DBinput1", CompanyId, olddate, Todaydatestring);

        GetDatafromDB(ContractorData.CompanyId, olddate, Todaydatestring, 152);

    }, [])

    useEffect(() => {

        if (state.CompanyId) { 
            
            let subscriptionPost = `Post::Location::${state.CompanyId}`;

            const subscribe1 = API.graphql({
                    query: onCreateSvcbotDbgsi2,
                    variables: {
                        gsi2Pk2: subscriptionPost
                    }
                })
                    .subscribe({
                        next: data => {
                            console.log('MapPage.subscriptiuon.data.Contractor: ', data)
                            UpdateSubsData(data.value.data.onCreateSvcbotDb.dataJSON)

                        }
                    })

            if (subscribe1) {
                return () => {
                    subscribe1.unsubscribe();
                }
            }
        }

    }, [state.CompanyId]);

    const UpdateSubsData = SubsData => {

        let newSubdata = JSON.parse(SubsData);

        if (state.Data || state.Data.length > 0) {

            let NewData = [newSubdata, ...state.Data];
            console.log('MapPage.subscriptiuon.data.NewData: ', NewData)
            setState(prevState => ({
                ...prevState,
                Data: NewData
            }));

        } else {
            GetDatafromDB(state.CompanyId, state.StartDate, state.EndDate, 219);
        }        
    }


    const GetDatafromDB = async (pk1, startDate, endDate, from = "") => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        //let UTCdate = new Date(endDate).toISOString()

        let UTCdate = new Date(endDate).toISOString();
        let StartDate = new Date(startDate).toISOString();

        console.log("MapPage.DBinput", `Post::Location::${pk1}`, StartDate, UTCdate);

        NewData = await ContreactorPostSearch(`Post::Location::${pk1}`, StartDate, UTCdate);
        
        console.log("MapPage.AutoSelectData1", NewData, from);
        
        let ShowString = "";
        if (NewData) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);
                if (ShowString && ShowString.Location && ShowString.Location !== "") {
                    AutoSelectData.push(ShowString);
                }
            })
        }

        setState(prevState => ({
            ...prevState,
            FullData: AutoSelectData,
        }))

        console.log("MapPage.AutoSelectData2", AutoSelectData, from);
      // 

        GetAddressOut(AutoSelectData);

        SetLoading(false);
        
    }

    const GetAddressOut = data => {
        try {

            let latt = 0;
            let lagg = 0;
            let marker = [];
            let FindingSameLocation = "";
            let tempmarker = "";
            let addSRinfo = {};
            let date = "";
            let center = {
                lat: 0,
                lng: 0,
            };

            if (data && data.length > 0) {

                data.map((each, indd) => {
                    if (each.Location && each.ServiceRequest) {
                        date = new Date(each.currentTime).toLocaleString();
                        FindingSameLocation = marker.findIndex(x => x.lat === each.Location.lat && x.lng === each.Location.lng)

                        if (FindingSameLocation > -1) {
                            //add the call to existing marker
                            tempmarker = marker[FindingSameLocation];
                            addSRinfo = {
                                ServiceRequest: each.ServiceRequest,
                                field1: each.title,
                                field2: `${each.field1} - ${each.field2}`,
                                date: date,
                            }
                            tempmarker.SRInfo = [...tempmarker.SRInfo, addSRinfo];
                            marker[FindingSameLocation] = tempmarker;

                            console.log("MapPage.FindingSameLocation", FindingSameLocation);
                            console.log("MapPage.tempmarker", tempmarker);

                        } else {
                            //add new marker
                            marker.push({
                                lat: each.Location.lat,
                                lng: each.Location.lng,
                                SRInfo: [{
                                    ServiceRequest: each.ServiceRequest,
                                    field1: each.title,
                                    field2: `${each.field1} - ${each.field2}`,
                                    date: date,
                                }]
                            })
                            latt += Number(each.Location.lat);
                            lagg += Number(each.Location.lng);
                        }
                    }
                })

                let Tlatt = latt / marker.length;
                let Tlagg = lagg / marker.length;

                center = {
                    lat: Tlatt,
                    lng: Tlagg,
                };

                setState(prevState => ({
                    ...prevState,
                    Data: marker,
                   // FullData: marker,
                    center: center
                }))

                console.log("MapPage.marker", marker);
                console.log("MapPage.lat.Tlatt", Tlatt, marker.length);
                console.log("MapPage.lat.Tlagg", Tlagg, marker.length);
            } else {

                setState(prevState => ({
                    ...prevState,
                    Data: [],
                   // FullData: [],
                    center: center
                }))

            }

        } catch (err) { console.log("MapPage.GetAddressOut.err", err);  }
    }

    useEffect(() => {
        SortPref();
    }, [state.SearchWords]);

    const SortPref = () => {

        let FullData = [];
        
        if (state.SearchWords !== undefined
            && state.SearchWords !== ""
            && state.FullData
            && state.FullData.length > 0) {
            FullData = state.FullData.filter(x => x.SearchWords.toLowerCase().includes(state.SearchWords.toLowerCase()))
        } else {
            FullData = state.FullData
        }

        console.log("MapPage.FullData", FullData);
        console.log("MapPage.FullData.state", state.FullData);

        GetAddressOut(FullData);

    }

    const SetEndDate = date => {
        let EndDate = new Date(date).toISOString();
        console.log("MapPage.Date.EndDate", EndDate);
        setState(prevState => ({
            ...prevState,
            EndDate: EndDate,
        }));

        GetDatafromDB(state.CompanyId, state.StartDate, EndDate, "SetEndDate");

    }

    const SetStartDate = date => {
        let StartDate = new Date(date).toISOString();
        console.log("MapPage.Date.StartDate", StartDate);
        setState(prevState => ({
            ...prevState,
            StartDate: StartDate,
        }));

        GetDatafromDB(state.CompanyId, StartDate, state.EndDate, "SetStartDate");

    }

   
    const SearchTerms = value => {
        setState(prevState => ({
            ...prevState,
            SearchWords: value,
        }));
    }

    console.log("MapPage.state", state);

    return (
        <Page
            title={state.Category}
        >
            <Container maxWidth={false}>
                {/* 
                <ToolbarHeading
                    SortPref={sort => SortPref(sort)}
                />
                */}
                <ToolbarHeading
                  //  SortPref={sort => SortPref(sort)}
                    SetStartDate={sort => SetStartDate(sort)}
                    SetEndDate={sort => SetEndDate(sort)}
                  //  SetThreeColumn={sort => SetThreeColumn(sort)}
                    SearchTerms={sort => SearchTerms(sort)}
                    StartDate={state.StartDate}
                    EndDate={state.EndDate}
                  //  AverageResponse={state.AverageResponse}
                />

                <Box mt={2}>

                    {state.Data && state.Data.length > 0 ? (
                        <GoogleMapShow
                            Data={state.Data}
                            center={state.center}
                        />
                    ) : (<Skeleton />)}

                </Box>
            </Container>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/*   
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.Category}

                propss={state.DetailsProps}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
            */}
        </Page>
    );
};

export default ListItems;
