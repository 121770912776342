import React, { useEffect, useState } from 'react';
import {
    TextField,
    Backdrop, CircularProgress,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    CardActions,
    Snackbar,
    CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import { API } from "aws-amplify";
import SelectAssets from '../components/SelectAssets'; 
import {
    removeEmptyStringElements,
} from '../utils/CommonGraphql';


export default function RadioButtonsGroup(props) {
  //  const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        AssetList: [],
        DoneDone: false,
        CompanyId: "",
    }
    const [state, setState] = useState(initialState);
    //  const [DrawerStatus, OpenDrawer] = useState(false);


    useEffect(() => {
                
        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        //  console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])

    useEffect(() => {

        let event = props.SREvent;
        if (event && event.ContractorData.CompanyId) {
            setState(prevState => ({
                ...prevState,
                CompanyId: event.ContractorData.CompanyId,
                CompanyName: event.ContractorData.CompanyName
            }));
        }
       
    }, [props.SREvent])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                handleNext();
                SetLoading(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);


    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const PutInDB = () => {

        let event = props.SREvent;

        
        let ImageStateSet = state.AssetList;
        let MiniDataJSON = {};
        let InputArray = [];

        if (ImageStateSet
            && ImageStateSet.length > 0) {

            setState(prevState => ({
                ...prevState,
                DoneDone: true
            }))

            let MiniDataJSON2 = {
                CompanyId: event.posterId.CompanyId,
                CompanyName: event.posterId.CompanyName,
                ContractorId: event.ContractorData.CompanyId,
                ContractorName: event.ContractorData.CompanyName,
            }


            ImageStateSet.map((each, indd) => {

                MiniDataJSON = {
                    ...MiniDataJSON2,
                   // ...each,
                    field1: each.field1,
                    field2: each.field2,
                    AssetPkSk: {
                        pk1: each.pk1,
                        sk1: each.sk1
                    },
                    SearchWords: `${event.SearchWords}, ${each.SearchWords}`,
                    pk1: `CheckIn::${event.PostId}`,
                    sk1: `CheckIn::Assets::${event.ServiceRequest.sk1}::${indd}`,
                    gsi1Pk2: `CheckIn::Assets::${each.sk1}`,
                    gsi1Sk2: event.currentTime,
                    gsi2Pk2: `Assets::${event.ContractorData.CompanyId}`,
                    gsi2Sk2: event.currentTime,
                }

                const DocData = {
                    pk1: `CheckIn::${event.PostId}`,
                    sk1: `CheckIn::Assets::${event.ServiceRequest.sk1}::${indd}`,
                    gsi1Pk2: `CheckIn::Assets::${each.sk1}`,
                    gsi1Sk2: event.currentTime,
                    gsi2Pk2: `Post::Assets::${event.ContractorData.CompanyId}`,
                    gsi2Sk2: event.currentTime,
                    dataJSON: JSON.stringify(removeEmptyStringElements(MiniDataJSON))
                };

                InputArray.push(DocData);
            })

        } else {

            handleNext();

        }

        console.log("UploadEachBox.MiniDataJSON", MiniDataJSON);    

        if (InputArray && InputArray.length > 0) {

            let PosttoDB = {
                MutationType: "createBatch",
                DBData: InputArray
            };

            // Post to DB
            TriggerLambda(PosttoDB);

        }
    }

    const TriggerLambda = (event) => {
        console.log("NewServiceCall.TriggerLambda", event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }

    //const TriggerESCData = (event) => {
    //    console.log("NewServiceCall.TriggerLambda", event);

    //    API.post("TriggerESCData", "/ESCData", {
    //        body: { Records: event }
    //    });
    //}

    const handleNext = () => {

        let Body = "No Assets";
        if (state.AssetList.length > 0) {
            Body = "";
            state.AssetList.map(each => {
                Body += `${each.field1}, ${each.field2}, `;
            })            
        }

        let SelectedDataReturn = {
            ...state,
            Body: Body,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("UploadBox.key", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const SetValues = (data, name) => {

             
        setState(prevState => ({
            ...prevState,
            [name]: data,
        }));

    }

    console.log("UploadBox.state", state)
    console.log("UploadBox.props", props)

    return (
        <>
            <Grid container spacing={1}>

                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title={`Select Assets`}
                        />

                        <CardContent>

                            {state.CompanyId && state.CompanyId !== "" && (
                            <SelectAssets
                                SelectedAsset={(e) => SetValues(e, "AssetList")}
                                CompanyId={state.CompanyId}
                                CompanyName={state.CompanyName}
                                title={`Select Assets`}
                                Category="Assets"
                                AssetList={state.AssetList}
                            />
                            )}

                        </CardContent>


                    </Card>

                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => PutInDB()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>
            
            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


        </>

    );
}
