import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import {
    Box,
    Button,
    FormControl, InputLabel, Select, MenuItem,
    Backdrop, CircularProgress,
    TextField, InputAdornment,
    Stack, Grid, Chip
} from '@mui/material';
//import { useSelector } from 'react-redux';
//import { Search as SearchIcon } from 'react-feather';
//import AutoComplateBox from '../components/AutoComplete';
import { getAutoCompletedata } from '../utils/CommonGraphql';
//import DateRange from '../components/DateRange';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime'
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useSelector } from 'react-redux';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
//import { adjustedTime } from

export const DocumentType = [

    {
        label: "Manager Approval",
        key: "Approval"
    },
    {
        label: "Service Stages",
        key: "Progress"
    },
    {
        label: "Customer Feedback",
        key: "Completed"
    },

];

const ShowResponse = (props, indd) => {
    let temmp = 0;
    let AvgChipColor = "#009933";
    let Unit = "minute"

    if (props.value > 60) {
        temmp = Math.round(props.value / 60);
        Unit = "hour"
        if (props.value > 480) {
            AvgChipColor = "#ff0000";
        }

    } else {
        temmp = Math.round(props.value);
        AvgChipColor = "#009933"
    }

    return (
        
        <Grid
           // xs={12} md={4}
            item key={"responn" + indd}>
                    <Chip
                label={`${props.label} ${temmp} ${Unit}`}
                        color="primary"
                style={{ background: AvgChipColor }}
                    />
                </Grid>
       
    )
}

const Toolbar = (props) => {
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        StartDate: "", //adjustedTime(-30, "Start"),
        EndDate: "", //adjustedTime(1, "End"),
        Sort: "Progress",
        SMSType: "Any",
        AvgChipColor: "#009933",
        AverageResponse: "",
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let SortPref = {
            StartDate: state.StartDate,
            EndDate: state.EndDate,
            Sort: state.Sort,
            SMSType: state.SMSType
        }

        // if (props.SortPref) {
        //      props.SortPref(SortPref);
        //  }

    }, [state.StartDate, state.EndDate, state.Sort, state.SMSType]);

    useEffect(() => {

        setState((prevState) => ({
            ...prevState,
            EndDate: props.EndDate,
            StartDate: props.StartDate,
        }))

    }, [props.StartDate, props.EndDate]);

    useEffect(() => {
        console.log("SRTools", props);
        GetContractorSMS("AddSMS", ContractorData.ContractorId, "SMSData");

        let ResponseAction = "";
        let temmp = 0;
        let AvgChipColor = "#009933"

        console.log("AverageResponse", props.AverageResponse);

        if (props.AverageResponse) {
            if (props.AverageResponse > 60) {
                temmp = Math.round(props.AverageResponse / 60);
                ResponseAction = `Average Response: ${temmp} hours`;

                if (props.AverageResponse > 480) {
                    AvgChipColor = "#ff0000";
                }

            } else {
                temmp = Math.round(props.AverageResponse);
                ResponseAction = `Response: ${temmp} min`;
                AvgChipColor = "#009933"
            }

            setState((prevState) => ({
                ...prevState,
                AverageResponse: ResponseAction,
                AvgChipColor: AvgChipColor
            }))

        }


    }, []);

    const GetContractorSMS = async (Category, CompanyId, ArrayName, Asset = "", SMS = "") => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: "status",
                    extraValue: "Active"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId, Asset, SMS);
        setState(prevState => ({ ...prevState, ...NewData }))
        SetLoading(false);
        // console.log("UrgencyDetails.NewData", NewData);

        console.log("SMSCompanySelect.NewData.SMSs - NewData", NewData);

    }

    const StartSelectedValues = (name, item) => {
        console.log("SRPage.Date.start", item);
        setState((prevState) => ({
            ...prevState,
            StartDate: item,
        }))

        if (props.SetStartDate) {
            props.SetStartDate(item);
        }

        //if start date > end date = change the end date
        if (name === "StartDate") {
            if (new Date(item) > new Date(state.EndDate)) {
                setState((prevState) => ({
                    ...prevState,
                    EndDate: item,
                }))
            }
        }
    }

    const EndSelectedValues = (name, item) => {
        console.log("SRPage.Date.enddate", item);
        setState((prevState) => ({
            ...prevState,
            [name]: item,
        }))

        if (props.SetEndDate) {
            props.SetEndDate(item);
        }
    }

    const SMSTypeSelectedValues = (name, item) => {

        setState((prevState) => ({
            ...prevState,
            [name]: item,
        }))

        if (props.SortPref) {
            props.SortPref(item);
        }
    }

    const SortSelectedValues = (name, item) => {

        setState((prevState) => ({
            ...prevState,
            [name]: item,
        }))

        if (props.SetThreeColumn) {
            props.SetThreeColumn(item);
        }
    }

    const ProcessSearch = (e) => {
        if (props.SearchTerms) {
            props.SearchTerms(e);
        }
    }

    return (

        <Box mt={2}>
            <Grid container sx={{ flexGrow: 1 }} spacing={2}>
                {UserData.CustomerType === "Contractor"
                    && props.AverageResponse
                    && props.AverageResponse.length > 0 ? (
                    <Grid xs={12} item>
                        <Grid container justifyContent="center" spacing={1}>
                            {props.AverageResponse.map((each, indd) => (
                                ShowResponse(each, indd)
                            ))}
                        </Grid>
                    </Grid>
                ) : null}

                <Grid xs={12} item>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack
                            direction={{ xs: 'column', lg: 'row' }}
                            spacing={2}
                        >

                            <DatePicker
                                value={state.StartDate}
                                label="Start Date"
                                onChange={item => StartSelectedValues("StartDate", item)}
                                renderInput={(props) => (
                                    <TextField variant="outlined" {...props} />
                                )}
                            />

                            <DatePicker
                                label="End Date"
                                value={state.EndDate}
                                onChange={item => EndSelectedValues("EndDate", item)}
                                renderInput={(props) => (
                                    <TextField variant="outlined" {...props} />
                                )}
                            />

                            <FormControl variant="outlined" style={{ width: '300px' }} >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Data List Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    //id={`select-${Item.key}-${index}`}
                                    defaultValue="All"
                                    error={state.ErrorMessage}
                                    required={true}
                                    disabled={state.disabled}
                                    autoWidth={true}
                                    value={state.Sort}
                                    onChange={(e) => {
                                        SortSelectedValues("Sort", e.target.value);
                                    }}
                                    label="Data List Type"
                                >

                                    {DocumentType.map((o, indd) => (
                                        <MenuItem key={indd + o.key} value={o.key}>
                                            {o.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl >

                            {state.SMSData && state.SMSData.length > 0 && (
                                <FormControl variant="outlined" style={{ width: '300px' }} >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Service Call Type
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        //id={`select-${Item.key}-${index}`}
                                        defaultValue="Any"
                                        error={state.ErrorMessage}
                                        required={true}
                                        disabled={state.disabled}
                                        autoWidth={true}
                                        value={state.SMSType}
                                        onChange={(e) => {
                                            SMSTypeSelectedValues("SMSType", e.target.value);
                                        }}
                                        label="Service Call Type"
                                    >
                                        <MenuItem key="Any" value="Any">
                                            Any
                                        </MenuItem>
                                        <MenuItem key="Assigned" value="Assigned">
                                            Assigned Accounts
                                        </MenuItem>
                                        <MenuItem key="Quote" value="Quote">
                                            Quotes / Proposals
                                        </MenuItem>
                                        {state.SMSData.map((o, indd) => (
                                            <MenuItem key={indd + o.key} value={o.mobile}>
                                                {o.field1}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl >
                            )}

                            <TextField
                                // fullWidth
                                disabled={props.disabled}
                                onChange={(e) => ProcessSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ManageSearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder={`Search..`}
                                variant="outlined"
                            />

                        </Stack>

                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>

    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    ProcessSearch: PropTypes.func
};

export default Toolbar;
