import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    Avatar,
    Grid,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    CardHeader,
    IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import AutocompleteModule from '../components/AutoComplete';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { NewPageId, FindCategoryIcon } from '../utils/CommonGraphql';
import { red } from '@mui/material/colors';

const NewKey = NewPageId("Asset")

//const user = {
//  avatar: '/static/images/avatars/avatar_6.png',
//  city: 'Los Angeles',
//  country: 'USA',
//  jobTitle: 'Senior Developer',
//  name: 'Katarina Smith',
//  timezone: 'GTM-7'
//};

//const AddRequestor = props => {
//  let Message = "";
//  if (props.CustomerType === "Contractor") {
//    if (props.PersonId === "") {
//      if (props.cid === "") {
//        Message = "Select Company";
//      } else {
//        Message = "Select Person";
//      }
//    }
//  }
//}

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        //maxWidth: '36ch',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

const Profile = ({ className, ...rest }) => {
    const classes = useStyles();
    const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const dispatch = useDispatch()

    const initialState = {
        CompanyId: "",
        RequesterList: [],
        //CustomerType: "Company",
        //ShowPersonalAuto: false
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            ...rest
        }));
    }, [rest.CompanyId])

    useEffect(() => {
        if (rest.AssetList && rest.AssetList.length > 0) {
            setState(prevState => ({
                ...prevState,
                RequesterList: rest.AssetList,
            }));
        }
    }, [rest.AssetList])

    //const SelectedCompany = (e) => {
    //  console.log("company", e)

    //  setState(prevState => ({
    //    ...prevState,
    //    CompanyId: e.CompanyId,
    //    ShowPersonalAuto: true
    //  }));

    //  //set cid if company
    // // if (UserData.UserInfo.CustomerType === "Company") {
    //    dispatch({ type: 'SET_CID', payload: e });
    ////  }

    //  //if (rest.SelectedPerson) {
    //  //  rest.SelectedPerson(e)
    //  //}

    //}

    const SelectAsset = (e) => {

        let RequesterList = [];

        if (state.RequesterList && state.RequesterList.length > 0) {
            RequesterList = state.RequesterList;
        }

        if (e && e.key && e !== null) {
            let Newlist = [...RequesterList, e];
            console.log("Testing.SelectAsset.Newlist", Newlist)
            setState(prevState => ({
                ...prevState,
                RequesterList: Newlist,
            }));

            if (rest && rest.SelectedAsset) {
                rest.SelectedAsset(Newlist)
            }
        }
    }

    const RemoveOption = (index) => {
        let TradeServicestemparr = state.RequesterList;
        TradeServicestemparr.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            RequesterList: TradeServicestemparr,
        }));

        if (rest.SelectedAsset) {
            rest.SelectedAsset(TradeServicestemparr)
        }

    }

    //console.log("ContractorData", ContractorData)
    //console.log("UserData", UserData)
    //console.log("cid", cid)
    console.log("Testing.SelectAsset.state", state)
    console.log("Testing.SelectAsset.props", rest)

    return (
        <Grid container spacing={1}>

            <Grid item xs={12} >
                <AutocompleteModule
                    key={NewKey + state.CompanyId}
                    id={NewKey + state.CompanyId}
                    Category={state.Category}
                    FormType={state.Category}
                    title={state.title}
                    CompanyId={state.CompanyId}
                    CompanyName={state.CompanyName}
                    onSubmit={(e) => SelectAsset(e)}
                />
            </Grid>
            <Grid item xs={12} >

                <List >
                    {state.RequesterList
                        && state.RequesterList.length > 0
                        && state.RequesterList.map((each, ind) => (
                            <ListItem alignItems="flex-start" key={"lisst" + ind}>
                                <ListItemAvatar>
                                    {each.avatar ?
                                        (<Avatar alt="Logo" src={each.avatar} />) : (
                                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                                {FindCategoryIcon(each.Category)}
                                            </Avatar>
                                        )}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={each.field1}
                                    secondary={each.field2}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge='end'
                                        aria-label="delete"
                                        onClick={() => RemoveOption(ind)}
                                        size="large">
                                        <HighlightOffIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}

                </List>


            </Grid>
        </Grid>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
