/*
 * 
 * Demo
 * 
 * 
 */


import React, { useState } from 'react';
import {
    Grid, Button, Container, Card, CardHeader, TextField, CardContent
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { Blockss } from './DemoInputEachRole';

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

const PutQuestionsInDB = async (event) => {
    try {
        console.log(`PutQuestionsInDB`, event);
        var params = {

            Key: {
                'pk1': event.pk1,
                'sk1': event.sk1,
            },

            TableName: "svcbot_db",
            UpdateExpression: "set dataJSON = :dataJSON, gsi1Sk2 = :gsi1Sk2, gsi2Sk2 = :gsi2Sk2",
            ExpressionAttributeValues: {
                ":dataJSON": event.dataJSON,
                ":gsi1Sk2": event.gsi1Sk2,
                ":gsi2Sk2": event.gsi2Sk2,
            },
        };

        dynamodb.update(params, function (err, data) {
            if (err) {
                console.log(`Database info err: ${err}`);
            } else {
                console.log(`Database info success`, event.gsi2Sk2);
            }
        });

    } catch (err) {
        console.log('PutInDB err', err);
    }

}; // end PutInDB

export default function SimpleCard() {
    const initialState = {
        Block: Blockss,
    }
    const [state, setState] = useState(initialState);

    const SaveData = () => {

        try {

            state.Block.map((block) => {
                PutQuestionsInDB(block)
            })

        } catch (err) {
            console.log("ContractorSignup.SaveData", err)
        }
    }
       
    const AddValue = (item, Indexx, pro) => {

        let Block = state.Block;

        if (pro === "email") {
            Block[Indexx].dataJSON.email = item;
            console.log("ContractorSignup.Block.email", Block[Indexx])

            //if (Block[Indexx].dataJSON.CustomerType === "Company") {
            //    Block[Indexx].gsi2Sk2 = `email::${item}`;
            //}

        }

        if (pro === "mobile") {
            Block[Indexx].dataJSON.mobile = item;
            console.log("ContractorSignup.Block.phone", Block[Indexx])

            if (Block[Indexx].dataJSON.CustomerType === "Company") {
                Block[Indexx].gsi1Sk2 = `phone::${item}`;
            }
        }
        
        setState(prevState => ({
            ...prevState,
            Block: Block
        }));

    }

    const Reset = () => {
        console.log("ContractorSignup.Resetting", Blockss)
        setState(prevState => ({
            ...prevState,
            Block: Blockss
        }));
    }

    console.log("ContractorSignup.state", state)

    return (

        <Container maxWidth="sm">

            <Grid container spacing={4}>

                {state.Block.map((block, index) => {
                    return (
                        <Grid key={`ggr-${index}`} item xs={12}>
                         
                            <Card key={block.dataJSON.id}>
                                <CardHeader
                                    title={block.dataJSON.field1}
                                />

                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>

                                            <TextField
                                                label="Email"
                                                name="Email"
                                                key={`email-${block.dataJSON.id}`}
                                                id={`emm-${block.dataJSON.id}`}
                                                value={block.dataJSON.email || ""}
                                                fullWidth
                                                variant="outlined"
                                                onChange={(e) => AddValue(e.target.value, index, "email")}
                                            />

                                        </Grid>
                                        <Grid item xs={12}>

                                            <PhoneInput
                                                country={'us'}
                                                key={`phone-${block.dataJSON.id}`}
                                                id={`phoo-${block.dataJSON.id}`}
                                                value={block.dataJSON.mobile || ""}
                                                // disabled={state.disabled}
                                                label="Mobile"
                                                inputProps={{
                                                    name: "Mobile",
                                                    label: "Mobile",
                                                    style: { width: '100%' },
                                                }}
                                                isValid={(value, country) => {
                                                    if (value.match(/12345/)) {
                                                        return 'Invalid value: ' + value + ', ' + country.name;
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                onChange={(e) => AddValue('+' + e, index, "mobile")}
                                            />

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>
                    )
                })}

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => SaveData()}
                                sx={{ mr: 2}}
                            //  className={classes.button}
                            >
                                Save Data
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => Reset()}
                            //  className={classes.button}
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

        </Container>
    );
}
