import React, { useEffect, useState } from 'react';
import {
    //TextField,
    ////Collapse,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    //CardActions,
    //Snackbar,
    CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import GoogleGetGPSAuto from '../views/modules/GoogleGetGPSAuto';
import { API } from "aws-amplify";
import { NewPageId, removeEmptyStringElements } from '../utils/CommonGraphql';

export default function RadioButtonsGroup(props) {
    //  const ContractorData = useSelector(state => state.ContractorData);
    const initialState = {
        AssetList: [],
        gpslink: "",
        gps: ""
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        let isLocationEnabled = false

        if ("geolocation" in navigator) {
            isLocationEnabled = true;
        }

        let isLocationEnabledMessage = "Please allow Location";
        //if (isLocationEnabled) {
        //    isLocationEnabledMessage = "Location is on";
        //}

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        console.log("SelectAssetBox.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
                isLocationEnabled: isLocationEnabled
            }));
        }

    }, [])


    const handleNext = () => {

        let SelectedDataReturn = {
            ...state,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("SelectAssetBox.key", SelectedDataReturn)

        if (state.gps) {
            LocationDB();
        }


        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const SetCoordinates = (gps) => {

        if (gps && gps.gpslink && gps.Location) {

            console.log("SelectAssetBox.gps", gps)

            setState(prevState => ({
                ...prevState,
                Location: gps.Location,
                gpslink: gps.gpslink,
                Body: gps.gpslink,
                gps: gps
            }));
        }
    }

    const LocationDB = () => {
        try {


            let event = props.SREvent;


            let FormdataJSON = {
                ...state.gps,
                title: event.title,
                PostId: event.PostId,
            };

            let DBInput = {
                pk1: `CheckIn::${event.PostId}`,
                sk1: `CheckIn::Location::${event.ServiceRequest.sk1}`,
                gsi1Pk2: `CheckIn::Location::${event.ContractorData.ContractorId}`,
                gsi1Sk2: event.ServiceRequest.sk1,
                gsi2Pk2: `CheckIn::Location::${event.ContractorData.ContractorId}`,
                gsi2Sk2: event.ServiceRequest.sk1,
                dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
            };

            let PosttoDB = {
                MutationType: "createSvcbotDb",
                DBData: DBInput
            };

            // Post to DB
            TriggerLambda(PosttoDB);


        } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }


    }

    const TriggerLambda = (event) => {
        console.log("NewServiceCall.TriggerLambda", event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }

    console.log("LocationBox.state", state)
    console.log("LocationBox.props", props)

    return (
        <>
            <Grid container spacing={1}>

                <Grid item xs={12} >

                    <Card>
                        <CardHeader
                            title={`Select ${props.Category}`}

                        />

                        <CardContent>

                            <Grid container spacing={1}>
                                {!state.isLocationEnabled ? (
                                    <>
                                        <Grid item xs={12} >
                                            <Typography variant="h4" gutterBottom>
                                                Please enable your location in setting
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography>
                                    In Apple Safari Settings > Privacy > Location Services
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography>
                                                On your Android phone or tablet
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography>
                                                <ol>
                                                    <li>Open the Chrome app.</li>
                                                    <li>To the right of the address bar, tap More. Settings.</li>
                                                    <li>Tap Site settings. Location.</li>
                                                    <li>Turn Location on</li>
                                                </ol>

                                            </Typography>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12} >
                                        <GoogleGetGPSAuto
                                            GPSfound={(GPS) => SetCoordinates(GPS)}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>


                    </Card>

                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>



        </>

    );
}
