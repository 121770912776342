import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import {
    Card, Typography, CardContent, TextField, FormControl,
    RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox, Divider,
    Select, MenuItem, InputLabel, CardHeader, Box, Button,
    Backdrop, CircularProgress
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import moment from 'moment';
import Toolbar from '../components/Toolbar';
import EditIcon from '@mui/icons-material/Edit';
import { Hours, Dayss, WeekOfMonth, MonthsOfYear, DateTodaySlash, currentTime } from '../utils/DateTime'
import { removeEmptyStringElements, Newpk1, getAutoCompletedata, EleminateDuplicateObjects } from '../utils/CommonGraphql';
import PersonalCheckList from '../components/PersonalCheckList';
import DateRange from '../components/DateRange';
import DrawerShow from '../utils/DrawerModule';


//import MobileDatePicker from '@mui/lab/MobileDatePicker';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';


const DateFormat = 'MM/DD/YYYY';
const TimeFormat = 'HH:mm';
const TimeFormatShow = 'h:mm A';
//const startdate = new Date() //.format(dateFormat) + "T23:59:59.000";
//const enddate = dayjs() //.format(dateFormat) + "T23:59:59.000";

//var d = new Date();
//var year = d.getFullYear();
//var month = d.getMonth();
//var day = d.getDate();
//let startdate = `${month}/${day}/${year}`;

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

let startdate = mm + '/' + dd + '/' + yyyy;

//let e = new Date(year, month, day + 1);
//var year2 = e.getFullYear();
//var month2 = e.getMonth();
//var day2 = e.getDate();
//let enddate = `${month2}/${day2}/${year2}`;

//const props.ESCId = Newpk1("Shift");

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//const WaitOptions = [
//  { value: 'end', label: "End Notification Steps" },
//  { value: '0', label: "No Wait" },
//  { value: '10', label: "10 minutes" },
//  { value: '30', label: "30 minutes" },
//  { value: '45', label: "45 minutes" },
//  { value: '60', label: "1 hour" },
//  { value: '90', label: "1.5 hour" },
//  { value: '120', label: "2 hours" },
//  { value: '180', label: "3 hours" },
//  { value: '240', label: "4 hours" },
//  { value: '480', label: "8 hours" },
//  { value: '720', label: "12 hours" },
//  { value: '1440', label: "24 hours" },
//];

let DateOption = [
    {
        label: "Start Date",
        VariableName: "StartDate",
        value: DateTodaySlash,
    },
    {
        label: "End Date",
        VariableName: "EndDate",
        value: DateTodaySlash,
    },
    //{
    //  label: "Start Time",
    //  VariableName: "StartTime",
    //  value: "07:00",
    //},
    //{
    //  label: "End Time",
    //  VariableName: "EndTime",
    //  value: "16:00",
    //}
];

const defaultdays = [1, 2, 3, 4, 5, 6, 7];
const DefaultWeekOfMonth = [1, 2, 3, 4, 5];
const DefaultMonthsOfYear = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    field: {
        margin: '10px 0',
    },
    countryList: {
        ...theme.typography.body1,
    },
}));

/*
 * props
 * step
 * CloseDrawer
 * ESCId
 * 
 */

const isEmptyObject = (value) => {
    return value && Object.keys(value).length > 0 && value.constructor === Object;
}

const RecurringSchedule = (props) => {
   // const classes = useStyles();
    const dispatch = useDispatch();
    const ContractorData = useSelector(state => state.ContractorData);
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)
    const [DrawerStatus, OpenDrawer] = useState(false);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const initialState = {
        StartTime: '07:00',
        EndTime: "17:00",
        StartDate: startdate,
        CurrentCompany: props.CompanyName,
        ContractorId: ContractorData.ContractorId,
        EndDate: "",
        SearchWords: "",
        StepPersonalList: [],
        StepName: `${props.ShiftName} Step ${props.StepNumber}`,
      //  delay: "",
        CompanyId: props.CompanyId,  //ContractorData.ContractorId, //

        CompanyName: props.CompanyName,
        pk1: props.CompanyId,
        ShowEditButton: props.ShowEditButton || false,
        disabled: props.disabled || false,
        OpenAlert: false,
        DateErr: false,
        EndTimeMessage: "",
        DateRange: DateOption,
        //DaysSelected: defaultdays,
        //WeekOfMonthSelected: DefaultWeekOfMonth,
        //MonthsOfYearSelected: DefaultMonthsOfYear,
        ScheduleType: "Recurring",
        Dayss: Dayss,
        WeekOfMonth: WeekOfMonth,
        MonthsOfYear: MonthsOfYear,
        EditKey: "",
        DataBack: [],
    };

    const [state, setState] = useState(initialState);
    const [loading, SetLoading] = useState(false);

    const CloseDrawer = (c) => {
       // GetDatafromDB("Person", state.CompanyId);
        GetDatafromDB("Person", state.CompanyId, [], state.CompanyName, "CloseDrawer");
        OpenDrawer(false);
    }
    //useEffect(() => {

    //  if (props && props.StepNumber > 1) {
    //    setState(prevState => ({
    //      ...prevState,
    //      delay: '60'
    //    }));
    //  }

    //}, [props]);

    //useEffect(() => {
    //  GetDatafromDB("Person", state.CompanyId);
    //  console.log("props", props);

    //  //setState(prevState => ({
    //  //  ...prevState,
    //  //  CurrentCompany: UserData.CompanyName,
    //  //}));

    //}, []);

    //edit items
    useEffect(() => {

        console.log("ESCAddPersonal.CompanyData.props", props);
        // console.log("ESCAddPersonal.state", state);
        // console.log('ESCMinSetup.gsi1Pk2.state.props', props.ESCId)

        try {

            if (Object.entries(props.EditItem).length > 0) {
                console.log("props.EditItem", props.EditItem);
                let PersonalList = props.EditItem.StepPersonalList;
                GetDatafromDB("Person", props.EditItem.pk1, PersonalList, props.CompanyName, "props.EditItem");
                setState(prevState => ({
                    ...prevState,
                    StepTeam: props.EditItem.StepTeam,
                    CompanyId: props.EditItem.pk1,
                    CompanyName: props.EditItem.CompanyName,
                    EditKey: props.EditItem.key,
                    pk1: props.EditItem.pk1
                }))
            } else {
                setState(prevState => ({
                    ...prevState,
                    StepTeam: props.StepTeam,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    EditKey: "",
                    pk1: props.CompanyId
                }))
                GetDatafromDB("Person", props.CompanyId, [], props.CompanyName, "else.props.EditItem");
            }

            

        } catch (err) { }
    }, [props.CompanyId]);

    useEffect(() => {

        if (TriggerUpdate === true) {
            GetDatafromDB("Person", state.CompanyId, [], state.CompanyName, "TriggerUpdate");
            dispatch({ type: 'TRIGGER_UPDATE', payload: false });
        }

    }, [TriggerUpdate]);

    const GetDatafromDB = async (Category, cid, PersonalList = [], CompanyName = "", from="") => {
        try {
            SetLoading(true);
            //console.log("ESCAddPersonal.CompanyData1.GetDatafromDB", Category, cid);
            const DataString = {
                Category: Category,
                FindArray: [
                    {
                        ArrayName: "DataBack",
                        //DuplicateArray: "RawData",
                        ArraySearchString: ":",
                    }
                ]
            };

            let NewData = await getAutoCompletedata(DataString, cid);

            //replace existing selection in to new list
            if (NewData && NewData.DataBack.length > 0 && PersonalList.length > 0) {
                NewList(NewData, PersonalList);
            }

            console.log("ESCAddPersonal.CompanyData1.NewData", CompanyName, from, NewData);

            setState(prevState => ({ ...prevState, ...NewData }))

            SetLoading(false);
        } catch (err) { }
    }

    const NewList = (NewData, PersonalList) => {

        let StepPersonalList = [];
        var foundIndex = "";
        PersonalList.forEach((element, index) => {
            foundIndex = NewData.DataBack.findIndex(x => x.key == element.key);
            NewData.DataBack[foundIndex].emailcheck = element.emailcheck;
            NewData.DataBack[foundIndex].phonecheck = element.phonecheck;
            NewData.DataBack[foundIndex].smscheck = element.smscheck;
            StepPersonalList.push(NewData.DataBack[foundIndex]);
        });

        setState(prevState => ({ ...prevState, StepPersonalList: StepPersonalList }))

    }

    const CompanyData = (data) => {
        //console.log("ESCAddPersonal.CompanyData2", data.CompanyId, data.CompanyName);
        //GetDatafromDB("Person", data.CompanyId);
        GetDatafromDB("Person", data.CompanyId, [], data.CompanyName, "CompanyData");
        setState(prevState => ({
            ...prevState,
            CurrentCompany: data.CompanyName,
            CompanyId: data.CompanyId,
            CustomerType: data.CustomerType,
            pk1: data.CompanyId
        }));
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };


    const SaveData = () => {
        //console.log('SaveData', state);
        try {

            let FarEndDate = "";
            let keydateadd = "";
            let Field2 = "";
            let SearchWords = state.StepName;
            let PersonalDB = [];
            let MissingInfoFlag = false;
            let AlarmMessage = 'Please enter ';


            if (state.ScheduleType === "Recurring") {
                keydateadd = "";
                FarEndDate = "3000-01-01"
                Field2 = `${moment(state.StartDate).format(DateFormat)} to On Going`;
            } else {

                //if schedule is temp - add to the list.  this will override the recurring schedule
                keydateadd = `#${state.StartDate}`;

                if (state.StartDate === "" || state.EndDate === "") {
                    MissingInfoFlag = true;
                    AlarmMessage += `* Please select Dates `;
                    setState(prevState => ({ ...prevState, DateErr: true }));
                } else {

                    if (new Date(state.StartDate) > new Date(state.EndDate)) {
                        MissingInfoFlag = true;
                        AlarmMessage += `* The End Date should be after Start Date `;
                        setState(prevState => ({ ...prevState, DateErr: true }));
                    }

                    FarEndDate = state.EndDate;
                    Field2 = `${moment(state.StartDate).format(DateFormat)} to ${moment(state.EndDate).format(DateFormat)}`;
                    setState(prevState => ({ ...prevState, DateErr: false }));
                }
            }

            if (!state.StepName || state.StepName === "") {
                MissingInfoFlag = true;
                AlarmMessage += `* Please Enter Notification Step Name `;
                setState(prevState => ({ ...prevState, StepNameErr: true }));
            } else {
                setState(prevState => ({ ...prevState, StepNameErr: false }));
            }

            if (state.StepPersonalList && state.StepPersonalList.length < 1) {
                MissingInfoFlag = true;
                AlarmMessage += `* Please select personnel `;
            }


            //if alerts
            if (MissingInfoFlag) {
                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: AlarmMessage,
                    OpenAlert: true
                }));
            } else {

                state.StepPersonalList.map(eachperson => {
                    SearchWords += ` | ${eachperson.field1} ${eachperson.email}`;
                    PersonalDB.push({
                        pk1: eachperson.pk1,
                        sk1: eachperson.sk1
                    })
                })

               // 

                //let key = `${props.ESCKey}#${props.StepNumber}#${state.StepTeam}${keydateadd}`;
                let key = `Data::Online::Lambda::ESC::Steps#${props.ESCId}#Step${props.StepNumber}#Team${state.StepTeam}#${state.StartDate}`;

                if (state.EditKey && state.EditKey !== "") {
                    key = state.EditKey;
                }

                let StepPersonal = {
                    ScheduleType: state.ScheduleType,
                    key: key,
                    ESCId: props.ESCId,
                    StartDate: state.StartDate,
                    EndDate: state.EndDate,
                    CompanyName: state.CurrentCompany,
                    CustomerType: state.CustomerType,
                    pk1: state.pk1,
                    StepPersonalList: state.StepPersonalList,
                    StepName: state.StepName,
                    SearchWords: SearchWords,
                    field1: state.StepName,
                    field2: Field2,
                    DB: PersonalDB,
                  //  delay: state.delay || "",
                    StepNumber: props.StepNumber,
                    StepTeam: state.StepTeam,
                    ESCId: props.ESCId,
                    date: currentTime,
                }


                props.StepPersonal(StepPersonal);

                props.CloseDrawer();


            } // end of no alerts

        } catch (err) { console.log("SaveData err", err); }
    }

    const handleChange = (event, ind, Arrayy, SelectedValues) => {
        let NewCheckList = state[Arrayy];
        let KeyArray = [];
        NewCheckList[ind].checked = event.target.checked;

        //console.log(NewCheckList)
        NewCheckList.map(each => {
            if (each.checked) {
                KeyArray.push(each.key)
            }
        })

        setState({
            ...state,
            [Arrayy]: NewCheckList,
            [SelectedValues]: KeyArray
        });


        // console.log("keys", KeyArray)
    };

    const DisableItems = () => {
        setState(prevState => ({ ...prevState, disabled: !state.disabled }));
    }

    const EndTimeCheck = (e) => {
        let Endtimemessage = ""
        if (e < state.StartTime) {
            Endtimemessage = "Next Day";
        }
        setState(prevState => ({ ...prevState, EndTime: e, EndTimeMessage: Endtimemessage }));
    }

    const StartTimeCheck = (e) => {
        let Endtimemessage = ""
        if (e > state.EndTime) {
            Endtimemessage = "Next Day";
        }
        setState(prevState => ({ ...prevState, StartTime: e, EndTimeMessage: Endtimemessage }));
    }

    const SetValueState = (e, name) => {
        console.log("ESCaddpersona.Date", name, e)
        setState(prevState => ({ ...prevState, [name]: e }));
    }

    const AddItem = (data) => {

        let DetailComponent = "ItemAddPage";

        if (data === "ESC") {
            DetailComponent = "ESCSetupPage"
        }

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
        }))

        OpenDrawer(true);
    }

    const SelectedPersonalList = (e) => {
        setState(prevState => ({ ...prevState, StepPersonalList: e }))
    }

    const ProcessSearch = (e) => {
        setState(prevState => ({ ...prevState, SearchWords: e }))
    }

    console.log("ESCAddPersonal.CompanyData.state", state);

    return (

        <div key={props.CompanyId}>
            <Card>
                <CardHeader
                    title={`Step ${props.StepNumber} - Notification List ${state.StepTeam}`}
                    action={
                        state.ShowEditButton && (<EditIcon
                            fontSize="small"
                            onClick={() => DisableItems()} />)
                    }
                />
                <CardContent>

                    <Grid container spacing={2} >
                        <Grid item key="20dws20" xs={12} >
                            <TextField
                                required={true}
                                error={state.StepNameErr}
                                disabled={state.disabled}
                                id="StepName"
                                //defaultValue={Item.defaultValue}
                                label="Notification Step Name"
                                name="StepName"
                                value={state.StepName || ""}
                                fullWidth
                                variant="outlined"
                                //onChange={e => setState((prevState) => ({ ...prevState, StepName: e.target.value }))}
                                onChange={e => SetValueState(e.target.value, "StepName")}

                            />
                        </Grid>

                        <Grid item key="20ddddsdws20" xs={12} >
                            <Divider />
                        </Grid>

                        <Grid item key="20ddddfgdws20" xs={12} >
                            <Grid item xs={12}>
                                <FormControl variant="outlined"  style={{ width: '100%' }} >
                                    <FormLabel component="legend">Schedule Type</FormLabel>
                                    <RadioGroup
                                        //aria-label={Item.label}
                                        //id={`radio-${Item.key}-${index}`}

                                        name="ScheduleType"
                                        //error={ErrorMessage}
                                        value={state.ScheduleType}
                                        required={true}
                                        onChange={e => setState((prevState) => ({ ...prevState, ScheduleType: e.target.value }))}
                                    >
                                        <FormControlLabel disabled={state.disabled} id="ScheduleType1" value="Recurring" control={<Radio />} label="Recurring Schedule" />
                                        <FormControlLabel disabled={state.disabled} id="ScheduleType2" value="DateRange" control={<Radio />} label="Special Dates/Holiday" />
                                    </RadioGroup>
                                </FormControl >
                            </Grid>

                            <Grid item xs={12}>
                                <DateRange
                                    //SelectedValues={(e) => SetValueState(e, "DateRange")}
                                    StartDate={e => SetValueState(e, "StartDate")}
                                    EndDate={e => SetValueState(e, "EndDate")}
                                    DataSent={state.DateRange}
                                    ShowStartDate={true}
                                    ShowEndDate={state.ScheduleType}
                                />
                            </Grid>

                        </Grid>
                        <Grid item key="20dd22dddws20" xs={12} >
                            <Divider />
                        </Grid>


                    </Grid>
                    <Grid container spacing={2} >

                        <Grid
                            key="sdadjffddsh"
                            item
                            xs={12}
                        >
                            <Toolbar
                                Headings="Person"
                                Category="Person"
                                ProcessSearch={(e) => ProcessSearch(e)}
                                CompanyData={(e) => CompanyData(e)}
                                AddCompany={false}
                                AddItem={() => AddItem()}
                                disabled={props.disabled}
                                CompanyName={state.CompanyName}
                                CompanyId={state.CompanyId}
                                CloseDrawerToolBar={(e) => CloseDrawer(e)}
                            />

                        </Grid>

                        {state.DataBack && (
                        <Grid
                            key="sdadjdsh"
                            item
                            xs={12}
                        >
                            <PersonalCheckList
                                Category="Person"
                                CompanyId={state.CompanyId}
                                CompanyName={state.CompanyName}
                                key={state.pk1 + "showpersonal"}
                                DetailComponent="Person"
                                SearchWords={state.SearchWords}
                                PerPage={10}
                                StepPersonalList={(e) => SelectedPersonalList(e)}
                                DataBack={state.DataBack}
                                disabled={state.disabled}
                            />

                        </Grid>
                        )}

                    </Grid>
                </CardContent>
            </Card>

            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => SaveData()}
                    disabled={state.disabled}
                >
                    Save details
                </Button>
            </Box>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

            <DrawerShow
                LoadComponenet="ItemAddPage"
                CloseDrawer={(e) => CloseDrawer(e)}
                propss={{
                    Category: "Person",
                    CompanyId: state.CompanyId,
                    CompanyName: state.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e)
                }}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>

    )
}

export default RecurringSchedule;
