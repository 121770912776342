import React, { useState, useEffect } from 'react';
import {

    Grid, Stepper, Step, StepLabel, StepContent, TextField,
    Backdrop,
    CircularProgress,
    Paper, Snackbar,
    Card, Typography, CardContent, FormControl,
    CardActions,
    RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox, Divider,
    Select, MenuItem, InputLabel, CardHeader, Box, Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch, createSvcbotDb } from '../graphql/mutations';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import CardBox from '../components/ShowCheckBoxGroup';
import {
    removeEmptyStringElements,
    NewPageId,
    EleminateDuplicateObjects,
    PrimaryKeyDataGet,
    getAutoCompletedata,
    UpdateData
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import ListCheckBox from '../List/ListCheckbox';
import ESCEachStepDetails from '../Setup/ESCEachStepDetails';
import {
    Hours, Dayss, WeekOfMonth, MonthsOfYear, DateTodaySlash, currentTime,
    formatDate, TimeStampEndofDay
} from '../utils/DateTime';
import DateRange from '../components/DateRange';
import ESCPerStep from '../Setup/ESCPerStep';
import CheckBoxGroup from '../components/CheckBoxGroup'




function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    Cardroot: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    formControl: {
        //margin: theme.spacing(1),
    },

}));

let Colorss = [
    {
        "name": "Shift Calendar Box Color",
        "color": "#f5a623",
        "key": "ShiftBoxColor"
    },
    {
        "name": "Shift Text Color",
        "color": "#7ed321",
        "key": "ShiftTextColor"
    }
]

let DateOption = [
    {
        label: "Start Date",
        VariableName: "StartDate",
        value: DateTodaySlash,
    },
    {
        label: "End Date",
        VariableName: "EndDate",
        value: DateTodaySlash,
    },
    //{
    //  label: "Start Time",
    //  VariableName: "StartTime",
    //  value: "07:00",
    //},
    //{
    //  label: "End Time",
    //  VariableName: "EndTime",
    //  value: "16:00",
    //}
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const defaultdays = [0, 1, 2, 3, 4, 5, 6];
const DefaultWeekOfMonth = [1, 2, 3, 4, 5];
const DefaultMonthsOfYear = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const StepList = [1, 2, 3, 4, 5];

const Account = (props) => {
    const classes = useStyles();
    const params = useParams();
    let query = useQuery();
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const cid = useSelector(state => state.cid);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    //console.log('cid cid ', cid)

    const initialState = {
        StartTime: '07:00',
        EndTime: "17:00",
        DetailComponent: "ESCPersonal",
        StartDate: "",
        EndDate: "",
        NextDay: false,
        ShiftName: "",
        showeditbutton: true,
        disabled: false,
        //ESCKey: ESCKey,
        DateErr: false,
        EndTimeMessage: "",
        DateRange: DateOption,
        DaysSelected: defaultdays,
        WeekOfMonthSelected: DefaultWeekOfMonth,
        MonthsOfYearSelected: DefaultMonthsOfYear,
        ScheduleType: "Recurring",
        Dayss: [],
        WeekOfMonth: [],
        MonthsOfYear: [],
        steps: [],
        Category: "",
        FormJSON: [],
        FormData: [],
        NotificationStepsList: [],
        AlarmMessage: "",
        ESCId: "",
        StepTitle: "Step 1",
        StepTeam: [],
        StepNumber: 0,
        step: 1,
        ShiftSelected: "",
        AreaSelected: "",
        Colors: Colorss,
        UrgencySelected: "",
        TradeSelected: "",
        OpenAlert: false,
        ShowNewServiceForm: false,
        ImagePic: "",
        AllowDelete: true,
        AllowEdit: true,
        StepValueArray: [],
        delay: [],
        Updatesk: ""

    }
    const [state, setState] = useState(initialState);
    const [activeStep, setActiveStep] = useState(0);
    const [counter, setCounter] = useState(0);

    useEffect(() => {

        if (!props.Event) {
            console.log('ESCMinSetup.state.props.id', props.id);
            console.log('ESCMinSetup.state.initialState', initialState);

            let InitialStepValueArray = [];
            let UUU = props.id
            for (let i = 1; i <= 5; i++) {
                InitialStepValueArray.push({
                    StepNumber: i,
                    key: UUU + i
                })
            }

            setState(prevState => ({
                ...prevState,
                StepValueArray: InitialStepValueArray,
                Dayss: RefreshValues(Dayss, UUU + 1),
                WeekOfMonth: RefreshValues(WeekOfMonth, UUU + 20),
                MonthsOfYear: RefreshValues(MonthsOfYear, UUU + 50)
            }));

            console.log('ESCMinSetup.Step.initial', InitialStepValueArray)

        }

    }, [props.id])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    const RefreshValues = (toRefech, UUU) => {

        let refreshdays = [];
        toRefech.map((each, ind) => {
            refreshdays.push({
                ...each,
                key: UUU + ind,
                checked: true
            })
        })

        return refreshdays;

    }



    const handleNext = (item, required = false) => {
        console.log('ESCSetupPage.handleNext', item);

        if (required) {

            if (state[item] && state[item].length > 0) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: "Please select items before proceeding to next step",
                    OpenAlert: true
                }));
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {

        console.log('ESCMinSetup.props', props);

        //  setState(initialState)

        const ESCId = NewPageId("ESC");
        //const ESCKey = `Data::Online::Lambda::ESC#${ESCId}`
        const ESCKey = `Data::Online::Lambda::ESC::Time#${ESCId}`;

        let CompanyId = "";
        let CompanyName = "";
        if (UserData.CustomerType === "Company") {
            setState(prevState => ({
                ...prevState,
                ShowNewServiceForm: true,
            }));
        }

        if (cid && cid.CompanyId) {
            CompanyId = cid.CompanyId;
        }

        if (props.CompanyId) {
            CompanyId = props.CompanyId;
            CompanyName = props.CompanyName
        }

        setState(prevState => ({
            ...prevState,
            CompanyId: CompanyId,
            CompanyName: CompanyName,
            ESCId: ESCId,
            ESCKey: ESCKey,
            //  StepValueArray: InitialStepValueArray
        }));


    }, [])

    useEffect(() => {

        let AllowDelete = false;
        let AllowOpenEdit = false;
        let AllowEdit = false;

        if (props.Event) {

            var dateToday1 = new Date("1/1/3000");
            let currentTime1 = dateToday1.toISOString();

            var dateToday2 = new Date("12/30/2021");
            let currentTime2 = dateToday2.toISOString();

            if (UserData.Level > 190) {
                AllowDelete = true;
                AllowOpenEdit = true;
                AllowEdit = true;
            }

            console.log('ESCMinSetup.CombineDateAndTime.end', currentTime1);
            console.log('ESCMinSetup.CombineDateAndTime.now', currentTime2);

            GetDatafromDB(props.Event);
            setActiveStep(4)
            
            setState(prevState => ({
                ...prevState,
                counter: counter,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                AllowDelete: AllowDelete,
                AllowOpenEdit: AllowOpenEdit,
                AllowEdit: AllowEdit
            }));
        }

    }, [props.Event])

    const GetDatafromDB = async (Event) => {

        let pk = Event.CompanyId;
        let sk = Event.key;

        SetLoading(true);
        let DataFile = "";
        let data = await PrimaryKeyDataGet(pk, sk);
        let NewData = await data[0];

        //  console.log("ESEditTeam.NewData", pk, sk, NewData);

        if (NewData && NewData.dataJSON) {

            DataFile = JSON.parse(NewData.dataJSON);
            console.log("ESCTeamEdit.DB.back", DataFile);
            console.log("ESCTeamEdit.DB.event", Event);
            //  GetEachTeam(`ESCTeams::${DataFile.id}`, DataFile.steps, Event);

            setState(prevState => ({
                ...prevState,
                counter: counter,
                ESCEvent: DataFile,
                ESCId2: DataFile.id,
                ESCId: DataFile.id,
                UpdatePk: pk,
                Updatesk: sk,
                ShiftName: DataFile.field1,
                StartTime: DataFile.StartTime,
                EndTime: DataFile.EndTime,
                StepValueArray: DataFile.StepValueArray,
                DaysSelected: DataFile.Days,
                WeekOfMonthSelected: DataFile.WeekOfMonth,
                MonthsOfYearSelected: DataFile.MonthsOfYear,
            }));

            console.log('ESCMinSetup.Step.Update', DataFile.StepValueArray)

            let UUU = props.ESCId
            SetDayValues("Dayss", Dayss, DataFile.Days, UUU + 1)
            SetDayValues("WeekOfMonth", WeekOfMonth, DataFile.WeekOfMonth, UUU + 20)
            SetDayValues("MonthsOfYear", MonthsOfYear, DataFile.MonthsOfYear, UUU + 50)

        }


        SetLoading(false);

    }

    const SetDayValues = (Name, Arrayy, SelectedValues, addr) => {
        let Newdattee = RefreshValues(Arrayy, addr)
        let NewCheckList = Newdattee;
        let NewCheckList2 = Newdattee;

        console.log("ESEditTeam.NewCheckList2-1", NewCheckList2)
        NewCheckList.map((each, ind) => {
            if (SelectedValues.includes(each.keyy)) {
                NewCheckList2[ind].checked = true;
            } else {
                NewCheckList2[ind].checked = false;
            }
        })
        console.log("ESEditTeam.NewCheckList2-2", NewCheckList2)

        setState(prevState => ({
            ...prevState,
            counter: counter,
            [Name]: NewCheckList2,
        }));
    };

    const SetValueState = (e, name) => {
        setState(prevState => ({ ...prevState, [name]: e }));
    }

    const SaveData = () => {
        //console.log('SaveData', FormData);
        try {

            let Field1 = "";
            let Field2 = "";
            let ESCKey = state.ESCKey;
            let ESCId = state.ESCId;

            if (state.Updatesk !== "") {
                ESCKey = state.Updatesk;
                ESCId = state.ESCId2;
            }

            let SearchWords = state.ShiftName;
            let MissingInfoFlag = false;
            let dataJSON = "";
            let FormdataJSON = {};
            let PhotoService = [];
            let Documents = [];
            let AlarmMessage = 'Please enter ';
            let DBInput = {};
            let DBInput2 = {};
            let Phone = "";
            let Email = "";
            let InputArray1 = [];
            let PersonalDB = [];
            let TotalReturn = [];
            let CompanyId = state.CompanyId;
            let CompanyName = state.CompanyName;
            let FormDD = {};
            let post = "";
            let title = "";
            let EndDate = "";
            let steps = [];
            let TotalPersonal = [];
            let EndDatetime = "";
            let NewTeamId = `${ESCId}::${NewPageId("Team")}`;

            state.StepValueArray.map((each, indexx) => {
                if (each && each.NotificationStepsList && each.NotificationStepsList.length > 0) {
                    TotalReturn = ProcessEachTeam(NewTeamId, each.NotificationStepsList, indexx);

                    if (TotalReturn.InputArray && TotalReturn.InputArray.length > 0) {
                        InputArray1 = [...InputArray1, ...TotalReturn.InputArray]
                    }

                    if (TotalReturn.DB && TotalReturn.DB.length > 0) {
                        PersonalDB = [...PersonalDB, ...TotalReturn.DB]
                    }

                }
            })

            PersonalDB = EleminateDuplicateObjects(PersonalDB, "sk1")

            FormdataJSON = {
                date: currentTime,
                posterId: {
                    pk1: UserData.pk1,
                    sk1: UserData.sk1,
                    field1: UserData.filed1,
                    field2: UserData.filed2,
                    avatar: UserData.avatar,
                    email: UserData.email,
                    mobile: UserData.mobile,
                    phone: UserData.phone,
                },
                StepValueArray: state.StepValueArray,
                PersonalDB: PersonalDB,
                NewTeamId: NewTeamId,
                Days: state.DaysSelected,
                WeekOfMonth: state.WeekOfMonthSelected,
                MonthsOfYear: state.MonthsOfYearSelected,
                StartTime: state.StartTime,
                EndTime: state.EndTime,
                NextDay: state.NextDay,
                Colors: state.Colors,
                SearchWords: SearchWords,
                CompanyId: CompanyId,
                CompanyName: CompanyName,
                id: ESCId,
                key: ESCKey,
                field1: state.ShiftName,
                field2: CompanyName,
                ContractorId: ContractorData.ContractorId,
                pk1: CompanyId,
                sk1: ESCKey,
                gsi1Pk2: "ESC::" + ContractorData.ContractorId,  //?? why do I need this?
                gsi1Sk2: "3000-01-01T23:59:59.000",
                gsi2Pk2: ContractorData.ContractorId,
                gsi2Sk2: ESCKey,
                status: "Active"
            }

            dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))

            DBInput = {
                pk1: CompanyId,
                sk1: ESCKey,
                gsi1Pk2: "ESC::" + ContractorData.ContractorId, //?? why do I need this?
                gsi1Sk2: "3000-01-01T23:59:59.000",
                gsi2Pk2: ContractorData.ContractorId,
                gsi2Sk2: ESCKey,
                dataJSON: dataJSON
            }

            InputArray1.push(removeEmptyStringElements(DBInput));

            console.log('ESCMinSetup.Total', InputArray1)
            console.log('ESCMinSetup.FormdataJSON', FormdataJSON)
            console.log('CalanderValues.ESCMinSetup.FormdataJSON', FormdataJSON)
            // console.log('state.NotificationStepsList', state.NotificationStepsList)


            if (InputArray1.length > 0) {
                BatchLimitPush(InputArray1);
            }

            // } // end of no alerts

        } catch (err) { console.log("SaveData err", err); }
    }

    const BatchLimitPush = (data) => {

        let item_count = 0;
        let current_batch = [];

        for (var i = 0; i < data.length; i++) {
            item_count++
            current_batch.push(data[i])
            if (item_count % 25 === 0) {
                BatchSendData(current_batch);
                current_batch = []
            }
        }

        // Add the last batch if it has records and is not equal to 25
        if (current_batch.length > 0 && current_batch.length !== 25) {
            BatchSendData(current_batch);
        }

        //if (props.CloseDrawer) {
        //    props.CloseDrawer();
        //}

        setState(prevState => ({
            ...prevState,
            DoneDone: true
        }));

    }

    const BatchSendData = (data) => {
        API.graphql(graphqlOperation(createBatch, { input: data }))
            .then((results) => {
                console.log('createBatch Data ', results);
            }).catch(error => console.log('createBatch error ', error));
    }

    const ProcessEachTeam = (NewTeamId, data, indexx) => {
        console.log('ESCMinSetup.ProcessEachTeam', NewTeamId, data);
        try {


            let DBInput1 = {};
            let DBInput2 = {};
            let InputArray = [];
            let CompanyId = state.CompanyId;
            let EndDatetime = "";
            let DB = [];
            let StepJson = {};
            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
                email: UserData.email,
                mobile: UserData.mobile,
                phone: UserData.phone,
            }

            data.map((Step) => {
                // console.log('each.NotificationStepsList', Step)

                Step.StepPersonalList.map(eachstep => {
                    if (eachstep) {

                        if (Step.ScheduleType === "Recurring") {
                            EndDatetime = "3000-01-01T23:59:59.000";
                        } else {
                            EndDatetime = TimeStampEndofDay(Step.EndDate);
                        }

                        DBInput1 = {
                            //find all shift for person
                            pk1: `${eachstep.key}::${CompanyId}::${NewTeamId}`,
                            sk1: `${EndDatetime}-${Step.StepNumber}-${Step.StepTeam}`,
                            //find all personal assigned for this shift
                            gsi1Pk2: NewTeamId,
                            gsi1Sk2: EndDatetime,
                            //contractor
                            gsi2Pk2: ContractorData.ContractorId,
                            gsi2Sk2: Step.key
                        }

                        StepJson = {
                            ...Step,
                            StepPersonalList: eachstep,
                            posterId: posterId,
                            ...DBInput1
                        };

                        DB.push({
                            pk1: eachstep.pk1,
                            sk1: eachstep.sk1
                        })

                        console.log('ESCMinSetup.ProcessEachTeam.StepJson', StepJson);

                        DBInput2 = {
                            ...DBInput1,
                            dataJSON: JSON.stringify(removeEmptyStringElements(StepJson))
                        }

                        InputArray.push(removeEmptyStringElements(DBInput2));
                    }
                })

            });

            let TotalReturn = {
                InputArray: InputArray,
                DB: DB
            }

            return TotalReturn;

        } catch (err) { console.log("SaveData err", err); }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const ClosingDrawer = () => {
        SetDeawerOpen(false);
    }

    const KeyArray = (data, cat) => {
        console.log("KeyArray", data, cat)
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));
    }

    const AddNextStepOrEnd = (data) => {
        try {
            console.log("AddNextStepOrEnd", state.StepNumber, data)
            //    let NotificationStepsList = state.NotificationStepsList;
            //    let StepIndex = NotificationStepsList.findIndex((post) => post.step === data.StepNumber);
            //    let NewStepNumber = state.StepNumber;

            //    if (StepIndex > -1) {
            //      NotificationStepsList[StepIndex] = {
            //        ScheduleType: data.ScheduleType,
            //        StartDate: data.StartDate,
            //        EndDate: data.EndDate,
            //        StepPersonalList: data.StepPersonalList,
            //        StepName: data.StepName,
            //        field1: data.field1,
            //        field2: data.field2,
            //        delay: data.delay,
            //        step: data.StepNumber,
            //        SearchWords: data.SearchWords,
            //        ESCId: data.ESCId
            //      }

            //    } else {

            //      NotificationStepsList.push({
            //        ScheduleType: data.ScheduleType,
            //        StartDate: data.StartDate,
            //        EndDate: data.EndDate,
            //        StepPersonalList: data.StepPersonalList,
            //        StepName: data.StepName,
            //        field1: data.field1,
            //        field2: data.field2,
            //        delay: data.delay,
            //        SearchWords: data.SearchWords,
            //        step: NewStepNumber,
            //        ESCId: data.ESCId
            //      })

            //      NewStepNumber = state.StepNumber + 1;

            //    }
            //    console.log("NotificationStepsList", StepIndex, NewStepNumber, NotificationStepsList);

            //    setState(prevState => ({
            //      ...prevState,
            //      NotificationStepsList: NotificationStepsList,
            //      StepNumber: NewStepNumber
            //    }));
        } catch (err) { }
    };

    const AddNewStep = (delay) => {
        let NotificationStepsList = state.NotificationStepsList;
        let NewStepNumber = state.StepNumber + 1;

        NotificationStepsList.push({
            step: NewStepNumber,
            Teams: [],
            delay: delay,
            ESCId: state.ESCId
        })

        setState(prevState => ({
            ...prevState,
            NotificationStepsList: NotificationStepsList,
            delay: delay,
            StepNumber: NewStepNumber
        }));

    }

    const AddStepData = (teams, index) => {
        console.log("NotificationStepsList", teams, index)
        console.log("NotificationStepsList2", state.NotificationStepsList)
        let NotificationStepsList = state.NotificationStepsList;

        NotificationStepsList[index] = {
            ...NotificationStepsList[index],
            TeamData: teams
        };
        console.log("NotificationStepsList3", NotificationStepsList)
        //{ ...NotificationStepsList[index], Teams: teams }
        setState(prevState => ({
            ...prevState,
            NotificationStepsList: NotificationStepsList
        }));
    }

    const SaveSelection = (data) => {

        let StepValueArray1 = [];

        let Indexx = -1;

        console.log("UrgencyDetails.step.5a - 352 - data", data);
        console.log("UrgencyDetails.step.5 - 353 - SaveSelection", state.StepValueArray);

        if (state.StepValueArray && state.StepValueArray.length > 0) {
            Indexx = state.StepValueArray.findIndex(x => x.StepNumber === data.StepNumber);
            StepValueArray1 = state.StepValueArray;
            //  console.log("UrgencyDetails.SelectStepBox.Update1a", state.StepValueArray);
        }

        //   console.log("UrgencyDetails.SelectStepBox.Update2", StepValueArray1);

        if (Indexx > -1) {

            StepValueArray1[Indexx] = data;

        } else {

            if (data && data.StepNumber && data.StepNumber !== "") {
                StepValueArray1[data.StepNumber] = data;
            } else {
                StepValueArray1.push(data);
            }

        }

       // console.log("UrgencyDetails.step.6 - 377 - SaveSelection", StepValueArray1);

        console.log('ESCMinSetup.Step.Add', StepValueArray1)

        setState(prevState => ({
            ...prevState,
            StepValueArray: StepValueArray1
        }))
    }


    const SelectedValues = (name, item) => {
        console.log("DateRange", name, item)
        setState((prevState) => ({
            ...prevState,
            [name]: item,
        }))
    }

    const SetDelay = (delayback, index) => {
        let delay = state.delay;
        delay[index] = delayback;
        console.log("ESCminSetup.delayback", delayback)
        setState((prevState) => ({
            ...prevState,
            delay: delay
        }))

        if (delayback === "end") {
            console.log("ESCminSetup.Going to save", delay)
            setActiveStep(9);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    }

    //const SaveStep = step => {

    //    console.log("NotificationStepsList[step].delay", state.delay[step])
    //    switch (state.delay[step]) {
    //        case "end":
    //            setActiveStep(9);
    //            break;
    //        case undefined:
    //        case "":
    //            setState(prevState => ({
    //                ...prevState,
    //                AlarmMessage: "Please select Delay before next step",
    //                OpenAlert: true
    //            }));
    //            break;
    //        default:
    //            setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //            break;
    //    }

    //}

    const handleChange = (event, ind, Arrayy, SelectedValues) => {

        console.log("ESCMinSetup.Add.state[Arrayy]", state[Arrayy])
        console.log("ESCMinSetup.Add.event.target.checked", SelectedValues, event.target.checked)

        let NewCheckList = state[Arrayy];
        let KeyArray = [];
        NewCheckList[ind].checked = event.target.checked;

        //console.log(NewCheckList)
        NewCheckList.map(each => {
            if (each.checked) {
                KeyArray.push(each.keyy)
            }
        })

        setState({
            ...state,
            [Arrayy]: NewCheckList,
            [SelectedValues]: KeyArray
        });

    };

    const ChangeColor = index => {

        setState(prevState => ({
            ...prevState,
            index: index,
            DetailComponent: "ColorPicker",
        }));

        SetDeawerOpen(true);
    }

    const SetNewColors = (NewColors) => {

        console.log("NewColors", NewColors)

        setState(prevState => ({
            ...prevState,
            Colors: NewColors
        }));

    }

    const EndTimeCheck = (e) => {
        let Endtimemessage = ""
        let NextDay = false;
        if (e < state.StartTime) {
            Endtimemessage = "Next Day";
            NextDay = true
        }
        setState(prevState => ({
            ...prevState,
            EndTime: e,
            EndTimeMessage: Endtimemessage,
            NextDay: NextDay
        }));
    }

    const StartTimeCheck = (e) => {
        let Endtimemessage = "";
        let NextDay = false;
        if (e > state.EndTime) {
            Endtimemessage = "Next Day";
            NextDay = true;
        }
        setState(prevState => ({
            ...prevState,
            StartTime: e,
            EndTimeMessage: Endtimemessage,
            NextDay: NextDay
        }));
    }

    console.log("ESCMinSetup.state", state)
    console.log('ESCMinSetup.Step.total', state.StepValueArray)
    return props.id && (
        <>
            <Paper
                key={props.id}
            >

                <Stepper key={"sepp" + props.id} activeStep={activeStep} orientation="vertical">

                    <Step key={0}>
                        <StepLabel>Schedule Name</StepLabel>
                        <StepContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Please name the policy that reflects all the selections
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>

                                    <TextField
                                        required={true}
                                        error={state.ErrorMessage}
                                        label="Schedule Name"
                                        name="ShiftName"
                                        value={state.ShiftName || ""}
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => {
                                            SelectedValues("ShiftName", e.target.value);
                                        }}
                                    />

                                </Grid>
                            </Grid>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleNext("ShiftName", true)}
                                        className={classes.button}
                                    >
                                        {'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>

                    <Step key={1}>
                        <StepLabel>Schedule Start & End Times</StepLabel>
                        <StepContent>
                            <Grid container spacing={1}>

                                <Grid
                                    key="5"
                                    item
                                    xs={4}>
                                    <Typography variant="h5" component="h2">
                                        Shift Start Time:
                                    </Typography>
                                </Grid>
                                <Grid
                                    key="6"
                                    item
                                    xs={8}>

                                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                                        <InputLabel id="demo-simple-select-outlined-label">Start Time</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            //id={`select-${Item.key}-${index}`}
                                            defaultValue={state.StartTime}
                                            //error={ErrorMessage}
                                            //required={Item.required}
                                            disabled={state.disabled}
                                            autoWidth={true}
                                            value={state.StartTime || ""}
                                            onChange={e => StartTimeCheck(e.target.value)}
                                            label="Start Time"
                                        >
                                            {Hours.map((o, indd) => (
                                                <MenuItem key={indd} value={o.value}>
                                                    {o.label}
                                                </MenuItem>
                                            ))
                                            }
                                        </Select>
                                    </FormControl >


                                </Grid>
                                <Grid
                                    key="7"
                                    item
                                    xs={4}>

                                    <Typography variant="h5" component="h2">
                                        Shift End Time:
                                    </Typography>
                                    {state.NextDay && (
                                        <Typography color='error'>
                                            {state.EndTimeMessage}
                                        </Typography>
                                    )}

                                </Grid>
                                <Grid
                                    key="8"
                                    item
                                    xs={8}
                                >


                                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                                        <InputLabel id="demo-simple-select-outlined-label">End Time</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            //id={`select-${Item.key}-${index}`}
                                            defaultValue={state.EndTime}
                                            //error={ErrorMessage}
                                            //required={Item.required}
                                            disabled={state.disabled}
                                            autoWidth={true}
                                            value={state.EndTime || ""}
                                            onChange={e => EndTimeCheck(e.target.value)}
                                            //onChange={e => setState((prevState) => ({ ...prevState, EndTime: e.target.value }))}
                                            label="End Time"
                                        >
                                            {Hours.map((o, indd) => (
                                                <MenuItem key={indd} value={o.value}>
                                                    {o.label}
                                                </MenuItem>
                                            ))
                                            }
                                        </Select>
                                    </FormControl >


                                </Grid>
                            </Grid>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleNext("AreaSelected")}
                                        className={classes.button}
                                    >
                                        {'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>


                    <Step key={2}>
                        <StepLabel>Active Days, Weeks, and Months</StepLabel>
                        <StepContent>
                            <Grid container spacing={1}>


                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h2">
                                        Days of Week
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    {state.Dayss.map((each, ind) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={each.checked}
                                                    onChange={(event) => handleChange(event, ind, "Dayss", "DaysSelected")}
                                                    name={each.field1}
                                                    color="primary"
                                                />
                                            }
                                            disabled={state.disabled}
                                            label={each.field1}
                                            key={`grdfrm-${ind}`}
                                        />
                                    ))}
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h2">
                                        Week of the Month
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    {state.WeekOfMonth.map((each, ind) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={each.checked}
                                                    onChange={(event) => handleChange(event, ind, "WeekOfMonth", "WeekOfMonthSelected")}
                                                    name={each.field1}
                                                    color="primary"
                                                />
                                            }
                                            disabled={state.disabled}
                                            label={each.field1}
                                            key={`grdfrmWeekOfMonth-${ind}`}
                                        />
                                    ))}
                                </Grid>

                                <Grid item key="20dehbhddws20" xs={12} >
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h2">
                                        Months of the Year
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    {state.MonthsOfYear.map((each, ind) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={each.checked}
                                                    onChange={(event) => handleChange(event, ind, "MonthsOfYear", "MonthsOfYearSelected")}
                                                    name={each.field1}
                                                    color="primary"
                                                />
                                            }
                                            disabled={state.disabled}
                                            label={each.field1}
                                            key={`grdfrmMonthsOfYear-${ind}`}
                                        />
                                    ))}
                                </Grid>


                            </Grid>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        //disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleNext("UrgencySelected")}
                                        className={classes.button}
                                    >
                                        {'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>

                    <Step key={3}>
                        <StepLabel>Colors</StepLabel>
                        <StepContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Please select Shift colors
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {state.Colors.map((each, ind) => (
                                            <Grid item xs={6}>
                                                <Card >
                                                    <CardContent style={{
                                                        border: `2px solid ${each.color}`
                                                    }}>
                                                        <CardActions>
                                                            <Button onClick={() => ChangeColor(ind)} size="small">{each.name}</Button>
                                                        </CardActions>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        //disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleNext("UrgencySelected")}
                                        className={classes.button}
                                    >
                                        {'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>

                    {WebsiteType !== "Checkin" && StepList.map((each, indd) => (
                        <Step key={indd + 4}>
                            <StepLabel>{`Escalation Step # ${each}`}</StepLabel>
                            <StepContent>
                                <Grid container spacing={1} key={indd + "gridcon"}>
                                    <Grid item xs={12} key={indd + "gridcon2"}>
                                        <Typography>
                                            Select team and time delay between each step before next team is sent notification
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} key={indd + "gridcon3"}>
                                        {state.StepValueArray.length > 0 && (
                                        <ESCPerStep
                                            StepNumber={each}
                                            AllowDelete={state.AllowDelete}
                                            AllowEdit={state.AllowEdit}
                                            key={indd + "keyy"}
                                            SelectedDataReturn={(e) => SaveSelection(e)}
                                            ShiftName={state.ShiftName || ""}
                                            ErrorMessage={false}
                                            gotoStep={step => setActiveStep(step)}
                                            CurrentStep={indd + 4}
                                            handleBack={() => handleBack()}
                                            WebsiteType="SO"
                                            // AllowDelete={true}
                                            //   delay={(e) => SetDelay(e, indd)}
                                            ESCId={state.ESCId}
                                            //  Teams={state.NotificationStepsList[0]}
                                            CompanyId={props.CompanyId}
                                            CompanyName={props.CompanyName}
                                            StepValueArray={state.StepValueArray}
                                        //  delayValue={state.NotificationStepsList[0] && state.NotificationStepsList[0].delay !== "" ? state.NotificationStepsList[0].delay : ""}

                                            />
                                        )}
                                    </Grid>


                                </Grid>
                            </StepContent>
                        </Step>
                    ))
                    }

                    {WebsiteType === "Checkin" ? (
                        <Step key={4}>
                            <StepLabel>Who to Notify</StepLabel>
                            <StepContent>
                                <Grid container spacing={1} key={ "gridcon"}>
                                    
                                    <Grid item xs={12} key={ "gridcon3"}>
                                        <ESCPerStep
                                            StepNumber={1}
                                            AllowDelete={state.AllowDelete}
                                            AllowEdit={state.AllowEdit}
                                            key={ "keyy"}
                                            SelectedDataReturn={(e) => SaveSelection(e)}
                                            ShiftName={state.ShiftName || ""}
                                            ErrorMessage={false}
                                            gotoStep={step => setActiveStep(5)}
                                            CurrentStep={4}
                                            handleBack={() => handleBack()}
                                            WebsiteType="Checkin"
                                            //   delay={(e) => SetDelay(e, indd)}
                                            ESCId={state.ESCId}
                                            //  Teams={state.NotificationStepsList[0]}
                                            CompanyId={props.CompanyId}
                                            CompanyName={props.CompanyName}
                                            StepValueArray={state.StepValueArray}
                                        //  delayValue={state.NotificationStepsList[0] && state.NotificationStepsList[0].delay !== "" ? state.NotificationStepsList[0].delay : ""}

                                        />
                                    </Grid>


                                </Grid>
                            </StepContent>
                        </Step>
                    ) : null }


                    <Step key={8}>
                        <StepLabel>Save data</StepLabel>
                        <StepContent>

                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        //disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}

                                    >
                                        Back
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => SaveData()}
                                        className={classes.button}
                                    >
                                        Submit Data
                                    </Button>


                                </div>
                            </div>
                        </StepContent>
                    </Step>





                </Stepper>
                <Button onClick={handleReset} className={classes.button}>
                    Reset
                </Button>




            </Paper>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}
            {/*    
        <DrawerShow
          LoadComponenet={state.DetailComponent}
          //DetailComponent: "AddSetupSelection" FormData
          //LoadComponenet="ItemAddPage"
        CloseDrawer={() => ClosingDrawer()}
        DrawerTitle="change this"
          propss={{
            CloseDrawerAdd: (e) => ClosingDrawer(e),
            Category: state.Category,
            FormData: state.FormData
          }}
          DrawerStatus={DeawerOpen}
          // CloseDrawer={(e) => SetDeawerOpen(false)}
          OpenDrawer={(e) => SetDeawerOpen(false)}
          anchor="left"
        />
      */}
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                //LoadComponenet={state.DetailComponent}
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle="Select Personnel"
                propss={{
                    CloseDrawer: () => ClosingDrawer(),
                    StepPersonal: (e) => AddNextStepOrEnd(e),
                    StepNumber: state.StepNumber,
                    EditStep: state.EditStep,
                    StepTeam: state.StepTeam[state.EditStep],
                    ESCId: state.ESCId,
                    CloseDrawerAdd: (e) => ClosingDrawer(e),
                    Category: state.Category,
                    FormData: state.FormData,
                    index: state.index,
                    Colors: state.Colors,
                    SelectedDataReturn: (NewColor) => SetNewColors(NewColor)
                }}
                DrawerStatus={DeawerOpen}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />



        </>
    );
};

export default Account;


