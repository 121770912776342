import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Tooltip, Chip, Box, Grid } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DrawerBottomCurved from '../utils/DrawerModule';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import TimelineIcon from '@mui/icons-material/Timeline';

let ShowChip = true;

let Colorss = [
    {
        name: "Pending Manager Approval",
        color: "#f5a623",
        key: "PendingManagerApproval"
    },
    {
        name: "Manager Approved",
        color: "#7ed321",
        key: "ManagerApproved"
    },
    {
        name: "Priority: Emergency",
        color: "#d0021b",
        key: "Emergency"
    },
    {
        name: "Priority: High",
        color: "#bd10e0",
        key: "High"
    },
    {
        name: "Priority: Medium",
        color: "#4a90e2",
        key: "Medium"
    },
    {
        name: "Priority: Low",
        color: "#f8e71c",
        key: "Low"
    },
    {
        name: "Box Color",
        color: "#ffffff",
        key: "BoxColor"
    },
    {
        name: "Manager Rejected",
        color: "#ff0000",
        key: "ManagerRejected"
    }
];

let actioncolor = "";

export default function RecipeReviewCard(props) {
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const UserData = useSelector(state => state.UserData);
    const dispatch = useDispatch();
    const initialState = {
        title: "",
        message: "",
        include: false,
        DrawerTitle: "Service Request Details",
        Progress: 0,
        ProgressList: [],
        label: "this is what",
        UpdateSREvent: "",
        UpdateSREventFlag: false,
        ManagerApprovalStatusColor: "#f5a623",
        Priority: "#4a90e2",
        BoxColor: "#ffffff"
    }
    const [state, setState] = useState(initialState);

    const ClosingDrawer = (e) => {
        dispatch({ type: 'TRIGGER_UPDATE', payload: true });
        SetDeawerOpen(false)
    }

    useEffect(() => {

        let Shwomessage = "";

        if (props && props.SR) {

            if (props.SR.postTEXT) {
                Shwomessage = props.SR.postTEXT.substring(0, 100);
            }

            if (props.SR.ColorSelection) {
                Colorss = props.SR.ColorSelection;
            }

            if (props.SR.EventData && props.SR.EventData.length > 0 && props.SR.EventData[0].Body) {
                Shwomessage = props.SR.EventData[0].Body.substring(0, 100);
            }

            let ShowRead = "#F5F2C9";
            if (props.SR && props.SR.Read) {
                let FindRead = props.SR.Read.findIndex(x => x.sk1 === UserData.key);
                if (FindRead > -1) {
                    ShowRead = "#ffffff";
                }
            }

            let ManagerApprovalStatusColor = "#f5a623";

            //if (props.SR.ManagerApprovalStatus && props.SR.ManagerApprovalStatus === "Approved") {
            //    ManagerApprovalStatus = Colorss.find(x => x.key === "ManagerApproved");
            //}

            let BoxColor = Colorss.find(x => x.key === "BoxColor");
            if (!BoxColor) {
                BoxColor = "#ffffff"
            }

            let Priority = "#4a90e2";
            let Prirotity2 = "";

            //

            if (props.SR.ManagerApprovalStatus) {
                switch (props.SR.ManagerApprovalStatus) {
                    case "Pending":
                        ManagerApprovalStatusColor = Colorss.find(x => x.key === "PendingManagerApproval");
                        break;
                    case "Approved":
                        ManagerApprovalStatusColor = Colorss.find(x => x.key === "ManagerApproved");
                        break;
                    case "Rejected":
                        ManagerApprovalStatusColor = Colorss.find(x => x.key === "ManagerRejected");
                        break;
                    default:
                        ManagerApprovalStatusColor = Colorss.find(x => x.key === "ManagerApproved");
                }
            }

            if (props.SR.Priority) {
                switch (props.SR.Priority) {
                    case "High":
                        Prirotity2 = Colorss.find(x => x.key === "High");
                        break;
                    case "Medium":
                        Prirotity2 = Colorss.find(x => x.key === "Medium");
                        break;
                    case "Low":
                        Prirotity2 = Colorss.find(x => x.key === "Low");
                        break;
                    default:
                        Prirotity2 = Colorss.find(x => x.key === "Medium");
                }
            }

            if (Prirotity2 && Prirotity2 !== "") {
                Priority = Prirotity2.color;
            }

            let currentTime = props.SR.currentTime;
            //.replace("Z", "");
            let NewTime = new Date(currentTime).toLocaleString();

            console.log("SRBox.currentTime", currentTime, NewTime)

            setState(prevState => ({
                ...prevState,
                ManagerApprovalStatusColor: ManagerApprovalStatusColor,
                BoxColor: BoxColor,
                Priority: Priority,
                title: props.SR.title,
                NewTime: NewTime,
                ShowRead: ShowRead,
                Shwomessage: Shwomessage
            }));

        }
    }, [props.SR])

    const SRDetails = () => {
        SetDeawerOpen(true);
    }

    const SelectedValues = (item, name) => {

        setState({
            ...state,
            [name]: item,
        });
        // props.handleInclude(change, props.step.key);
    };
    console.log("SRSummery.props", props)
    console.log("SRSummery.state", state)

    return props.SR && (

        <Grid
            container
            direction="row"
        // justifyContent="space-between"
        >

            {props.SR.Priority && props.SR.Priority !== "" && (
                <Grid>
                    <Tooltip title="Priority">
                        <Chip

                            label={`Priority: ${props.SR.Priority}`}
                            //  onClick={() => SRDetails()}
                            color="primary"
                            //  size="small"
                            style={{ background: state.Priority }}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
            )}

            <Grid>
                <Tooltip title="Work Progress">
                    <Chip
                        label={`Progress: ${props.SR.Progress}%`}
                        icon={<TimelineIcon />}
                        color="primary"
                        //  size="small"
                        variant="outlined"
                        style={{ background: props.SR.Progress > 50 ? "#33ffff" : "#ffff33" }}
                    />
                </Tooltip>
            </Grid>


            {props.SR.ProgressList && props.SR.ProgressList.length > 0 && (
                props.SR.ProgressList.map((eachProgress, indd) => (
                    eachProgress.stepcomplete && (
                        eachProgress.avatar && eachProgress.avatar !== "" ? (
                            <Grid>
                                <Chip
                                    avatar={<Avatar alt={eachProgress.label} src={eachProgress.avatar} />}
                                    label={eachProgress.label}
                                    color="primary"
                                    //   size="small"
                                    variant="outlined"
                                    key={"Chipavtr1" + indd}
                                />
                            </Grid>
                        ) : (
                            <Grid>
                                <Chip
                                    icon={<DoneIcon />}
                                    label={eachProgress.label}
                                    color="primary"
                                    //   size="small"
                                    variant="outlined"
                                    key={"Chipavtr2" + indd}
                                />
                            </Grid>
                        )
                    )
                ))
            )}

            {props.SR.ManagerApprovalNeeded && props.SR.ManagerApprovalStatus && (
                <Grid>
                    <Tooltip title="Manager Approval">
                        <Chip
                            label={`Manager: ${props.SR.ManagerApprovalStatus}`}
                            //  onClick={() => SRDetails()}
                            color="primary"
                            //   size="small"
                            style={{ background: state.ManagerApprovalStatusColor }}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
            )}

            {props.SR.Quote === true ? (
                <Grid>
                    <Tooltip title="Quote Needed">
                        <Chip
                            label="Quote Needed"
                            //  onClick={() => SRDetails()}
                            color="primary"
                            //   size="small"
                            style={{
                                background: "#009933"
                            }}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
            ) : null}

            {props.SR.ManagerApprovedBy && props.SR.ManagerApprovedBy.length > 0 && (
                props.SR.ManagerApprovedBy.map((eachProgress, indd) => {
                    ShowChip = true;
                    switch (eachProgress.Action) {                        
                        case "Approved":  
                            actioncolor = "#009933";
                            break;
                        case "Canceled":
                            actioncolor = "#ff0000";
                            break;
                        case "Rejected":
                            actioncolor = "#ff0000";
                            break;
                        case "Accepted":  
                            actioncolor = "#009933";
                            break;                        
                        default:
                            actioncolor = "#000000";
                    }

                    if (eachProgress.Action && eachProgress.Action.includes("Response:")) {
                        if (UserData.CustomerType !== "Contractor") {
                            ShowChip = false;
                        }
                    }

                    return ShowChip && (
                        eachProgress.avatar && eachProgress.avatar !== "" ? (
                            <Grid>
                                <Chip
                                    avatar={<Avatar alt={eachProgress.label} src={eachProgress.avatar} />}
                                    label={eachProgress.Action || eachProgress.Body}
                                    variant="outlined"
                                    style={{ background: actioncolor, color: "#ffffff" }}
                                    key={"Chipavtr3" + indd}
                                />
                            </Grid>
                        ) : (
                            <Grid>
                                <Chip
                                        label={eachProgress.Action && eachProgress.Action}
                                        style={{ background: actioncolor, color: "#ffffff" }}
                                    variant="outlined"
                                    key={"Chipavtr4" + indd}
                                />
                            </Grid>
                        )

                    )

                })
            )}

            {/*
                props.SR.CompanyStatus === "Accepted" ? (
                <Grid>
                    <Tooltip title="Final: Accepted">
                        <Chip
                            label="Final: Accepted"
                            color="primary"
                            style={{
                                background: "#009933"
                            }}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
                ) : null
            */}

            {/*
            props.SR.CompanyStatus === "Rejected" ? (
                <Grid>
                    <Tooltip title="Final: Rejected">
                        <Chip
                            label="Final: Rejected"
                            // color="primary"
                            style={{
                                background: "#ff0000"
                            }}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
            ) : null
            */}






        </Grid>
    );
}
